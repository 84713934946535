import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingBarComponent } from './booking-bar.component';
import { DatepickerPopupModule } from '@shared/datepicker-popup/datepicker-popup.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CounterModule } from '@shared/counter/counter.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    BookingBarComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    DatepickerPopupModule,
    CounterModule
  ],
  exports: [
    BookingBarComponent
  ],
  providers: [
    {
      provide: 'DT_FORMAT',
      useValue: {
        default: 'D MMMM',
        mobile: 'D MMM'
      }
    }
  ]
})
export class BookingBarModule { }
