<div class="input-container" [ngClass]="{'filters': filters, 'bigger-label': bigger, 'dark-label': dark, 'select-none': selectNone}">
  <div
    class="form-group checkbox-group"
    [formGroup]="group"
    [ngClass]="
    { 'invalid':
      (group?.controls[control]?.errors
        && (group?.controls[control]?.dirty || group?.controls[control]?.touched))
      || apiValidationMsg,
      'disabled': disabled
    }"
  >
    <label class="form-checkbox-label">
      <span class="form-checkbox-text" [innerHTML]="text|newLineBreak|removeWidows"></span>
      <input
        type="checkbox"
        [value]="value"
        [formControlName]="control"
        (change)="emitCheck( group?.controls[control] )"
        #checkboxRef
      >
      <span class="form-checkbox-check"></span>
    </label>
    <p class="validation-msg">
      <span *ngFor="let msg of validationMsg">
        {{ msg|translate }}
      </span>
      <span class="text-right d-block w-100">{{ apiValidationMsg }}</span>
    </p>
  </div>
</div>
