<div class="success-container pt-lg-tera">
  <app-step-header
    header="reservation_success_header"
    description="reservation_success_description"
  ></app-step-header>
  <div class="row">
    <div class="col-12 col-md-6" [class]="isLoggedIn ? 'justify-content-end d-flex' : ''">
      <div class="pb-mega pb-md-giga" *ngIf="isLoggedIn">
        <a [routerLink]="'/:lang/my-reservation/reservation-panel-group'|i18nRoute" class="btn btn-arrow">{{ 'my_reservations'|translate }}</a>
      </div>
      <div class="pb-mega pb-md-giga" *ngIf="!isLoggedIn">
        <a [routerLink]="'/:lang'|i18nRoute" class="btn btn-arrow">{{ 'go_to_home'|translate }}</a>
      </div>
    </div>
  </div>
</div>
