import { Component, OnInit, ViewChild } from '@angular/core';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import { UserService } from '@services/auth/user.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/_models/auth/User';
import { IAllReservations, IReservationSummaryShort } from 'src/app/_subprojects/reservation/_interfaces/IAllReservations';
import { IBookingType } from 'src/app/_subprojects/reservation/_interfaces/IBookingType';
import { ChangeReservationPopupComponent } from 'src/app/_subprojects/reservation/_pages/popups/change-reservation-popup/change-reservation-popup.component';
import { ReservationStayTypesEnum } from '../../../../../../_enums/ReservationStayTypesEnum';
import { Observable } from 'rxjs';
import IFilter from '@interfaces/IFilter';
import { IOffer } from '@interfaces/IOffer';
import IOrder from '@interfaces/IOrder';
import { OfferService } from '@services/offer.service';

@Component({
  selector: 'app-my-reservations',
  templateUrl: './my-reservations.component.html',
  styleUrls: ['./my-reservations.component.scss']
})
export class MyReservationsComponent extends SubscribeHelper implements OnInit {
  reservations: IAllReservations;
  reservationsLength: number;
  reservationStayTypesEnum: typeof ReservationStayTypesEnum;
  user: User;

  @ViewChild('popup') popup: ChangeReservationPopupComponent;

  bookingType: IBookingType[] = [
    {
      id: 8,
      label: 'book_offer_stay',
      extra: null,
      email: 'rezerwacje@shuumhotel.pl',
      default: true,
      requiredFields: ['bookingType', 'email', 'phone']
    }
  ];

  visibleItems = {
    cancelled: 2,
    rest: 2,
    upcoming: 2
  };

  offers$: Observable<IOffer[]>;
  offersLoading: boolean;

  constructor(
    private userService: UserService,
    private offerService: OfferService
  ) {
    super();
  }

  ngOnInit(): void {
    this.reservationStayTypesEnum = ReservationStayTypesEnum;

    this.userService.getReservationHistory()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(res => {
        for ( const prop in res ) {
          if ( res.hasOwnProperty(prop) ) {
            res[prop].forEach(room => {
              room.start = room.start.split('-').reverse().join('.');
              room.end = room.end.split('-').reverse().join('.');
            });
          }
        }

        try {
          this.reservationsLength = Object.keys(res).length;
        } catch (e) {
          this.reservationsLength = 0;
        }
        this.reservations = res;
      }, _ => {
        this.reservationsLength = 0;
      });

    this.user = this.userService.userValue;
    this.userService.user
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe( user => {
        this.user = user;
    });

    this.getOffers();
  }

  getOffers() {
    this.offersLoading = true;

    const filters: IFilter[] = [];
    const orders: IOrder[] = [];

    orders.push({
      field: 'updatedAt',
      type: 'desc'
    });

    this.offers$ = this.offerService.getList(filters, orders, 2)
      .pipe(finalize(() => this.offersLoading = false))
  }

  showMore(key: string) {
    this.visibleItems[key] += 2;
  }

  showPopup( data: IReservationSummaryShort ) {
    this.popup.showPopup(data);
  }

}
