<app-popup-content #popup>
  <div content1>
    <app-password-recovery-content
      header="recover_password"
      description="recover_password_description"
      (formSent)="formSent($event)" 
      [popup]="popup"
      [link]="'/:lang/my-reservation/authorization-group/sign-in-group'|i18nRoute"
    ></app-password-recovery-content>
  </div>
  <div content2>
    <h3 class="pb-mega text-center">{{ 'create_account'|translate }}</h3>
    <a
      (click)="redirectWithBlockedScroll($event, register.getAttribute('href'))"
      [href]="'/:lang/my-reservation/authorization-group/sign-up-group'|i18nRoute"
      #register
      class="btn btn-bordered on-white w-100"
    >
      {{ 'register'|translate }}
    </a>
  </div>
</app-popup-content>
