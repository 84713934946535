<app-popup-content #popup [hideSecondPart]="true">
  <div content1>
    <div class="d-flex justify-content-center" *ngIf="isError === null">
      <app-loader [size]="30" [opacity]="0.7"></app-loader>
    </div>
    <ng-container *ngIf="isError !== null">
      <h3 class="pb-giga text-center">
        <span *ngIf="isError === false">{{ 'activate_account_mail_header_success'|translate }}</span>
        <span *ngIf="isError === true">{{ 'activate_account_mail_header_error'|translate }}</span>
      </h3>
      <p
        class="smaller-text weight-300 pb-giga text-center"
      >
        <span
          [innerHTML]="'activate_account_mail_desc_success'|translate|newLineBreak|removeWidows"
          *ngIf="isError === false"
        ></span>
        <span
          [innerHTML]="'activate_account_mail_desc_error'|translate|newLineBreak|removeWidows"
          *ngIf="isError === true"
        ></span>
      </p>
      <app-back-arrow
        *ngIf="isError === false"
        text="back_to_login"
        [popup]="popup"
        [withBlocked]="true"
        [linkTo]="'/:lang/my-reservation/authorization-group/sign-in-group'|i18nRoute"
      ></app-back-arrow>
      <app-back-arrow
        *ngIf="isError === true"
        text="back_to_home"
        [popup]="popup"
      ></app-back-arrow>
    </ng-container>
  </div>
</app-popup-content>
