import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-text-medium-title-button',
  templateUrl: './text-medium-title-button.component.html',
  styleUrls: ['./text-medium-title-button.component.scss']
})
export class TextMediumTitleButtonComponent implements OnInit {
  @Input() header: string;
  @Input() button: string;
  @Input() linkHref: string;

  constructor() { }

  ngOnInit() {
  }

}
