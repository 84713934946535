import { Pipe, PipeTransform } from '@angular/core';
import ConfigService from '@config/ConfigService';
import * as moment from 'moment';

@Pipe({
  name: 'datex'
})

export class DatexPipe implements PipeTransform {

  constructor() {
    moment.locale(ConfigService.getCurrentLang());
  }

  transform(value: any, format: string = ''): string {
    const momentDate = moment(value);

    if (!momentDate.isValid()) {
      return value;
    }

    return momentDate.format(format);
  }
}
