import { Component, OnInit } from '@angular/core';
import CollageImageEnum from '@enums/CollageImageEnum';
import IImageCollage from '@interfaces/IImageCollage';

@Component({
  selector: 'app-menu-a-la-carte',
  templateUrl: './menu-a-la-carte.component.html',
  styleUrls: ['./menu-a-la-carte.component.scss']
})
export class MenuALaCarteComponent implements OnInit {
  collageEnum: typeof CollageImageEnum;
  imagesCollage: IImageCollage;
  imagesCollage2: IImageCollage;
  imagesCollage3: IImageCollage;

  constructor() { }

  ngOnInit() {
    this.collageEnum = CollageImageEnum;
    this.setImageCollages();
  }

  setImageCollages() {
    const path = '/assets/images/food_and_wine/menu/';

    this.imagesCollage = {
      smallImage: {
        image: path + 'section-1-right.jpg',
        image2x: path + 'section-1-right@2x.jpg'
      },
      bigImage: {
        image: path + 'section-1-left.jpg',
        image2x: path + 'section-1-left@2x.jpg'
      }
    };

    this.imagesCollage2 = {
      smallImage: {
        image: path + 'section-2-left.jpg',
        image2x: path + 'section-2-left@2x.jpg'
      },
      bigImage: {
        image: path + 'section-2-right.jpg',
        image2x: path + 'section-2-right@2x.jpg'
      }
    };

    this.imagesCollage3 = {
      smallImage: {
        image: path + 'section-3-right.jpg',
        image2x: path + 'section-3-right@2x.jpg'
      },
      bigImage: {
        image: path + 'section-3-left.jpg',
        image2x: path + 'section-3-left@2x.jpg'
      }
    };
  }
}
