import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {InputField} from '../../_subprojects/reservation/_helpers/InputField';
import {NgbCalendar, NgbDate, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-datepicker-popup',
  templateUrl: './datepicker-popup.component.html',
  styleUrls: ['./datepicker-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatepickerPopupComponent extends InputField implements OnInit {

  @ViewChild('dp') dp: NgbInputDatepicker;

  startDate: NgbDate | null = null;
  isOpen = false;

  @Input() minDate: NgbDate;
  @Input() hideField = true;
  @Input() showCloseBtn = true;
  @Input() class: string;

  @Output() triggerChange: EventEmitter<NgbDate> = new EventEmitter<NgbDate>();

  constructor(
    private calendar: NgbCalendar
  ) {
    super();
    this.minDate = this.calendar.getToday();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( this.apiValidationMsg?.length > 0  ) {
      this.validationMsg = [];
    }

    super.ngOnChanges(changes);
  }

  ngOnInit(): void {
    this.startDate = this.minDate;
  }

  clear(dp) {
    dp.writeValue(null);
    this.group?.controls[this.control]?.setValue(null);
    this.group?.controls[this.control]?.markAsUntouched();
  }

  onChange(date: NgbDate): void {
    this.triggerChange.emit(date);
  }

  toggle() {
    this.isOpen = !this.isOpen;
    this.dp.toggle();
  }

  onClose() {
    if (this.isOpen) {
      this.isOpen = false;
    }
  }

}
