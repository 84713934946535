<a
  [routerLink]="link"
  [class]="class + (inNav ? ' in-nav' : '')"
  (click)="onClick($event)"
  (mouseenter)="startTearsAnimation()"
  (mouseleave)="endTearsAnimation()"
>
  <div class="tear-line" *ngIf="inNav">
    <span [@tearsAnimation]="tearsAnimationState"></span>
  </div>
  <div class="c-scrolldown" *ngIf="!inNav">
    <div class="c-line"></div>
  </div>
  <ng-content></ng-content>
</a>


