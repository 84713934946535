import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { I18nModule } from '../../../i18n/i18n.module';
import { PopupContentComponent } from '../_shared/popup-content/popup-content.component';

@NgModule({
  declarations: [
    PopupContentComponent
  ],
  imports: [
    CommonModule,
    I18nModule
  ],
  providers: [

  ],
  bootstrap: [],
  exports: [
    PopupContentComponent
  ]
})
export class SharedPopupsUniversalModule { }