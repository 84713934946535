import { Component, OnInit, HostBinding, forwardRef, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { InputField } from '../../../_helpers/InputField';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})


export class InputTextComponent extends InputField implements OnChanges {
  @Input() onDark?: boolean;

  constructor( ) { 
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( this.apiValidationMsg?.length > 0 ) {
      this.validationMsg = [];
    }

    super.ngOnChanges(changes);
  }
}
