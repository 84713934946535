<div class="popup-container popup-close-bg" *ngIf="isOpen" [@fadeAnimation] [@.disabled]="!animation">
  <div class="container popup-close-bg">
    <div class="row popup-close-bg">

      <div
        class="position-relative popup-close-bg"
        [ngClass]="popupParentClasses ? popupParentClasses : 'col-12 col-xl-10 offset-xl-1'"
      >
        <div
          class="popup-content-container"
          [ngClass]="popupContentClasses ? popupContentClasses : ''"
        >
          <button class="close-popup" (click)="closePopup()" *ngIf="fixedCloseBtn">
            <img src="/assets/images/reservations/forms/close-forms.svg" alt="close" />
          </button>

          <div
            class="popup-content"
            [ngClass]="borderRadius ? 'bordered-element' : ''"
            #scrolled
          >
            <button class="close-popup" (click)="closePopup()" *ngIf="!fixedCloseBtn">
              <img src="/assets/images/reservations/forms/close-forms.svg" alt="close" />
            </button>

            <div
              class="row pt-giga pb-giga pl-deci pr-deci pl-sm-zero pr-sm-zero"
              [ngClass]="{ 'bg-light-gray': !colorsReversed, 'justify-content-center': centerContent }"
              *ngIf="!hideFirstPart"
            >
              <div [ngClass]="rwdClasses ? rwdClasses : 'col-12 col-sm-10 col-md-7 col-xxl-6'">
                <ng-content select="[content1]"></ng-content>
              </div>
            </div>
            <div
              class="row justify-content-center pl-deci pr-deci pl-sm-zero pr-sm-zero"
              [ngClass]="(content2ParentClasses ? content2ParentClasses : 'pt-giga pb-giga') + (colorsReversed ? ' bg-light-gray' : ' bg-white')"
              *ngIf="!hideSecondPart"
            >
              <div
                [ngClass]="rwdClasses ? rwdClasses : 'col-12 col-sm-10 col-md-7 col-xxl-6'"
              >
                <ng-content select="[content2]"></ng-content>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

