import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import ConfigService from '@config/ConfigService';
import {UserService} from '@services/auth/user.service';
import {I18nRoutePipe} from '../../../_pipes/i18n-route.pipe';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyProgramScreenGuardGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
    private i18nRoutePipe: I18nRoutePipe
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    const user = this.userService.userValue;

    if (!user?.loyaltyProgram) {
      return true;
    }

    const lang = ConfigService.getCurrentLang();
    const i18nRoute = this.i18nRoutePipe.transform(
      '/:lang/my-reservation/shuumme-panel-group/treatments-massages-group',
      lang
    );
    this.router.navigate([i18nRoute]);

    return false;
  }

}
