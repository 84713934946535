<app-after-before-grey
  width="28%"
  height="335px"
  top="46%"
  right="0"
  additionalClasses="to-top-left"
>
  <div class="container pt-zetta mobile-padding pb-mega">
    <div class="row flex-center">
      <div class="col-12 col-md-10 col-lg-8">
        <h2 class="text-center">{{ 'shuum_me_header'|translate}}</h2>
        <p class="p-responsive small pt-kilo pt-lg-giga" [innerHTML]="'shuum_me_desc'|translate|newLineBreak|removeWidows"></p>
        <div class="text-center mt-kilo mt-lg-giga">
          <a
            class="btn btn-bordered"
            [routerLink]="'/:lang/my-reservation/authorization-group/sign-up-group'|i18nRoute"
          >
          {{ 'create_account'|translate }}
        </a>
        </div>
      </div>
    </div>
  </div>
</app-after-before-grey>
<router-outlet></router-outlet>
