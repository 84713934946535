<div class="menu-wrapper">
  <ng-content select="[header]"></ng-content>
  <div class="menu-container">
    <ul class="items-menu">
      <li *ngFor="let menuElement of menuElements">
        <div class="left">
          <div class="label-wrapper">
            <p class="name dark-bold-small">{{menuElement.name}}</p>
            <div class="tags-wrapper">
              <div *ngFor="let tag of menuElement.tags" class="hover-img-wrapper" #hover>
                <img src="{{tag.icon | apiUrl}}" />
              </div>
              <div class="hover-show-wrapper">
                <span *ngFor="let tag of menuElement.tags" #show>{{tag.name}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="price-wrapper">
          <app-price [price]="{ value: menuElement.price.value, currency: menuElement.price.currency }"></app-price>
        </div>
      </li>
    </ul>
  </div>
  <div *ngIf="showMenuBtn" class="pt-tera text-center">
    <a [href]="restaurantMenu|apiUrl" target="_blank" class="btn btn-bordered">{{ 'see_menu'|translate }}</a>
  </div>
</div>
