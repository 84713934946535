<div class="pt-peta pt-md-zetta pt-lg-200">
  <div
    class="header"
    *ngIf="topHeader"
  >
    <h6 class="the-biggest">{{ topHeader|translate }}</h6>
  </div>
  <div class="row">
    <div class="col-4 col-lg-2 offset-sm-1">
      <div class="row align-items-end h-100">
        <div class="col-12">
          <div class="out-mobile-padding">
            <div class="image-01" *ngIf="smallImage">
              <img
                [defaultImage]="'/assets/images/placeholders/placeholder.png'"
                [useSrcset]="hasSrcSetSupport"
                [lazyLoad]="hasSrcSetSupport ? (smallImage.image + ' 1x, ' + smallImage.image2x + ' 2x') : smallImage.image"
                alt="image 01"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-8 col-sm-6 col-lg-3 order-1 order-lg-2">
      <div class="out-mobile-padding">
        <div class="image-02">
          <img
            [defaultImage]="'/assets/images/placeholders/placeholder318x461.png'"
            [useSrcset]="hasSrcSetSupport"
            [lazyLoad]="hasSrcSetSupport ? (bigImage.image + ' 1x, ' + bigImage.image2x + ' 2x') : bigImage.image"
            alt="image 02"
          />
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-5 order-2 order-lg-1">
      <div class="pt-deca pt-md-mega">
        <app-indent-content
          [header]="header"
        >
          <ng-content></ng-content>
        </app-indent-content>
      </div>
    </div>
  </div>
</div>
