import {Component, OnInit, OnDestroy, ElementRef, ViewChild} from '@angular/core';
import IJobOfferShort from '../../../../_interfaces/IJobOfferShort';
import {CareerService} from '../../../../_services/career.service';
import {environment} from '../../../../../environments/environment';
import { IContent2plus1 } from '@interfaces/IContent2plus1';
import { GlobalStateHelper } from '@helpers/GlobalStateHelper';
import { HttpResponse } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import { ICertificate } from '@interfaces/ICertificate';
import { CertificateService } from '@services/certificate.service';

@Component({
  selector: 'app-career-home',
  templateUrl: './career-home.component.html',
  styleUrls: ['./career-home.component.scss']
})
export class CareerHomeComponent extends SubscribeHelper implements OnInit, OnDestroy {
  public jobOffers: IJobOfferShort[] = [];
  content2plus1: IContent2plus1[] = [];
  careerCertificates: ICertificate[] = [];

  private page: number = 1;

  public responseMax: number = 0;
  public responseCurrent: number = 0;
  public loading: boolean = false;

  constructor(
    private careerService: CareerService,
    private globalStateService: GlobalStateHelper,
    private certificateService: CertificateService,
  ) {
    super();
  }

  ngOnInit() {
    this.globalStateService.setIsBtnSticked(true);
    this.careerCertificates = this.certificateService.getCareerCertificates();

    this.getCareerList();
    this.setContent2plus1();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getCareerList() {
    /* TODO: if ( isPlatformServer(this.platformId) ) { */
    this.careerService.getCareerListExtended([], [], 6, this.page)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((res: HttpResponse<IJobOfferShort[]>) => {
        this.jobOffers = res.body;

        this.responseMax = JSON.parse(res.headers.get('Query-Count'));
        this.responseCurrent += res.body.length;
        this.page += 1;
        this.loading = false;
      }, (err) => {
        console.log('Error!' + err);
      });
  }

  private setContent2plus1() {
    this.content2plus1.push(
      {
        imageLeft: {
          image: '/assets/images/career_page/2plus1/img-1-01.jpg',
          image2x: '/assets/images/career_page/2plus1/img-1-01@2x.jpg',
          alt: 'Image 01 Left'
        },
        imageRight: {
          image: '/assets/images/career_page/2plus1/img-1-02.jpg',
          image2x: '/assets/images/career_page/2plus1/img-1-02@2x.jpg',
          alt: 'Image 01 Right'
        },
        heading: 'career_storytelling_h_01',
        content: 'career_storytelling_c_01'
      },
      {
        imageLeft: {
          image: '/assets/images/career_page/2plus1/img-2-01.jpg',
          image2x: '/assets/images/career_page/2plus1/img-2-01@2x.jpg',
          alt: 'Image 01 Left'
        },
        imageRight: {
          image: '/assets/images/career_page/2plus1/img-2-02.jpg',
          image2x: '/assets/images/career_page/2plus1/img-2-02@2x.jpg',
          alt: 'Image 01 Right'
        },
        heading: 'career_storytelling_h_02',
        content: 'career_storytelling_c_02'
      },
      {
        imageLeft: {
          image: '/assets/images/career_page/2plus1/img-3-01.jpg',
          image2x: '/assets/images/career_page/2plus1/img-3-01@2x.jpg',
          alt: 'Image 01 Left'
        },
        imageRight: {
          image: '/assets/images/career_page/2plus1/img-3-02.jpg',
          image2x: '/assets/images/career_page/2plus1/img-3-02@2x.jpg',
          alt: 'Image 01 Right'
        },
        heading: 'career_storytelling_h_03',
        content: 'career_storytelling_c_03'
      },
      {
        imageLeft: {
          image: '/assets/images/career_page/2plus1/img-4-01.jpg',
          image2x: '/assets/images/career_page/2plus1/img-4-01@2x.jpg',
          alt: 'Image 01 Left'
        },
        imageRight: {
          image: '/assets/images/career_page/2plus1/img-4-02.jpg',
          image2x: '/assets/images/career_page/2plus1/img-4-02@2x.jpg',
          alt: 'Image 01 Right'
        },
        heading: 'career_storytelling_h_04',
        content: 'career_storytelling_c_04'
      },
      {
        imageLeft: {
          image: '/assets/images/career_page/2plus1/img-5-01.jpg',
          image2x: '/assets/images/career_page/2plus1/img-5-01@2x.jpg',
          alt: 'Image 01 Left'
        },
        imageRight: {
          image: '/assets/images/career_page/2plus1/img-5-02.jpg',
          image2x: '/assets/images/career_page/2plus1/img-5-02@2x.jpg',
          alt: 'Image 01 Right'
        },
        heading: 'career_storytelling_h_05',
        content: 'career_storytelling_c_05'
      }
    );
  }

  loadMore(): void {
    this.loading = true;

    this.careerService.getList([], [], 6, this.page)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((list: any) => {
        this.jobOffers.push.apply(this.jobOffers, list);

        this.responseCurrent += list.length;
        this.page += 1;
        this.loading = false;
      });
  }
}
