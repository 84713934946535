import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

/**
 * Serwis odpowiedzialny za emitowanie
 * wartości, kiedy użytkownik jest na
 * danym kroku i próbuje przejść na kolejny
 * (np jest na 2 i próbuje przejść na 3,
 * wtedy uruchamia się walidacja)
 */
export class ValidationEmitterService {
  private firstStepValidationToggle$: Subject<string> = new Subject<''>();
  private secondStepValidationToggle$: Subject<string> = new Subject<''>();
  private thirdStepValidationToggle$: Subject<string> = new Subject<''>();
  private fourthStepValidationToggle$: Subject<string> = new Subject<''>();

  constructor() { }

  getFirstStepValidationToggle() {
    return this.firstStepValidationToggle$;
  }

  setFirstStepValidationToggle(val: string) {
    this.firstStepValidationToggle$.next(val);
  }

  getSecondStepValidationToggle() {
    return this.secondStepValidationToggle$;
  }

  setSecondStepValidationToggle(val: string) {
    this.secondStepValidationToggle$.next(val);
  }

  getFourthStepValidationToggle() {
    return this.fourthStepValidationToggle$;
  }

  setFourthStepValidationToggle(val: string) {
    this.fourthStepValidationToggle$.next(val);
  }

  getThirdStepValidationToggle() {
    return this.thirdStepValidationToggle$;
  }

  setThirdStepValidationToggle(val: string) {
    this.thirdStepValidationToggle$.next(val);
  }
}
