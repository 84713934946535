import {Component, Input, OnInit} from '@angular/core';
import ConfigService from '@config/ConfigService';

@Component({
  selector: 'app-content-images-overlapping-right',
  templateUrl: './content-images-overlapping-right.component.html',
  styleUrls: ['./content-images-overlapping-right.component.scss']
})
export class ContentImagesOverlappingRightComponent implements OnInit {
  public hasSrcSetSupport: boolean;

  @Input() imageFront: string;
  @Input() imageFront2x: string;
  @Input() imageFrontAlt: string;
  @Input() imageFrontMaxWidth?: number;
  @Input() imageBack: string;
  @Input() imageBack2x: string;
  @Input() imageBackAlt: string;
  @Input() imageBackMaxWidth?: number;
  @Input() imageMobile?: string;
  @Input() lazyLoading = true;

  @Input() allImagesWidth: string;
  @Input() widthBack?: string;
  @Input() widthFront?: string;
  @Input() frontBottomSpacing?: string;
  @Input() reversed?: boolean;
  @Input() customMargin?: string;
  @Input() classes?: string;

  constructor() {
    this.hasSrcSetSupport = ConfigService.getHasSrcSetSupport();
  }

  ngOnInit() { }

}
