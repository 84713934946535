import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { GlobalStateHelper } from '@helpers/GlobalStateHelper';
import { PopupContentComponent } from 'src/app/_subprojects/reservation/_shared/popup-content/popup-content.component';
import { InputField } from '../../../_helpers/InputField';
import { UserAuthHelper } from '@helpers/UserAuthHelper';
import { RouteMapHelper } from '@helpers/RouteMapHelper';
import ConfigService from '@config/ConfigService';
import {first, takeUntil} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '@services/auth/user.service';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import GlobalFunctions from '@helpers/GlobalFunctions';
import { I18nRoutePipe } from 'src/app/_pipes/i18n-route.pipe';
import { CurrentRouteHelper } from '@helpers/CurrentRouteHelper';

@Component({
  selector: 'app-log-in-popup',
  templateUrl: './log-in-popup.component.html',
  styleUrls: ['./log-in-popup.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        display: 'block'
      })),
      state('closed', style({
        display: 'none',
        opacity: 0
      })),
      transition('open => closed', [
        animate('0.15s')
      ]),
      transition('closed => open', [
        animate('0s', style({ opacity: 0, display: 'block' })),
        animate('0.15s')
      ]),
    ]),
  ],
})

export class LogInPopupComponent extends SubscribeHelper implements OnInit {
  returnUrl: string = null;

  @ViewChild('popup') popup: PopupContentComponent;

  constructor(
    private globalStateHelper: GlobalStateHelper,
    private route: ActivatedRoute,
    private i18nRoute: I18nRoutePipe,
    private routeMapHelper: RouteMapHelper
  ) {
    super();
  }

  ngOnInit(): void {
    try {
      this.returnUrl = this.route.snapshot.queryParams.returnUrl || null;
      const urlSegment = this.returnUrl.split('/');
    }
    catch (e) {

    }
  }

  onLogin() {
    let linkTo = this.returnUrl || this.i18nRoute.transform('/:lang/my-reservation');

    const urlSegment = linkTo.split('/');
    const fragment = this.route.snapshot.fragment;
    if (
      urlSegment
      && urlSegment[3]
      && this.routeMapHelper.getGroup(urlSegment[3]) === 'my-reservation_authorization-group'
    ) {
      linkTo = this.i18nRoute.transform('/:lang/my-reservation');
    }

    this.popup.closePopup(linkTo, true, fragment);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  redirectWithBlockedScroll($e: MouseEvent, link: string): void {
    $e.preventDefault();
    this.globalStateHelper.showPopupWithoutAnimation = true;
    this.popup.redirectWithoutHiding(link);
  }
}
