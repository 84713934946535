import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PopupContentComponent } from 'src/app/_subprojects/reservation/_shared/popup-content/popup-content.component';
import { GlobalStateHelper } from '@helpers/GlobalStateHelper';
import { InputField } from '../../../_helpers/InputField';
import { UserService } from '@services/auth/user.service';

@Component({
  selector: 'app-password-recovery-popup',
  templateUrl: './password-recovery-popup.component.html',
  styleUrls: ['./password-recovery-popup.component.scss']
})

/**
 * Popup odzyskiwania hasła - po podaniu maila,
 * zostaje wysłany link do resetu hasła
 */
export class PasswordRecoveryPopupComponent implements OnInit {
  @ViewChild('popup') popup: PopupContentComponent;

  constructor( private globalStateHelper: GlobalStateHelper ) { }

  ngOnInit(): void {
  }

  formSent(_: FormGroup): void {
  }

  redirectWithBlockedScroll($e: MouseEvent, link: string): void {
    $e.preventDefault();
    this.globalStateHelper.showPopupWithoutAnimation = true;
    this.popup.redirectWithoutHiding(link);
  }
}
