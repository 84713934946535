import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '@services/auth/user.service';
import {filter, takeUntil} from 'rxjs/operators';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {PopupContentComponent} from '../../../_shared/popup-content/popup-content.component';
import {Router, NavigationEnd} from '@angular/router';

@Component({
  selector: 'app-loyalty-program-popup',
  templateUrl: './loyalty-program-popup.component.html',
  styleUrls: ['./loyalty-program-popup.component.scss']
})
export class LoyaltyProgramPopupComponent extends SubscribeHelper implements OnInit {

  @ViewChild('popupLoyaltyProgram') popupLoyaltyProgram: PopupContentComponent;

  loyaltyProgram = false;
  blogostanSubpage = false;

  private loyaltyProgramStorageKey = 'loyalty-program-modal';

  constructor(
    private userService: UserService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.userService.isLoggedIn().pipe(
      takeUntil(this.componentDestroyed$),
      filter(isLoggedIn => isLoggedIn),
    ).subscribe(_ => {
      const user = this.userService.userValue;

      /* na podstronach zw. z logowaniem/wylogowaniem z blogostanmagazine, tj  /:lang/blogostan/* wylaczamy popup ShuumME, bo pojawia się
      w bezsensowynych momentach np, na ulamek sekundy po zalogowaniu, nastepnie od razu nastepuje przekierowanie na blogostanmagazine.pl,
      lub po wylogowaniu, co nie ma sensu logicznego   */
      const currentUrl = window.location.href;
      const includesBlogostan = currentUrl.includes('blogostan');


      if (!user.loyaltyProgram) {
        this.loyaltyProgram = !user.loyaltyProgram;

        if (JSON.parse(localStorage.getItem(this.loyaltyProgramStorageKey)) !== true && !includesBlogostan) {
          setTimeout(() => {
            this.popupLoyaltyProgram.showPopup();
          }, 2000);
        }
      }
    });
  }

  onCloseModal() {
    localStorage.setItem(this.loyaltyProgramStorageKey, 'true');
  }

  closeModal() {
    this.popupLoyaltyProgram.closePopup();
  }

}
