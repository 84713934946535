<div class="overflow-hidden">
  <div
    class="container mobile-padding overflow-xs-hidden"
    [ngClass]="{ 'gray-box': item?.card }"
  >
    <div class="row">
      <div class="col-xl-10 offset-xl-1 pt-peta pt-md-zetta">
        <a [routerLink]="'/:lang/wellness/relax'|i18nRoute">
          <img src="/assets/images/nav_arrow_dark.svg" alt="back" />
        </a>
      </div>
    </div>

    <div class="pt-peta">
      <app-cards [cardEmitter]="card$" [recommended]="recommendedItems" [price]="lowestPrice" [isTreatment]="true"></app-cards>
    </div>
  </div>

  <app-footer></app-footer>
</div>

