import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {INotification} from '../_interfaces/INotification';
import {delay, map, tap} from 'rxjs/operators';
import IFilter from '@interfaces/IFilter';
import {MainService} from '@services/main.service';
import { INotificationResponse } from '../_interfaces/INotificationResponse';
import GlobalFunctions from '@helpers/GlobalFunctions';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends MainService {
  private readonly apiUrl: string;

  private notificationsSubject: BehaviorSubject<INotification[]> = new BehaviorSubject<INotification[]>([]);
  public notifiactions$: Observable<INotification[]>;
  private notificationsQuantitySubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public notificationsQuantity$: Observable<number>;

  constructor(
    protected http: HttpClient,
  ) {
    super(http);

    this.apiUrl = environment.shuumApiHostShort;

    this.notifiactions$ = this.notificationsSubject.asObservable();
    this.notificationsQuantity$ = this.notificationsQuantitySubject.asObservable();
  }

  getNotifications(filters?: IFilter[], limit: number = 6, page?: number): Observable<INotificationResponse> {
    let params = new HttpParams();
    params = this.setFilters( params, filters );
    params = this.setLimit( params, limit, page );

    return this.http.get<INotificationResponse>(`${this.apiUrl}/users/notifications`, { params });
  }

  updateNotification(id: number, formData: { [key: string]: number }): Observable<any> {
    const fd: FormData = GlobalFunctions.convertToFd(new FormData(), formData);

    return this.http.post<any>(`${this.apiUrl}/users/notification/${id}`, fd);
  }

  setNotifications(notifications: INotification[]) {
    this.notificationsSubject.next(notifications);
  }

  setNotification(notification: INotification) {
    const notifications = this.notificationsSubject.getValue();
    const updatedNotifications = notifications.map(n => n.id === notification.id ? notification : n);

    this.notificationsSubject.next(updatedNotifications);
  }

  setNotificationsQuantity(quantity: number) {
    this.notificationsQuantitySubject.next(quantity);
  }

  reduceNotificationsQuantity() {
    const quantity = this.notificationsQuantitySubject.value;
    this.notificationsQuantitySubject.next(quantity - 1);
  }

}
