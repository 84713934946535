<div
  class="indent-content"
  [ngClass]="{ 'wider': this.wider, 'auto-width': this.autoWidth }"
>
  <div>
    <h2
      *ngIf="header"
      [class]="headerClass"
      [innerHTML]="(header|translate)|newLineBreak"
    ></h2>
    <img
      *ngIf="headerImage"
      [src]="headerImage"
    />
  </div>
  <div class="content" [class]="contentClass">
    <ng-content></ng-content>
  </div>
</div>
