import { Component, OnInit, Input } from '@angular/core';
import {IImage} from '@interfaces/IImage';
import ConfigService from '@config/ConfigService';

@Component({
  selector: 'app-triple-images',
  templateUrl: './triple-images.component.html',
  styleUrls: ['./triple-images.component.scss']
})
export class TripleImagesComponent implements OnInit {
  public hasSrcSetSupport: boolean;

  @Input() imageLeft: IImage;
  @Input() imageRight: IImage;
  @Input() imageCenter: IImage;

  constructor() {
    this.hasSrcSetSupport = ConfigService.getHasSrcSetSupport();
  }

  ngOnInit() { }

}
