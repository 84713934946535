<div [className]="(theme === 'reversed' ? 'justify-content-end flex-row-reverse reversed' : null) + ' row align-items-center'">
  <div [className]="(theme === 'reversed' ? 'offset-md-1' : 'offset-md-0 offset-lg-1 offset-xl-1 offset-xxl-2') + ' offset-2 col-8 col-md-5 col-lg-3 col-xl-3'" >
    <img
      [defaultImage]="'/assets/images/placeholders/placeholder318x461.png'"
      [lazyLoad]="hasSrcSetSupport ? (image + ' 1x, ' + image2x + ' 2x') : image"
      [useSrcset]="hasSrcSetSupport"
      [alt]="imageAlt"
    />
  </div>
  <div [className]="(theme === 'reversed' ? 'offset-lg-2 offset-xl-2 offset-xxl-3' : 'offset-md-1') + ' content col-md-6 col-lg-5 col-xxl-3 pt-giga pt-md-zero'">
    <ng-content></ng-content>
  </div>
</div>
