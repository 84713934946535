import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shuumme',
  templateUrl: './shuumme.component.html',
  styleUrls: ['./shuumme.component.scss']
})
export class ShuummeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
