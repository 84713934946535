import {Component, Inject, OnInit, Optional, PLATFORM_ID} from '@angular/core';
import {LayoutService} from '@services/layout.service';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';
import {isPlatformServer} from '@angular/common';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit {
  constructor(
    private layoutService: LayoutService,
    @Optional() @Inject(RESPONSE) private response: Response,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
  }

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      this.response.statusCode = 404;
    }
  }

}
