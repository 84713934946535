import {Inject, Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {MetaTag} from '../_models/MetaTag';
import {environment} from '../../environments/environment';
import {DOCUMENT} from '@angular/common';
import {AlternateURL} from '../_models/AlternateURL';
import ITrans from '@interfaces/ITrans';
import ConfigService from '@config/ConfigService';
import * as lastIndexOf from 'lodash/lastIndexOf';
import * as forEach from 'lodash/forEach';
import {I18nRoutePipe} from '../_pipes/i18n-route.pipe';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  private titlePostfix = 'Shuum | Boutique Wellness Hotel i Restauracja Kołobrzeg';
  private urlMeta = 'og:url';
  private titleMeta = 'og:title';
  private descriptionOgMeta = 'og:description';
  private descriptionMeta = 'description';
  private keywordsMeta = 'keywords';
  private imageMeta = 'og:image';
  private secureImageMeta = 'og:image:secure_url';
  private twitterTitleMeta = 'twitter:text:title';
  private twitterImageMeta = 'twitter:image';
  private lastTitle: string = '';

  private defaultShareImg = '/assets/share/shuum_share_static.jpg';

  constructor(
    private titleService: Title,
    private metaService: Meta,
    @Inject(DOCUMENT) private document
  ) {
    this.setSocialImage('', true);
  }

  public setTitle( title: string ): void {
    let t = title ? title + ' - ' + this.titlePostfix : this.titlePostfix;
    t = t.replace(/<[^>]*>?/gm, '');

    this.titleService.setTitle(t + '');

    const tags = [
      new MetaTag(this.titleMeta, t, true),
      new MetaTag(this.twitterTitleMeta, t, false),
    ];

    this.lastTitle = title;

    this.setTags(tags);
  }

  public appendTitle( title: string ): void {
    if ( title ) {
      let t = title;

      if (this.lastTitle) {
        t = t + ' - ' + this.lastTitle;
      }

      this.setTitle(t);
    }
  }

  public setDescription( description: string ) {
    const tags = [
      new MetaTag(this.descriptionOgMeta, description, true),
      new MetaTag(this.descriptionMeta, description, false),
    ];

    this.setTags(tags);
  }

  public setKeywords( keywords: string ) {
    const tags = [
      new MetaTag(this.keywordsMeta, keywords, false)
    ];

    this.setTags(tags);
  }

  public setSocialMediaTags(url: string, title: string, description: string, image: string ): void {

    if ( title !== null ) {
      this.setTitle(title);
    }

    if ( description !== null ) {
      this.setDescription(description);
    }

    if ( image !== null ) {
      this.setSocialImage(image);
    }

    if ( url !== null ) {
      this.setUrl(url);
    }
  }

  public setSocialImage( image: string, local = false ) {
    const host = local ? environment.domain : environment.imageHost;
    const imageUrl = image ? host + image : environment.domain + this.defaultShareImg;

    const tags = [
      new MetaTag(this.imageMeta, imageUrl, true),
      new MetaTag(this.secureImageMeta, imageUrl, true),
      new MetaTag(this.twitterImageMeta, imageUrl, false)
    ];

    this.setTags(tags);
  }

  public setUrl( url: string ) {
    url = environment.domain + url;

    const tags = [
      new MetaTag(this.urlMeta, url, true),
    ];

    this.setTags(tags);
  }

  public setAlternateURL( alternate: AlternateURL ): void {
    const elementId = 'alternate-' + alternate.hrefLang;
    let link: HTMLLinkElement = this.document.getElementById(elementId);

    if ( !link ) {
      link = this.document.createElement('link');
    }

    link.setAttribute('id', elementId);
    link.setAttribute('rel', 'alternate');
    link.setAttribute('hreflang', alternate.hrefLang);
    link.setAttribute('href', alternate.href);

    this.document.head.appendChild( link );
  }

  private removeAlternateUrls( trans: ITrans ) {
    const langs = environment.languages;

    langs.forEach( lang => {
      if ( typeof trans[lang] === 'undefined' ) {
        const elementId = 'alternate-' + lang;
        const elem = this.document.getElementById(elementId);

        if ( elem ) {
          try {
            elem.remove();
          }
          catch (e) {
            elem.parentNode.removeChild(elem);
          }
        }
      }
    });
  }

  public setAlternateURLs( alternates: AlternateURL[] ): void {
    if ( alternates.length ) {
      alternates.forEach( alternate => {
        this.setAlternateURL( alternate );
      });
    }
  }

  public setAlternateURLWithITrans( trans: ITrans, currentSlug: string, i18nRoute: I18nRoutePipe ) {
    const currentGroupPath = Object.assign([], ConfigService.getCurrentRouteGroupPath());

    if ( trans ) {
      this.removeAlternateUrls( trans );

      if (currentGroupPath.length) {
        const lastIndexOfSlugOccurance = lastIndexOf(currentGroupPath, currentSlug);
        const toReplaceString = '#--to-replace--#';

        if (lastIndexOfSlugOccurance >= 0) {
          currentGroupPath[lastIndexOfSlugOccurance] = toReplaceString;
        }

        const currentGroupPathAsString = currentGroupPath.join('/');
        const alternates: AlternateURL[] = [];

        forEach(trans, (val, key) => {
          alternates.push({
            href: i18nRoute.transform(currentGroupPathAsString.replace(toReplaceString, val), key),
            hrefLang: key
          });
        });

        this.setAlternateURLs(alternates);
      }
    }
  }

  public setRobots() {
    const tags = [
      new MetaTag('robots', 'noindex, nofollow', false),
    ];

    this.setTags(tags);
  }

  private setTags(tags: MetaTag[]): void {
    tags.forEach(siteTag => {
      const tag = siteTag.isFacebook
        ? this.metaService.getTag(`property='${siteTag.name}'`)
        : this.metaService.getTag(`name='${siteTag.name}'`);

      if (siteTag.isFacebook) {
        this.metaService.updateTag({ property: siteTag.name, content: siteTag.value });
      }
      else {
        this.metaService.updateTag({ name: siteTag.name, content: siteTag.value });
      }
    });
  }
}
