import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {environment} from '../../../environments/environment';
import {LangGuard} from '../../_guards/LangGuard';
import {RestaurantALaCarteComponent} from '@pages/food/_pages/restaurant-a-la-carte/restaurant-a-la-carte.component';
import {MenuALaCarteComponent} from '@pages/food/_pages/menu-a-la-carte/menu-a-la-carte.component';
import {NorthRestaurantComponent} from '@pages/food/_pages/north-restaurant/north-restaurant.component';
import {LobbyBarComponent} from '@pages/food/_pages/lobby-bar/lobby-bar.component';
import {WineEveningsComponent} from '@pages/food/_pages/wine-evenings/wine-evenings.component';
import { DelicatessenComponent } from './_pages/delicatessen/delicatessen.component';

const defaultRoute = '/' + environment.defaultLanguage;

/**
 * !!! DONT FORGET TO UPDATE RouteMapHelper
 */
const routes: Routes = [
  {
    path: 'a-la-carte-restaurant',
    component: RestaurantALaCarteComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['en', 'de'],
      group: 'a-la-carte',
      meta: {
        title: 'restaurant_a_la_carte',
        description: 'restaurant_a_la_carte_meta_description',
        keywords: 'restaurant_a_la_carte_meta_keywords'
      }
    }
  }, {
    path: 'restauracja-a-la-carte',
    component: RestaurantALaCarteComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['pl'],
      group: 'a-la-carte',
      meta: {
        title: 'restaurant_a_la_carte',
        description: 'restaurant_a_la_carte_meta_description',
        keywords: 'restaurant_a_la_carte_meta_keywords'
      }
    }
  },

  {
    path: 'a-la-carte-menu',
    component: MenuALaCarteComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['en', 'de', 'pl'],
      group: 'a-la-carte-menu-child',
      meta: {
        title: 'food_menu_meta_title',
        description: 'a_la_carte_menu_meta_description',
        keywords: 'a_la_carte_menu_meta_keywords'
      }
    },
  },

  {
    path: 'north-restaurant',
    component: NorthRestaurantComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['en'],
      group: 'north-restaurant-child',
      meta: {
        title: 'north_restaurant',
        description: 'north_restaurant_meta_description',
        keywords: 'north_restaurant_meta_keywords'
      }
    },
  }, {
    path: 'restauracja-polnocna',
    component: NorthRestaurantComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['pl'],
      group: 'north-restaurant-child',
      meta: {
        title: 'north_restaurant',
        description: 'north_restaurant_meta_description',
        keywords: 'north_restaurant_meta_keywords'
      }
    },
  }, {
    path: 'nord-restaurant',
    component: NorthRestaurantComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['de'],
      group: 'north-restaurant-child',
      meta: {
        title: 'north_restaurant',
        description: 'north_restaurant_meta_description',
        keywords: 'north_restaurant_meta_keywords'
      }
    },
  },

  {
    path: 'lobby-bar',
    component: LobbyBarComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['de', 'pl', 'en'],
      group: 'lobby-bar-child',
      meta: {
        title: 'lobby_bar',
        description: 'lobby_meta_description',
        keywords: 'lobby_meta_keywords'
      }
    },
  },

  // {
  //   path: 'wine-evenings',
  //   component: WineEveningsComponent,
  //   canActivate: [LangGuard],
  //   data: {
  //     forLanguages: ['en'],
  //     group: 'wine-evenings-child',
  //     meta: {
  //       title: 'wine_evenings',
  //       description: 'wine_evenings_meta_description',
  //       keywords: 'wine_evenings_meta_keywords'
  //     }
  //   },
  // }, {
  //   path: 'wieczory-z-winem',
  //   component: WineEveningsComponent,
  //   canActivate: [LangGuard],
  //   data: {
  //     forLanguages: ['pl'],
  //     group: 'wine-evenings-child',
  //     meta: {
  //       title: 'wine_evenings',
  //       description: 'wine_evenings_meta_description',
  //       keywords: 'wine_evenings_meta_keywords'
  //     }
  //   },
  // }, {
  //   path: 'weinabende',
  //   component: WineEveningsComponent,
  //   canActivate: [LangGuard],
  //   data: {
  //     forLanguages: ['de'],
  //     group: 'wine-evenings-child',
  //     meta: {
  //       title: 'wine_evenings',
  //       description: 'wine_evenings_meta_description',
  //       keywords: 'wine_evenings_meta_keywords'
  //     }
  //   },
  // },

  {
    path: 'delikatesy',
    component: DelicatessenComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['pl'],
      group: 'delicatessen-group'
    }
  }, {
    path: 'delicatessen',
    component: DelicatessenComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['en'],
      group: 'delicatessen-group'
    }
  }, {
    path: 'delikatessen',
    component: DelicatessenComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['de'],
      group: 'delicatessen-group'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class FoodRoutingModule { }

export {
  routes
};
