<app-section-introduction [text]="'loyalty_program_desc'"></app-section-introduction>
<div class="container mobile-padding">
  <div class="row">
    <div class="col-xl-10 offset-xl-1">
      <app-section-header header="restore_vital_forces" subHeader="restore_vital_forces_desc"></app-section-header>
      <div class="restore-vital-forces-slider pt-giga pb-giga">
        <app-item-slider [itemTpl]="itemTpl" [items]="activities" [itemsPerPage]="1" [constantItemsPerPage]="true"
                         [loop]="true" [columnClass]="'col-12 mobile-slider'" [arrowsHorizontalPosition]="6" [itemPadding]="'55px'">
        </app-item-slider>

        <ng-template let-item #itemTpl>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-5 col-xl-6 col-xxl-6">
              <app-content-images-overlapping-right
                [imageFront]="item.images.smallImage.image"
                [imageFront2x]="item.images.smallImage.image2x"
                imageFrontAlt="Double Image Right"
                imageFrontMaxWidth="204"
                [imageBack]="item.images.bigImage.image"
                [imageBack2x]="item.images.bigImage.image2x"
                imageBackAlt="Double Image Left"
                frontBottomSpacing="25%"
                [imageMobile]="item.images.bigImage.rwd[0].image"
                reversed="true"
                imageBackMaxWidth="318"
              ></app-content-images-overlapping-right>
            </div>

            <div class="col-md-6 col-xl-5 col-xxl-5 offset-lg-1 pt-giga pt-md-zero mobile-padding">
              <app-indent-content [header]="item.header" headerClass="h3" contentClass="ml-md-4 pt-3">
                <p class="tiny" [innerHTML]="item.description|translate|newLineBreak|removeWidows"></p>
                <div *ngIf="item.note" class="pt-mega">
                  <p
                    class="tiny text-light-gray"
                    [innerHTML]="item.note|translate|newLineBreak|removeWidows"
                  ></p>
                </div>
              </app-indent-content>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<div class="bg-white pt-kilo pb-kilo">
  <div class="container mobile-padding">
    <div class="row">
      <div class="col-xl-10 offset-xl-1">
        <app-section-header header="activity" subHeader="activity_desc"></app-section-header>
        <app-event-scheduler [eventsData]="events$ | async" (onEventClick)="setElementInfo($event)"></app-event-scheduler>
      </div>
    </div>
  </div>
</div>


<app-contact-form [bookingType]="bookingType" [bookingNames]="fitnessNames"></app-contact-form>
