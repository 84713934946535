import { Component, OnInit, OnDestroy } from '@angular/core';
import {ReservationDataService} from '@services/reservations/reservation-data.service';
import { UserService } from '@services/auth/user.service';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent extends SubscribeHelper implements OnInit, OnDestroy {
  isLoggedIn: boolean;

  constructor(
    private reservationData: ReservationDataService,
    private userService: UserService
  ) { super(); }

  ngOnInit(): void {
    this.reservationData.clear();
    this.userService.isLoggedIn$
      .pipe( takeUntil(this.componentDestroyed$) )
      .subscribe(res => {
        this.isLoggedIn = res;
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
