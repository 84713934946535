import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import IPrice from '@interfaces/IPrice';
import TextOfferEnum from '@enums/TextOfferEnum';
import ApiConfigurationEndpointEnum from '@enums/ApiConfigurationEndpointEnum';
import {takeUntil} from 'rxjs/operators';
import {catchError} from 'rxjs/internal/operators';
import {IConfiguration} from '@interfaces/IConfiguration';
import {ConfigurationService} from '@services/configuration.service';
import {SubscribeHelper} from '@helpers/SubscribeHelper';

@Component({
  selector: 'app-text-offer',
  templateUrl: './text-offer.component.html',
  styleUrls: ['./text-offer.component.scss']
})
export class TextOfferComponent extends SubscribeHelper implements OnInit, OnDestroy {
  @Input() image: string;
  @Input() imageAlt: string;
  @Input() header: string;
  @Input() isWellness?: boolean;
  @Input() isSingledOut?: boolean;
  @Input() isBestseller?: boolean;
  @Input() isNew?: boolean;
  @Input() price?: IPrice;
  @Input() type?: TextOfferEnum;
  @Input() callendar?: string;

  @Input() link?: string;
  @Input() itemId?: number;
  @Input() onLinkClick?: (id: number) => void;

  calendariumFile: IConfiguration;

  constructor(
    private apiConfiguration: ConfigurationService
  ) {
    super();
  }

  ngOnInit() {
    if (typeof this.isWellness !== 'undefined') {
      if (this.isWellness !== false) {
        this.isWellness = true;
      }
    }

    if (typeof this.isSingledOut !== 'undefined') {
      if (this.isSingledOut !== false) {
        this.isSingledOut = true;
      }
    }

    if (this.type === 1) {
      this.getCalendariumFileUrl();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getCalendariumFileUrl(): void {
    this.apiConfiguration
      .getConfiguration(ApiConfigurationEndpointEnum.EventCalendar)
      .pipe(
        takeUntil(this.componentDestroyed$),
        catchError(err => {
          this.calendariumFile = {id: null, value: '', iso: '', name: ''};
          return err;
        })
      )
      .subscribe((res: IConfiguration) => {
        this.calendariumFile = res;
      });
  }

}
