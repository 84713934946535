import {Component, OnInit, ViewChild, OnDestroy, Inject, PLATFORM_ID} from '@angular/core';
import { PopupContentComponent } from '../../../_shared/popup-content/popup-content.component';
import { UserService } from '@services/auth/user.service';
import { ActivatedRoute } from '@angular/router';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import { takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import {LayoutService} from '@services/layout.service';

@Component({
  selector: 'app-activate-account-mail-popup',
  templateUrl: './activate-account-mail-popup.component.html',
  styleUrls: ['./activate-account-mail-popup.component.scss']
})

/**
 * Aktywacja konta po rejestracji
 * Wejście na komponent następuje po kliknięciu
 * w link aktywacyjny przesłany w mailu
 */
export class ActivateAccountMailPopupComponent extends SubscribeHelper implements OnInit, OnDestroy {
  isError: boolean = null;

  @ViewChild('popup') popup: PopupContentComponent;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private layoutService: LayoutService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    super();
  }

  ngOnInit(): void {
    const uuid = this.route.snapshot.queryParams.token;
    if ( !uuid ) {
      this.isError = true;
    } else {
      if ( isPlatformBrowser(this.platformId) ) {
        this.userService.checkUUID(uuid)
          .pipe(
            takeUntil(this.componentDestroyed$)
          )
          .subscribe(_ => {
            this.userService.activation(uuid)
              .pipe(takeUntil(this.componentDestroyed$))
              .subscribe(_ => {
                this.isError = false;
              }, _ => {
                this.isError = true;
              });

            // this.isError = false;
          }, _ => {
            this.isError = true;
          });
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
