import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from '@services/layout.service';
import {NgbCalendar, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {I18nRoutePipe} from '../../_pipes/i18n-route.pipe';
import {ReservationDataService} from '@services/reservations/reservation-data.service';
import * as moment from 'moment';
import {takeUntil} from 'rxjs/operators';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import ReservationConfig from '../../_subprojects/reservation-form/_config/ReservationConfig';
import ConfigService from '@config/ConfigService';

@Component({
  selector: 'app-booking-bar',
  templateUrl: './booking-bar.component.html',
  styleUrls: ['./booking-bar.component.scss'],
})
export class BookingBarComponent extends SubscribeHelper implements OnInit, AfterViewInit, OnDestroy {

  isScrolled: boolean;
  bookingForm: FormGroup;
  toDateStart: NgbDate | null = null;

  numberOfAdults = 2;
  numberOfChildren = 0;

  limitations = ReservationConfig.getReservationLimits();

  maxAdultsValue = this.limitations.maxAdultsValue;
  minAdultsValue = this.limitations.minAdultsValue;
  maxChildrenValue = this.limitations.maxChildrenValue;
  minChildrenValue = this.limitations.minChildrenValue;

  isReservation: boolean;

  isMobile = window.innerWidth < 992;

  constructor(
    private layoutService: LayoutService,
    private calendar: NgbCalendar,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private reservationData: ReservationDataService,
    private router: Router,
    private i18nRoute: I18nRoutePipe
  ) {
    super();

    this.bookingForm = this.formBuilder.group({
      fromDate: this.calendar.getToday(),
      toDate: null
    });
  }

  ngOnInit(): void {
    this.bookingForm.valueChanges.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(data => {
      const fromDate = moment(data.fromDate);
      const toDate = moment(data.toDate);
      const formDateMoment = moment(data.fromDate).add(1, 'days');
      const tomorrow = {
        day: formDateMoment.date(),
        month: formDateMoment.month(),
        year: formDateMoment.year()
      };

      if (fromDate.isSameOrAfter(toDate)) {
        this.bookingForm.get('toDate').setValue(tomorrow);
      }
    });

    this.layoutService.getIsReservationRoute().pipe(takeUntil(this.componentDestroyed$)).subscribe(val => {
      const currentRouteGroup = ConfigService.getCurrentRouteGroupPath();

      if ( currentRouteGroup.join('/').startsWith(':lang/reservation') || currentRouteGroup.join('/').startsWith(':lang/blogostan') ) {
        this.isReservation = true;
      } else {
        this.isReservation = false;
      }
    });
  }

  ngAfterViewInit() {
    const date = new Date();
    const formDateMoment = moment(date).add(1, 'days');
    const ngbDate = new NgbDate(
      formDateMoment.year(),
      formDateMoment.month() + 1,
      formDateMoment.date()
    );
    this.bookingForm.get('toDate').setValue(ngbDate);
    this.toDateStart = this.calendar.getToday();
    this.cdr.detectChanges();
  }

  @HostListener('window:scroll')
  onScroll() {
    if (window.scrollY > 0) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobile = window.innerWidth < 992;
  }

  onSubmit() {
    const fromDate = this.bookingForm.get('fromDate').value;
    const toDate = this.bookingForm.get('toDate').value;

    if ( fromDate && toDate ) {
      this.reservationData.setDate({
        from: fromDate,
        to: toDate
      }, false);
    }

    if ( this.numberOfAdults ) {
      this.reservationData.setRooms([{
        adults: this.numberOfAdults,
        children: this.numberOfChildren,
        childrenAges: []
      }]);
    }

    this.router.navigate([ this.i18nRoute.transform('/:lang/reservation') ]);
  }

  changeDate(date: NgbDate) {
    const formDateMoment = moment(date).add(1, 'days');

    this.toDateStart = new NgbDate(
      formDateMoment.year(),
      formDateMoment.month(),
      formDateMoment.date()
    );
  }

  /**
   * przypisuje ilosc doroslych (type: 0) lub dzieci (type: 1)
   *
   * @param value - ilosc osob
   * @param type - dorosli 0, dzieci 1
   */
  setNumberOf( value: number, type: number ) {
    if ( type === 1 ) {
      this.numberOfChildren = value;

      const maxAdults = this.limitations.maxAdultsValue - value;
      this.maxAdultsValue = maxAdults >= this.limitations.minAdultsValue ? maxAdults : this.limitations.minAdultsValue;

      if ( this.numberOfChildren > 0 && this.numberOfChildren < this.limitations.maxChildrenValue ) {
        this.maxAdultsValue += 1;
      }
    }
    else {
      this.numberOfAdults = value;

      // +1 musi byc, bo 1 dorosly jest wymagany i maxChildren byl od razu 2
      const maxChildren = this.limitations.maxChildrenValue - value + 1;
      this.maxChildrenValue = maxChildren >= this.limitations.minChildrenValue ? maxChildren : this.limitations.minChildrenValue;
    }
  }
  
  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
