import { Component, OnInit, HostListener, Renderer2, PLATFORM_ID, Inject, Input, ViewChild, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { GlobalStateHelper } from '@helpers/GlobalStateHelper';
import { Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { fadeAnimation } from '@helpers/animations';

@Component({
  selector: 'app-popup-content',
  templateUrl: './popup-content.component.html',
  styleUrls: ['./popup-content.component.scss'],
  animations: [ fadeAnimation ]
})
export class PopupContentComponent implements OnInit, OnDestroy {
  @Input() linkAfterClose: string;
  @Input() rwdClasses: string;
  @Input() popupParentClasses: string;
  @Input() popupContentClasses: string;
  @Input() content2ParentClasses: string;
  @Input() hideFirstPart?: boolean;
  @Input() hideSecondPart?: boolean;
  @Input() scrollToTop?: boolean;
  @Input() borderRadius?: boolean;
  @Input() fixedCloseBtn?: boolean;
  @Input() colorsReversed?: boolean;
  @Input() centerContent = true;

  /**
   * inPlace - if popup is not a separate page,
   * do not redirect anywhere after close,
   * do not show after init
   */
  @Input() inPlace?: boolean = false;

  @ViewChild('ref2') ref2: ElementRef;
  @ViewChild('scrolled') scrolled: ElementRef;

  @Output() closeOutput?: EventEmitter<null> = new EventEmitter();

  isOpen = false;
  animation = true;

  private scrollTopPosition: number = 0;

  constructor(
    private renderer: Renderer2,
    private globalStateHelper: GlobalStateHelper,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit(): void {
    if( !this.inPlace ) {
      this.showPopup();
    }
  }

  ngOnDestroy() {
    if ( isPlatformBrowser(this.platformId) ) {
      this.globalStateHelper.setLockMenu(false);
      this.renderer.removeClass(this.document.body, 'noscroll');
    }
  }

  closePopup(linkTo?: string, scrollToTop?: boolean, fragment?: string) {
    this.isOpen = false;
    this.removeScrollLock();

    if (scrollToTop) {
      window.scrollTo(0, 0);
    }

    if (this.inPlace && this.closeOutput) {
      this.closeOutput.emit();
    }

    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        if ( !this.inPlace ) {
          let url = '';

          if ( !this.linkAfterClose && !linkTo ) {
            let url_temp = this.router.url.split('/');
            url_temp.pop();
            url = url_temp.join('/');
          } else if ( this.linkAfterClose ) {
            url = this.linkAfterClose;
          } else if ( linkTo ) {
            url = linkTo;
          }

          if (this.closeOutput) {
            this.closeOutput.emit();
          }

          this.router.navigate([url], { fragment } );
        }
      }, 150);
    }
  }

  /**
   * Close popup without the hide animation - can be used
   * for switching between popups
   * @param link link to which it will redirect to TODO
   */
  redirectWithoutHiding(link) {
    this.removeScrollLock();

    this.router.navigate([link]);
  }

  /**
   * Showing popup - if variable showPopupWithoutAnimation
   * is set to true, show popup with no animation. No animation
   * is used for switching between popups
   */
  showPopup() {
    if ( this.scrollToTop ) {
      window.scrollTo(0, 0);
    }

    this.lockScroll();

    if ( this.globalStateHelper.showPopupWithoutAnimation === true ) {
      this.animation = false;
      this.isOpen = true;
      this.globalStateHelper.showPopupWithoutAnimation = false;

      if(isPlatformBrowser(this.platformId)) {
        setTimeout(() => {
          this.animation = true;
        }, 0);
      }
    } else {
      this.isOpen = true;
    }
  }

  removeScrollLock() {
    if ( isPlatformBrowser(this.platformId) ) {
      this.globalStateHelper.setLockMenu(false);

      this.renderer.removeClass(this.document.body, 'noscroll');
      this.renderer.setStyle(this.document.body, 'top', null);
      window.scrollTo(0, this.scrollTopPosition);
    }
  }

  lockScroll() {
    if ( isPlatformBrowser(this.platformId) ) {
      this.globalStateHelper.setLockMenu(true);

      this.scrollTopPosition = window.pageYOffset || document.documentElement.scrollTop;
      this.renderer.setStyle(this.document.body, 'top', -(this.scrollTopPosition) + 'px');
      this.renderer.addClass(this.document.body, 'noscroll');
    }
  }

  scrollPopupToTop() {
    this.scrolled.nativeElement.scrollTo({top: 0, behavior: 'smooth'});
  }

  @HostListener('click', ['$event'])
  onBackgroundClick(event) {
    const containerClass = 'popup-close-bg';
    const classList = event.target.classList;

    if ( classList.contains(containerClass) ) {
      this.closePopup();
      return;
    }
  }

}
