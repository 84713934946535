import { Component, OnInit } from '@angular/core';
import {routeAnimation} from '@helpers/animations';
import {LayoutService} from '@services/layout.service';

@Component({
  template: `
    <div [@routeAnimation]="o.isActivated ? o.activatedRoute : ''">
        <router-outlet #o="outlet"></router-outlet>
    </div>
  `,
  styleUrls: ['./default.component.scss'],
  animations: [
    routeAnimation
  ]
})
export class DefaultComponent {
  constructor(private layoutService: LayoutService) {}

}
