import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {Inject, Injectable, Optional} from '@angular/core';
import ConfigService from '@config/ConfigService';

@Injectable()
export class MomentDateFormatter extends NgbDateParserFormatter {
  DT_FORMAT: string;

  constructor(
    @Optional() @Inject('DT_FORMAT') private dateFormat,
  ) {
    super();
    moment.locale(ConfigService.getCurrentLang());

    if (dateFormat?.default) {
      this.DT_FORMAT = dateFormat.default;
    }
    
    if (dateFormat?.mobile && window.innerWidth < 992) {
      this.DT_FORMAT = dateFormat.mobile;
    }
  }

  parse(value: string): NgbDateStruct {
    if (value) {
      value = value.trim();
      const mdt = moment(value, this.DT_FORMAT);
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) {
      return '';
    }
    return mdt.format(this.DT_FORMAT);
  }
}
