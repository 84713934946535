import {Component, OnInit, HostListener, OnDestroy, Inject} from '@angular/core';
import { debounceEvent } from '../../_decorators/DebounceEvent';
import { LayoutService } from '@services/layout.service';
import {takeUntil} from 'rxjs/operators';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {DOCUMENT} from '@angular/common';
import {UserService} from '@services/auth/user.service';
import ConfigService from '@config/ConfigService';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent extends SubscribeHelper implements OnInit, OnDestroy {
  public isScrolledToTop = true;
  public isReservation = false;
  private scrollTo = 0;
  public isSideSummaryVisible: boolean;

  constructor(
    private layoutService: LayoutService,
    private userService: UserService,
    @Inject(DOCUMENT) private document
  ) {
    super();
  }

  ngOnInit(): void {
    this.layoutService.getIsReservationRoute().subscribe(val => {
      const currentRouteGroup = ConfigService.getCurrentRouteGroupPath();

      if ( currentRouteGroup.join('/').startsWith(':lang/reservation') ) {
        this.isReservation = true;
      } else {
        this.isReservation = false;
      }
    });

    this.layoutService.getIsMyReservationsRoute()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        const currentRouteGroup = ConfigService.getCurrentRouteGroupPath();

        if ( currentRouteGroup.join('/').startsWith(':lang/my-reservation') ) {
          this.scrollTo = this.document.getElementById('account_details').offsetTop;
        } else {
          this.scrollTo = 0;
        }
      });

    this.layoutService.getSideSummaryVisible()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(val => this.isSideSummaryVisible = val);
  }

  @HostListener('window:scroll')
  @debounceEvent()
  onWindowScroll() {
    this.isScrolledToTop = window.pageYOffset === 0;
  }

  onClick(e: MouseEvent): void {
    window.scrollTo({top: this.scrollTo - 106, behavior: 'smooth'});
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
