import { Component, OnInit } from '@angular/core';
import {IImage} from '@interfaces/IImage';
import IImageCollage from '@interfaces/IImageCollage';
import CarouselImageEnum from '@enums/CarouselImageEnum';
import ICarouselImages from '@interfaces/ICarouselImages';

@Component({
  selector: 'app-private-wellness',
  templateUrl: './private-wellness.component.html',
  styleUrls: ['./private-wellness.component.scss']
})
export class PrivateWellnessComponent implements OnInit {
  galleryImages: IImage[] = [];
  carouselImages: ICarouselImages[] = [];

  constructor() { }

  ngOnInit() {
    this.setCarouselImages();
    this.setGalleryImages();
  }

  setGalleryImages() {
    const path = '../../../assets/images/gallery/wellness_spa';

    for (let i = 1; i < 7; i++) {
      for (let j = 1; j < 6; j++) {
        this.galleryImages.push({
          image: `${path}/${i}_slide/${j}.jpg`,
          image2x: `${path}/${i}_slide/${j}.jpg`,
        });
      }
    }
  }

  setCarouselImages() {
    const path = '../../../assets/images/carousel/wellness/';

    this.carouselImages.push({
      image: {
        image: path + '01.jpg',
        image2x: path + '01@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '02.jpg',
        image2x: path + '02@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '03.jpg',
        image2x: path + '03@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    });
  }
}
