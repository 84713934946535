<div class="container mobile-padding">
  <div class="row">
    <div class="col-xl-10 offset-xl-1">
      <div class="loyalty-program mx-auto pt-tera">
        <h2 class="text-center mb-mega">{{ 'shuum_me_header' | translate }}</h2>
        <p class="mb-kilo" [innerHTML]="'shuum_me_desc' | translate | removeWidows | newLineBreak"></p>
        <form [formGroup]="loyaltyProgramForm" class="form checkbox-form" (ngSubmit)="onSubmit()">
          <app-input-checkbox
            control="loyaltyProgram"
            [group]="loyaltyProgramForm"
            [text]="'loyalty_program_checkbox' | translate"
            #input
          ></app-input-checkbox>
          <div class="text-center">
            <button class="btn btn-bordered btn-medium mt-kilo">{{ 'join' | translate }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
