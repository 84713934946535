<app-banner
  header="private_wellness_room"
  [withOverlay]="true"
  description="private_wellness_room_description"
  background="/assets/images/wellness_page/private_wellness/banner.jpg"
  background2x="/assets/images/wellness_page/private_wellness/banner@2x.jpg"
>
  <app-line-btn
    class="h4"
    scrollTo="private-wellness"
    [noOffset]="true"
    bottomButton
  >
    <span>{{ 'see_more'|translate }}</span>
  </app-line-btn>
</app-banner>

<div id="private-wellness">
  <app-big-background-image
    image="/assets/images/wellness_page/private_wellness/full_image.jpg"
    image2x="/assets/images/wellness_page/private_wellness/full_image@2x.jpg"
    imageAlt="Banner Image"
  >
    <div class="pt-peta pt-xl-zetta blueText text-center">
      <h2 [innerHTML]="'private_wellness_h_01'|translate|removeWidows" class="heading"></h2>
      <p class="p-responsive pt-kilo pt-lg-giga" [innerHTML]="'private_wellness_c_01'|translate|newLineBreak|removeWidows"></p>
    </div>
  </app-big-background-image>

  <div class="container mobile-padding">
    <div class="pt-peta pt-md-zetta pt-lg-200">
      <div class="row flex-column-reverse flex-md-row align-items-center">
        <div class="col-md-6 col-xl-5 col-xxl-4 offset-xl-1 pt-giga pt-md-zero">
          <app-indent-content header="private_wellness_h_02">
            <p class="small" [innerHTML]="'private_wellness_c_02'|translate|newLineBreak|removeWidows"></p>
          </app-indent-content>
        </div>

        <div class="col-10 col-md-6 col-xl-4 col-xxl-5 offset-xl-1">
          <app-content-images-overlapping-right
            imageFront="/assets/images/wellness_page/private_wellness/double_image_1_left.jpg"
            imageFront2x="/assets/images/wellness_page/private_wellness/double_image_1_left@2x.jpg"
            imageFrontAlt="Double Image 1 Left"
            imageBack="/assets/images/wellness_page/private_wellness/double_image_1_right.jpg"
            imageBack2x="/assets/images/wellness_page/private_wellness/double_image_1_right@2x.jpg"
            imageBackAlt="Double Image 1 Right"
            frontBottomSpacing="8%"
            classes="private-wellness"
          >
          </app-content-images-overlapping-right>
        </div>
      </div>
    </div>

    <div class="pt-peta pt-md-zetta pt-lg-200">
      <app-double-images-absolute
        imageLeft="/assets/images/wellness_page/private_wellness/absolute_left_1.jpg"
        imageLeft2x="/assets/images/wellness_page/private_wellness/absolute_left_1@2x.jpg"
        imageLeftAlt="Double Image 2 left"
        imageRight="/assets/images/wellness_page/private_wellness/absolute_right_1.png"
        imageRight2x="/assets/images/wellness_page/private_wellness/absolute_right_1@2x.png"
        imageRightAlt="Double Image 2 Right"
      >
        <app-indent-content header="private_wellness_h_03">
          <p class="small" [innerHTML]="'private_wellness_c_03'|translate|newLineBreak|removeWidows"></p>
        </app-indent-content>
      </app-double-images-absolute>
    </div>
  </div>

  <app-after-before-grey
    width="23%"
    height="584px"
    top="43%"
    left="0"
    additionalClasses="to-top-left thick-no-responsive"
  >
    <div class="container mobile-padding">
      <div class="pt-peta pt-md-zetta pt-lg-200">
        <div class="row flex-column-reverse flex-md-row align-items-center">
          <div class="col-md-6 col-xl-5 col-xxl-4 offset-xl-1 pt-giga pt-md-zero">
            <app-indent-content header="private_wellness_h_04">
              <p class="small" [innerHTML]="'private_wellness_c_04'|translate|newLineBreak|removeWidows"></p>
            </app-indent-content>
          </div>

          <div class="col-10 col-md-6 col-xl-5 col-xxl-6">
            <app-content-images-overlapping-right
              imageFront="/assets/images/wellness_page/private_wellness/double_image_2_left.jpg"
              imageFront2x="/assets/images/wellness_page/private_wellness/double_image_2_left@2x.jpg"
              imageFrontAlt="Double Image 3 Left"
              imageBack="/assets/images/wellness_page/private_wellness/double_image_2_right.png"
              imageBack2x="/assets/images/wellness_page/private_wellness/double_image_2_right@2x.png"
              imageBackAlt="Double Image 3 Right"
              reversed="true"
              widthBack="66%"
              imageBackMaxWidth="432"
              widthFront="37%"
              customMargin="-8%"
            ></app-content-images-overlapping-right>
          </div>
        </div>
      </div>
    </div>
  </app-after-before-grey>

  <div class="container mobile-padding">
    <div class="pt-peta pt-md-zetta pt-lg-200">
      <div class="pt-xxl-peta">
      <app-double-images-absolute
        imageLeft="/assets/images/wellness_page/private_wellness/absolute_img_2_left.jpg"
        imageLeft2x="/assets/images/wellness_page/private_wellness/absolute_img_2_left@2x.jpg"
        imageLeftAlt="Double Image 4 left"
        imageRight="/assets/images/wellness_page/private_wellness/absolute_img_2_right.png"
        imageRight2x="/assets/images/wellness_page/private_wellness/absolute_img_2_right@2x.png"
        imageRightAlt="Double Image 4 Right"
      >
        <app-indent-content header="private_wellness_h_05">
          <p class="small" [innerHTML]="'private_wellness_c_05'|translate|newLineBreak|removeWidows"></p>
        </app-indent-content>
      </app-double-images-absolute>
      </div>
    </div>

    <div class="pt-peta pt-md-zetta pt-lg-200">
      <app-gallery
        [header]="'wellness_and_spa'"
        [description]="'private_wellness_c_06'"
        [images]="galleryImages"
      ></app-gallery>
    </div>

<!--    <div class="pt-peta pt-md-zetta pt-lg-200">-->
<!--      <app-discover-->
<!--        [header]="'discover_also'"-->
<!--        [name]="'Intense Repair\nShea Butter'"-->
<!--        [description]="'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit in voluptate velit esse.'"-->
<!--        [price]="{ value: 49.99, currency: 'PLN' }"-->
<!--        [link]="''"-->
<!--      ></app-discover>-->
<!--    </div>-->

    <div class="pt-peta pt-md-zetta pt-lg-200">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 offset-xl-1">
          <div class="out-mobile-padding">
            <app-images-carousel
              [images]="carouselImages"
            >
            </app-images-carousel>
          </div>
        </div>
        <div class="col-12 col-lg-5 col-xl-4 offset-lg-1">
          <div class="pt-kilo pt-md-giga pt-lg-zero">
            <app-indent-content
              [header]="'our_team'"
            >
              <p
                class="small"
                [innerHTML]="'our_team_description'|translate|newLineBreak|removeWidows"
              >
              </p>

              <div class="actions pt-mega">
                <a [routerLink]="'/:lang/career'|i18nRoute" class="btn btn-bordered">{{ 'join_us'|translate }}</a>
              </div>
            </app-indent-content>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-peta pt-md-zetta pt-lg-200">
      <div class="row align-items-end">
        <div class="col-10 offset-lg-1 offset-xl-2">
          <h3 class="text-light-gray" [innerHTML]="'certifications'|translate|removeWidows"></h3>
        </div>
        <div class="col-12 col-md-6 col-lg-5 col-xl-4 offset-lg-1 order-1">
          <p
            class="pt-giga pt-lg-peta small"
            [innerHTML]="'certifications_description'|translate|newLineBreak|removeWidows"
          ></p>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-4 offset-lg-1 offset-xl-2 order-0">
          <div class="row justify-content-center">
            <div class="col-xl-10">
              <div class="pt-giga pt-md-zero">
                <a
                  href="https://www.wellnessverband.de/wellness-freunde/anbieter/detail.php?we_oid=245"
                  target="_blank"
                  class="opacity-link"
                >
                  <app-text-centered-image
                    header="{{ 'award_h_04'|translate}}"
                    image="/assets/images/wellness_page/certificate.png"
                    image2x="/assets/images/wellness_page/certificate@2x.png"
                    imgMaxHeight="118px"
                    imageAlt="certificate logo"
                  ></app-text-centered-image>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

