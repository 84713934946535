<div class="image-doubled-container">
  <div class="row justify-content-between">
    <div class="col-11 col-xl-4 col-lg-5 description-left">
      <ng-content select="[desc1]"></ng-content>
    </div>
    <div class="col-11 col-xl-4 col-lg-5 description-right pt-lg-zero pt-peta">
      <ng-content select="[desc2]"></ng-content>
    </div>
  </div>
  <div class=" row justify-content-center">
    <div class="col-12 col-sm-9 col-xl-12">
      <div class="img-wrapper pt-lg-zero pt-peta">
        <div class="">
          <img [srcset]="image1x + ' 1x, ' + image2x + ' 2x'" />
        </div>
      </div>
    </div>
  </div>
</div>