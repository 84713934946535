import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '@services/main.service';
import IFilter from '@interfaces/IFilter';
import IOrder from '@interfaces/IOrder';
import {Observable} from 'rxjs';
import IEvent from '@interfaces/IEvent';

@Injectable({
  providedIn: 'root'
})

export class EventService extends MainService {
  listUrl = '/events';

  constructor( protected http: HttpClient ) {
    super( http );
  }

  public getList( filters?: IFilter[], orders?: IOrder[], limit?: number, page?: number ): Observable<IEvent[]> {
    return super.getList( filters, orders, limit, page );
  }
}
