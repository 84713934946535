import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import ConfigService from '@config/ConfigService';
import ApiConfigurationEndpointEnum from '@enums/ApiConfigurationEndpointEnum';
import {takeUntil} from 'rxjs/operators';
import {catchError} from 'rxjs/internal/operators';
import {IConfiguration} from '@interfaces/IConfiguration';
import {ConfigurationService} from '@services/configuration.service';
import {SubscribeHelper} from '@helpers/SubscribeHelper';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends SubscribeHelper implements OnInit, OnDestroy {
  public currentLang: string = ConfigService.getDefaultLang();
  HotelStatuteFile: IConfiguration = null;

  @Input() scrollTo?: string;

  constructor(
    private apiConfiguration: ConfigurationService
  ) {
    super();
  }

  ngOnInit() {
    this.currentLang = ConfigService.getCurrentLang();
    this.getHotelStatuteFileUrl();
  }

  getHotelStatuteFileUrl(): void {
    this.apiConfiguration
      .getConfiguration(ApiConfigurationEndpointEnum.HotelStatute)
      .pipe(
        takeUntil(this.componentDestroyed$),
        catchError(err => {
          this.HotelStatuteFile = { id: null, value: '', iso: '', name: '' };
          return err;
        })
      )
      .subscribe((res: IConfiguration) => {
        this.HotelStatuteFile = res;
      });
  }

}
