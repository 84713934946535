import ConfigService from '../_config/ConfigService';
import VisibilityEnum from '@enums/VisibilityEnum';
import { IImage } from '@interfaces/IImage';
import * as is from 'is_js';
import * as forEach from 'lodash/forEach';
import * as isArray from 'lodash/isArray';
import IReservationRoom from '../_subprojects/reservation-form/_interfaces/IReservationRoom';
import 'core-js/features/url-search-params';
import ReservationAvailableTypeEnum from '../_subprojects/reservation-form/_enums/ReservationAvailableTypeEnum';
import IReservationRoomStandard from '../_subprojects/reservation-form/_interfaces/IReservationRoomStandard';
import IReservationRoomStandardPrice from '../_subprojects/reservation-form/_interfaces/IReservationRoomStandardPrice';
import IReservationExtraNew from '../_subprojects/reservation-form/_interfaces/IReservationExtraNew';
import * as moment from 'moment';
import IReservationDate from '../_subprojects/reservation-form/_interfaces/IReservationDate';
import { Params } from '@angular/router';

abstract class GlobalFunctions {
  // tslint:disable-next-line:only-arrow-functions
  public static delayer = (function(){
    let timer: any = 0;

    // tslint:disable-next-line:only-arrow-functions
    return function(callback, ms){
      clearTimeout (timer);
      timer = setTimeout(callback, ms);
    };
  })();

  public static getLangFromUrl( url: string, defaultValue?: string ): string|null {
    const langs = ConfigService.getLanguages();
    // const routeRegex = /^\/([a-zA-z]{2})/gi;
    const routeRegex = /\/([a-z]{2})(?:\/|$)/;
    const match = routeRegex.exec( url );

    if ( match ) {
      if ( typeof match[1] !== 'undefined' ) {
        if ( langs.includes(match[1]) ) {
          return match[1];
        }
      }
    }

    return defaultValue || null;
  }

  public static isLangAvailable( lang: string ): boolean {
    const langs = ConfigService.getLanguages();

    return langs.includes(lang);
  }

  public static getVisibilityClass( rwds: VisibilityEnum[], reverse: boolean ): string {
    const result = [];
    const notIn = [];
    const all = ['d-block', 'd-sm-block', 'd-md-block', 'd-lg-block', 'd-xl-block'];

    rwds.forEach( (r: VisibilityEnum) => {
      if ( r === VisibilityEnum.xs ) {
        result.push('d-block');
        notIn.push('d-block');
        result.push('d-sm-none');
      }
      else {
        result.push('d-none');
        result.push('d-' + (VisibilityEnum[r]) + '-block');
        notIn.push('d-' + (VisibilityEnum[r]) + '-block');

        if ( typeof VisibilityEnum[r + 1] !== 'undefined' ) {
          result.push('d-' + (VisibilityEnum[r + 1]) + '-none');
        }
      }
    });

    if ( reverse === true ) {
      notIn.forEach( n => {
        let index = all.indexOf(n);

        if ( index >= 0 ) {
          all[index] = all[index].replace('block', 'none');
        }
      });

      return all.join(' ');
    }

    return result.join(' ');
  }

  public static isRetinaDisplay(): boolean {
    /* TODO: wykrywanie ssr czy retina */

    if ( typeof window !== 'undefined' ) {
      if (window.matchMedia) {
        // tslint:disable-next-line:max-line-length
        var mq = window.matchMedia('only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)');
        return (mq && mq.matches || (window.devicePixelRatio > 1));
      }
    }

    return null;
  }

  public static getActualBootstrapBreakPointName( width: number ): string {
    if ( width >= 1200 ) {
      return 'xl';
    }
    else if ( width >= 992 ) {
      return 'lg';
    }
    else if ( width >= 768 ) {
      return 'md';
    }
    else if ( width >= 576 ) {
      return 'sm';
    }

    return 'xs';
  }

  public static getSrcSetFromIImage( img: IImage ): string {
    const ss = [];

    if ( img.image ) {
      ss.push(img.image + ' 1x');
    }

    if ( img.image2x ) {
      ss.push(img.image2x + ' 2x');
    }

    if ( img.image3x ) {
      ss.push(img.image3x + ' 3x');
    }

    return ss.join(',');
  }

  public static getScrollToOffsetMap(): Map<number, number> {
    const map = new Map();
    map
      .set(320, -70)
      .set(767, -80)
      .set(991, -106);

    return map;
  }

  public static scrollToValue( top: number ): void {
    if ( typeof window !== 'undefined' ) {
      if ( typeof window.scrollTo === 'function' ) {
        const supportsNativeSmoothScroll = 'scrollBehavior' in window.document.documentElement.style;

        if (supportsNativeSmoothScroll) {
          window.scrollTo({
            top,
            behavior: 'smooth'
          });
        } else {
          window.scrollTo(0, top);
        }
      }
    }
  }

  public static isElementInViewport( element: HTMLElement ): Boolean {
    const topDistance = element.getBoundingClientRect().top - (window.innerHeight || document.documentElement.clientHeight);
    const bottomDistance = element.getBoundingClientRect().bottom;

    if (topDistance < 0 && bottomDistance > 0) {
      return true;
    }

    return false;
  }

  public static getDevicePixelRatio() {
    if ( typeof window !== 'undefined' ) {
      if (window.matchMedia) {
        let mediaQuery;

        if (window.devicePixelRatio !== undefined) {
          return window.devicePixelRatio;
        }
        else if (window.matchMedia) {
          mediaQuery = '(-webkit-min-device-pixel-ratio: 1.5),\
          (min--moz-device-pixel-ratio: 1.5),\
          (-o-min-device-pixel-ratio: 3/2),\
          (min-resolution: 1.5dppx)';

          if (window.matchMedia(mediaQuery).matches) {
            return 1.5;
          }

          mediaQuery = '(-webkit-min-device-pixel-ratio: 2),\
          (min--moz-device-pixel-ratio: 2),\
          (-o-min-device-pixel-ratio: 2/1),\
          (min-resolution: 2dppx)';

          if (window.matchMedia(mediaQuery).matches) {
            return 2;
          }

          mediaQuery = '(-webkit-min-device-pixel-ratio: 0.75),\
          (min--moz-device-pixel-ratio: 0.75),\
          (-o-min-device-pixel-ratio: 3/4),\
          (min-resolution: 0.75dppx)';

          if (window.matchMedia(mediaQuery).matches) {
            return 0.7;
          }
        }
        else {
          return 1;
        }
      }
    }

    return 1;
  }

  public static getCanBrowserUsePixelRatioQuery(): boolean {
    try {
      if (is.not.mac() && is.not.mobile() && is.not.tablet() ) {
        return true;
      }

      return false;
    }
    catch (e) {
      return true;
    }
  }

  public static getEmailRegex(): RegExp {
    // tslint:disable-next-line:max-line-length
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  }

  public static getPhoneRegex(): RegExp {
    return /^\+?\(?([0-9]{2,3})\)?([0-9 -.]*)+$/;
  }

  public static getUserDataToApi( rooms: IReservationRoom[], removeUser: boolean ) {
    const guestFirst = rooms[0]?.guestData;

    const userData = {
      customer: {
        name: guestFirst?.name,
        lastName: guestFirst?.lastName,
        email: guestFirst?.email,
        phone: guestFirst?.phone,
        address: guestFirst?.street,
        postcode: guestFirst?.postcode,
        city: guestFirst?.city,
        country: guestFirst?.country,
        rules: guestFirst?.rules,
      },
      company: !guestFirst?.vatOtherToggle ? {
        id: guestFirst?.vatCompanyId
      } : {
        companyName: guestFirst?.vatCompanyName,
        address: guestFirst?.vatStreet,
        postcode: guestFirst?.vatPostcode,
        city: guestFirst?.vatCity,
        taxNumber: guestFirst?.vatNip,
        country: guestFirst?.vatCountry
      },
      rooms: null
    };

    if ( !guestFirst?.vatToggle ) {
      delete userData.company;
    }

    /**
     * Jeżeli użytkownik jest zalogowany
     * nie wymagamy przesłania danych customers
     */
    if ( removeUser ) {
      delete userData.customer;
    }

    const roomsData = [];

    rooms.forEach((room, i) => {
      if ( i === 0 ) {
        if ( room.guestData?.otherCustomerToggle ) {
          const guest: any = {
            name: guestFirst.otherCustomerName,
            lastName: guestFirst.otherCustomerLastname,
            email: guestFirst.otherCustomerEmail,
            phone: guestFirst.otherCustomerPhone
          };

          if ( guestFirst.otherCustomerConfirm ) {
            guest.sendEmail = true;
          }

          roomsData.push({
            guest
          });
        } else {
          roomsData.push({});
        }
      } else {
        if ( room.guestData ) {
          roomsData.push({
            guest: {
              name: room.guestData.name,
              lastName: room.guestData.lastName
            }
          });
        } else {
          roomsData.push({});
        }
      }
    });

    userData.rooms = roomsData;

    return userData;
  }

  // public static simpleConvertToFormdata( data: any ): FormData {
  //   const fd = new FormData();
  //   for ( const key of Object.keys(data) ) {
  //     fd.append(key, data[key]);
  //   }

  //   return fd;
  // }



  // public static setReservationUserData( data: FormData, reservationUserData: any ): FormData {
  public static convertToFd( data: FormData, reservationUserData: any ): FormData {
    forEach( reservationUserData, (value, key) => {
      if ( typeof value === 'object' ) {
        forEach( value, (v, k) => {
          if ( value ) {
            if ( k === 'rules' ) {
              if ( isArray(v)  ) {
                v.forEach( ruleValue => {
                  forEach( ruleValue, (v2, k2) => {
                    if ( v2 === true ) {
                      data.append(`${key}[${k}][]`, k2.replace('rule_', ''));
                    }
                  });
                });
              }
            } else if ( typeof v === 'object' ) {
              this.recurrentConvertToFormData(v, k, `${key}`, data);
            } else {
              data.append(`${key}[${k}]`, v);
            }
          }
        });
      } else {
        data.append(`${key}`, value);
      }
    });

    return data;
  }

  private static recurrentConvertToFormData(value: any, key: any, data: string, fd: FormData) {
    if ( typeof value !== 'object' ) {
      fd.append(`${data}[${key}]`, value);
      return '';
    } else {
      forEach( value, (v, k) => {
        return this.recurrentConvertToFormData(v, k, `${data}[${key}]`, fd);
      });
    }
  }

  public static convertToUrlencoded( data: any ): string {
    const encoded = new URLSearchParams();

    forEach( data, (value, key) => {
      if ( isArray(value) ) {
        if ( key === 'rules' ) {
          value.forEach( ruleValue => {
            if ( typeof ruleValue === 'object' ) {
              forEach( ruleValue, (v, k) => {
                if ( v === true ) {
                  encoded.append(key + '[]', k.replace('rule_', ''));
                }
              });
            } else {
              encoded.append(key + '[]', ruleValue);
            }
          });
        }
      }
      else {
        encoded.set(key, value);
      }
    });

    return encoded.toString();
  }

  public static enumAsArray( enumName: any ): any[] {
    const arr = [];

    for (const [key, value] of Object.entries(enumName)) {
      arr.push({ id: key, value });
    }

    return arr;
  }

  public static copyToClipboard( text: string ) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.style.width = '0';
    selBox.style.height = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public static checkRoomsAvailability(
    standard: IReservationRoomStandard,
    booking: IReservationRoomStandardPrice[],
    room: IReservationRoom
  ) {
    let save = false;

    if ( standard ) {
      if ( standard.amount > 0 ) {
        standard.amount --;
      } else {
        room.price = null;
        room.priceType = null;
        room.standardId = null;
        room.name = null;
        save = true;
      }
    }

    if ( booking && booking[room.standardId] && booking[room.standardId].availableType !== ReservationAvailableTypeEnum.available ) {
      room.price = null;
      room.priceType = null;
      room.standardId = null;
      room.name = null;
      save = true;
    }

    return save;
  }

  public static getDurationOfExcludedExtras( extra: IReservationExtraNew, date: IReservationDate ): number {
    let excludedDays = 0;

    try {
      if ( extra ) {
        if ( typeof extra.excludes !== 'undefined' ) {
          extra.excludes.forEach(exclude => {
            const reservationStartDate = moment([date.from.year, date.from.month - 1, date.from.day]);
            const reservationEndDate = moment([date.to.year, date.to.month - 1, date.to.day]);
            const startExcludeDate = moment(exclude.start.date);
            const endExcludeDate = moment(exclude.end.date);

            //count overlap
            const overlapStart = moment.max(reservationStartDate, startExcludeDate);
            const overlapEnd = moment.min(reservationEndDate, endExcludeDate);

            let countOverlapDays = 0;

            if (reservationEndDate.isSame(endExcludeDate)) {
              countOverlapDays = overlapEnd.diff(overlapStart, 'days');
              excludedDays += countOverlapDays;
            } else {
              countOverlapDays = overlapEnd.diff(overlapStart, 'days') + 1;
              excludedDays += countOverlapDays;
            }

            // if ( reservationStartDate.isSameOrBefore(startExcludeDate) && reservationEndDate.isAfter(endExcludeDate) ) {
            //   console.log('if-3');
            //   // console.log('inside IF');
            //   // + 1 bo start i end zwracaja te sama date jesli na 1 dzien jest wykluczenie
            //   const days = endExcludeDate.diff(startExcludeDate, 'days') + 1;
            //   // console.log('endExcludeDate.diff', endExcludeDate.diff(startExcludeDate, 'days'));
            //   // console.log('days', days);
            //
            //   excludedDays += days;
            // }
          });
        }
      }
    }
    catch (e) {
      excludedDays = 0;
    }

    return excludedDays;
  }

  public static queryStringToObject( url: string ): Params {
    const qs = url.split('?')[1];
    const params = new URLSearchParams(qs);
    const obj = {};

    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key)
      } else {
        obj[key] = params.get(key)
      }
    }

    return obj;
  }


}

export default GlobalFunctions;
