<div class="container mobile-padding" [class]="user?.loyaltyProgram ? 'pb-giga' : 'pt-tera'">
  <div class="row">
    <div class="col-lg-8 col-xl-7 offset-xl-1 pb-mega">
      <p *ngIf="user?.complete === false" class="regular not-break">{{ 'not_filled_profile'|translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-xl-5 offset-xl-1">
      <div class="bg-white h-100 bordered-element">
        <div class="d-flex">
          <h4 class="bold-grey-text flex-grow-1 pr-deca">{{ 'user_data'|translate }}</h4>
          <a
            [routerLink]="'/:lang/my-reservation/edit-account-group'|i18nRoute"
            class="flex-shrink-0"
          >
            <img src="/assets/images/reservations/user-profile/edit.svg" class="edit-icon" />
          </a>
        </div>
        <div class="pt-mega pt-sm-tera" *ngIf="user?.name">
          <p class="light-subtitle">{{ 'form_name'|translate }}</p>
          <p class="bold-bigger-text pt-deci">{{ user?.name }}</p>
        </div>
        <div class="pt-kilo pt-sm-mega" *ngIf="user?.lastName">
          <p class="light-subtitle">{{ 'form_last_name'|translate }}</p>
          <p class="bold-bigger-text pt-deci">{{ user?.lastName }}</p>
        </div>
        <div class="pt-kilo pt-sm-mega" *ngIf="user?.email">
          <p class="light-subtitle">{{ 'form_email'|translate }}</p>
          <p class="bold-bigger-text pt-deci">{{ user?.email }}</p>
        </div>
        <div class="pt-kilo pt-sm-mega pb-lg-peta" *ngIf="user?.phone">
          <p class="light-subtitle">{{ 'form_phone'|translate }}</p>
          <p class="bold-bigger-text pt-deci">{{ user?.phone }}</p>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-5 d-flex flex-column">
      <div class="bg-white bordered-element mt-deca mt-lg-zero">
        <div class="d-flex">
          <h4 class="bold-grey-text flex-grow-1 pr-deca">{{ 'address_data'|translate }}</h4>
        </div>
        <div class="pt-mega">
          <ng-container *ngIf="user?.complete">
            <div>
              <p class="bold-bigger-text" *ngIf="user?.name && user?.lastName">{{ user.name }} {{ user.lastName }}</p>
              <p class="bold-bigger-text" *ngIf="user?.address">{{ user.address }}</p>
              <p class="bold-bigger-text" *ngIf="user?.postcode || user?.city">{{ user.postcode }} {{ user.city }}</p>
              <p class="bold-bigger-text" *ngIf="user?.country">{{ ('country_' + user.country) | translate }}</p>
            </div>
          </ng-container>
          <ng-container *ngIf="!user?.complete">
            <div class="flex-grow-1 d-flex align-items-center justify-content-center">
              <p class="text-light-gray">{{ 'no_data_address'|translate }}</p>
            </div>
          </ng-container>
        </div>
        <div class="pt-mega">
          <a
            [routerLink]="'/:lang/my-reservation/edit-account-group'|i18nRoute"
            fragment="address"
            class="btn btn-bordered on-white w-100"
          >
            <span *ngIf="user?.complete">
              {{ 'change_address'|translate }}
            </span>
            <span *ngIf="!user?.complete">
              {{ 'fill_address_data'|translate }}
            </span>
          </a>
        </div>
      </div>
      <div class="bg-gray mt-deca bordered-element flex-grow-1 d-flex flex-column">
        <h4 class="bold-grey-text pr-deca">{{ 'data_for_vat'|translate }}</h4>
        <div class="pt-mega">
          <ng-container *ngIf="user?.company?.id">
            <div>
              <p class="bold-bigger-text" *ngIf="user?.company?.name">{{ user.company.name }}</p>
              <p class="bold-bigger-text" *ngIf="user?.company?.address">{{ user.company.address }}</p>
              <p class="bold-bigger-text" *ngIf="user?.company?.postcode || user?.company?.city">{{ user.company.postcode }} {{ user.company.city }}</p>
              <p class="bold-bigger-text" *ngIf="user?.company?.country">{{ ('country_' + user.company.country) | translate }}</p>
              <p class="bold-bigger-text" *ngIf="user?.company?.taxNumber">{{ 'nip_eu' | translate }}: {{ user.company.taxNumber }}</p>
            </div>
          </ng-container>
          <ng-container *ngIf="!user?.company?.id">
            <div class="flex-grow-1 d-flex align-items-center justify-content-center">
              <p class="text-light-gray">{{ 'no_data_address'|translate }}</p>
            </div>
          </ng-container>
        </div>
        <div class="pt-mega">
          <a
            [routerLink]="'/:lang/my-reservation/edit-account-group'|i18nRoute"
            fragment="invoice"
            class="btn btn-bordered w-100"
          >
            <span *ngIf="!user?.company?.id">
              {{ 'fill_invoice_data'|translate }}
            </span>
            <span *ngIf="user?.company?.id">
              {{ 'change_invoice_data'|translate }}
            </span>
          </a>
        </div>
      </div>
    </div>
    <div class="col-xl-10 offset-xl-1 d-flex pt-deca" *ngIf="user?.complete">
      <div class="bg-white bordered-element mt-deca mt-lg-zero w-100">
        <div class="pl-sm-mega pr-sm-mega pl-lg-zetta pr-lg-zetta">
          <form [formGroup]="checkboxForm" class="form checkbox-form">
            <div [ngClass]="{ 'pb-deca': i < controlsLength - 1 }" *ngFor="let r of r.controls; let i = index">
              <app-input-checkbox
                inputId="sign-in-data-process"
                [control]="'rule_' + rules[i].id"
                [group]="r"
                [text]="rules[i]?.description"
                [disabled]="!rules[i].isVoluntary"
                (checkboxChanged)="formChanges()"
                #input
              ></app-input-checkbox>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xl-10 offset-xl-1 d-flex pt-deca">
      <div class="bg-white bordered-element mt-deca mt-lg-zero w-100">
        <div class="pl-sm-mega pr-sm-mega pl-lg-zetta pr-lg-zetta">
         <p class="light-subtitle" [innerHTML]="'account_removal_info'|translate"></p>
        </div>
      </div>
    </div>
    <div class="col-xl-10 offset-xl-1 d-flex pt-deca" *ngIf="user?.loyaltyProgram">
      <div class="bg-white bordered-element mt-deca mt-lg-zero w-100">
        <div class="pl-sm-mega pr-sm-mega pl-lg-zetta pr-lg-zetta">
          <p class="light-subtitle">
            <span [outerHTML]="'loyalty_program_cancellation'|translate|removeWidows"></span>
            <button class="btn-link" (click)="showPopup()">{{ 'click_here'|translate }}</button>.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-popup-content
  #popupLoyaltyProgramCancellation
  [hideSecondPart]="true"
  [inPlace]="true"
  [borderRadius]="true"
  [colorsReversed]="true"
  [popupParentClasses]="'col-12 col-xl-8 offset-xl-2'"
  [rwdClasses]="'pl-mega pr-mega'"
>
  <div content1>
    <h3 class="text-loyalty-gray weight-200" [innerHTML]="'loyalty_program_cancellation_popup_header'|translate"></h3>
    <p class="smaller-text pt-deca" [innerHTML]="'loyalty_program_cancellation_popup_content'|removeWidows|newLineBreak|translate"></p>
    <div class="d-flex justify-content-center mt-kilo">
      <button
        class="btn btn-bordered on-white"
        (click)="closePopup()"
      >{{ 'however_dont_want'|translate }}</button>
      <button
        class="btn link-underlined weight-500 ml-4"
        (click)="cancelMembership()"
      >{{ 'yes_want_to'| translate }}</button>
    </div>
  </div>
</app-popup-content>

<app-popup-content
  #popupLoyaltyProgramStay
  [hideSecondPart]="true"
  [inPlace]="true"
  [borderRadius]="true"
  [colorsReversed]="true"
  [popupParentClasses]="'col-12 col-xl-8 offset-xl-2'"
  [rwdClasses]="'pl-mega pr-mega'"
  [centerContent]="false"
>
  <div content1>
    <h3 class="text-loyalty-gray weight-200" [innerHTML]="'loyalty_program_stay_popup_header'|translate"></h3>
  </div>
</app-popup-content>

<app-popup-content
  #popupLoyaltyProgramCancelled
  [hideSecondPart]="true"
  [inPlace]="true"
  [borderRadius]="true"
  [colorsReversed]="true"
  [popupParentClasses]="'col-12 col-xl-8 offset-xl-2'"
  [rwdClasses]="'pl-mega pr-mega'"
  [centerContent]="false"
>
  <div content1>
    <h3 class="text-loyalty-gray weight-200" [innerHTML]="'loyalty_program_cancelled_popup_header'|translate"></h3>
    <p class="smaller-text pt-deca" [innerHTML]="'loyalty_program_cancelled_popup_content'|removeWidows|newLineBreak|translate"></p>
  </div>
</app-popup-content>
