<div class="fixed-bottom"
     [ngClass]="{ 'extended': !isScrolledToTop, 'isReservation': isReservation, 'isSideSummaryVisible': isSideSummaryVisible }"
     (click)="onClick($event)"
>
    <svg version="1.1" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 56 55" style="enable-background:new 0 0 56 55;" xml:space="preserve">
      <style type="text/css">
        .st0{fill:#A2A2A2;}
        .st1{fill:none;}
      </style>
            <g id="Line_23_00000093870145453508832570000016728332162652222379_">
        <polygon class="st0" points="23.6,39.6 22.2,38.1 32.5,27.8 22.2,17.4 23.6,16 35.4,27.8 	"/>
      </g>
            <g id="Rectangle_6" transform="translate(53.708) rotate(90)">
        <g>
          <path class="st1" d="M54.5,15.4v19.7c0,9.4-7.6,17-17,17H17.8c-9.4,0-17-7.6-17-17V15.4c0-9.4,7.6-17,17-17h19.7
            C46.9-1.6,54.5,6,54.5,15.4z"/>
        </g>
              <g>
          <path class="st0" d="M0.8,15.4v19.7c0,9.4,7.6,17,17,17h19.7c9.4,0,17-7.6,17-17V15.4c0-9.4-7.6-17-17-17H17.8
            C8.4-1.6,0.8,6.1,0.8,15.4z M37.5,0.4c8.3,0,15,6.7,15,15v19.7c0,8.3-6.7,15-15,15H17.8c-8.3,0-15-6.7-15-15V15.4
            c0-8.3,6.7-15,15-15H37.5z"/>
        </g>
      </g>
    </svg>
</div>
