<div class="container pt-zetta mobile-padding text-simple-centered">
  <app-text-simple-centered
    header="{{ 'career_text_centered_h'|translate}}"
  >
    <p class="p-responsive" [innerHTML]="'career_text_centered_c'|translate|newLineBreak|removeWidows"></p>
  </app-text-simple-centered>
</div>

<div id="careerTrigger">
  <app-content2plus1
    trigger="#careerTrigger"
    [data]="content2plus1"
    [firstVisible]="true"
  ></app-content2plus1>
</div>

<app-after-before-grey
  width="30%"
  height="1159px"
  right="0px"
  bottom="-550px"
  additionalClasses="to-top-right"
>
  <div class="container mobile-padding pt-peta">
    <h3 class="text-light-gray offset-2">{{ 'our_hotel'|translate}}</h3>
    <div class="pt-giga pt-xl-peta">
      <div class="row flex-center">
        <div class="col-xl-10 col-xxl-12">
          <div class="row shrinked-xs-row">
            <div class="col-xxl-4 col-lg-4 col-6">
              <app-movie-text-hover
                header="{{ 'career_triple_video_h_01'|translate}}"
                [video]="{
                  'webm': '/assets/videos/career/sauny_spa.webm',
                  'mp4': '/assets/videos/career/sauny_spa.mp4',
                  'ogg': '/assets/videos/career/sauny_spa.ogg',
                  'fallbackImage': '/assets/images/career_page/sauny_spa.jpg'
                }"
              >
                <a [routerLink]="'/:lang/wellness/saunas'|i18nRoute" class="btn btn-bordered btn-white">{{ 'know_more'|translate}}</a>
              </app-movie-text-hover>
            </div>

            <div class="col-xxl-4 col-lg-4 col-6">
              <app-movie-text-hover
                header="{{ 'career_triple_video_h_02'|translate}}"
                [video]="{
                  'webm': '/assets/videos/career/relax_beauty.webm',
                  'mp4': '/assets/videos/career/relax_beauty.mp4',
                  'ogg': '/assets/videos/career/relax_beauty.ogg',
                  'fallbackImage': '/assets/images/career_page/relax_beauty.jpg'
                }"
              >
                <a [routerLink]="'/:lang/wellness/relax'|i18nRoute" class="btn btn-bordered btn-white">{{ 'know_more'|translate}}</a>
              </app-movie-text-hover>
            </div>

            <div class="pt-deca pt-lg-zero col-xxl-4 col-lg-4 col-6">
              <app-movie-text-hover
                header="{{ 'career_triple_video_h_03'|translate}}"
                [video]="{
                  'webm': '/assets/videos/career/medical_wellness.webm',
                  'mp4': '/assets/videos/career/medical_wellness.mp4',
                  'ogg': '/assets/videos/career/medical_wellness.ogg',
                  'fallbackImage': '/assets/images/career_page/medical_wellness.jpg'
                }"
              >
                <a [routerLink]="'/:lang/wellness/medical-wellness'|i18nRoute" class="btn btn-bordered btn-white">{{ 'know_more'|translate}}</a>
              </app-movie-text-hover>
            </div>

            <div class="pt-deca col-xxl-4 col-lg-4 col-6">
              <app-movie-text-hover
                header="{{ 'career_triple_video_h_04'|translate}}"
                video="/assets/images/career_page/joga_fitness"
                [video]="{
                  'webm': '/assets/videos/career/joga_fitness.webm',
                  'mp4': '/assets/videos/career/joga_fitness.mp4',
                  'ogg': '/assets/videos/career/joga_fitness.ogg',
                  'fallbackImage': '/assets/images/career_page/joga_fitness.jpg'
                }"
              >
                <a [routerLink]="'/:lang/wellness/yoga'|i18nRoute" class="btn btn-bordered btn-white">{{ 'know_more'|translate}}</a>
              </app-movie-text-hover>
            </div>

            <div class="pt-deca col-xxl-4 col-lg-4 col-6">
              <app-movie-text-hover
                header="{{ 'career_triple_video_h_05'|translate}}"
                [video]="{
                  'webm': '/assets/videos/career/restauracje.webm',
                  'mp4': '/assets/videos/career/restauracje.mp4',
                  'ogg': '/assets/videos/career/restauracje.ogg',
                  'fallbackImage': '/assets/images/career_page/restauracje.jpg'
                }"
              >
                <a [routerLink]="'/:lang/food/a-la-carte'|i18nRoute" class="btn btn-bordered btn-white">{{ 'know_more'|translate}}</a>
              </app-movie-text-hover>
            </div>

            <div class="pt-deca col-xxl-4 col-lg-4 col-6">
              <app-movie-text-hover
                header="{{ 'career_triple_video_h_06'|translate}}"
                [video]="{
                  'webm': '/assets/videos/career/lobby_bar.webm',
                  'mp4': '/assets/videos/career/lobby_bar.mp4',
                  'ogg': '/assets/videos/career/lobby_bar.ogg',
                  'fallbackImage': '/assets/images/career_page/lobby_bar.jpg'
                }"
              >
                <a [routerLink]="'/:lang/food/lobby-bar-child'|i18nRoute" class="btn btn-bordered btn-white">{{ 'know_more'|translate}}</a>
              </app-movie-text-hover>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-after-before-grey>

<div class="container wide hotel-history">
  <div class="container mobile-padding pt-zetta pt-xl-200 mobile-padding">
    <app-hotel-history header="{{ 'hotel_history'|translate }}"></app-hotel-history>
  </div>
</div>

<div class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200 pb-peta pb-md-zetta">
    <div class="offset-lg-1 offset-xl-2 text-light-gray">
      <h3 [innerHtml]="'certifications' | translate | removeWidows"></h3>
    </div>
    <div class="pt-mega pt-md-peta">
      <app-item-slider [itemTpl]="itemTpl" [items]="careerCertificates">
      </app-item-slider>

      <ng-template let-item #itemTpl>
        <app-certificate-part [data]="item"></app-certificate-part>
      </ng-template>
    </div>
  </div>
</div>

<div class="container mobile-padding pt-exa pt-xl-200">
  <div class="row justify-content-center">
    <div class="col-lg-10">
      <app-image-doubled-description
        image1x="/assets/images/career_page/agnieszkaadam.png"
        image2x="/assets/images/career_page/agnieszkaadam@2x.png"
        imageAlt="Agnieszka Trafas Adam Hok"
      >
        <div desc1>
          <h2 class="heading">Agnieszka Trafas</h2>
          <h4 class="pt-deci text-very-light-gray high">{{ 'hotel_owner'|translate}}</h4>
          <p class="small-text pt-hecto">{{ 'agnieszka_trafas_description'|translate }}</p>
        </div>
        <div desc2>
          <h2 class="heading">Adam Hok</h2>
          <h4 class="pt-deci text-very-light-gray high">{{ 'hotel_director'|translate}}</h4>
          <p class="small-text pt-hecto">{{ 'adam_hok_description'|translate }}</p>
        </div>
      </app-image-doubled-description>
    </div>
  </div>
</div>

<div>
  <div id="careerOffers" class="container mobile-padding pt-zetta pt-xl-200" >
    <div id="right-sticked-btn-scroll"></div>
    <ng-container *ngIf="jobOffers.length > 0">
      <h3 class="text-light-gray offset-lg-1 offset-xl-2">{{ 'actual_offers'|translate }}</h3>
      <div class="pt-mega pt-xl-giga row">
        <div *ngFor="let jobOffer of jobOffers; let i = index" class="col-12 offset-0  offset-lg-0 col-lg-6 col-xl-5 pt-giga" [ngClass]="{'offset-xl-1' : i % 2 === 0, 'pt-0' : i === 0, 'pt-lg-0' : i === 1 }">
          <app-text-offer
            [image]="jobOffer.photo.path|apiUrl"
            [imageAlt]="jobOffer.photo.alt"
            [link]="'/:lang/career/'+jobOffer.slug|i18nRoute"
          >
            <h3 [innerHTML]="jobOffer.title"></h3>
            <div class="pt-tera">
              <a [routerLink]="'/:lang/career/'+jobOffer.slug|i18nRoute" class="btn btn-bordered on-white">{{ 'check_details'|translate }}</a>
            </div>
          </app-text-offer>
        </div>
      </div>

      <div class="row" *ngIf="responseCurrent < responseMax && !loading">
        <div class="col-12 text-center pt-giga content-center">
          <button class="btn btn-collapse" (click)="loadMore()">{{ 'see_more'|translate }}</button>
        </div>
      </div>

      <div class="row" *ngIf="loading">
        <div class="col-12 pt-giga">
          <app-loader [size]="30" [opacity]="'0.7'" [center]="true"></app-loader>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="jobOffers.length === 0">
      <div class="text-center">
        <p class="text-light-gray h3">{{'no_offers_available'|translate|removeWidows}}</p>
      </div>
    </ng-container>
  </div>
</div>

<app-footer></app-footer>
