<app-popup-content #popup [hideSecondPart]="true" [scrollToTop]="true">
    <div content1 class="pt-centi pb-centi text-center">
      <h3 class="pb-giga">{{ 'logout_header'|translate }}</h3>
      <p class="smaller-text weight-300 pb-giga">{{ 'blogostan_logout_desc'|translate }}</p>
      <a
        class="btn btn-bordered"
        (click)="closePopup()"
        [href]="blogostanUrl"
       >
        {{ 'back_to_home' | translate }}
      </a>
    </div>
  </app-popup-content>