import { Component, OnInit } from '@angular/core';
import CollageImageEnum from '@enums/CollageImageEnum';
import IImageCollage from '@interfaces/IImageCollage';
import {IImage} from '@interfaces/IImage';
import ICarouselImages from '@interfaces/ICarouselImages';
import CarouselImageEnum from '@enums/CarouselImageEnum';

@Component({
  selector: 'app-lobby-bar',
  templateUrl: './lobby-bar.component.html',
  styleUrls: ['./lobby-bar.component.scss']
})
export class LobbyBarComponent implements OnInit {
  collageEnum: typeof CollageImageEnum;
  galleryImages: IImage[] = [];
  carouselImages: ICarouselImages[] = [];
  imagesCollage: IImageCollage;
  imagesCollage2: IImageCollage;

  constructor() { }

  ngOnInit() {
    this.collageEnum = CollageImageEnum;
    this.setImageCollages();
    this.setGalleryImages();
    this.setCarouselImages();
  }

  setImageCollages() {
    const path = '/assets/images/food_and_wine/lobby_bar/';

    this.imagesCollage = {
      smallImage: {
        image: path + 'section-1-img2.jpg',
        image2x: path + 'section-1-img2@2x.jpg',
        maxWidth: 204
      },
      bigImage: {
        image: path + 'section-1-img1.jpg',
        image2x: path + 'section-1-img1@2x.jpg'
      }
    };

    this.imagesCollage2 = {
      smallImage: {
        image: path + 'section-3-img2.jpg',
        image2x: path + 'section-3-img2@2x.jpg',
        maxWidth: 204
      },
      bigImage: {
        image: path + 'section-3-img1.jpg',
        image2x: path + 'section-3-img1@2x.jpg'
      },
    };
  }

  setCarouselImages() {
    const path = '../../../assets/images/carousel/food/';

    this.carouselImages.push({
      image: {
        image: path + '01.jpg',
        image2x: path + '01@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '02.jpg',
        image2x: path + '02@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '03.jpg',
        image2x: path + '03@2x.jpg',
      },
      type: CarouselImageEnum.Wide
    });
  }

  setGalleryImages() {
    const path = '../../../assets/images/gallery/food_wine';

    for (let i = 1; i < 6; i++) {
      for (let j = 1; j < 6; j++) {
        this.galleryImages.push({
          image: `${path}/${i}_slide/${j}.jpg`,
          image2x: `${path}/${i}_slide/${j}.jpg`,
        });
      }
    }
  }
}
