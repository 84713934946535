import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {I18nModule} from '../i18n/i18n.module';

import {BannerComponent} from '@shared/banner/banner.component';
import {SharedPipesModule} from './shared-pipes.module';


@NgModule({
  declarations: [
    BannerComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    SharedPipesModule
  ],
  providers: [

  ],
  bootstrap: [],
  exports: [
    BannerComponent
  ]
})
export class SharedBannerModule { }
