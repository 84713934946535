<div class="container mobile-padding">
  <div class="row">
    <div class="col-xl-10 offset-xl-1 pt-kilo pb-kilo loyalty-program-nav">
      <nav>
        <owl-carousel-o [options]="options" #owlNav>
          <ng-container *ngFor="let nav of navigation; let index = index;">
            <ng-template carouselSlide [id]="'item-' + index">
              <a
                [style.background-image]="nav.image ? 'url('+ nav.image +')' : ''"
                class="no-underline position-relative d-flex align-items-end"
                [routerLinkActive]="['active']"
                [routerLink]="nav.route|i18nRoute"
              >
                <span [innerHTML]="nav.name|translate"></span>
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </nav>
    </div>
  </div>
</div>
