import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { IImage } from '@interfaces/IImage';
import GlobalFunctions from '@helpers/GlobalFunctions';

@Component({
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss']
})
export class ImgComponent implements OnInit {

  srcSet = '';
  rwdElements = [];

  @Input() image: IImage;
  @Input() alt: string;
  @Input() class?: string;

  imageClass: string;
  imageChildData: { src: string, class: string }[];

  constructor() {}

  ngOnInit() {
    this.setSrcSet();

    this.rwdElements = this.image.rwd || [];
    this.imageClass = this.imgClass();

    this.rwdElements.forEach( r => {
      this.imageChildData.push({
        src: this.setSrcSet( r ),
        class: this.imgClass( r )
      });
    });
  }

  setSrcSet( img?: IImage ): string {
    const isMainImage = typeof img === 'undefined';

    if ( typeof img === 'undefined' ) {
      img = this.image;
    }

    const ss = GlobalFunctions.getSrcSetFromIImage( img );

    if ( isMainImage ) {
      this.srcSet = ss;
    }
    else {
      return ss;
    }
  }

  imgClass( img?: IImage ): string {
    const rwds = [];
    let checkElements = false;

    if ( typeof img === 'undefined' ) {
      img = this.image;
      checkElements = true;
    }

    if ( typeof img.container !== 'undefined' ) {
      rwds.push( img.container );
    }

    if ( checkElements ) {
      if (this.rwdElements.length > 0) {
        this.rwdElements.forEach(r => {
          if ( typeof r.container !== 'undefined' ) {
            rwds.push(r.container);
          }
        });
      }
    }

    if ( rwds.length > 0 ) {
      return GlobalFunctions.getVisibilityClass( rwds, checkElements );
    }

    return null;
  }
}
