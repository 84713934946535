<app-step-header
  header="reservation_step_2_header"
  description="reservation_step_2_description"
  [showDate]="true"
></app-step-header>

<div class="reservation-step">
  <div #roomTabs class="rooms" *ngIf="rooms && allStandardRooms && booking">
    <ng-container *ngFor="let room of rooms;index as i">
      <app-room-tab
        [index]="i"
        [room]="room"
        [booking]="booking"
        [selectedRoomPrice]="{ priceType: room?.priceType, standardId: room?.standardId, offerId: room?.offer?.id, withDinner: room?.withDinner }"
        [standardRooms]="booking.rooms[i] || null"
        [allStandardRooms]="allStandardRoomsCurrent"
        [isActive]="activeRoomIndex === i"
        (onTabClick)="setActiveRoomIndex($event)"
      ></app-room-tab>
    </ng-container>
  </div>

  <p class="validation-msg-above" *ngIf="nextStepMsg">{{ nextStepMsg|translate }}</p>

  <app-booking-nav
    [backLink]="'/:lang/reservation'"
    [nextLink]="'/:lang/reservation/step-3'"
    [blocked]="buttonBlocked"
    [nextText]="'next_step'"
  ></app-booking-nav>

  <app-footer></app-footer>
</div>

<app-popup-content
  #popup
  [inPlace]="true"
  [hideFirstPart]="true"
  [borderRadius]="true"
  [fixedCloseBtn]="true"
  rwdClasses="col-12"
  popupParentClasses="w-100"
  popupContentClasses="w-100"
  content2ParentClasses="' '"
>
  <div content2>
    <app-shared-room-details
      [topPadding]="false"
      [showBackLink]="false"
      [item]="roomDetails"
      [tags]="roomTags"
    ></app-shared-room-details>
  </div>
</app-popup-content>
