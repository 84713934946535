import { Component, OnInit, Inject, PLATFORM_ID, HostListener, Renderer2 } from '@angular/core';
import CollageImageEnum from '@enums/CollageImageEnum';
import IImageCollage from '@interfaces/IImageCollage';
import { CookieService } from 'ngx-cookie';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import ConfigService from '@config/ConfigService';
import {ModalService} from '@services/modal.service';
import {IModal} from '@interfaces/IModal';
import {ApiUrlPipe} from '../../_pipes/api-url.pipe';

@Component({
  selector: 'app-appla',
  templateUrl: './appla.component.html',
  styleUrls: ['./appla.component.scss']
})
export class ApplaComponent implements OnInit {
  collageEnum: typeof CollageImageEnum;
  imageCollage: IImageCollage;

  modalVisible = false;
  modalUntouched = true;
  currentLang: string;
  modal: IModal = null;

  private cookieName = 'modal-';
  private scrollTopPosition = 0;

  constructor(
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private modalService: ModalService,
    private apiUrlPipe: ApiUrlPipe
  ) { }

  ngOnInit() {
    this.collageEnum = CollageImageEnum;
    this.currentLang = ConfigService.getCurrentLang();

    this.getModal();
  }

  setImageCollages() {
    if ( this.modal ) {
      this.imageCollage = {
        smallImage: {
          image: this.apiUrlPipe.transform(this.modal.smallPhoto.path)
        },
        bigImage: {
          image: this.apiUrlPipe.transform(this.modal.photo.path)
        }
      };
    }
  }

  showModal() {
    if ( isPlatformBrowser(this.platformId) ) {
      this.modalVisible = true;
      this.modalUntouched = false;

      this.scrollTopPosition = window.pageYOffset || document.documentElement.scrollTop;
      this.renderer.setStyle(this.document.body, 'top', -(this.scrollTopPosition) + 'px');
      this.renderer.addClass(this.document.body, 'noscroll');
    }
  }

  closeModal() {
    if ( isPlatformBrowser(this.platformId) ) {
      this.modalVisible = false;
      this.modalUntouched = false;

      const expire = new Date();
      expire.setDate(expire.getDate() + 365);
      this.cookieService.put(this.cookieName, 'true', { expires: expire });

      this.renderer.removeClass(this.document.body, 'noscroll');
      this.renderer.setStyle(this.document.body, 'top', null);
      window.scrollTo(0, this.scrollTopPosition);
    }
  }

  getModal() {
    this.modalService.getModal().subscribe( modal => {
      if ( modal ) {
        this.modal = modal;
        this.cookieName += modal.id + '-' + modal.updatedAt.date.toString().replace(/[ :-]/g, '');

        if (isPlatformBrowser(this.platformId)) {
          const cookie = this.cookieService.get(this.cookieName);

          if (cookie !== 'true') {
            setTimeout(() => {
              this.showModal();
            }, 2000);
          }
        }

        this.setImageCollages();
      }
    });
  }
}
