import { Component, OnDestroy, OnInit } from '@angular/core';
import IReservationRoom from 'src/app/_subprojects/reservation-form/_interfaces/IReservationRoom';
import IReservationDate from 'src/app/_subprojects/reservation-form/_interfaces/IReservationDate';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@services/auth/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nRoutePipe } from 'src/app/_pipes/i18n-route.pipe';
import { IReservationSummaryRoom } from 'src/app/_subprojects/reservation-form/_interfaces/IReservationSummary';
import { takeUntil } from 'rxjs/operators';
import { SubscribeHelper } from '@helpers/SubscribeHelper';

@Component({
  selector: 'app-my-reservations-details',
  templateUrl: './my-reservations-details.component.html',
  styleUrls: ['./my-reservations-details.component.scss']
})
export class MyReservationsDetailsComponent extends SubscribeHelper implements OnInit, OnDestroy {
  rooms: IReservationSummaryRoom[];
  stayDate: IReservationDate;
  activeRoomIndex = 0;

  payment: { isPaid: boolean; isPaymentExpired?: boolean; paymentUrl?: string; };

  shuumMEPoints = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private i18nRoute: I18nRoutePipe,
    private userService: UserService
  ) { super(); }

  ngOnInit(): void {
    this.subscribeToReservation();
  }

  subscribeToReservation(): void {
    const id = this.route.snapshot.paramMap.get('id');

    if ( id ) {
      this.userService
        .getReservation(id)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(res => {
          if ( !res ) {
            this.redirectTo404();
          } else {
            this.payment = { isPaid: res.isPaid, isPaymentExpired: res.isPaymentExpired, paymentUrl: res.paymentUrl };

            this.stayDate = {
              from: this.setDate(res.start),
              to: this.setDate(res.end)
            };

            this.shuumMEPoints = res.points === null ? '-' : res.points;

            if ( res.rooms ) {
              res.rooms.forEach(room => {
                if ( typeof room.children === 'undefined' ) {
                  room.children = (room.baby ?? 0) + (room.smallChild ?? 0) + (room.middleAgeChild ?? 0);
                }
              });
            }

            this.rooms = res.rooms;
          }
        }, _ => {
          this.redirectTo404();
        });
    } else {
      this.redirectTo404();
    }
  }

  setDate(date: string) {
    const dateArray = date.split('-');
    let value = null;

    try {
      value = new NgbDate(
        parseInt(dateArray[0], 10),
        parseInt(dateArray[1], 10),
        parseInt(dateArray[2], 10)
      );
    } catch (e) { }

    return value;
  }

  redirectTo404() {
    this.router.navigate([this.i18nRoute.transform('/:lang/404')]);
  }

  setActiveRoomIndex( index: number ) {
    this.activeRoomIndex = this.activeRoomIndex === index ? null : index;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
