<div class="gallery-filters">
  <div class="row">
    <div class="col-12">
      <div class="d-flex flex-wrap justify-content-center">
        <ng-container *ngFor="let category of categories; index as i">
          <button
            class="btn btn-bordered mx-2 my-2"
            [class.active]="category.active"
            (click)="filter(category.id)"
          >{{ category.name }}</button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
