import { Component, OnInit } from '@angular/core';
import ICarouselImages from '@interfaces/ICarouselImages';
import CarouselImageEnum from '@enums/CarouselImageEnum';
import {IImage} from '@interfaces/IImage';
import ConfigService from '@config/ConfigService';
import { ICertificate } from '@interfaces/ICertificate';
import { CertificateService } from '@services/certificate.service';

@Component({
  selector: 'app-north-restaurant',
  templateUrl: './north-restaurant.component.html',
  styleUrls: ['./north-restaurant.component.scss']
})
export class NorthRestaurantComponent implements OnInit {
  public hasSrcSetSupport: boolean;
  carouselImages: ICarouselImages[] = [];
  galleryImages: IImage[] = [];
  foodCertificates: ICertificate[] = [];
  showAmPm: boolean = false;

  constructor( private certificateService: CertificateService ) {
    this.hasSrcSetSupport = ConfigService.getHasSrcSetSupport();
    this.showAmPm = ConfigService.getCurrentLang() === 'en';
  }

  ngOnInit() {
    this.foodCertificates = this.certificateService.getFoodCertificates();

    this.setCarouselImages();
    this.setGalleryImages();
  }

  setCarouselImages() {
    const path = '../../../assets/images/carousel/food/';

    this.carouselImages.push({
      image: {
        image: path + '01.jpg',
        image2x: path + '01@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '02.jpg',
        image2x: path + '02@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '03.jpg',
        image2x: path + '03@2x.jpg',
      },
      type: CarouselImageEnum.Wide
    });
  }

  setGalleryImages() {
    const path = '../../../assets/images/gallery/food_wine';

    for (let i = 1; i < 6; i++) {
      for (let j = 1; j < 6; j++) {
        this.galleryImages.push({
          image: `${path}/${i}_slide/${j}.jpg`,
          image2x: `${path}/${i}_slide/${j}.jpg`,
        });
      }
    }
  }

}
