import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripParagraph'
})
export class StripParagraphPipe implements PipeTransform {

  transform(value: string): any {
    return value?.replace(/<\/?p.*?>/g, '');
  }

}
