import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../../_models/auth/User';
import {Router} from '@angular/router';
import {fadeAnimation} from '@helpers/animations';

@Component({
  selector: 'app-nav-user',
  templateUrl: './nav-user.component.html',
  styleUrls: ['./nav-user.component.scss'],
  animations: [
    fadeAnimation
  ]
})
export class NavUserComponent implements OnInit {

  @Input() user: User;
  @Input() isNavShort: boolean;
  @Input() loginVisible = true;
  @Input() class: string;
  @Input() active: boolean;
  @Input() isShort = false;
  @Output() onHideSubNav: EventEmitter<null> = new EventEmitter();

  constructor(
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  clickHideSubNav(event) {
    this.onHideSubNav.emit(event);
  }

}
