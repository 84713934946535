import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import RuleEnum from '@enums/RuleEnum';
import IRule, { IRuleResponse } from '@interfaces/IRule';

@Injectable({
  providedIn: 'root'
})
export class RuleService {
  private readonly apiUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.apiUrl = environment.shuumApiHost;
  }

  /**
   * Pobiera zgody potrzebne podczas rejestracji lub rezerwacji
   *
   * @param type - RuleEnum
   */
  getRules( type: RuleEnum ): Observable<IRuleResponse> {
    return this.http.get<IRuleResponse>(`${this.apiUrl}/rules/${type}`);
  }
}
