<app-after-before-grey
  width="40%"
  height="1100px"
  top="0"
  left="0"
  additionalClasses="to-top-left"
>
  <ng-container *ngIf="jobOffer">
    <div class="container mobile-padding">
      <div class="row">
        <div class="col-xl-10 offset-xl-1 pt-peta pt-md-zetta">
          <a [routerLink]="'/:lang/career'|i18nRoute">
            <img src="/assets/images/nav_arrow_dark.svg" />
          </a>
        </div>

        <div class="col-xl-10 offset-xl-1 pt-tera pt-md-peta">
          <app-career-header
            [header]="jobOffer.title"
            [image]="jobOffer.photo.path|apiUrl "
            [imageAlt]="jobOffer.photo.alt ? jobOffer.photo.alt : jobOffer.photo.name"
          >
          </app-career-header>
        </div>
      </div>
    </div>


    <app-after-before-grey
      width="40%"
      height="333px"
      top="56px"
      right="0px"
      additionalClasses="to-top-right"
    >
      <div class="container mobile-padding">
        <div class="row">
          <ng-container *ngFor="let content of jobContentInOrder; let i = index">
            <ng-container *ngIf="i !== 4">
      <!--        text -->
              <div *ngIf="content && content.type === 0" class="col-md-6 col-xl-5 offer-element hind" [ngClass]="{'offset-xl-1': i % 2 === 0, 'top-indent': (i === 0 || i === 1)}">
                <app-text-element
                  [colorTheme]="content.type === 0 ? i %2 === 0 ? 'light' : 'dark' : null"
                  [content]="content.content"
                >
                </app-text-element>
              </div>

    <!--          let's meet, send your cv -->
              <div *ngIf="content && content.type === 3" class="col-md-6 col-xl-5 offer-element" [ngClass]="{'offset-xl-1': i % 2 === 0, 'top-indent': (i === 0 || i === 1)}">
                <app-text-element
                  [colorTheme]="content.type === 0 ? i %2 === 0 ? 'light' : 'dark' : null"
                >
                  <h3>{{ 'career_lets_meet'|translate }}</h3>
                  <a href="mailto:rekrutacja@shuumhotel.pl" class="btn btn-bordered">{{ 'career_send_cv'|translate }}</a>
                  <div class="very-small-text pt-centi pt-md-deca" *ngIf="jobContentInOrder.length > 4" [innerHTML]="jobContentInOrder[4].content"></div>
                </app-text-element>
              </div>

      <!--        quote -->
              <div *ngIf="content && content.type === 2" class="col-md-6 col-xl-5 offer-element" [ngClass]="{'offset-xl-1': i % 2 === 0}">
                <app-quote
                  [content]="content.content"
                  [author]="content.author"
                  [authorPosition]="content.position"
                ></app-quote>
              </div>

      <!--        image -->
              <div *ngIf="content && content.type === 1" class="col-md-6 col-xl-5 custom-job-offer-padding image-user-content offer-element" [ngClass]="{'offset-xl-1': i % 2 === 0}">
                <img [src]="content.content.path|apiUrl"
                    [alt]="content.content.alt ? content.content.alt : content.content.name" />
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </app-after-before-grey>

    <div class="container mobile-padding">
      <ng-container *ngIf="jobOffers.length > 0">
        <h3 class="text-light-gray offset-2 pt-tera pt-md-exa">{{ 'remaining_offers'|translate}}</h3>
        <div class="row pt-giga" *ngIf="jobOffers.length > 0">
          <div *ngFor="let jobOffer of jobOffers; let i = index" class="col-12 offset-0  offset-lg-0 col-md-6 col-xl-5 pt-giga" [ngClass]="{'offset-xl-1' : i % 2 === 0, 'pt-0' : i === 0, 'pt-md-0' : i === 1 }">
            <app-text-offer
              [image]="jobOffer.photo.path|apiUrl"
              [imageAlt]="jobOffer.photo.alt ? jobOffer.photo.alt : jobOffer.photo.name"
            >
              <h3 [innerHTML]="jobOffer.title"></h3>
              <div class="pt-tera">
                <a [routerLink]="'/:lang/career/'+jobOffer.slug|i18nRoute" class="btn btn-bordered">{{ 'check_details'|translate }}</a>
              </div>
            </app-text-offer>
          </div>
        </div>

        <div class="row" *ngIf="responseCurrent < responseMax && !loading">
          <div class="col-12 text-center pt-giga content-center">
            <button class="btn btn-collapse" (click)="loadMore()">{{ 'see_more'|translate }}</button>
          </div>
        </div>

        <div class="row" *ngIf="loading">
          <div class="col-12 pt-giga">
            <app-loader [size]="30" [opacity]="'0.7'" [center]="true"></app-loader>
          </div>
        </div>
      </ng-container>
<!--      <ng-container *ngIf="jobOffers.length === 0">-->
<!--        <div class="text-center pt-exa pt-md-zetta">-->
<!--          <p class="text-light-gray h3">{{'no_offers_available'|translate|removeWidows}}</p>-->
<!--        </div>-->
<!--      </ng-container>-->
    </div>
  </ng-container>
</app-after-before-grey>

<app-footer></app-footer>
