<app-banner
  header="wellness_etiquette"
  description="wellness_etiquette_description"
  background="/assets/images/wellness_page/etiquette/banner.jpg"
  background2x="/assets/images/wellness_page/etiquette/banner@2x.jpg"
>
  <app-line-btn
    class="h4"
    scrollTo="swimmingPool"
    [noOffset]="true"
    bottomButton
  >
    <span>{{ 'see_more'|translate }}</span>
  </app-line-btn>
</app-banner>

<!--<app-images-collage-->
<!--  [images]="images"-->
<!--  [type]="collageType"-->
<!--&gt;</app-images-collage>-->



<div id="swimmingPool" class="container pt-zetta mobile-padding text-simple-centered">
  <app-text-simple-centered
    header="{{ 'wellness_etiquette_01_h'|translate}}"
  >
    <p class="p-responsive" [innerHTML]="'wellness_etiquette_01_d'|translate|newLineBreak|removeWidows"></p>
  </app-text-simple-centered>
</div>

<div class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-center">
      <div class="col-md-5 col-xl-4 col-xxl-5 offset-xl-1 col-10 offset-1 offset-md-0">
        <app-content-images-overlapping-right
          imageFront="/assets/images/wellness_page/etiquette/01_small.jpg"
          imageFront2x="/assets/images/wellness_page/etiquette/01_small@2x.jpg"
          imageFrontAlt="Double Image Left"
          imageFrontMaxWidth="239"
          imageBack="/assets/images/wellness_page/etiquette/01_big.jpg"
          imageBack2x="/assets/images/wellness_page/etiquette/01_big@2x.jpg"
          imageBackAlt="Double Image Right"
          frontBottomSpacing="6%"
          reversed="true"
          imageBackMaxWidth="346"
        ></app-content-images-overlapping-right>
      </div>

      <div class="col-md-6 col-xl-5 col-xxl-4 offset-md-1 pt-giga pt-md-zero">
        <app-indent-content header="wellness_etiquette_02_h">
          <p class="small" [innerHTML]="'wellness_etiquette_02_d'|translate|newLineBreak|removeWidows"></p>
        </app-indent-content>
      </div>
    </div>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-center flex-column-reverse flex-md-row">
      <div class="col-md-6 col-xl-5 col-xxl-4 offset-xl-1 pt-giga pt-md-zero">
        <div class="pb-mega">
          <h2 [innerHTML]="'wellness_etiquette_03_h'|translate|removeWidows"></h2>
        </div>
        <p
          class="small hind"
          [innerHTML]="
            'wellness_etiquette_03_d'
              | translate
              | newLineBreak
              | removeWidows
          "
        ></p>
      </div>

      <div class="col-10 col-md-5 col-xl-4 col-xxl-5 offset-1">
        <app-content-images-overlapping-right
          imageFront="/assets/images/wellness_page/etiquette/02_small.jpg"
          imageFront2x="/assets/images/wellness_page/etiquette/02_small@2x.jpg"
          imageFrontAlt="Double Image 1 Left"
          imageBack="/assets/images/wellness_page/etiquette/02_big.jpg"
          imageBack2x="/assets/images/wellness_page/etiquette/02_big@2x.jpg"
          imageBackAlt="Double Image 1 Right"
          frontBottomSpacing="6%"
          imageBackMaxWidth="346"
        ></app-content-images-overlapping-right>
      </div>
    </div>
  </div>

  <app-indent-content-between-images
    [header]="'wellness_etiquette_04_h'"
    [smallImage]="{ image: '/assets/images/wellness_page/etiquette/03_small.jpg', image2x: '/assets/images/wellness_page/etiquette/03_small@2x.jpg' }"
    [bigImage]="{ image: '/assets/images/wellness_page/etiquette/03_big.jpg', image2x: '/assets/images/wellness_page/etiquette/03_big@2x.jpg' }"
  >
    <p
      class="small pb-zero pb-lg-exa"
      [innerHTML]="'wellness_etiquette_04_d'|translate|newLineBreak|removeWidows"
    >
    </p>
  </app-indent-content-between-images>

  <app-indent-content-images
    [header]="'wellness_etiquette_05_h'"
    [collageType]="collageEnum.LargeTopSmall3ColBottomRight"
    [images]="imagesCollage"
  >
    <p
      class="small"
      [innerHTML]="'wellness_etiquette_05_d'|translate"
    >
    </p>
  </app-indent-content-images>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-center flex-column-reverse flex-md-row">
      <div class="col-md-6 col-xl-5 col-xxl-4 offset-xl-1 pt-giga pt-md-zero">
        <div class="pb-mega">
          <h2 [innerHTML]="'wellness_etiquette_06_h'|translate|removeWidows"></h2>
        </div>
        <p
          class="small hind"
          [innerHTML]="
            'wellness_etiquette_06_d'
              | translate
              | newLineBreak
              | removeWidows
          "
        ></p>
      </div>

      <div class="col-10 col-md-5 col-xl-4 col-xxl-5 offset-1">
        <app-content-images-overlapping-right
          imageFront="/assets/images/wellness_page/etiquette/05_small.jpg"
          imageFront2x="/assets/images/wellness_page/etiquette/05_small@2x.jpg"
          imageFrontAlt="Double Image 1 Left"
          imageBack="/assets/images/wellness_page/etiquette/05_big.jpg"
          imageBack2x="/assets/images/wellness_page/etiquette/05_big@2x.jpg"
          imageBackAlt="Double Image 1 Right"
          frontBottomSpacing="6%"
          imageBackMaxWidth="346"
        ></app-content-images-overlapping-right>
      </div>
    </div>
  </div>


  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-center">
      <div class="col-md-5 col-xl-4 col-xxl-5 offset-xl-1 col-10 offset-1 offset-md-0">
        <app-content-images-overlapping-right
          imageFront="/assets/images/wellness_page/etiquette/06_small.jpg"
          imageFront2x="/assets/images/wellness_page/etiquette/06_small@2x.jpg"
          imageFrontAlt="Double Image Left"
          imageFrontMaxWidth="239"
          imageBack="/assets/images/wellness_page/etiquette/06_big.jpg"
          imageBack2x="/assets/images/wellness_page/etiquette/06_big@2x.jpg"
          imageBackAlt="Double Image Right"
          frontBottomSpacing="-6%"
          reversed="true"
          imageBackMaxWidth="346"
        ></app-content-images-overlapping-right>
      </div>

      <div class="col-md-6 col-xl-5 col-xxl-4 offset-md-1 pt-giga pt-md-zero">
        <app-indent-content header="wellness_etiquette_07_h">
          <p class="small" [innerHTML]="'wellness_etiquette_07_d'|translate|newLineBreak|removeWidows"></p>
        </app-indent-content>
      </div>
    </div>
  </div>

  <div class="pt-zetta">
    <app-text-simple-centered
      header="{{ 'wellness_etiquette_08_h'|translate}}"
    >
      <p class="p-responsive" [innerHTML]="'wellness_etiquette_08_d'|translate|newLineBreak|removeWidows"></p>
    </app-text-simple-centered>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-center flex-column-reverse flex-md-row">
      <div class="col-md-6 col-xl-5 col-xxl-4 offset-xl-1 pt-giga pt-md-zero">
        <div class="pb-mega">
          <h2 [innerHTML]="'wellness_etiquette_09_h'|translate|removeWidows"></h2>
        </div>
        <p
          class="small hind"
          [innerHTML]="
            'wellness_etiquette_09_d'
              | translate
              | newLineBreak
              | removeWidows
          "
        ></p>
      </div>

      <div class="col-10 col-md-5 col-xl-4 col-xxl-5 offset-1">
        <app-content-images-overlapping-right
          imageFront="/assets/images/wellness_page/etiquette/07_small.jpg"
          imageFront2x="/assets/images/wellness_page/etiquette/07_small@2x.jpg"
          imageFrontAlt="Double Image 1 Left"
          imageBack="/assets/images/wellness_page/etiquette/07_big.jpg"
          imageBack2x="/assets/images/wellness_page/etiquette/07_big@2x.jpg"
          imageBackAlt="Double Image 1 Right"
          frontBottomSpacing="6%"
          imageBackMaxWidth="346"
        ></app-content-images-overlapping-right>
      </div>
    </div>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-center">
      <div class="col-md-5 col-xl-4 col-xxl-5 offset-xl-1 col-10 offset-1 offset-md-0">
        <app-content-images-overlapping-right
          imageFront="/assets/images/wellness_page/etiquette/08_small.jpg"
          imageFront2x="/assets/images/wellness_page/etiquette/08_small@2x.jpg"
          imageFrontAlt="Double Image Left"
          imageFrontMaxWidth="239"
          imageBack="/assets/images/wellness_page/etiquette/08_big.jpg"
          imageBack2x="/assets/images/wellness_page/etiquette/08_big@2x.jpg"
          imageBackAlt="Double Image Right"
          frontBottomSpacing="6%"
          reversed="true"
          imageBackMaxWidth="346"
        ></app-content-images-overlapping-right>
      </div>

      <div class="col-md-6 col-xl-5 col-xxl-4 offset-md-1 pt-giga pt-md-zero">
        <app-indent-content header="wellness_etiquette_10_h">
          <p class="small" [innerHTML]="'wellness_etiquette_10_d'|translate|newLineBreak|removeWidows"></p>
        </app-indent-content>
      </div>
    </div>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-center flex-column-reverse flex-md-row">
      <div class="col-md-6 col-xl-5 col-xxl-4 offset-xl-1 pt-giga pt-md-zero">
        <div class="pb-mega">
          <h2 [innerHTML]="'wellness_etiquette_11_h'|translate|removeWidows"></h2>
        </div>
        <p
          class="small hind"
          [innerHTML]="
            'wellness_etiquette_11_d'
              | translate
              | newLineBreak
              | removeWidows
          "
        ></p>
      </div>

      <div class="col-10 col-md-5 col-xl-4 col-xxl-5 offset-1">
        <app-content-images-overlapping-right
          imageFront="/assets/images/wellness_page/etiquette/09_small.jpg"
          imageFront2x="/assets/images/wellness_page/etiquette/09_small@2x.jpg"
          imageFrontAlt="Double Image 1 Left"
          imageBack="/assets/images/wellness_page/etiquette/09_big.jpg"
          imageBack2x="/assets/images/wellness_page/etiquette/09_big@2x.jpg"
          imageBackAlt="Double Image 1 Right"
          frontBottomSpacing="6%"
          imageBackMaxWidth="346"
        ></app-content-images-overlapping-right>
      </div>
    </div>
  </div>

  <div class="pt-zetta">
    <app-text-simple-centered
      header="{{ 'wellness_etiquette_12_h'|translate}}"
    >
      <p class="p-responsive" [innerHTML]="'wellness_etiquette_12_d'|translate|newLineBreak|removeWidows"></p>
    </app-text-simple-centered>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 offset-xl-1">
        <div class="out-mobile-padding">
          <app-images-carousel
            [images]="carouselImages"
          >
          </app-images-carousel>
        </div>
      </div>
      <div class="col-12 col-lg-5 col-xl-4 offset-lg-1">
        <div class="pt-kilo pt-md-giga pt-lg-zero">
          <app-indent-content
            [header]="'our_team'"
          >
            <p
              class="small"
              [innerHTML]="'our_team_description'|translate|newLineBreak|removeWidows"
            >
            </p>

            <div class="actions pt-mega pb-deca">
              <a [routerLink]="'/:lang/career'|i18nRoute" class="btn btn-bordered mb-deci">{{ 'join_us'|translate }}</a>
            </div>
          </app-indent-content>
        </div>
      </div>
    </div>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-end">
      <div class="col-10 offset-lg-1 offset-xl-2">
        <h3 class="text-light-gray" [innerHTML]="'certifications'|translate|removeWidows"></h3>
      </div>
      <div class="col-12 col-md-6 col-lg-5 col-xl-4 offset-lg-1 offset-xl-2 order-1 order-md-0">
        <p
          class="pt-giga pt-lg-peta small"
          [innerHTML]="'certifications_description'|translate|newLineBreak|removeWidows"
        ></p>
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-3 offset-lg-1 order-0 order-md-1">
        <div class="pt-giga pt-md-zero">
          <a
            href="https://www.wellnessverband.de/wellness-freunde/anbieter/detail.php?we_oid=245"
            target="_blank"
            class="opacity-link"
          >
            <app-text-centered-image
              header="{{ 'award_h_04'|translate}}"
              image="/assets/images/wellness_page/certificate.png"
              image2x="/assets/images/wellness_page/certificate@2x.png"
              imgMaxHeight="118px"
              imageAlt="certificate logo"
            ></app-text-centered-image>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
