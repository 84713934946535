import {Component, Input, OnInit} from '@angular/core';
import {IImage} from '@interfaces/IImage';
import ConfigService from '@config/ConfigService';

@Component({
  selector: 'app-simple-gallery',
  templateUrl: './simple-gallery.component.html',
  styleUrls: ['./simple-gallery.component.scss']
})
export class SimpleGalleryComponent implements OnInit {
  @Input() images?: IImage[] = [];

  public hasSrcSetSupport: boolean;

  constructor() {
    this.hasSrcSetSupport = ConfigService.getHasSrcSetSupport();
  }

  ngOnInit(): void {
  }

}
