import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IConfiguration} from '@interfaces/IConfiguration';
import ApiConfigurationEndpointEnum from '@enums/ApiConfigurationEndpointEnum';
import {takeUntil} from 'rxjs/operators';
import {catchError} from 'rxjs/internal/operators';
import {ConfigurationService} from '@services/configuration.service';
import {SubscribeHelper} from '@helpers/SubscribeHelper';

@Component({
  selector: 'app-wellness-menu-btn',
  templateUrl: './wellness-menu-btn.component.html',
  styleUrls: ['./wellness-menu-btn.component.scss']
})
export class WellnessMenuBtnComponent extends SubscribeHelper implements OnInit, OnDestroy {

  public wellnessMenuFile: IConfiguration = null;

  @Input() text: string;
  @Input() btnClass: string;

  constructor(
    private apiConfiguration: ConfigurationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getWellnessMenuFile();
  }

  getWellnessMenuFile(): void {
    this.apiConfiguration
      .getConfiguration(ApiConfigurationEndpointEnum.WellnessMenu)
      .pipe(
        takeUntil(this.componentDestroyed$),
        catchError(err => {
          this.wellnessMenuFile = { id: null, value: '', iso: '', name: '' };
          return err;
        })
      )
      .subscribe((res: IConfiguration) => {
        this.wellnessMenuFile = res;
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
