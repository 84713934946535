<app-banner
  header="restaurant_a_la_carte"
  description="restaurant_a_la_carte_description"
  background="/assets/images/food_and_wine/a_la_carte/banner.jpg"
  background2x="/assets/images/food_and_wine/a_la_carte/banner@2x.jpg"
>
  <app-line-btn
    class="h4"
    scrollTo="a-la-carte-restaurant"
    [noOffset]="true"
    bottomButton
  >
    <span>{{ "know_more" | translate }}</span>
  </app-line-btn>
</app-banner>

<div class="container pt-peta pt-md-zetta mobile-padding text-simple-centered">
  <app-text-simple-centered>
    <p class="p-responsive" [innerHTML]="'restaurant_a_la_carte_menu_d'|translate|newLineBreak|removeWidows"></p>
    <div class="actions pt-mega pb-md-kilo">
      <app-ala-carte-menu-btn text="restaurant_a_la_carte_menu_cta"></app-ala-carte-menu-btn>
    </div>
  </app-text-simple-centered>
</div>

<div id="a-la-carte-restaurant" class="container mobile-padding">
  <div class="pt-giga">
    <app-text-simple-centered
      header="{{ 'restaurant_a_la_carte_h_01' | translate }}"
      maxHeaderWidth="500px"
    >
      <p
        class="p-responsive"
        [innerHTML]="
          'restaurant_a_la_carte_c_01' | translate | newLineBreak | removeWidows
        "
      ></p>
    </app-text-simple-centered>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-center flex-column-reverse flex-md-row">
      <div class="col-md-6 col-xl-5 col-xxl-4 offset-xl-1 pt-giga pt-md-zero">
        <p
          class="small hind"
          [innerHTML]="
            'restaurant_a_la_carte_c_02'
              | translate
              | newLineBreak
              | removeWidows
          "
        ></p>
      </div>

      <div class="col-10 col-md-5 col-xl-4 col-xxl-5 offset-1">
        <app-content-images-overlapping-right
          imageFront="/assets/images/food_and_wine/a_la_carte/section_1_left.jpg"
          imageFront2x="/assets/images/food_and_wine/a_la_carte/section_1_left@2x.jpg"
          imageFrontAlt="Double Image 1 Left"
          imageBack="/assets/images/food_and_wine/a_la_carte/section_1_right.jpg"
          imageBack2x="/assets/images/food_and_wine/a_la_carte/section_1_right@2x.jpg"
          imageBackAlt="Double Image 1 Right"
          frontBottomSpacing="8%"
          imageBackMaxWidth="346"
        ></app-content-images-overlapping-right>
      </div>
    </div>
  </div>
</div>

<app-after-before-grey
  width="48%"
  height="1262px"
  top="400px"
  right="0px"
  additionalClasses="to-top"
>
  <div class="container mobile-padding">
    <div class="pt-peta pt-md-zetta pt-lg-200">
      <div class="row">
        <div class="col-10 offset-1 offset-md-0 col-md-6 img-bigger">
          <img
            [defaultImage]="'/assets/images/placeholders/placeholder610x619.png'"
            [lazyLoad]="
              hasSrcSetSupport
              ? '/assets/images/food_and_wine/a_la_carte/section_2.png 1x, /assets/images/food_and_wine/a_la_carte/section_2@2x.png 2x'
              : '/assets/images/food_and_wine/a_la_carte/section_2.png'
            "
            [useSrcset]="hasSrcSetSupport"
            [alt]="'restaurant_a_la_carte_c_03'|translate"
          />
        </div>

        <div class="col-md-6 col-xl-5 col-xxl-4">
          <app-indent-content header="restaurant_a_la_carte_h_03">
            <p
              class="small hind"
              [innerHTML]="
                'restaurant_a_la_carte_c_03'
                  | translate
                  | newLineBreak
                  | removeWidows
              "
            ></p>
            <a class="btn btn-bordered mt-kilo" [routerLink]="'/:lang/food/a-la-carte-menu-child'|i18nRoute">{{
              "see_menu" | translate
            }}</a>
          </app-indent-content>
        </div>
      </div>
    </div>
  </div>
</app-after-before-grey>

<div class="container wide mobile-padding chiefs-container">
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-chiefs
      [topHeader]="
        'restaurant_a_la_carte_h_04' | translate | newLineBreak | removeWidows
      "
      [imageLeft]="{
        image: '/assets/images/food_and_wine/a_la_carte/section_3_left.jpg',
        image2x:
          '/assets/images/food_and_wine/a_la_carte/section_3_left@2x.jpg',
        alt: 'Piotr Buryło'
      }"
      [imageRight]="{
        image: '/assets/images/food_and_wine/a_la_carte/section_3_right.jpg',
        image2x:
          '/assets/images/food_and_wine/a_la_carte/section_3_right@2x.jpg',
        alt: 'Piotr Buryło'
      }"
    >
      <p
        class="small hind"
        [innerHTML]="
          'restaurant_a_la_carte_c_04' | translate | newLineBreak | removeWidows
        "
      ></p>
      <div class="pt-mega pt-xl-tera">
        <img
          width="113px"
          src="/assets/images/food_and_wine/a_la_carte/piotr-burylo.svg"
          alt="Piotr Buryło"
        />
      </div>
      <p class="pt-hecto">
        {{ "restaurant_a_la_carte_c_04_title" | translate }}
      </p>
      <p class="light-subtitle pt-deci">
        {{ "restaurant_a_la_carte_c_04_position" | translate }}
      </p>
    </app-chiefs>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-center">
      <div class="col-10 offset-1 offset-md-0 col-md-5 align-items-center">
        <app-content-images-overlapping-right
          imageFront="/assets/images/food_and_wine/a_la_carte/section_4_right.jpg"
          imageFront2x="/assets/images/food_and_wine/a_la_carte/section_4_right@2x.jpg"
          imageFrontAlt="Chief Image 1"
          imageBack="/assets/images/food_and_wine/a_la_carte/section_4_left.jpg"
          imageBack2x="/assets/images/food_and_wine/a_la_carte/section_4_left@2x.jpg"
          imageBackAlt="Chief Image 1"
          reversed="true"
          classes="no-padding reversed-order bigger-images"
        ></app-content-images-overlapping-right>
      </div>
      <div class="col-md-4 offset-md-1">
        <div class="pt-mega pt-md-0">
          <p
            class="small hind"
            [innerHTML]="
              'restaurant_a_la_carte_c_05'
                | translate
                | newLineBreak
                | removeWidows
            "
          ></p>
          <div class="pt-mega pt-xl-tera">
            <img
              src="/assets/images/food_and_wine/a_la_carte/wiktor-ignaczak.svg"
              alt="Wiktor Ignaczak"
            />
          </div>
          <p class="pt-hecto">
            {{ "restaurant_a_la_carte_c_05_title" | translate }}
          </p>
          <p class="light-subtitle pt-deci">
            {{ "restaurant_a_la_carte_c_05_position" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200" *ngIf="foodCertificates && foodCertificates.length > 0">
    <div class="offset-lg-1 offset-xl-2 text-light-gray">
      <h3 [innerHtml]="'certifications' | translate | removeWidows"></h3>
    </div>
    <div class="pt-mega pt-md-peta">
      <app-item-slider [itemTpl]="itemTpl" [items]="foodCertificates">
      </app-item-slider>

      <ng-template let-item #itemTpl>
        <app-certificate-part [data]="item"></app-certificate-part>
      </ng-template>
    </div>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 offset-xl-1">
        <div class="out-mobile-padding">
          <app-images-carousel [images]="carouselImages"> </app-images-carousel>
        </div>
      </div>
      <div class="col-12 col-lg-5 col-xl-4 offset-lg-1">
        <div class="pt-kilo pt-md-giga pt-lg-zero">
          <app-indent-content [header]="'our_team'">
            <p
              class="small"
              [innerHTML]="
                'food_and_wine_our_team'
                  | translate
                  | newLineBreak
                  | removeWidows
              "
            ></p>

            <div class="actions pt-mega">
              <a
                [routerLink]="'/:lang/career' | i18nRoute"
                class="btn btn-bordered"
                >{{ "join_us" | translate }}</a
              >
            </div>
          </app-indent-content>
        </div>
      </div>
    </div>
  </div>
</div>
<app-after-before-grey
  width="42%"
  height="760px"
  top="-23%"
  left="0"
  additionalClasses="to-top-left no-responsive"
>
  <div class="container mobile-padding">
    <div class="pt-peta pt-md-zetta pt-lg-200">
      <div class="row">
        <h2 class="gray-opacity col-md-11 col-lg-10 offset-md-1 offset-lg-2 pb-kilo pb-md-giga pb-xl-zetta">
          {{ "opening_hours" | translate }}
        </h2>
        <div class="col-md-10 col-lg-9 offset-md-2 offset-lg-3">
          <div class="row">
            <div class="col-xl-7 pb-mega pb-xl-0">
              <div class="opening-hours pb-deca pb-md-hecto">
                <h4 class="h1">13:00<span> - </span>23:00</h4>
              </div>
              <p>{{ "every_day"|translate }}</p>
            </div>
            <div class="col-xl-5 pt-centi pb-mega pb-xl-0">
              <h5 class="gray-opacity h3">13:00<span> - </span>22:30</h5>
              <p class="gray-opacity pt-deci pt-xl-centi">{{ 'accepting_orders'|translate}}</p>
            </div>
            <div class="pt-xl-tera col-12 pt-zero">
              <p class="pb-deci pb-xl-deca">{{ 'reserve_table'|translate}}:</p>
              <h5 class="h3"><a href="tel:+48943554020" class="no-underline">+48 94 355 4020</a></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-after-before-grey>
<div class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-gallery
      [header]="'food_and_wine'"
      [description]="'food_and_wine_gallery_description'"
      [images]="galleryImages"
    ></app-gallery>
  </div>

<!--  <div class="pt-peta pt-md-zetta pt-lg-200">-->
<!--    <app-discover-->
<!--      [header]="'discover_also'"-->
<!--      [name]="'Intense Repair\nShea Butter'"-->
<!--      [description]="-->
<!--        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit in voluptate velit esse.'-->
<!--      "-->
<!--      [price]="{ value: 49.99, currency: 'PLN' }"-->
<!--      [link]="''"-->
<!--    ></app-discover>-->
<!--  </div>-->
</div>

<app-footer></app-footer>
