<div
  *ngIf="notification"
  class="notification-modal-wrapper"
  [ngClass]="{'modal-visible': modalVisible, 'modal-hidden': !modalVisible, 'modal-untouched': modalUntouched}"
>
  <div class="d-flex justify-content-center align-items-center h-100">
    <div class="notification-modal position-relative w-100">
      <button class="close-popup" (click)="closeModal()">
        <img src="/assets/images/close.svg" alt="close" />
      </button>
      <div class="notification-modal-content">
        <h4 class="pb-3" [innerHTML]="'notifications'|translate"></h4>
        <span>{{ notification?.created_at?.date | datex:'DD MMMM yyyy' }}</span>
        <p class="pt-3" [innerHTML]="notification?.content|removeWidows|newLineBreak"></p>
        <div *ngIf="notification?.event_link" class="d-flex justify-content-center mt-deci">
          <a
            class="btn btn-bordered on-white"
            target="_blank"
            [href]="notification?.event_link"
            (click)="closeModal()"
          >{{ 'know_more'|translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
