import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss']
})
export class HamburgerComponent implements OnInit {
  @Input()
  isActive = false;

  constructor() { }

  ngOnInit() {
  }

  toggleClass() {
    this.isActive = !this.isActive;
  }
}
