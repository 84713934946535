enum ReservationExtraTypeEnum {
  extra,
  treatment,
  extraGroup,
  category,
  offer,
  dinner
}

export default ReservationExtraTypeEnum;
