import { Pipe, PipeTransform } from '@angular/core';
import IReservationExtraNew from '../_subprojects/reservation-form/_interfaces/IReservationExtraNew';

@Pipe({
  name: 'filterExtras'
})
export class FilterExtrasPipe implements PipeTransform {

  transform(extras: IReservationExtraNew[], fields: { [key: string]: boolean }): IReservationExtraNew[] {
    return extras?.filter(extra => {
      for (let field in fields) {
        return !!extra[field] === fields[field];
      }
    });
  }

}
