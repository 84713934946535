<ng-container *ngIf="asset?.type === assetTypeEnum.Image">
  <img [src]="asset?.path|apiUrl" [alt]="asset?.alt||asset?.name" *ngIf="asset" />
</ng-container>

<ng-container *ngIf="asset?.type === assetTypeEnum.Video">
  <video playsinline muted="muted" autoplay loop *ngIf="asset?.path" [poster]="asset?.thumb|apiUrl" #videoElement>
    <source [src]="asset?.webm|apiUrl" type="video/webm" *ngIf="asset?.webm" />
    <source [src]="asset?.path|apiUrl" type="video/mp4" *ngIf="asset?.path" />
  </video>
</ng-container>
