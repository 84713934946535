<div [className]="(classes ? classes : null) + ' text-simple-centered'">
  <div class="row flex-center">
    <div class="col-md-8 col-12">
      <h2 class="heading" *ngIf="header" [ngStyle]="{'max-width': maxHeaderWidth}">{{header}}</h2>
      <div [ngClass]="{'pt-kilo pt-lg-giga': header}">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
