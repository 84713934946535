import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatepickerPopupComponent } from './datepicker-popup.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgbDateParserFormatter, NgbDatepickerI18n, NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MomentDateFormatter} from '@shared/datepicker-popup/moment-date-formatter';
import {CustomDatepickerI18n} from '../../_subprojects/reservation-form/pages/step-one/_shared/datepicker/datepicker-i18n';



@NgModule({
  declarations: [
    DatepickerPopupComponent
  ],
  exports: [
    DatepickerPopupComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    NgbDatepickerModule
  ],
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: MomentDateFormatter }
  ],
})
export class DatepickerPopupModule { }
