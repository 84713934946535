import {Component, Inject, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {UserService} from '@services/auth/user.service';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {INotification} from '../../../_interfaces/INotification';
import {NotificationsService} from '../../../_services/notifications.service';
import IFilter from '@interfaces/IFilter';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})
export class NotificationPopupComponent extends SubscribeHelper implements OnInit {

  modalVisible = false;
  modalUntouched = true;
  notification: INotification;

  private scrollTopPosition = 0;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private userService: UserService,
    private notificationsService: NotificationsService
  ) {
    super();
  }

  ngOnInit() {
    this.userService.isLoggedIn().pipe(
      takeUntil(this.componentDestroyed$),
      filter(isLoggedIn => isLoggedIn),
    ).subscribe(_ => {
      const user = this.userService.userValue;
      if (user.loyaltyProgram) {
        this.getNotifications();
      }
    });
  }

  getNotifications() {
    const filters: IFilter[] = [];

    filters.push({
      key: 'type',
      value: 'unclosed'
    });

    this.notificationsService.getNotifications(filters, 1).pipe(
      takeUntil(this.componentDestroyed$),
      tap((notifications) => {
        const notificationsQuantity = notifications?.headers['Total-Unread'];
        this.notificationsService.setNotificationsQuantity(notificationsQuantity);
      }),
      filter(notifications => !!notifications.list.length)
    ).subscribe(notifications => {
      this.notification = notifications.list[0];

      if (this.notification) {
        this.showModal();
      }
    });
  }

  showModal() {
    if ( isPlatformBrowser(this.platformId) ) {
      this.modalVisible = true;
      this.modalUntouched = false;

      this.scrollTopPosition = window.pageYOffset || document.documentElement.scrollTop;
      this.renderer.setStyle(this.document.body, 'top', -(this.scrollTopPosition) + 'px');
      this.renderer.addClass(this.document.body, 'noscroll');
    }
  }

  closeModal() {
    if ( isPlatformBrowser(this.platformId) ) {
      this.modalVisible = false;
      this.modalUntouched = false;

      this.markAsClosed();

      this.renderer.removeClass(this.document.body, 'noscroll');
      this.renderer.setStyle(this.document.body, 'top', null);
      window.scrollTo(0, this.scrollTopPosition);
    }
  }

  markAsClosed() {
    const notification: INotification = {
      ...this.notification,
      is_closed: 1
    };


    this.notificationsService.updateNotification(notification.id, { is_closed: 1 }).pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(n => {
      this.notificationsService.setNotification(notification);
    });
  }

}
