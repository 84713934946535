<app-step-header
  header="make_payment"
></app-step-header>

<div class="reservation-step">
  <div class="pt-xl-deci d-md-flex align-items-end pb-deci pb-sm-mega pb-md-mega pb-lg-deca">
    <div class="flex-grow-1 pb-centi pb-kilo pb-md-zero">
      <app-reservation-term
        header="summary"
        [date]="stayDate"
      ></app-reservation-term>
    </div>
    <div class="flex-shrink-0">
      <div class="d-flex align-items-center">
        <div class="mr-deci" *ngIf="copyMsg?.loading">
          <app-loader [size]="20" [opacity]="'1'" [center]="true"></app-loader>
        </div>
        <button
          class="btn btn-hover pb-zero pl-zero pr-zero d-flex align-items-center"
          [disabled]="copyMsg?.loading"
          [ngClass]="{'disabled': copyMsg?.loading}"
          (click)="copyReservationLink()"
        >
          <span class="btn-small-underlined">{{ 'copy_reservation_link'|translate }}</span>
          <img src="/assets/images/reservations/icons/link.svg" class="pl-deci"/>
        </button>
      </div>
      <div class="copy-toast" *ngIf="copyMsg?.error || copyMsg?.success" [@fadeAnimation]>
        <span>{{ (copyMsg.success || copyMsg.error) |translate }}</span>
      </div>
    </div>
  </div>

  <div class="rooms pb-deca pb-xl-hecto">
    <ng-container *ngFor="let room of rooms; index as i">
      <app-room-tab-payment
        [room]="room"
        [roomSummary]="summary?.rooms[i]"
        [index]="i"
        [isActive]="i === activeRoomIndex"
        (tabClickHandler)="setActiveRoomIndex($event)"
      ></app-room-tab-payment>
    </ng-container>
  </div>

  <div class="bg-white bordered-element pt-hecto pb-hecto pt-lg-hecto pb-lg-hecto pl-deci pr-deci pl-sm-56 pr-sm-56 pl-xxl-peta pr-xxl-peta payment-container">
    <app-payment-info
      [finalPrice]="summary?.bookingPrice"
      [finalSubprice]="summary?.paymentBookingPrice"
      [advancePercent]="advancePercent"
      [shuummeDiscount]="shuummeDiscount"
      (checkboxChanged)="applyShuummePoints($event)"
    ></app-payment-info>
  </div>

  <app-booking-nav
    [backLink]="'/:lang/reservation/step-4'"
    [blocked]="processingForPaymentLink"
    [nextText]="'book_obligation'"
    [loading]="processingForPaymentLink"
    (clickHandler)="getPaymentLink($event)"
  ></app-booking-nav>

  <app-footer></app-footer>
</div>

