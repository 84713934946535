import {Injectable} from '@angular/core';
import {routes} from '../app-routing.module';
import { routes as wellnesRoutes } from '../pages/wellness/wellness-routing.module';
import { routes as careerRoutes } from '../pages/career/career-routing.module';
import { routes as foodRoutes } from '../pages/food/food-routing.module';
import { routes as myReservationRoutes } from '../_subprojects/reservation/_pages/reservations-routing.module';
import { routes as blogostanRoutes } from '../_subprojects/blogostan/_pages/blogostan-routing.module';
// import { routes as reservationRoutes } from '../_subprojects/reservation/_pages/reservations-routing.module';
import { routes as reservationFormRoutes } from '../_subprojects/reservation-form/reservation-form-routing.module';
import * as isEmpty from 'lodash/isEmpty';
import * as forEach from 'lodash/forEach';
import {environment} from '../../environments/environment';
import {Routes} from '@angular/router';
import { PossibleTranslationsEnum } from '@enums/PossibleTranslationsEnum';

@Injectable()
export class RouteMapHelper {
  public groups: object = {};

  constructor() {
    this.prepareGroups();
  }

  private prepareGroups(): void {
    if ( isEmpty( this.groups || null ) ) {
      forEach(routes, (value, key) => {
        if (value.path === environment.routeLanguageKey) {
          const children: Routes = value.children;

          this.parseRoutes( null, children );

          return false;
        }
      });

      if ( wellnesRoutes ) {
        this.parseRoutes( 'wellness', wellnesRoutes );
      }

      if ( careerRoutes ) {
        this.parseRoutes( 'career', careerRoutes );
      }

      if ( foodRoutes ) {
        this.parseRoutes( 'food', foodRoutes );
      }

      if ( myReservationRoutes ) {
        this.parseRoutes( 'my-reservation', myReservationRoutes );

        myReservationRoutes.forEach(reservationRoute => {
          const children = reservationRoute.children;
          if( children ) {
            this.parseRoutes( 'my-reservation', children );

            children.forEach(child => {
              if( child.children ) {
                const group = child.path === '' ? '' : `_${child.data.group}`;
                this.parseRoutes( `my-reservation${group}`, child.children );

                child.children.forEach(child2 => {
                  if( child2.children ) {
                    const group2 = child2.path === '' ? '' : `_${child2.data.group}`;
                    this.parseRoutes( `my-reservation${group}${group2}`, child2.children );

                    child2.children.forEach(child3 => {
                      if( child3.children ) {
                        const group3 = child3.path === '' ? '' : `_${child3.data.group}`;
                        this.parseRoutes( `my-reservation${group}${group2}${group3}`, child3.children );
                      }
                    })
                  }
                })
              }
            })
          }
        })
      }

      if ( blogostanRoutes ) {
        this.parseRoutes( 'blogostan', blogostanRoutes );

        blogostanRoutes.forEach(blogostanRoute => {
          const children = blogostanRoute.children;
          if( children ) {
            this.parseRoutes( 'blogostan', children );

            children.forEach(child => {
              if( child.children ) {
                const group = child.path === '' ? '' : `_${child.data.group}`;
                this.parseRoutes( `blogostan${group}`, child.children );
              }
            })
          }
        })
      }

      if ( reservationFormRoutes ) {
        this.parseRoutes( 'reservation', reservationFormRoutes );

        reservationFormRoutes.forEach(reservationRoute => {
          const children = reservationRoute.children;
          if( children ) {
            this.parseRoutes( 'reservation', children );

            children.forEach(child => {
              if( child.children ) {
                this.parseRoutes( `reservation_${child.data.group}`, child.children );
              }
            })
          }
        })
      }
    }
  }

  private parseRoutes( prefix, routes ) {
    forEach(routes, (childVal, childKey) => {
      const data = childVal.data || {};
      const languages = data.forLanguages || [];
      let group = data.group || null;

      if (group) {
        group = (prefix ? prefix + '_' : '') + group;

        if (typeof this.groups[group] === 'undefined') {
          this.groups[group] = {};
        }

        languages.forEach(l => {
          this.groups[group][l] = childVal.path;
        });
      }
    });
  }

  public getGroups(): object {
    return this.groups;
  }

  public getRoute( key: string, lang: string ): string|null {
    if ( typeof this.groups[key] !== 'undefined' ) {
      if ( typeof this.groups[key][lang] !== 'undefined' ) {
        return this.groups[key][lang];
      }
    }

    return null;
  }

  public getGroup( path: string ): string {
    for ( let [key, value] of Object.entries(this.groups) ) {
      for ( let lang in PossibleTranslationsEnum ) {
        const enumVal = PossibleTranslationsEnum[lang];
        if( value[enumVal] && path === value[enumVal] ) {
          return key;
        }
      }
    }

    return '';
  }


}
