import { environment } from '../../environments/environment';

abstract class ConfigService {
  private static currentLang = null;
  private static currentRoute = '';
  private static previousRoute = '';
  private static currentRouteGroupPath: string[] = [];
  private static hasSrcSetSupport = null;

  public static getDefaultLang(): string {
    return environment.defaultLanguage;
  }

  public static getCurrentLang(): string|null {
    return this.currentLang ? this.currentLang : this.getDefaultLang();
  }

  public static setCurrentLang(value: any) {
    if ( typeof sessionStorage !== 'undefined' ) {
      const lang = sessionStorage.getItem('lang');

      if ( lang ) {
        if ( lang !== value ) {
          sessionStorage.setItem('langChanged', '1');
        }
      }

      sessionStorage.setItem('lang', value);
    }

    this.currentLang = value;
  }

  public static getLanguages(): Array<string> {
    return environment.languages;
  }

  public static getCurrentCurrency() {
    const currencies = environment.languageData;
    let currency = null;

    currencies.forEach( curr => {
      if ( curr.lang === this.getCurrentLang() ) {
        currency = curr.currency;
      }
    });

    return currency;
  }

  public static setCurrentRouteGroupPath( groups: string[] ): void {
    this.currentRouteGroupPath = groups;
  }

  public static getCurrentRouteGroupPath(): string[] {
    return this.currentRouteGroupPath;
  }

  public static getHasSrcSetSupport(): boolean {
    if ( this.hasSrcSetSupport !== null ) {
      return this.hasSrcSetSupport;
    }

    try {
      const img = new Image();
      this.hasSrcSetSupport = 'srcset' in img;

      return this.hasSrcSetSupport;
    }
    catch (e) {
      return true;
    }
  }

  public static isMobile(): boolean {
    try {
      return (typeof window.screen.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
    }
    catch (e) {
      return false;
    }
  }

  public static setCurrentRoute( route: string ): void {
    this.previousRoute = this.currentRoute;
    this.currentRoute = route;
  }

  public static getCurrentRoute(): string {
    return this.currentRoute;
  }

  public static getPreviousRoute(): string {
    return this.previousRoute;
  }
}

export default ConfigService;
