<div
  [ngClass]="{ 'pt-peta pt-md-zetta pt-lg-200': !removeTopSpacing, 'indent-moved': indentMoved }"
>
  <div
    class="header"
    *ngIf="topHeader"
  >
    <h6 class="the-biggest">{{ topHeader|translate }}</h6>
  </div>
  <div class="row">
    <div [class]="leftCol">
      <div class="out-mobile-padding">
        <app-images-collage
          [images]="images"
          [type]="collageType"
        ></app-images-collage>
      </div>
    </div>
    <div [class]="rightCol">
      <div class="pt-kilo pt-md-tera">
        <app-indent-content
          [header]="header"
        >
          <ng-content></ng-content>
        </app-indent-content>
      </div>
    </div>
  </div>
</div>
