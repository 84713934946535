import { Pipe, PipeTransform } from '@angular/core';
import {not} from 'rxjs/internal-compatibility';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value: number): number {
    const str = value.toString();
    let decimalOnly = 0;
    let notDecimal = 0;

    if ( str.indexOf('.') !== -1 ) {
      const split = Math.abs(value).toString().split('.');
      notDecimal = parseFloat(split[0]);
      decimalOnly = parseFloat(split[1]);
    }

    if ( isNaN(decimalOnly) ) {
      return notDecimal;
    }

    return value;
  }

}
