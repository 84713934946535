<app-banner
  header="yoga_and_fitness"
  [withOverlay]="false"
  textColor="text-main"
  description="yoga_and_fitness_description"
  background="../../../assets/images/wellness_page/yoga/banner_tiny.jpg"
  background2x="../../../assets/images/wellness_page/yoga/banner@2x_tiny.jpg"
>
  <app-line-btn
    class="h4 dark-btn"
    scrollTo="yoga"
    [noOffset]="true"
    bottomButton
  >
    <span>{{ 'see_more'|translate }}</span>
  </app-line-btn>
</app-banner>

<div id="yoga" class="container pt-zetta mobile-padding text-simple-centered">
  <app-text-simple-centered
    header="{{ 'yoga_and_fitness_text_centered_h'|translate}}"
  >
    <p class="p-responsive" [innerHTML]="'yoga_and_fitness_text_centered_c'|translate|newLineBreak|removeWidows"></p>
  </app-text-simple-centered>
</div>


<div class="container mobile-padding">
  <app-indent-content-images
    [header]="'yoga'"
    [topHeader]="'yoga_and_fitness'"
    [collageType]="collageEnum.LargeTopSmall2ColBottomRight"
    [images]="imageCollage"
  >
    <p
      class="small"
      [innerHTML]="'yoga_description'|translate|newLineBreak|removeWidows"
    >
    </p>

    <div class="pt-mega">
      <p
        class="tiny text-light-gray"
        [innerHTML]="'yoga_description_note'|translate|newLineBreak|removeWidows"
      ></p>
    </div>

    <div class="actions pt-mega pb-kilo" *ngIf="configuration">
      <a [href]="configuration.value | apiUrl" target="_blank" class="btn btn-bordered">{{ 'check_schedule'|translate }}</a>
    </div>
  </app-indent-content-images>
</div>

<app-after-before-grey
  width="22%"
  height="732px"
  top="60%"
  left="0"
  additionalClasses="to-top-left thick-no-responsive offset-top-sm"
>
  <div class="container mobile-padding">
    <app-indent-content-between-images
      [header]="'pilates'"
      [topHeader]="'yoga_and_fitness'"
      [smallImage]="{ image: '../../../assets/images/wellness_page/yoga/pilates_small_tiny.jpg', image2x: '../../../assets/images/wellness_page/yoga/pilates_small@2x_tiny.jpg' }"
      [bigImage]="{ image: '../../../assets/images/wellness_page/yoga/pilates_big_tiny.jpg', image2x: '../../../assets/images/wellness_page/yoga/pilates_big@2x_tiny.jpg' }"
    >
      <p
        class="small pb-xxl-zetta pb-md-tera"
        [innerHTML]="'pilates_description'|translate|newLineBreak|removeWidows"
      >
      </p>
    </app-indent-content-between-images>
  </div>
</app-after-before-grey>

<div class="container mobile-padding">
  <app-indent-content-images
    [header]="'aquafitness'"
    [topHeader]="'yoga_and_fitness'"
    [collageType]="collageEnum.LargeTopSmall2ColBottomRight"
    [images]="imageCollage2"
  >
    <p
      class="small"
      [innerHTML]="'aquafitness_description'|translate|newLineBreak|removeWidows"
    >
    </p>
  </app-indent-content-images>
</div>

<app-after-before-grey
  width="17%"
  height="721px"
  top="72%"
  right="0"
  additionalClasses="to-top-right thick-no-responsive offset-top-sm"
>
<div class="container mobile-padding">
    <app-indent-content-between-images
      [header]="'nordic_walking'"
      [topHeader]="'yoga_and_fitness'"
      [smallImage]="{ image: '../../../assets/images/wellness_page/yoga/nordic_walking_small_tiny.jpg', image2x: '../../../assets/images/wellness_page/yoga/nordic_walking_small@2x_tiny.jpg' }"
      [bigImage]="{ image: '../../../assets/images/wellness_page/yoga/nordic_walking_big_tiny.jpg', image2x: '../../../assets/images/wellness_page/yoga/nordic_walking_big@2x_tiny.jpg' }"
    >
      <p
        class="small"
        [innerHTML]="'nordic_walking_description'|translate|newLineBreak|removeWidows"
      >
      </p>

      <div class="actions pt-mega pb-deca" *ngIf="configuration">
        <a [href]="configuration.value | apiUrl" target="_blank" class="btn btn-bordered mb-deci">{{ 'check_schedule'|translate }}</a>
      </div>
    </app-indent-content-between-images>
  </div>
</app-after-before-grey>

<div class="container mobile-padding">
  <app-indent-content-images
    [header]="'bike'"
    [topHeader]="'yoga_and_fitness'"
    [collageType]="collageEnum.LargeTopSmall2ColBottomRight"
    [images]="imageCollage3"
  >
    <p
      class="small"
      [innerHTML]="'bike_description'|translate|newLineBreak|removeWidows"
    >
    </p>
  </app-indent-content-images>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-gallery
      [header]="'wellness_and_spa'"
      [description]="'wellness_and_spa_gallery_description'"
      [images]="galleryImages"
    ></app-gallery>
  </div>

  <!--  <div class="pt-peta pt-md-zetta pt-lg-200">-->
  <!--    <app-discover-->
  <!--      [header]="'discover_also'"-->
  <!--      [name]="'Intense Repair\nShea Butter'"-->
  <!--      [description]="'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit in voluptate velit esse.'"-->
  <!--      [price]="{ value: 49.99, currency: 'PLN' }"-->
  <!--      [link]="''"-->
  <!--    ></app-discover>-->
  <!--  </div>-->

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 offset-xl-1">
        <div class="out-mobile-padding">
          <app-images-carousel
            [images]="carouselImages"
          >
          </app-images-carousel>
        </div>
      </div>
      <div class="col-12 col-lg-5 col-xl-4 offset-lg-1">
        <div class="pt-kilo pt-md-giga pt-lg-zero">
          <app-indent-content
            [header]="'our_team'"
          >
            <p
              class="small"
              [innerHTML]="'our_team_description'|translate|newLineBreak|removeWidows"
            >
            </p>

            <div class="actions pt-mega pb-deca">
              <a [routerLink]="'/:lang/career'|i18nRoute" class="btn btn-bordered mb-deci">{{ 'join_us'|translate }}</a>
            </div>
          </app-indent-content>
        </div>
      </div>
    </div>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-end">
      <div class="col-10 offset-lg-1 offset-xl-2">
        <h3 class="text-light-gray" [innerHTML]="'certifications'|translate|removeWidows"></h3>
      </div>
      <div class="col-12 col-md-6 col-lg-5 col-xl-4 offset-lg-1 offset-xl-2 order-1 order-md-0">
        <p
          class="pt-giga pt-lg-peta small"
          [innerHTML]="'certifications_description'|translate|newLineBreak|removeWidows"
        ></p>
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-3 offset-lg-1 order-0 order-md-1">
        <div class="pt-giga pt-md-zero">
          <a
            href="https://www.wellnessverband.de/wellness-freunde/anbieter/detail.php?we_oid=245"
            target="_blank"
            class="opacity-link"
          >
            <app-text-centered-image
              header="{{ 'award_h_04'|translate}}"
              image="/assets/images/wellness_page/certificate.png"
              image2x="/assets/images/wellness_page/certificate@2x.png"
              imgMaxHeight="118px"
              imageAlt="certificate logo"
            ></app-text-centered-image>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
