import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import GlobalFunctions from '@helpers/GlobalFunctions';
import { environment } from 'src/environments/environment';
import { UserService } from '@services/auth/user.service';
import { map } from 'rxjs/operators';
import IReservationRoom from '../../reservation-form/_interfaces/IReservationRoom';
import IReservationPayment from '../../reservation-form/_interfaces/IReservationPayment';
import IReservationExtraTab from '../../reservation-form/_interfaces/IReservationExtraTab';
import {IExtraAccess} from '../_interfaces/IExtraAccess';
import {IRedeemResponse} from '../_interfaces/IRedeemResponse';
import ReservationExtraTypeEnum from '../../reservation-form/_enums/ReservationExtraTypeEnum';
import {IShopProduct} from '../_interfaces/IShopProduct';

@Injectable({
  providedIn: 'root'
})
export class BlogostanService {
  private readonly apiUrl: string;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
    this.apiUrl = environment.shuumApiHostShort;
  }

  getExtras(): Observable<IReservationExtraTab[]> {
    const url = '/booking/extras';

    let params = null;

    try {
      params = new FormData();
      params.append('purchaseType', 'shop');
    }
    catch (e) {

    }

    return this.http.post<IReservationExtraTab[]>(
      `${environment.shuumApiHost}${url}`,
      params
    );
  }

  sendRedeem( code: string ): Observable<IRedeemResponse> {
    const url = '/users/redeem';

    const fd: FormData = GlobalFunctions.convertToFd(new FormData(), code);

    return this.http.post<IRedeemResponse>(`${this.apiUrl}${url}`, fd);
  }

  hasAccess(): Observable<IExtraAccess> {
    const url = '/users/access';

    let params = null;

    try {
      params = new FormData();
      params.append('extra', 63);
    }
    catch (e) {

    }

    return this.http.post<IExtraAccess>(`${this.apiUrl}${url}`, params);
  }

  getPaymentLink( products: IShopProduct[] ): Observable<IReservationPayment> {
    const url = '/users/shop';
    let params = null;

    try {
      params = new FormData();
      params = this.setProductData(params, products);
    }
    catch (e) {

    }

    return this.http.post<IReservationPayment>(
      `${environment.shuumApiHostShort}${url}`,
      params
    );
  }

  private setProductData( formData: FormData, products: IShopProduct[] = [] ) {
    if ( products.length ) {
      products.forEach((product, index) => {
        if ( product?.id ) {
          formData.append('products[' + index + '][id]', product.id.toString());
        }

        if ( product?.name ) {
          formData.append('products[' + index + '][name]', product.name.toString());
        }

        if ( product?.name ) {
          formData.append('products[' + index + '][price]', product.price.toString());
        }
      });
    }
    return formData;
  }

}
