import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-text-subtle-header',
  templateUrl: './text-subtle-header.component.html',
  styleUrls: ['./text-subtle-header.component.scss']
})
export class TextSubtleHeaderComponent implements OnInit {
  @Input() header: string;

  constructor() { }

  ngOnInit() {
  }

}
