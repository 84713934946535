<app-popup-content #popup [hideSecondPart]="true">
  <div content1 class="pt-centi pb-centi">
    <div class="d-flex justify-content-center" *ngIf="isError === null">
      <app-loader [size]="30" [opacity]="0.7"></app-loader>
    </div>

    <ng-container *ngIf="isError === true">
      <h3 class="pb-mega text-center">{{ 'add_new_password_error'|translate }}</h3>
      <p class="smaller-text weight-300 pb-mega text-center">{{ 'add_new_password_desc_error'|translate }}</p>
      <app-back-arrow
        [linkTo]="'/:lang/my-reservation/authorization-group/sign-in-group'|i18nRoute"
        text="back_to_login_short"
        [popup]="popup"
        [withBlocked]="true"
      ></app-back-arrow>
    </ng-container>

    <ng-container *ngIf="isError === false">
      <h3 class="pb-mega text-center">{{ 'add_new_password'|translate }}</h3>
      <p class="smaller-text weight-300 pb-mega text-center">{{ 'add_new_password_desc'|translate }}</p>
      <form [formGroup]="newPasswordForm" class="form" (ngSubmit)="onSubmit()">
        <div class="pb-deca pb-md-28">
          <app-input-password 
            inputId="new-password" 
            control="plainPassword" 
            [group]="newPasswordForm"
            fieldName="new_password"
            [apiValidationMsg]="formErrors?.plainPassword"
            #input
          ></app-input-password>
        </div>
        <div class="pb-kilo">
          <app-input-password 
            inputId="repeat-password" 
            control="plainPasswordRepeat" 
            [group]="newPasswordForm"
            fieldName="form_repeat_password"
            [matchingError]="newPasswordForm.errors?.matchingFields"
            [apiValidationMsg]="formErrors?.plainPasswordRepeat"
            #input
          ></app-input-password>
        </div>
        <div class="pb-hecto pb-md-kilo">
          <button class="btn btn-bordered w-100" [ngClass]="{'disabled': loading}" [disabled]="loading" type="submit">
            <span *ngIf="!loading">{{ 'confirm'|translate }}</span>
            <app-loader [size]="16" [opacity]="'1'" [center]="true" *ngIf="loading"></app-loader>
          </button>
        </div>
        <div class="validation-msg-container summary-top pb-deca pb-md-hecto">
          <p class="error">{{ errorMsg|translate }}</p>
          <p class="success">{{ successMsg|translate }}</p>
        </div>
        <div>
          <app-back-arrow
            [linkTo]="'/:lang/my-reservation/authorization-group/sign-in-group'|i18nRoute"
            text="back_to_login_short"
            [popup]="popup"
            [withBlocked]="true"
          ></app-back-arrow>
        </div>
      </form>
    </ng-container>
  </div>
</app-popup-content>
