import { Component, OnDestroy, OnInit } from '@angular/core';
import CarouselImageEnum from '@enums/CarouselImageEnum';
import ICarouselImages from '@interfaces/ICarouselImages';
import { ITreatment } from '@interfaces/ITreatment';
import { TreatmentService } from '@services/treatment.service';
import { CategoryService } from '@services/category.service';
import ICategory from '@interfaces/ICategory';
import IFilter from '@interfaces/IFilter';
import IOrder from '@interfaces/IOrder';
import { HttpResponse } from '@angular/common/http';
import * as cloneDeep from 'lodash/cloneDeep';
import { LayoutService } from '@services/layout.service';
import ConfigService from '@config/ConfigService';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import { takeUntil } from 'rxjs/operators';
import { RouteMapHelper } from '@helpers/RouteMapHelper';
import { BehaviorSubject } from 'rxjs';
import IBackToClickedElem from '@interfaces/IBackToClickedElem';

@Component({
  selector: 'app-relax-and-beauty',
  templateUrl: './relax-and-beauty.component.html',
  styleUrls: ['./relax-and-beauty.component.scss']
})
export class RelaxAndBeautyComponent extends SubscribeHelper implements OnInit, OnDestroy {

  carouselImages: ICarouselImages[] = [];
  list: ITreatment[] = [];
  treatmentsCategories: ICategory[] = [];
  isFirstSingledOut = false;

  backElementStore: IBackToClickedElem = null;

  private orders: IOrder[] = [];
  private page = 1;
  private limit = 10;
  private filters: IFilter[] = [];

  public responseMax = 0;
  public responseCurrent = 0;
  public loading = false;
  public isListLoaded = false;
  public showCategoryLoader$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public currentLang: string = ConfigService.getCurrentLang();

  constructor(
    private treatmentService: TreatmentService,
    private categoryService: CategoryService,
    private layoutService: LayoutService,
    private routeMapHelper: RouteMapHelper
  ) {
    super();
    this.orders.push({
      field: 'isSingledOut',
      type: 'desc'
    }, {
      field: 'sequence',
      type: 'asc'
    });
  }

  ngOnInit() {
    this.setCarouselImages();
    this.getTreatmentList();
    this.getTreatmentsCategories();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getTreatmentList(clickSource?): void {
    this.loading = true;
    if ( this.isListLoaded ) {
      this.layoutService.itemLoader$.next(true);
    }

    if (clickSource !== 'filter') {
      this.backElementStore = this.layoutService.getBackToClickedElementStoreByRoute( ConfigService.getCurrentRouteGroupPath() );
    }

    const secondPart = this.routeMapHelper.getGroup( ConfigService.getPreviousRoute().split('/')[3] );

    if (
      this.routeMapHelper.getGroup( ConfigService.getPreviousRoute().split('/')[2] )
      !== 'wellness'
      || secondPart === 'wellness_medical-wellness'
      || secondPart === 'wellness_saunas'
      || secondPart === 'wellness_yoga'
      || secondPart === 'wellness_private-wellness'
    ) {
      this.backElementStore = null;
    }

    let limit = this.limit;
    let page = this.page;
    let filters = this.filters;
    let orders = this.orders;

    if ( !filters.length ) {
      if ( sessionStorage ) {
        if ( sessionStorage.getItem('filtersActive') ) {
          const sessionFilters: string[] = JSON.parse(sessionStorage.getItem('filtersActive'));
          filters.push({
            key: 'category',
            value: sessionFilters
          });
        }
      }
    }

    if ( this.backElementStore ) {
      limit = this.backElementStore.limit * (this.backElementStore.page > 1 ? this.backElementStore.page - 1 : 1);
      page = 1;
      filters = this.backElementStore.filters;
      orders = this.backElementStore.orders;

      this.page = this.backElementStore.page;

    }

    const singledOutFilters: IFilter[] = cloneDeep(filters);
    singledOutFilters.push({
      key: 'isSingledOut',
      value: 1
    });

    const notSingledOutFilters: IFilter[] = cloneDeep(filters);
    notSingledOutFilters.push({
      key: 'isSingledOut',
      value: 0
    });

    this.treatmentService.getListExtended( notSingledOutFilters, orders, limit, page )
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((list: HttpResponse<ITreatment[]>) => {
        this.treatmentService.getList(singledOutFilters, [], 1)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(singledOutList => {
            this.list = list.body;

            if (typeof singledOutList[0] !== 'undefined') {
              this.list.unshift( singledOutList[0] );
              this.setIsSingledOut( singledOutList[0] );
            }
            else {
              this.isFirstSingledOut = false;
            }

            if ( this.isListLoaded ) {
              this.layoutService.itemLoader$.next(false);
            }
            this.loading = false;
            this.isListLoaded = true;

            if ( this.backElementStore ) {
              this.backToClickedElement();
            }
          });

        this.responseMax = JSON.parse(list.headers.get('Query-Count'));
        this.responseCurrent = list.body.length;

        if ( !this.backElementStore ) {
          this.page += 1;
        }
      });
  }

  getTreatmentsCategories(): void {
    this.showCategoryLoader$.next(true);
    this.categoryService.getTreatmentList( [], [{field: 'id', type: 'ASC'}], 0 )
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe( list => {
        this.treatmentsCategories = list;

        this.showCategoryLoader$.next(false);
      });
  }

  filterValues( filters: IFilter[] ): void {
    this.filters = filters;
    this.page = 1;
    this.backElementStore = null;
    this.getTreatmentList('filter');
  }

  setIsSingledOut( firstItem: ITreatment ) {
    if ( firstItem ) {
      this.isFirstSingledOut = firstItem.isSingledOut;
    }
    else {
      this.isFirstSingledOut = false;
    }
  }

  setCarouselImages(): void {
    const path = '../../../assets/images/carousel/wellness/';

    this.carouselImages.push({
      image: {
        image: path + '01.jpg',
        image2x: path + '01@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '02.jpg',
        image2x: path + '02@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '03.jpg',
        image2x: path + '03@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    });
  }

  loadMore(): void {
    this.loading = true;

    const notSingledOutFilters: IFilter[] = cloneDeep(this.filters);
    notSingledOutFilters.push({
      key: 'isSingledOut',
      value: 0
    });

    this.treatmentService.getList(notSingledOutFilters, this.orders, this.limit, this.page)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((list: any) => {
        this.list.push.apply(this.list, list);

        this.responseCurrent += list.length;
        this.page += 1;
        this.loading = false;
      });
  }

  backToClickedElement() {
    const clickedElemStore = this.layoutService.backToClickedElementStore;

    if ( clickedElemStore && window ) {
      setTimeout(() => {
        try {
          const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

          if ( supportsNativeSmoothScroll ) {
            window.scrollTo({top: clickedElemStore.offset.top, behavior: 'smooth'});
          }
          else {
            window.scrollTo(0, clickedElemStore.offset.top);
          }
        }
        catch (e) {
          window.scrollTo(0, clickedElemStore.offset.top);
        }
      }, 500);
    }
  }

  setElementInfo = ( id: number ) => {
    if ( window ) {
      this.layoutService.backToClickedElementStore = {
        id,
        page: this.page,
        limit: 10,
        route: ConfigService.getCurrentRouteGroupPath(),
        offset: {
          top: window.pageYOffset
        },
        filters: this.filters,
        orders: this.orders,
        doAction: false
      };
    }
  }
}
