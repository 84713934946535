import { IChildrenAgesLimits } from '../_interfaces/IChildrenAgesLimits';
import IReservationLimits from '../_interfaces/IReservationLimits';

abstract class ReservationConfig {
  private static reservationLimits: IReservationLimits = {
    defaultAdultsValue: 2,
    maxAdultsValue: 4,
    minAdultsValue: 1,
    maxChildrenValue: 4,
    minChildrenValue: 0,
    minRooms: 1,
    maxRooms: 3
  };

  private static reservationChildrenAges: number[] = [...Array(15).keys()];

  private static childrenAgesLimits: IChildrenAgesLimits = {
    minBabyAge: 0,
    maxBabyAge: 3,
    minSmallChildAge: 4,
    maxSmallChildAge: 9,
    minMiddleChildAge: 10,
    maxMiddleChildAge: 15
  };

  public static getReservationLimits(): IReservationLimits {
    return this.reservationLimits;
  }

  public static getReservationChildrenAges(): number[] {
    return this.reservationChildrenAges;
  }

  public static getChildrenAgesLimits(): IChildrenAgesLimits {
    return this.childrenAgesLimits;
  }
}

export default ReservationConfig;
