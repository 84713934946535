import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {BlogostanComponent} from './blogostan.component';
import {LangGuard} from '@guards/LangGuard';
import {BlogostanLoginComponent} from './blogostan-login/blogostan-login.component';
import {BuyAccessComponent} from './buy-access/buy-access.component';
import {AuthGuard} from '../_guards/auth.guard';
import {LogoutUserGuard} from '../_guards/logout-user.guard';
import {BlogostanSignUpComponent} from './blogostan-sign-up/blogostan-sign-up.component';
import {BlogostanActivateAccountComponent} from './blogostan-activate-account/blogostan-activate-account.component';
import {ActivateAccountScreenGuard} from '../_guards/activate-account-screen.guard';
import {BlogostanPasswordRecoveryComponent} from './blogostan-password-recovery/blogostan-password-recovery.component';
import {SuccessComponent} from './success/success.component';
import {HasAccessGuard} from '../_guards/has-access.guard';
import {ErrorComponent} from './error/error.component';
import { LogoutBlogostanPopupComponent } from './logout-blogostan-popup/logout-blogostan-popup.component';
import { LogoutScreenGuard } from '../_guards/logout-screen.guard';


const routes: Routes = [
  {
    path: '',
    component: BlogostanComponent,
    data: {
      forLanguages: ['pl', 'de', 'en']
    },
    children: [
      {
        path: 'zaloguj-sie',
        component: BlogostanLoginComponent,
        canActivate: [LangGuard, LogoutUserGuard],
        data: {
          forLanguages: ['pl'],
          group: 'sign-in-group',
          meta: {
            title: 'log_in'
          }
        },
        children: [
          {
            path: 'wylogowanie',
            component: LogoutBlogostanPopupComponent,
            canActivate: [LangGuard, LogoutScreenGuard, LogoutUserGuard],
            data: {
              forLanguages: ['pl'],
              group: 'logout-group',
              meta: {
                title: 'logout_header'
              }
            }
          }
        ]
      },
      {
        path: 'zarejestruj-sie',
        component: BlogostanSignUpComponent,
        canActivate: [LangGuard, LogoutUserGuard],
        data: {
          forLanguages: ['pl'],
          group: 'sign-up-group',
          meta: {
            title: 'join_us'
          }
        }
      },
      {
        path: 'aktywuj-konto',
        component: BlogostanActivateAccountComponent,
        canActivate: [LangGuard, LogoutUserGuard, ActivateAccountScreenGuard],
        data: {
          forLanguages: ['pl'],
          group: 'activate-account-group',
          meta: {
            title: 'activate_account'
          }
        }
      },
      {
        path: 'odzyskaj-haslo',
        component: BlogostanPasswordRecoveryComponent,
        canActivate: [LangGuard, LogoutUserGuard],
        data: {
          forLanguages: ['pl'],
          group: 'recover-password-group',
          meta: {
            title: 'recover_password_short'
          }
        }
      },
      {
        path: '',
        component: BuyAccessComponent,
        canActivate: [LangGuard, AuthGuard, HasAccessGuard],
        data: {
          forLanguages: ['pl', 'de', 'en'],
          meta: {
            title: 'blogostan'
          }
        }
      },
      {
        path: 'dokonano-zakupu',
        component: SuccessComponent,
        canActivate: [LangGuard],
        data: {
          forLanguages: ['pl'],
          group: 'success-page',
          // omitGTM: true,
          meta: {
            title: 'reservation_success_meta_header'
          }
        }
      },
      {
        path: 'sprobuj-ponownie',
        component: ErrorComponent,
        canActivate: [LangGuard],
        data: {
          forLanguages: ['pl'],
          group: 'error-page',
          meta: {
            title: 'reservation_error_header'
          }
        },
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BlogostanRoutingModule { }

export {
  routes
};
