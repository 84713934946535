<div
  class="carousel"
  (swipeLeft)="activateNextIndex()"
  (swipeRight)="activatePrevIndex()"
>
  <ul class="list-unstyled">
    <ng-container *ngFor="let img of images;index as i;last as last;first as first">
      <li
        [ngClass]="{
          'active': activeIndex === i,
          'prev': (activeIndex-1) === i || activeIndex === 0 && last,
          'next': (activeIndex+1) === i || activeIndex === maxIndexNo && first,
          'inactive': (!(activeIndex === i || activeIndex-1 === i || activeIndex+1 === i) && !(activeIndex === 0 && last) && !(activeIndex === maxIndexNo && first)),
          'wide': img.type === carouselImageEnum.Wide
        }"
      >
        <img
          (click)="activateIndex($event, i)"
          [src]="img.image.image"
          [srcset]="img?.image?.image + ' 1x, ' + img?.image?.image2x + ' 2x'"
        />
      </li>
    </ng-container>
  </ul>
</div>
