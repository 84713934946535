import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable, Subject} from 'rxjs';
import GlobalFunctions from '@helpers/GlobalFunctions';
import {IContactForm} from '../_interfaces/IContactForm';
import {BookingTypeExtra} from '../_interfaces/IBookingType';

@Injectable({
  providedIn: 'root'
})
export class ContactFormService {
  private readonly apiUrl: string;

  private bookingType$: Subject<BookingTypeExtra> = new Subject<BookingTypeExtra>();

  constructor(
    private http: HttpClient,
  ) {
    this.apiUrl = environment.shuumApiHostShort;
  }

  sendContactForm( formData: IContactForm ): Observable<any> {
    const fd: FormData = GlobalFunctions.convertToFd(new FormData(), formData);

    return this.http.post<any>(`${this.apiUrl}/extra/request`, fd);
  }

  public setBookingType(value: BookingTypeExtra) {
    this.bookingType$.next(value);
  }

  public getBookingType(): Subject<BookingTypeExtra> {
    return this.bookingType$;
  }

}
