import {Component, Input, OnInit} from '@angular/core';
import CollageImageEnum from '../../_enums/CollageImageEnum';
import IImageCollage from '@interfaces/IImageCollage';
import {IImage} from '@interfaces/IImage';
import ConfigService from '@config/ConfigService';

@Component({
  selector: 'app-indent-content-between-images',
  templateUrl: './indent-content-between-images.component.html',
  styleUrls: ['./indent-content-between-images.component.scss']
})
export class IndentContentBetweenImagesComponent implements OnInit {
  public hasSrcSetSupport: boolean;

  @Input() header: string;
  @Input() topHeader?: string;
  @Input() smallImage?: IImage;
  @Input() bigImage?: IImage;

  constructor() {
    this.hasSrcSetSupport = ConfigService.getHasSrcSetSupport();
  }

  ngOnInit() {
  }

}
