<div class="triple-images-container">
  <div class="img img-left">
    <img
      [defaultImage]="'/assets/images/placeholders/placeholder318x306.png'"
      [useSrcset]="hasSrcSetSupport"
      [lazyLoad]="hasSrcSetSupport ? (imageLeft.image + ' 1x, ' + imageLeft.image2x + ' 2x') : imageLeft.image"
      [alt]="imageLeft.alt"
    />
  </div>

  <div class="img img-center">
    <img
      [defaultImage]="'/assets/images/placeholders/placeholder318x487.png'"
      [useSrcset]="hasSrcSetSupport"
      [lazyLoad]="hasSrcSetSupport ? (imageCenter.image + ' 1x, ' + imageCenter.image2x + ' 2x') : imageCenter.image"
      [alt]="imageCenter.alt"
    />
  </div>

  <div class="img img-right">
    <img
      [defaultImage]="'/assets/images/placeholders/placeholder318x306.png'"
      [useSrcset]="hasSrcSetSupport"
      [lazyLoad]="hasSrcSetSupport ? (imageRight.image + ' 1x, ' + imageRight.image2x + ' 2x') : imageRight.image"
      [alt]="imageRight.alt"
    />
  </div>
</div>
