import {Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {UserService} from '@services/auth/user.service';
import {Observable} from 'rxjs';
import {finalize, takeUntil, tap} from 'rxjs/operators';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BlogostanService} from '../../_services/blogostan.service';
import {InputField} from '../../../reservation/_helpers/InputField';
import IReservationExtraTab from '../../../reservation-form/_interfaces/IReservationExtraTab';
import {IShopProduct} from '../../_interfaces/IShopProduct';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-buy-access',
  templateUrl: './buy-access.component.html',
  styleUrls: ['./buy-access.component.scss']
})
export class BuyAccessComponent extends SubscribeHelper implements OnInit, OnDestroy {
  extras$!: Observable<IReservationExtraTab[]>;
  codeForm: FormGroup;
  loading = false;
  errorMsg = '';
  successMsg = '';
  products: IShopProduct[] = [];

  processingForPaymentLink = false;
  paymentLink: string;

  @ViewChildren('input') input: QueryList<InputField>;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private blogostanService: BlogostanService
  ) {
    super();
  }

  ngOnInit(): void {
    this.extras$ = this.blogostanService.getExtras().pipe(
      tap(data => {
        if (data?.length) {
          this.products.push({
            id: data[0]?.values[0]?.id,
            name: data[0]?.values[0]?.name,
            price: data[0]?.values[0]?.price.value
          });
        }
      })
    );

    this.codeForm = this.formBuilder.group({
      code: ['', [Validators.required]]
    });
  }

  back() {
    const user = this.userService.userValue;
    window.location.href = `${environment.blogostganAuthUrl}${user.token}`;
  }

  getPaymentLink( e: MouseEvent ): void {
    e.preventDefault();

    if ( !this.processingForPaymentLink ) {
      this.processingForPaymentLink = true;

      this.blogostanService.getPaymentLink( this.products )
        .pipe( takeUntil(this.componentDestroyed$) )
        .subscribe(link => {
          this.processingForPaymentLink = false;

          if (link.paymentUrl) {
            this.paymentLink = link.paymentUrl;

            if (this.paymentLink) {
              window.parent.location.href = this.paymentLink;
            }
          }
          },
          error => {
          this.processingForPaymentLink = false;

          console.log(error);
        });
    }
  }

  onSubmit() {
    this.errorMsg = '';
    this.successMsg = '';

    if (this.codeForm.valid) {
      this.loading = true;
      this.blogostanService.sendRedeem( this.codeForm.value )
        .pipe(
          takeUntil( this.componentDestroyed$ ),
          finalize( () => { this.loading = false; } )
        )
        .subscribe(res => {
          this.successMsg = 'code_added';
          this.codeForm.reset();

          if ( typeof window !== 'undefined' ) {
            if (window.parent) {
              const user = this.userService.userValue;
              window.parent.location.href = `${environment.blogostganAuthUrl}${user.token}`;
            }
          }
        }, err => {
          console.log(err);
          this.errorMsg = err;
        });
    } else {
      this.input.toArray().forEach(field => {
        field.validateField();
      });
    }
  }

}
