import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import IReservationGA from '../_interfaces/IReservationGA';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ReservationGaService {
  public cachedDetails: BehaviorSubject<IReservationGA> = new BehaviorSubject<IReservationGA>(null);

  constructor(
    protected http: HttpClient,
  ) {

  }

  getDetails( token: string ) {
    const url = '/ga/details/' + token;

    return this.http.post<IReservationGA> (
      `${environment.shuumApiHost}${url}`, []
    )
      .pipe(map(reservationData => {
        this.cachedDetails.next(reservationData);
        return reservationData;
      }));

  }

}
