import { Component, OnInit } from '@angular/core';
import CollageImageEnum from '@enums/CollageImageEnum';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import IActivity from '@interfaces/IActivity';
import {ActivityService} from '@services/activity.service';
import {IBookingType} from '../../../../../../_interfaces/IBookingType';
import IFilter from '@interfaces/IFilter';
import { EventCategoryEnum } from 'src/app/_subprojects/reservation/_enums/EventCategoryEnum';
import { EventService } from '@services/event.service';
import { Observable } from 'rxjs';
import IEvent from '@interfaces/IEvent';
import * as moment from 'moment';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { ContactFormService } from 'src/app/_subprojects/reservation/_services/contact-form.service';
import { map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-restore-vital-forces',
  templateUrl: './restore-vital-forces.component.html',
  styleUrls: ['./restore-vital-forces.component.scss']
})
export class RestoreVitalForcesComponent extends SubscribeHelper implements OnInit {

  bookingType: IBookingType[] = [
    {
      id: 3,
      label: 'book_activity',
      extra: null,
      email: 'wellness@shuumhotel.pl',
      default: true
    }
  ]

  collageEnum: typeof CollageImageEnum;
  activities: IActivity[] = [];
  events$: Observable<IEvent[]>;

  fitnessNames: { [key: string]: string }[] = [];

  constructor(
    private activityService: ActivityService,
    private eventService: EventService,
    private scrollToService: ScrollToService,
    private contactFormService: ContactFormService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.collageEnum = CollageImageEnum;
    this.activities = this.activityService.getActivities();
    this.activities.map(activity => {
      this.fitnessNames.push({
        type: 'book_activity',
        name: this.translateService.instant(activity.header)
      });
    });

    this.fetchEvents();
  }

  fetchEvents() {
    const filters: IFilter[] = [];

    filters.push({
      key: 'eventType',
      value: 0
    }, {
      key: 'categoryKey',
      value: EventCategoryEnum.FITNESS
    });

    this.events$ = this.eventService.getList(filters, [], 100).pipe(
      tap(events => events.filter(event => {
        const date = moment(event.dateStart);
        const isTodayOrFuture = date.isSameOrAfter(moment(), 'day');
        return isTodayOrFuture && event.isSignUpRequired;
      }).map(event => {
        this.fitnessNames = [];
        if (!this.fitnessNames.some(e => e.name === event.title)) {
          this.fitnessNames.push({
            type: 'book_activity',
            name: event.title,
            description: event.description
          });
        }
      }))
    );
  }

  setElementInfo = ( { data, e } ) => {
    if ( window ) {
      const date = moment(data.start);
      const isTodayOrFuture = date.isSameOrAfter(moment(), 'day');

      if (isTodayOrFuture && data.isSignUpRequired) {
        this.contactFormService.setBookingType({
          id: 3,
          extra: {
            name: data.title,
            description: data.description,
            date: data.start,
            time: data.time
          }
        });

        this.scrollTo(e);
      }
    }
  }

  scrollTo( e: MouseEvent ): void {
    e.preventDefault();

    const scrollToConfig = {
      target: 'contactForm',
      duration: 300
    };

    this.scrollToService
      .scrollTo(scrollToConfig);
  }
}
