import {Component, OnInit, OnDestroy, Inject, PLATFORM_ID} from '@angular/core';
import { ReservationDataService } from '@services/reservations/reservation-data.service';
import { UserService } from '@services/auth/user.service';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import {GaPusherService} from '@services/ga-pusher.service';
import {ReservationGaService} from '../../_services/reservation-ga.service';
import {isPlatformBrowser} from '@angular/common';

declare const ga: any;

declare global {
  interface Window { dataLayer: any[]; }
}

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent extends SubscribeHelper implements OnInit, OnDestroy {
  isLoggedIn: boolean;
  gaToken: string;

  constructor(
    private reservationData: ReservationDataService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private gaPusher: GaPusherService,
    private reservationGaService: ReservationGaService,
    @Inject(PLATFORM_ID) private platformId: any
  ) { super(); }

  ngOnInit(): void {
    this.reservationData.clear();
    this.userService.isLoggedIn$
      .pipe( takeUntil(this.componentDestroyed$) )
      .subscribe(res => {
        this.isLoggedIn = res;
      });

    this.gaToken = this.activatedRoute.snapshot.queryParams.ga;
    this.gaPush(this.gaToken);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  gaPush(token: string) {
    if ( isPlatformBrowser(this.platformId) ) {
      this.reservationGaService.cachedDetails.subscribe( data => {
        if (data) {
          const gaDataToSend = this.gaPusher.getData(data);

          if (typeof ga !== 'undefined') {
            const trackerName = ga.getAll()[0].get('name');

            ga(trackerName + '.require', 'ecommerce');

            ga(trackerName + '.ecommerce:addTransaction', {
              id: gaDataToSend.ecommerce.purchase.actionField.id,
              affiliation: gaDataToSend.ecommerce.purchase.actionField.affiliation,
              revenue: gaDataToSend.ecommerce.purchase.actionField.revenue
            });

            gaDataToSend.ecommerce.purchase.products.forEach( product => {
              ga(trackerName + '.ecommerce:addItem', {
                id: gaDataToSend.ecommerce.purchase.actionField.id,
                name: product.name,
                sku: product.sku,
                category: product.category,
                price: product.price,
                quantity: product.quantity
              });
            });
            ga(trackerName + '.ecommerce:send');
          }
        }
      });
    }
  }
}
