import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {I18nModule} from '../i18n/i18n.module';

import { SharedPipesModule } from './shared-pipes.module';
import { IndentContentComponent } from '@shared/indent-content/indent-content.component';


@NgModule({
  declarations: [
    IndentContentComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    SharedPipesModule
  ],
  providers: [

  ],
  bootstrap: [],
  exports: [
    IndentContentComponent
  ]
})
export class IndentContentModule { }
