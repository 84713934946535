import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {ReservationGaService} from '../_subprojects/reservation-form/_services/reservation-ga.service';
import {GaPusherService} from '@services/ga-pusher.service';
import {isPlatformBrowser} from '@angular/common';
import { environment } from 'src/environments/environment';
import {GlobalStateHelper} from '@helpers/GlobalStateHelper';


@Injectable()
export class GtmGuard implements CanActivate {
  constructor(
    private router: Router,
    private reservationGaService: ReservationGaService,
    private gaPusher: GaPusherService,
    private globalStateHelper: GlobalStateHelper,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {

    const token = route.queryParams.ga;

    if (isPlatformBrowser(this.platformId)) {
      return new Promise<boolean>((resolve) => {
        this.reservationGaService.getDetails(token).subscribe(
          data => {
            if (data) {
              const gaDataToSend = this.gaPusher.getData(data);

              const purchase = {
                id: gaDataToSend.ecommerce.purchase.actionField.id,
                affiliation: gaDataToSend.ecommerce.purchase.actionField.affiliation,
                value: gaDataToSend.ecommerce.purchase.actionField.revenue,
                items: []
              };

              gaDataToSend.ecommerce.purchase.products.forEach( product => {
                purchase.items.push({
                  id: gaDataToSend.ecommerce.purchase.actionField.id,
                  name: product.name,
                  sku: product.sku,
                  category: product.category,
                  price: product.price,
                  quantity: product.quantity
                });
              });

              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                purchase
              });

              this.loadGoogleAnalytics(environment.gtmKey);
            }
            resolve(true);
          },
          error => {
            this.loadGoogleAnalytics(environment.gtmKey);
            resolve(true);
          }
        );
      });
    }
    return true;
  }


  loadGoogleAnalytics(trackingID: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const gtmInitialized = this.globalStateHelper.getGtmInitialized().getValue();
      if (!gtmInitialized) {
        const gaScript2 = document.createElement('script');
        gaScript2.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer',"${trackingID}");`;
        document.documentElement.firstChild.appendChild(gaScript2);
      }
    }
  }


}
