<div class="pt-tera pt-lg-exa container mobile-padding">
  <div class="row">
    <div class="col-xl-10 offset-xl-1">
      <div class="pb-mega">
        <a [routerLink]="'/:lang/my-reservation'|i18nRoute">
          <img src="/assets/images/nav_arrow_dark.svg"/>
        </a>
      </div>
    </div>
    <!-- <div class="col-xl-10 offset-xl-1">
      <div class="pb-mega pb-lg-giga">
        <a [routerLink]="'/:lang/my-reservation'|i18nRoute">
          <img src="/assets/images/nav_arrow_dark.svg"/>
        </a>
      </div>
      <div class="bg-white mt-deca mt-lg-zero w-100">
        <div class="pl-mega pr-mega pt-mega pb-mega pt-md-peta pb-md-peta">
          <app-slider-universal
            [childrenWidth]="126"
          >
            <div class="item-container">
              <div class="item checked">
                <button (click)="chooseImage(1)">
                  <img
                    srcset="/assets/images/reservations/user-profile/avatars/01.png 1x, /assets/images/reservations/user-profile/avatars/01@2x.png 2x"
                  />
                </button>
              </div>
              <div class="item">
                <button (click)="chooseImage(2)">
                  <img
                    srcset="/assets/images/reservations/user-profile/avatars/02.png 1x, /assets/images/reservations/user-profile/avatars/02@2x.png 2x"
                  />
                </button>
              </div>
              <div class="item">
                <button (click)="chooseImage(3)">
                  <img
                    srcset="/assets/images/reservations/user-profile/avatars/03.png 1x, /assets/images/reservations/user-profile/avatars/03@2x.png 2x"
                  />
                </button>
              </div>
              <div class="item">
                <button (click)="chooseImage(4)">
                  <img
                    srcset="/assets/images/reservations/user-profile/avatars/04.png 1x, /assets/images/reservations/user-profile/avatars/04@2x.png 2x"
                  />
                </button>
              </div>
              <div class="item">
                <button (click)="chooseImage(5)">
                  <img
                    srcset="/assets/images/reservations/user-profile/avatars/05.png 1x, /assets/images/reservations/user-profile/avatars/05@2x.png 2x"
                  />
                </button>
              </div>
              <div class="item">
                <button (click)="chooseImage(6)">
                  <img
                    srcset="/assets/images/reservations/user-profile/avatars/06.png 1x, /assets/images/reservations/user-profile/avatars/06@2x.png 2x"
                  />
                </button>
              </div>
            </div>
          </app-slider-universal>
        </div>
      </div>
    </div> -->

    <div class="col-xl-10 offset-xl-1 d-flex pt-hecto">
      <div class="bg-white mt-deca mt-lg-zero w-100">
        <div class="pt-mega pb-mega pt-md-kilo pb-md-mega row justify-content-center">
          <form [formGroup]="editForm" class="form col-10 col-md-7 col-lg-6" (ngSubmit)="onEditSubmit()">
            <h4 class="form-header pr-deca">{{ 'user_data'|translate }}</h4>
            <div class="pb-deca pb-md-deca pt-giga">
              <app-input-text
                inputId="edit-name"
                control="name"
                [group]="editForm"
                fieldName="form_name"
                [apiValidationMsg]="editUserMsg?.apiErrrors?.name"
                #input
              ></app-input-text>
            </div>
            <div class="pb-deca pb-md-deca">
              <app-input-text
                inputId="edit-lastname"
                control="lastName"
                [group]="editForm"
                fieldName="form_last_name"
                [apiValidationMsg]="editUserMsg?.apiErrrors?.lastName"
                #input
              ></app-input-text>
            </div>
            <div class="pb-deca pb-md-deca">
              <app-input-text
                inputId="edit-email"
                control="email"
                [group]="editForm"
                fieldName="form_email"
                [apiValidationMsg]="editUserMsg?.apiErrrors?.email"
                #input
              ></app-input-text>
            </div>
            <div class="pb-tera pb-md-peta">
              <app-input-text
                inputId="edit-phone"
                control="phone"
                [group]="editForm"
                fieldName="form_phone"
                [apiValidationMsg]="editUserMsg?.apiErrrors?.phone"
                #input
              ></app-input-text>
            </div>

            <h4 class="form-header" id="address">{{ 'address_data'|translate }}</h4>
            <div class="pb-deca pb-md-deca pt-giga">
              <app-input-text
                inputId="edit-street"
                control="address"
                [group]="editForm"
                fieldName="form_street"
                fieldNameSecondary="form_street_secondary"
                [apiValidationMsg]="editUserMsg?.apiErrrors?.address"
                #input
              ></app-input-text>
            </div>
            <div class="pb-deca pb-md-deca">
              <app-input-text
                inputId="edit-postcode"
                control="postcode"
                [group]="editForm"
                fieldName="form_postcode"
                [apiValidationMsg]="editUserMsg?.apiErrrors?.postcode"
                #input
              ></app-input-text>
            </div>
            <div class="pb-deca pb-md-deca">
              <app-input-text
                inputId="edit-city"
                control="city"
                [group]="editForm"
                fieldName="form_city"
                [apiValidationMsg]="editUserMsg?.apiErrrors?.city"
                #input
              ></app-input-text>
            </div>
            <div class="pb-kilo pb-md-mega">
              <app-select-single
                inputId="edit-country"
                control="country"
                [group]="editForm"
                fieldName="form_country"
                [items]="countries"
                [apiValidationMsg]="editUserMsg?.apiErrrors?.country"
                #input
              ></app-select-single>
            </div>
            <div class="pb-kilo pb-md-mega">
              <button class="btn btn-bordered w-100 on-white" [ngClass]="{'disabled': editUserMsg?.loading}" [disabled]="editUserMsg?.loading" type="submit">
                <span *ngIf="!editUserMsg?.loading">{{ 'save_changes'|translate }}</span>
                <app-loader [size]="16" [opacity]="'1'" [center]="true" *ngIf="editUserMsg?.loading"></app-loader>
              </button>
            </div>
            <div class="validation-msg-container summary-top pb-kilo pb-md-mega">
              <p class="error">{{ editUserMsg?.error|translate }}</p>
              <p class="success">{{ editUserMsg?.success|translate }}</p>
            </div>
          </form>
        </div>
        <div class="pb-kilo pb-md-mega row justify-content-center">
          <form [formGroup]="vatEditForm" class="form col-10 col-md-7 col-lg-6" (ngSubmit)="handleInvoice()">
            <h4 class="form-header" id="invoice">{{ 'data_for_vat'|translate }}</h4>
            <div class="pb-deca pb-md-deca pt-giga">
              <app-input-text
                inputId="edit-company"
                control="name"
                [group]="vatEditForm"
                fieldName="form_company"
                [disabled]="invoiceToDelete"
                [apiValidationMsg]="editInvoiceMsg?.apiErrrors?.companyName"
                #inputInvoice
              ></app-input-text>
            </div>
            <div class="pb-deca pb-md-deca">
              <app-input-text
                inputId="edit-vat-street"
                control="address"
                [group]="vatEditForm"
                fieldName="form_street"
                fieldNameSecondary="form_street_secondary"
                [disabled]="invoiceToDelete"
                [apiValidationMsg]="editInvoiceMsg?.apiErrrors?.address"
                #inputInvoice
              ></app-input-text>
            </div>
            <div class="pb-deca pb-md-deca">
              <app-input-text
                inputId="edit-vat-post-code"
                control="postcode"
                [group]="vatEditForm"
                fieldName="form_postcode"
                [disabled]="invoiceToDelete"
                [apiValidationMsg]="editInvoiceMsg?.apiErrrors?.postcode"
                #inputInvoice
              ></app-input-text>
            </div>
            <div class="pb-deca pb-md-deca">
              <app-input-text
                inputId="edit-vat-city"
                control="city"
                [group]="vatEditForm"
                fieldName="form_city"
                [disabled]="invoiceToDelete"
                [apiValidationMsg]="editInvoiceMsg?.apiErrrors?.city"
                #inputInvoice
              ></app-input-text>
            </div>
            <div class="pb-deca pb-md-deca">
              <app-input-text
                inputId="edit-nip"
                control="taxNumber"
                [group]="vatEditForm"
                fieldName="form_nip"
                [disabled]="invoiceToDelete"
                [apiValidationMsg]="editInvoiceMsg?.apiErrrors?.taxNumber"
                #inputInvoice
              ></app-input-text>
            </div>
            <div class="pb-kilo pb-md-mega">
              <app-select-single
                inputId="edit-country"
                control="country"
                [group]="vatEditForm"
                fieldName="form_country"
                [items]="countries"
                [disabled]="invoiceToDelete"
                [apiValidationMsg]="editInvoiceMsg?.apiErrrors?.country"
                #inputInvoice
              ></app-select-single>
            </div>

            <div class="pb-kilo pb-md-mega" *ngIf="!invoiceToDelete">
              <button
                class="btn btn-bordered w-100 on-white"
                [ngClass]="{'disabled': editInvoiceMsg?.loading}"
                [disabled]="editInvoiceMsg?.loading"
                type="submit"
              >
                <span *ngIf="!editInvoiceMsg?.loading">{{ 'add_invoice'|translate }}</span>
                <app-loader [size]="16" [opacity]="'1'" [center]="true" *ngIf="editInvoiceMsg?.loading"></app-loader>
              </button>
            </div>

            <div class="pb-kilo pb-md-mega d-flex alifn-items-center" *ngIf="invoiceToDelete">
              <button
                class="btn btn-bordered w-100 on-white"
                [ngClass]="{'disabled': editInvoiceMsg?.loading}"
                [disabled]="editInvoiceMsg?.loading"
                type="submit"
              >
                <span *ngIf="!editInvoiceMsg?.loading">{{ 'delete_invoice'|translate }}</span>
                <app-loader [size]="16" [opacity]="'1'" [center]="true" *ngIf="editInvoiceMsg?.loading"></app-loader>
              </button>

              <div class="position-relative pl-centi d-flex align-items-center">
                <div
                  *ngIf="popoverVisible"
                  class="shuum-popover"
                  [@fadeAnimation]
                >
                  <app-popover
                    arrowType="right"
                  >
                    <p
                      class="regular-hind"
                    >
                    {{ 'edit_account_disclaimer'|translate }}
                    </p>
                  </app-popover>
                </div>
                <div
                  class="hint"
                  (mouseenter)="showPopover($event)"
                  (mouseleave)="hidePopover($event)"
                >
                  <img src="/assets/images/reservations/icons/help-dark.svg" alt="info">
                </div>
              </div>

            </div>

            <div class="validation-msg-container summary-top pb-kilo pb-md-mega">
              <p class="error">{{ editInvoiceMsg?.error|translate }}</p>
              <p class="success">{{ editInvoiceMsg?.success|translate }}</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<app-popup-content
  #popup
  [hideSecondPart]="true"
  [inPlace]="true"
>
  <div content1>
    <p class="smaller-text weight-300 text-center inside-links" [innerHTML]="'previous_company_data_description'|translate"></p>
  </div>
</app-popup-content>
