import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {
  counter = 0;
  @Input() text: string = null;
  @Input() startValue: number = null;
  @Input() minValue: number = null;
  @Input() maxValue: number = null;
  @Input() class: string;

  @Output() value: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
    this.setCounter();
  }

  increment() {
    let nextValue: number = null;
    if ( this.maxValue !== null ) {
      if ( this.counter + 1 <= this.maxValue ) {
        nextValue = this.counter + 1;
      }
    }
    else {
      nextValue = this.counter + 1;
    }

    if ( nextValue !== null ) {
      this.counter = nextValue;
      this.value.emit( nextValue );
    }
  }

  decrement() {
    let nextValue: number = null;

    if ( this.minValue !== null ) {
      if ( this.counter - 1 >= this.minValue ) {
        nextValue =  this.counter - 1;
      }
    }
    else {
      nextValue = this.counter - 1;
    }

    if ( nextValue !== null ) {
      this.counter = nextValue;
      this.value.emit( nextValue );
    }
  }

  setCounter() {
    const minValue = this.minValue !== null ? parseInt(String(this.minValue), 10) : null;
    const maxValue = this.maxValue !== null ? parseInt(String(this.maxValue), 10) : null;
    const startValue = this.startValue !== null ? parseInt(String(this.startValue), 10) : 0;

    if ( minValue !== null && maxValue !== null ) {
      if ( startValue >= minValue && startValue <= maxValue ) {
        this.counter = startValue;
      }
      else {
        this.counter = minValue;
      }
    }
    else if ( minValue !== null ) {
      if ( startValue >= minValue ) {
        this.counter = startValue;
      }
      else {
        this.counter = minValue;
      }
    }
    else if ( maxValue !== null ) {
      if ( startValue <= maxValue ) {
        this.counter = startValue;
      }
      else {
        this.counter = maxValue;
      }
    }
  }
}
