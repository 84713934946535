<img
  [src]="image.image"
  [srcset]="srcSet"
  [alt]="alt"
  [class]="class + ' ' + imageClass"
  [ngStyle]="{'max-width': image.maxWidth + 'px'}"
/>

<ng-container *ngFor="let e of rwdElements;index as i">
  <img
    [src]="e.image"
    [srcset]="imageChildData[i]?.src"
    [alt]="alt + '_' + i"
    [class]="class + ' ' + imageChildData[i]?.class"
    [ngStyle]="{'max-width': image.maxWidth + 'px'}"
  />
</ng-container>
