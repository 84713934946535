<div class="pt-zetta">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-xl-10">
        <div class="success-container">
          <div class="pb-mega pb-md-giga">
            <h2
              *ngIf="'shop_success_header'"
              class="small light"
              [innerHTML]="'shop_success_header'|translate|newLineBreak|removeWidows"
            ></h2>

            <div class="row align-items-md-end">
              <div class="col-12 col-md-6">
                <p
                  *ngIf="'shop_success_description'"
                  class="pt-hecto pt-md-kilo regular-hind"
                  [innerHTML]="'shop_success_description'|translate|newLineBreak|removeWidows"
                ></p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 justify-content-end d-flex">
              <div class="pb-mega pb-md-giga">
                <a class="btn btn-arrow" (click)="goToBlogostan($event)">{{ 'go_to_blogostan'|translate }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
