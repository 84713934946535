import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import IJobOfferShort from '../_interfaces/IJobOfferShort';
import IJobOfferDetails from '../_interfaces/IJobOfferDetails';
import {MainService} from '@services/main.service';
import IFilter from '@interfaces/IFilter';
import IOrder from '@interfaces/IOrder';

@Injectable({
  providedIn: 'root'
})
export class CareerService extends MainService {
  listUrl = '/jobs/offers';
  detailsUrl = '/jobs/offers';

  constructor(protected http: HttpClient) {
    super( http );
  }

  public getCareerList( filters?: IFilter[], orders?: IOrder[], limit?: number, page?: number ): Observable<IJobOfferShort[]> {
    return super.getList( filters, orders, limit, page );
  }

  public getCareerListExtended( filters?: IFilter[], orders?: IOrder[], limit?: number, page?: number ): Observable<HttpResponse<IJobOfferShort[]>> {
    return super.getList( filters, orders, limit, page, true );
  }

  public getCareerDetails( slug: string ): Observable<IJobOfferDetails> {
    return super.getDetails( slug );
  }
}
