import { Component, OnInit } from '@angular/core';
import { GlobalStateHelper } from '@helpers/GlobalStateHelper';

@Component({
  selector: 'app-activate-account-popup',
  templateUrl: './activate-account-popup.component.html',
  styleUrls: ['./activate-account-popup.component.scss']
})
export class ActivateAccountPopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
