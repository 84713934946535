import {Component, OnDestroy, OnInit} from '@angular/core';
import CarouselImageEnum from '@enums/CarouselImageEnum';
import { IImage } from '@interfaces/IImage';
import ICarouselImages from '@interfaces/ICarouselImages';
import { IConfiguration} from '@interfaces/IConfiguration';
import ApiConfigurationEndpointEnum from '@enums/ApiConfigurationEndpointEnum';
import { takeUntil } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators';
import * as cloneDeep from 'lodash/cloneDeep';
import { ConfigurationService } from '@services/configuration.service';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import ICategory from '@interfaces/ICategory';
import {BehaviorSubject} from 'rxjs';
import {CategoryService} from '@services/category.service';
import IFilter from '@interfaces/IFilter';
import ConfigService from '@config/ConfigService';
import {ITreatment} from '@interfaces/ITreatment';
import {LayoutService} from '@services/layout.service';
import IOrder from '@interfaces/IOrder';
import IBackToClickedElem from '@interfaces/IBackToClickedElem';
import {RouteMapHelper} from '@helpers/RouteMapHelper';
import {MedicalWellnessService} from '@services/medical-wellness.service';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'app-medical-wellness',
  templateUrl: './medical-wellness.component.html',
  styleUrls: ['./medical-wellness.component.scss']
})
export class MedicalWellnessComponent extends SubscribeHelper implements OnInit, OnDestroy {

  galleryImages: IImage[] = [];
  carouselImages: ICarouselImages[] = [];

  medicalWellnessCategories: ICategory[] = [];

  medicalWellnessMenuFile: IConfiguration = null;
  showCategoryLoader$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  backElementStore: IBackToClickedElem = null;
  loading = false;
  currentLang: string = ConfigService.getCurrentLang();
  list: ITreatment[] = [];
  page = 1;
  limit = 10;
  responseMax = 0;
  responseCurrent = 0;
  isFirstSingledOut = false;
  filters: IFilter[] = [];
  orders: IOrder[] = [];
  isListLoaded = false;

  constructor(
    private apiConfiguration: ConfigurationService,
    private categoryService: CategoryService,
    private medicalWellnessService: MedicalWellnessService,
    private layoutService: LayoutService,
    private routeMapHelper: RouteMapHelper
  ) {
    super();

    this.orders.push({
      field: 'isSingledOut',
      type: 'desc'
    }, {
      field: 'sequence',
      type: 'asc'
    });
  }

  ngOnInit() {
    this.setCarouselImages();
    this.getList();
    this.setGalleryImages();
    this.getMedicalWellnessMenuFile();
    this.getMedicalWellnessCategories();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getList( clickSource?: string ): void {
    this.loading = true;
    if ( this.isListLoaded ) {
      this.layoutService.itemLoader$.next(true);
    }

    if (clickSource !== 'filter') {
      this.backElementStore = this.layoutService.getBackToClickedElementStoreByRoute( ConfigService.getCurrentRouteGroupPath() );
    }

    const groupPart = ConfigService.getPreviousRoute().split('/');
    const firstPart = this.routeMapHelper.getGroup( groupPart[2] );
    const secondPart = this.routeMapHelper.getGroup( groupPart[3] );

    if ( firstPart !== 'wellness' ) {
      this.backElementStore = null;
    }

    let limit = this.limit;
    let page = this.page;
    let filters = this.filters;
    let orders = this.orders;

    if ( !filters.length ) {
      if ( sessionStorage ) {
        if ( sessionStorage.getItem('filtersActive') ) {
          const sessionFilters: string[] = JSON.parse(sessionStorage.getItem('filtersActive'));
          filters.push({
            key: 'category',
            value: sessionFilters
          });
        }
      }
    }

    if ( this.backElementStore ) {
      limit = this.backElementStore.limit * (this.backElementStore.page > 1 ? this.backElementStore.page - 1 : 1);
      page = 1;
      filters = this.backElementStore.filters;
      orders = this.backElementStore.orders;

      this.page = this.backElementStore.page;
    }

    const singledOutFilters: IFilter[] = cloneDeep(filters);
    singledOutFilters.push({
      key: 'isSingledOut',
      value: 1
    });

    const notSingledOutFilters: IFilter[] = cloneDeep(filters);
    notSingledOutFilters.push({
      key: 'isSingledOut',
      value: 0
    });

    this.medicalWellnessService.getListExtended( notSingledOutFilters, orders, limit, page )
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((list: HttpResponse<ITreatment[]>) => {
        this.medicalWellnessService.getList(singledOutFilters, [], 1)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(singledOutList => {
            this.list = list.body;

            if (typeof singledOutList[0] !== 'undefined') {
              this.list.unshift( singledOutList[0] );
              this.setIsSingledOut( singledOutList[0] );
            }
            else {
              this.isFirstSingledOut = false;
            }

            if ( this.isListLoaded ) {
              this.layoutService.itemLoader$.next(false);
            }
            this.loading = false;
            this.isListLoaded = true;

            if ( this.backElementStore ) {
              this.backToClickedElement();
            }
          });

        this.responseMax = JSON.parse(list.headers.get('Query-Count'));
        this.responseCurrent = list.body.length;

        if ( !this.backElementStore ) {
          this.page += 1;
        }
      });

  }

  setGalleryImages() {
    const path = '../../../assets/images/gallery/wellness_spa';

    for (let i = 1; i < 7; i++) {
      for (let j = 1; j < 6; j++) {
        this.galleryImages.push({
          image: `${path}/${i}_slide/${j}.jpg`,
          image2x: `${path}/${i}_slide/${j}.jpg`,
        });
      }
    }
  }

  setCarouselImages() {
    const path = '../../../assets/images/carousel/wellness/';

    this.carouselImages.push({
      image: {
        image: path + '01.jpg',
        image2x: path + '01@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '02.jpg',
        image2x: path + '02@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '03.jpg',
        image2x: path + '03@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    });
  }

  getMedicalWellnessMenuFile(): void {
    this.apiConfiguration
      .getConfiguration(ApiConfigurationEndpointEnum.MedicalWellnessMenu)
      .pipe(
        takeUntil(this.componentDestroyed$),
        catchError(err => {
          this.medicalWellnessMenuFile = { id: null, value: '', iso: '', name: '' };
          return err;
        })
      )
      .subscribe((res: IConfiguration) => {
        this.medicalWellnessMenuFile = res;
      });
  }

  getMedicalWellnessCategories(): void {
    this.showCategoryLoader$.next(true);
    this.categoryService.getMedicalWellnesList( [], [{field: 'id', type: 'ASC'}], 0 )
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe( list => {
        this.medicalWellnessCategories = list;

        this.showCategoryLoader$.next(false);
      });
  }

  filterValues( filters: IFilter[] ): void {
    this.filters = filters;
    this.page = 1;
    this.backElementStore = null;
    this.getList('filter');
  }

  loadMore(): void {
    this.loading = true;

    const notSingledOutFilters: IFilter[] = cloneDeep(this.filters);
    notSingledOutFilters.push({
      key: 'isSingledOut',
      value: 0
    });

    this.medicalWellnessService.getList(notSingledOutFilters, this.orders, this.limit, this.page)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((list: any) => {
        this.list.push.apply(this.list, list);

        this.responseCurrent += list.length;
        this.page += 1;
        this.loading = false;
      });
  }

  setElementInfo = ( id: number ) => {
    if ( window ) {
      this.layoutService.backToClickedElementStore = {
        id,
        page: this.page,
        limit: 10,
        route: ConfigService.getCurrentRouteGroupPath(),
        offset: {
          top: window.pageYOffset
        },
        filters: this.filters,
        orders: this.orders,
        doAction: false
      };
    }
  }

  backToClickedElement() {
    const clickedElemStore = this.layoutService.backToClickedElementStore;

    if ( clickedElemStore && window ) {
      setTimeout(() => {
        try {
          const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

          if ( supportsNativeSmoothScroll ) {
            window.scrollTo({top: clickedElemStore.offset.top, behavior: 'smooth'});
          }
          else {
            window.scrollTo(0, clickedElemStore.offset.top);
          }
        }
        catch (e) {
          window.scrollTo(0, clickedElemStore.offset.top);
        }
      }, 500);
    }
  }

  setIsSingledOut( firstItem: ITreatment ) {
    if ( firstItem ) {
      this.isFirstSingledOut = firstItem.isSingledOut;
    }
    else {
      this.isFirstSingledOut = false;
    }
  }
}
