<div class="container">
  <div class="row justify-content-center pt-giga pb-giga pl-deci pr-deci pl-sm-zero pr-sm-zero">
    <div class="col-12 col-sm-10 col-md-7 col-xxl-6">

      <app-password-recovery-content
        header="recover_password"
        description="recover_password_description"
        (formSent)="formSent($event)"
        [link]="'/:lang/blogostan/sign-in-group'|i18nRoute"
      ></app-password-recovery-content>

    </div>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="row justify-content-center pl-deci pr-deci pl-sm-zero pr-sm-zero pt-giga pb-giga">
      <div class="col-12 col-sm-10 col-md-7 col-xxl-6">
        <h3 class="pb-mega text-center">{{ 'create_account'|translate }}</h3>
        <a
          [href]="'/:lang/blogostan/sign-up-group'|i18nRoute"
          #register
          class="btn btn-bordered on-white w-100"
        >
          {{ 'register'|translate }}
        </a>
      </div>
    </div>
  </div>
</div>
