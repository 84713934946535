import { Component, OnInit } from '@angular/core';
import {routeAnimation} from '@helpers/animations';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss'],
  animations: [
    routeAnimation
  ]
})
export class CareerComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
