import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'currencySign'
})
export class CurrencySignPipe implements PipeTransform {

  transform( lang: string ): string {
    const currencies = environment.languageData;
    let currency = null;

    currencies.forEach( curr => {
      if ( curr.lang.toLowerCase() === lang ) {
        currency = curr.currency;
      }
    });

    return currency ? currency : lang;
  }

}
