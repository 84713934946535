import {Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MenuService} from '@services/menu.service';
import {IMenuElement} from '@interfaces/IMenuElement';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends SubscribeHelper implements OnInit {
  @ViewChildren('hover') hoverElements: QueryList<ElementRef>;
  @ViewChildren('show') showElements: QueryList<ElementRef>;

  @Input() showMenuBtn: boolean;

  menuElements: IMenuElement[];
  restaurantMenu: string;

  constructor( private menuService: MenuService ) {
    super();
  }

  ngOnInit() {
    this.getMenuList();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getMenuList() {
    this.menuService
      .getList()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((res: IMenuElement[]) => {
        this.menuElements = res;
        if (this.menuElements && this.menuElements.length > 0) {
          this.restaurantMenu = this.menuElements[0].restaurantMenu;
        }
        this.setHoverFunctions();
      });
  }

  setHoverFunctions() {
    this.hoverElements.changes
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(c => {
        c.toArray().forEach((item, i) => {
          item.nativeElement.addEventListener('mouseenter', e => {
            this.showElements.toArray()[i].nativeElement.classList.add('visible');
          });

          item.nativeElement.addEventListener('mouseleave', e => {
            this.showElements.toArray()[i].nativeElement.classList.remove('visible');
          });
        });
      });
  }

}
