<div class="simple-gallery-container">
  <div class="row align-items-end">
    <div class="col-4 col-md-2 col-xl-2 offset-xl-1">
      <div class="image">
        <img
          class="img-fluid"
          [defaultImage]="'/assets/images/placeholders/placeholder.png'"
          [useSrcset]="hasSrcSetSupport"
          [lazyLoad]="hasSrcSetSupport ? (images[0].image + ' 1x, ' + images[0].image2x  + ' 2x') : images[0].image"
          alt="image 1"
        />
      </div>
    </div>
    <div class="col-8 col-md-6 col-xl-4">
      <div class="image mt-minus-tera">
        <img
          class="img-fluid"
          [defaultImage]="'/assets/images/placeholders/placeholder.png'"
          [useSrcset]="hasSrcSetSupport"
          [lazyLoad]="hasSrcSetSupport ? (images[1].image + ' 1x, ' + images[1].image2x + ' 2x') : images[1].image"
          alt="image 2"
        />
      </div>
    </div>
    <div class="offset-2 col-5 offset-md-0 col-md-2 col-xl-2">
      <div class="image mt-deca mt-md-0 mb-md-tera">
        <img
          class="img-fluid"
          [defaultImage]="'/assets/images/placeholders/placeholder.png'"
          [useSrcset]="hasSrcSetSupport"
          [lazyLoad]="hasSrcSetSupport ? (images[2].image + ' 1x, ' + images[2].image2x + ' 2x') : images[2].image"
          alt="image 3"
        />
      </div>
    </div>
    <div class="col-5 col-md-2 col-xl-2">
      <div class="image mt-deca mt-md-tera">
        <img
          class="img-fluid"
          [defaultImage]="'/assets/images/placeholders/placeholder.png'"
          [useSrcset]="hasSrcSetSupport"
          [lazyLoad]="hasSrcSetSupport ? (images[3].image + ' 1x, ' + images[3].image2x + ' 2x') : images[3].image"
          alt="image 4"
        />
      </div>
    </div>
  </div>
</div>
