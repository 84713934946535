<div class="container mobile-padding">
  <div class="row">
    <div class="col-xl-10 offset-xl-1">
      <app-section-header header="notifications" icon="/assets/images/notification-icon.svg"></app-section-header>
      <div *ngIf="notifications?.length > 0; else noNotifications" class="notifications pt-deca pb-deca">
        <div class="notification d-flex flex-column flex-md-row align-items-center pl-deca pr-deca pt-deci pb-deci pt-md-centi pb-md-centi"
             (click)="markAsRead(notification)"
             [ngClass]="{ 'mb-deca': !last, 'unread': !notification.is_received }"
             *ngFor="let notification of notifications; let last = last;">
          <div class="notification__date d-flex flex-column align-items-center align-self-stretch mr-mega">
            <span class="notification__month text-capitalize mb-1">{{ notification?.created_at?.date | datex:'MMM' }}</span>
            <span class="notification__day">{{ notification?.created_at?.date | datex:'DD' }}</span>
          </div>
          <p class="pt-deca pt-md-0 w-100" [ngClass]="{ 'pr-md-deca pr-lg-mega pb-deci pb-md-0': notification?.event_link }" [innerHTML]="notification?.content|removeWidows|newLineBreak"></p>
          <a
            *ngIf="notification?.event_link"
            class="btn btn-bordered on-white flex-shrink-0 ml-md-auto align-self-start align-self-center"
            target="_blank"
            (click)="$event.stopPropagation()"
            [href]="notification?.event_link"
          >{{ 'know_more'|translate }}</a>
        </div>
        <div class="content-center mt-kilo" *ngIf="responseCurrent < responseMax && !loading">
          <button class="btn btn-collapse" (click)="loadMore()">{{ 'load_more'|translate }}</button>
        </div>
        <div class="pt-kilo" *ngIf="loading">
          <app-loader [size]="30" [opacity]="'0.7'" [center]="true"></app-loader>
        </div>
      </div>
      <ng-template #noNotifications>
        <p class="small text-center pt-mega pb-mega">{{ 'no_notifications' | translate }}</p>
      </ng-template>
    </div>
  </div>
</div>
