<div class="bg-white">
  <div class="container">
    <div class="row justify-content-center pl-deci pr-deci pl-sm-zero pr-sm-zero pt-giga pb-giga">
      <div class="col-12 col-sm-10 col-md-7 col-xxl-6">
        <app-sign-up-content
          header="create_account"
          (formSent)="formSent($event)"
        ></app-sign-up-content>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row justify-content-center pt-giga pb-giga pl-deci pr-deci pl-sm-zero pr-sm-zero">
    <div class="col-12 col-sm-10 col-md-7 col-xxl-6">
      <h3 class="pb-mega text-center">{{ 'have_account'|translate }}</h3>
      <div>
        <a
          [href]="'/:lang/blogostan/sign-in-group'|i18nRoute"
          #login
          class="btn btn-bordered w-100"
        >
          {{ 'log_in'|translate }}
        </a>
      </div>
    </div>
  </div>
</div>
