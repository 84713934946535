import {Component, Input, OnInit} from '@angular/core';
import CollageImageEnum from '../../_enums/CollageImageEnum';
import IImageCollage from '@interfaces/IImageCollage';
import {IImage} from '@interfaces/IImage';

@Component({
  selector: 'app-images-collage',
  templateUrl: './images-collage.component.html',
  styleUrls: ['./images-collage.component.scss']
})
export class ImagesCollageComponent implements OnInit {

  @Input() type: CollageImageEnum;
  @Input() images: IImageCollage;

  smallImage: IImage;
  bigImage: IImage;

  smallImageClass: string;

  constructor() { }

  ngOnInit() {
    if ( this.images ) {
      this.smallImage = this.images.smallImage;
      this.bigImage = this.images.bigImage;
    }

    if ( this.type === CollageImageEnum.LargeTopSmall2ColBottomRight || this.type === CollageImageEnum.LargeTopSmall2ColBottomLeft ) {
      this.smallImageClass = 'col-2-image';
    }
  }

}
