<div class="container">
  <button class="close-btn" (click)="back()">
    <img src="/assets/images/reservations/forms/close-forms.svg" alt="close" />
  </button>
  <div class="row justify-content-center pt-giga pb-giga pl-sm-zero pr-sm-zero">
    <div class="col-12 col-sm-10 col-md-7 col-xxl-6">
      <h3 class="pb-deca pb-md-hecto text-center">{{ 'log_in'|translate }}</h3>
      <app-login-content (loginOutput)="onLogin()" [googleRedirect]="googleRedirect">
        <a
          class="link-underlined text-center d-block mt-hecto mt-md-zero"
          [href]="'/:lang/blogostan/recover-password-group'|i18nRoute"
          #forgottenPassword
        >
          {{ 'forgotten_password'|translate }}
        </a>
      </app-login-content>
    </div>
  </div>
</div>
<div class="bg-white">
  <div class="container">
    <div class="row justify-content-center pl-deci pr-deci pl-sm-zero pr-sm-zero pt-giga pb-giga">
      <div class="col-12 col-sm-10 col-md-7 col-xxl-6">
        <h3 class="pb-mega text-center">{{ 'create_account'|translate }}</h3>
        <a
          class="btn btn-bordered on-white w-100"
          [href]="'/:lang/blogostan/sign-up-group'|i18nRoute"
          #register
        >
          {{ 'register'|translate }}
        </a>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>