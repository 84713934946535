<div
  *ngIf="modal"
  class="appla-container"
  [ngClass]="{'modal-visible': modalVisible, 'modal-hidden': !modalVisible, 'modal-untouched': modalUntouched}"
>
  <div class="container mobile-padding">
    <div class="row align-items-center">
      <div class="col pt-deci pb-deci">
        <app-indent-content-images
          [header]="modal.title"
          [collageType]="collageEnum.LargeTopSmall3ColBottomLeft"
          [images]="imageCollage"
          [indentMoved]="true"
          [removeTopSpacing]="true"
        >
          <div class="content-text">
            <h4 *ngIf="modal.subtitle" class="pb-hecto high" [innerHTML]="modal.subtitle|removeWidows"></h4>
            <p class="pb-hecto small" [innerHTML]="modal.description|removeWidows|newLineBreak"></p>
            <div class="d-flex flex-sm-row flex-column">
              <a
                *ngIf="modal.ctaLink && modal.ctaName"
                class="btn btn-bordered btn-white mr-deci"
                [href]="modal.ctaLink"
                (click)="closeModal()"
              >{{ modal.ctaName }}</a>
              <button class="btn btn-white btn-underlined mt-deci mt-sm-zero" (click)="closeModal()">{{ 'close_message'|translate }}</button>
            </div>
          </div>
        </app-indent-content-images>
      </div>
    </div>
  </div>
</div>
