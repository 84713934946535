import { Component, OnInit, ViewChild } from '@angular/core';
import { PopupContentComponent } from 'src/app/_subprojects/reservation/_shared/popup-content/popup-content.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout-blogostan-popup',
  templateUrl: './logout-blogostan-popup.component.html',
  styleUrls: ['./logout-blogostan-popup.component.scss']
})
export class LogoutBlogostanPopupComponent implements OnInit {
  @ViewChild('popup') popup: PopupContentComponent;
  blogostanUrl = environment.blogostanUrl;
  
  constructor() { }

  ngOnInit(): void {
  }

  closePopup() {
    this.popup.closePopup();
  }
  
}

