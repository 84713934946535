import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-text-simple-centered',
  templateUrl: './text-simple-centered.component.html',
  styleUrls: ['./text-simple-centered.component.scss']
})
export class TextSimpleCenteredComponent implements OnInit {
  @Input() header?: string;
  @Input() classes?: string;
  @Input() maxHeaderWidth?: string;

  constructor() { }

  ngOnInit() {
  }

}
