import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-image-doubled-description',
  templateUrl: './image-doubled-description.component.html',
  styleUrls: ['./image-doubled-description.component.scss']
})
export class ImageDoubledDescriptionComponent implements OnInit {
  @Input() image1x: string;
  @Input() image2x: string;
  @Input() imageAlt: string;

  constructor() { }

  ngOnInit() {
  }

}
