import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import ICategory from '@interfaces/ICategory';
import IFilter from '@interfaces/IFilter';
import * as map from 'lodash/map';
import * as filter from 'lodash/filter';

@Component({
  selector: 'app-gallery-filters',
  templateUrl: './gallery-filters.component.html',
  styleUrls: ['./gallery-filters.component.scss']
})
export class GalleryFiltersComponent implements OnInit {
  filters: IFilter[] = [];

  @Input() categories: ICategory[];
  @Output() values: EventEmitter<IFilter[]> = new EventEmitter<IFilter[]>();

  constructor() { }

  ngOnInit(): void {
  }

  filter(id: number) {
    this.filters = [];

    this.categories = this.categories.map(category => {
      if (category.id === id) {
        return {
          ...category,
          active: !category.active
        };
      }

      return {
        ...category,
        active: false
      };
    });

    this.filters.push({
      key: 'category',
      value: filter(map(this.categories, n => n.active ? n.id : false))
    });

    this.values.emit(this.filters);
  }

}
