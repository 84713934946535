import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class UserAuthHelper {
  private loggedIn: boolean = false;
  public loggedInChange: Subject<boolean>;

  constructor() {
    this.loggedInChange = new Subject<boolean>();
  }

  public setLoggedIn(value: boolean): void {
    this.loggedIn = value;
    this.loggedInChange.next(value);
  }

  public getLoggedIn(): boolean {
    return this.loggedIn;
  }
}