import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import AssetTypeEnum from '@enums/AssetTypeEnum';
import ICardAsset from '@interfaces/cards/ICardAsset';
import IPhoto from '@interfaces/IPhoto';

@Component({
  selector: 'app-image-or-video',
  templateUrl: './image-or-video.component.html',
  styleUrls: ['./image-or-video.component.scss']
})
export class ImageOrVideoComponent implements AfterViewInit, OnDestroy {

  public videoTimeout = null;
  public assetTypeEnum: typeof AssetTypeEnum = AssetTypeEnum;
  @ViewChild('videoElement') videoElement: ElementRef;

  @Input() asset: IPhoto;

  constructor() { }
  ngOnDestroy() {
    if ( this.videoElement ) {
      this.videoElement.nativeElement.pause();
    }

    clearTimeout(this.videoTimeout);
  }

  ngAfterViewInit() {
    this.tryToPlayVideo();
  }

  tryToPlayVideo() {
    if ( this.asset.type === AssetTypeEnum.Video ) {
      if ( this.videoElement ) {
        this.videoTimeout = setTimeout(() => {
          this.videoElement.nativeElement.play().catch(err => {
            this.tryToPlayVideo();
          });
        }, 1000);
      }
    }
  }
}
