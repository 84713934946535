enum ReservationAvailableTypeEnum {
  wrongDate, // before term
  wrongConfig, // before people
  available, // before avail
  notFound,
  wrongPrice,
  customMsg
}

export default ReservationAvailableTypeEnum;
