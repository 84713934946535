import { Component, OnInit } from '@angular/core';
import {FormGroup} from '@angular/forms';
import ConfigService from '@config/ConfigService';
import {Router} from '@angular/router';
import {RouteMapHelper} from '@helpers/RouteMapHelper';
import {GlobalStateHelper} from '@helpers/GlobalStateHelper';

@Component({
  selector: 'app-blogostan-sign-up',
  templateUrl: './blogostan-sign-up.component.html',
  styleUrls: ['./blogostan-sign-up.component.scss']
})
export class BlogostanSignUpComponent implements OnInit {

  constructor(
    private router: Router,
    private routeMapHelper: RouteMapHelper,
    private globalStateHelper: GlobalStateHelper
  ) { }

  ngOnInit(): void {
  }

  formSent(_: FormGroup) {
    this.globalStateHelper.unlockActivateAccountPopup = true;

    const currentLang = ConfigService.getCurrentLang();
    this.router.navigate(
      [
        `${currentLang}/${this.routeMapHelper.getRoute('blogostan', currentLang)}/${this.routeMapHelper.getRoute('blogostan_activate-account-group', currentLang)}`
      ]);
  }

}
