import {EventEmitter, Injectable} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';
import IBackToClickedElem from '@interfaces/IBackToClickedElem';
import * as difference from 'lodash/difference';
import { IPopupInfoMessage } from '../_subprojects/reservation-form/_interfaces/IPopupInfoMessage';

@Injectable({
  providedIn: 'root'
})
export class LayoutService
{
  constructor(private router: Router) { }

  backToClickedElementStore: IBackToClickedElem = null;
  backToClickedElement: string = null;
  itemLoader$: Subject<boolean> = new Subject<boolean>();
  itemLoaderForce$: Subject<number> = new Subject();
  shortNav$: Subject<boolean> = new Subject<boolean>();
  isBannerLoaded$: EventEmitter<boolean> = new EventEmitter<boolean>();
  isNavigationPending$: Observable<boolean> = this.router.events.pipe(
    filter((event: RouterEvent) => this.isConsideredEvent(event)),
    map((event: RouterEvent) => this.isNavigationStart(event)),
    distinctUntilChanged()
  );
  isReservationRoute$: Subject<boolean> = new Subject<boolean>();
  isMyReservationsRoute$: Subject<boolean> = new Subject<boolean>();
  isHomepageRoute$: Subject<boolean> = new Subject<boolean>();
  isSideSummaryVisible: Subject<boolean> = new Subject<boolean>();
  private error500Popup$: Subject<boolean> = new Subject<boolean>();
  private reservationInfoPopup$: BehaviorSubject<IPopupInfoMessage> = new BehaviorSubject<IPopupInfoMessage>(null);
  private notificationPopup$: Subject<boolean> = new Subject<boolean>();
  private openSidebar$: Subject<boolean> = new Subject<boolean>();

  private forceReservationDataRefresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  langHidden: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public getBackToClickedElementStoreByRoute( route: string[] ): IBackToClickedElem
  {
    if ( this.backToClickedElementStore ) {
      const diff = difference(this.backToClickedElementStore.route, route);

      if ( diff.length === 0 ) {
        return this.backToClickedElementStore;
      }
    }

    return null;
  }

  public setItemLoader( value: boolean ): void {
    this.itemLoader$.next( value );
  }

  public getItemLoader(): Subject<boolean> {
    return this.itemLoader$;
  }

  public setItemLoaderForce( value: number ): void {
    this.itemLoaderForce$.next( value );
  }

  public getItemLoaderForce(): Subject<number> {
    return this.itemLoaderForce$;
  }

  public setShortNav( value: boolean ): void {
    this.shortNav$.next( value );
  }

  public getShortNav(): Subject<boolean> {
    return this.shortNav$;
  }

  public setError500Popup(value: boolean) {
    this.error500Popup$.next(value);
  }

  public getError500Popup(): Subject<boolean> {
    return this.error500Popup$;
  }

  public setReservationInfoPopup( msg: IPopupInfoMessage ): void {
    this.reservationInfoPopup$.next(msg);
  }

  public getReservationInfoPopup(): BehaviorSubject<IPopupInfoMessage> {
    return this.reservationInfoPopup$;
  }

  public setNotificationPopup(value: boolean) {
    this.notificationPopup$.next(value);
  }

  public getNotificationPopup(): Subject<boolean> {
    return this.notificationPopup$;
  }

  private isConsideredEvent(event: RouterEvent): boolean
  {
    return this.isNavigationStart(event)
      || this.isNavigationEnd(event);
  }

  private isNavigationStart(event: RouterEvent): boolean
  {
    return event instanceof NavigationStart;
  }

  private isNavigationEnd(event: RouterEvent): boolean
  {
    return event instanceof NavigationEnd
      || event instanceof NavigationCancel
      || event instanceof NavigationError;
  }

  getLangHidden(): BehaviorSubject<boolean> {
    return this.langHidden;
  }

  setLangHidden(val: boolean) {
    this.langHidden.next(val);
  }

  public setOpenSidebar(value: boolean) {
    this.openSidebar$.next(value);
  }

  public getOpenSidebar(): Subject<boolean> {
    return this.openSidebar$;
  }

  public getForceReservationDataRefresh(): BehaviorSubject<boolean> {
    return this.forceReservationDataRefresh;
  }

  public setForceReservationDataRefresh( val: boolean ) {
    this.forceReservationDataRefresh.next(val);
  }

  public setIsReservationRoute( val: boolean ) {
    this.isReservationRoute$.next(val);
  }
  public getIsReservationRoute(): Subject<boolean> {
    return this.isReservationRoute$;
  }

  public setIsMyReservationsRoute( val: boolean ) {
    this.isMyReservationsRoute$.next(val);
  }
  public getIsMyReservationsRoute(): Subject<boolean> {
    return this.isMyReservationsRoute$;
  }
  
  public setIsHomepageRoute( val: boolean ) {
    this.isHomepageRoute$.next(val);
  }
  public getIsHomepageRoute(): Subject<boolean> {
    return this.isHomepageRoute$;
  }

  public setSideSummaryVisible( val: boolean ) {
    this.isSideSummaryVisible.next(val);
  }
  public getSideSummaryVisible(): Subject<boolean> {
    return this.isSideSummaryVisible;
  }
}
