import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {IUserBanner} from '../_interfaces/IUserBanner';

@Injectable({
  providedIn: 'root'
})
export class UserBannerService {
  private readonly apiUrl: string;

  constructor(
    private http: HttpClient,
  ) {
    this.apiUrl = environment.shuumApiHostShort;
  }

  getBannerInfo(): Observable<IUserBanner> {
    return this.http.get<IUserBanner>(`${this.apiUrl}/users/banner`);
  }

}
