import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalStateHelper {
  private disableMenuAppear = false;
  private disableMenuAppearChange: Subject<boolean>;

  private lockMenu = false;
  public lockMenuChange: Subject<boolean>;

  private btnStickedChange: Subject<boolean>;
  private isBtnSticked = false;

  public showPopupWithoutAnimation = false;
  public unlockLogoutPopup = false;
  public unlockActivateAccountPopup = false;

  public loginVisible$: BehaviorSubject<boolean>;
  public accountNavigation: number;
  public accountNavigationChange: Subject<number>;

  public especiallyForYouNavigation: number;

  public gtmInitialized$: BehaviorSubject<boolean>;

  constructor() {
    this.disableMenuAppearChange = new Subject<boolean>();
    this.btnStickedChange = new Subject<boolean>();
    this.accountNavigationChange = new Subject<number>();
    this.lockMenuChange = new Subject<boolean>();
    this.loginVisible$ = new BehaviorSubject<boolean>(true);
    this.gtmInitialized$ = new BehaviorSubject<boolean>(false);
  }

  getDisableMenuAppear(): boolean {
    return this.disableMenuAppear;
  }

  setDisableMenuAppear(val: boolean) {
    this.disableMenuAppearChange.next(val);
    this.disableMenuAppear = val;
  }

  getDisableMenuAppearChange(): Subject<boolean> {
    return this.disableMenuAppearChange;
  }

  getIsBtnSticked(): boolean {
    return this.isBtnSticked;
  }

  setIsBtnSticked(val: boolean) {
    this.btnStickedChange.next(val);
    this.isBtnSticked = val;
  }

  getBtnStickedChange(): Subject<boolean> {
    return this.btnStickedChange;
  }

  updateAccountNavigationChange(value: number) {
    this.accountNavigation = value;
    this.accountNavigationChange.next(value);
  }

  updateEspeciallyForYouNavigationChange(value: number) {
    this.especiallyForYouNavigation = value;
  }

  setLockMenu(value: boolean) {
    this.lockMenu = value;
    this.lockMenuChange.next(value);
  }

  setLoginVisible( value: boolean ) {
    this.loginVisible$.next(value);
  }

  getLoginVisible(): BehaviorSubject<boolean> {
    return this.loginVisible$;
  }

  getLockMenu(): boolean {
    return this.lockMenu;
  }

  setGtmInitialized( value: boolean ) {
    this.gtmInitialized$.next(value);
  }

  getGtmInitialized(): BehaviorSubject<boolean> {
    return this.gtmInitialized$;
  }
}
