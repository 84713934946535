<div class="user-header container mobile-padding" [ngClass]="!user?.loyaltyProgram ? 'pt-tera pt-lg-exa' : 'pt-kilo'">
  <div class="row">
    <div class="col-xl-10 offset-xl-1 user-header-content">
      <ng-container *ngIf="!user?.loyaltyProgram">
        <div class="user-header-image">
          <img
            srcset="/assets/images/reservations/user-profile/avatars/profile_bigger.png 1x, /assets/images/reservations/user-profile/avatars/profile_bigger@2x.png 2x"
            src="/assets/images/reservations/user-profile/avatars/profile_bigger.png"
          />
        </div>
        <div class="user-header-name pl-lg-mega d-flex">
          <h3 class="pt-hecto pt-lg-zero">{{ user?.name }} {{user?.lastName}}</h3>
          <p class="pt-deca pt-lg-hecto small-text">{{ 'joined'|translate }} {{ user?.createdAt?.date|datex:'DD.MM.yyyy' }}</p>
        </div>
      </ng-container>
      <ng-container *ngIf="user?.loyaltyProgram">
        <img
          *ngIf="user?.loyaltyProgram"
          src="/assets/images/reservations/user-profile/avatars/profile_loyalty_program.svg"
        />
        <div class="pl-lg-deca d-flex flex-column w-100">
          <h3 class="pt-hecto pt-lg-zero pb-centi">{{ user?.name }} {{user?.lastName}}</h3>
          <div *ngIf="user?.role" class="h4">{{ 'shuum_me'|translate }}: {{ roleEnum[user?.role] }}</div>
          <div class="h5 text-loyalty-gray font-weight-bold text-underline mt-3">{{ 'number_of_points' | translate }} {{ user?.points || 0 }}</div>
        </div>
      </ng-container>
      <div class="user-header-links pt-mega pt-lg-zero pb-0 justify-content-between flex-column align-items-center">
        <button (click)="showPopup()" class="bg-none">{{ 'change_password'|translate }}</button>
        <a
          class="logout-link no-underline mt-deca mt-lg-zero"
          [routerLink]="'/:lang/my-reservation/authorization-group/logout-group'|i18nRoute"
          (click)="logout()"
        >{{ 'log_out'|translate }}</a>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>

<app-contact-form *ngIf="user?.loyaltyProgram" header="contact_us_any_matter" [bookingType]="bookingType"></app-contact-form>

<app-popup-content #popupNewPassword [hideSecondPart]="true" [inPlace]="true">
  <div content1 class="pt-centi pb-centi">
    <h3 class="pb-mega text-center">{{ 'add_new_password'|translate }}</h3>
    <p class="smaller-text weight-300 pb-mega text-center">{{ 'add_new_password_desc_account'|translate }}</p>
    <form [formGroup]="newPasswordForm" class="form" (ngSubmit)="onSubmit()">
      <div class="pb-deca pb-md-28">
        <app-input-password
          inputId="old-password"
          control="currentPassword"
          [group]="newPasswordForm"
          fieldName="old_password"
          [apiValidationMsg]="errors?.currentPassword"
          #input
        ></app-input-password>
      </div>
      <div class="pb-deca pb-md-28">
        <app-input-password
          inputId="new-password"
          control="plainPassword"
          [group]="newPasswordForm"
          fieldName="new_password"
          [apiValidationMsg]="errors?.plainPassword"
          #input
        ></app-input-password>
      </div>
      <div class="pb-kilo">
        <app-input-password
          inputId="repeat-password"
          control="plainPasswordRepeat"
          [group]="newPasswordForm"
          fieldName="form_repeat_password"
          [matchingError]="newPasswordForm.errors?.matchingFields"
          [apiValidationMsg]="errors?.plainPasswordRepeat"
          #input
        ></app-input-password>
      </div>
      <div class="pb-deca pb-md-hecto">
        <button class="btn btn-bordered w-100" [ngClass]="{'disabled': loading}" [disabled]="loading" type="submit">
          <span *ngIf="!loading">{{ 'confirm'|translate }}</span>
          <app-loader [size]="16" [opacity]="'1'" [center]="true" *ngIf="loading"></app-loader>
        </button>
      </div>
      <div class="validation-msg-container summary-top">
        <p class="error">{{ errorMsg|translate }}</p>
        <p class="success">{{ successMsg|translate }}</p>
      </div>
      <div class="pt-hecto pt-md-kilo">
        <app-back-arrow
          text="back_to_profile"
          [popup]="popupNewPassword"
        ></app-back-arrow>
      </div>
    </form>
  </div>
</app-popup-content>
