import { FormControl, ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

export function checkboxValidate(c: AbstractControl): { [key: string]: boolean } | null {
  if ( !c.value ) {
    return {
      checkbox: true
    };
  }

  return null;
}

export function matchingFieldsValidate(field1: string, field2: string): ValidatorFn {
  return (fg: FormGroup): { [key: string]: boolean } | null => {
    if ( fg.get(field1).value !== fg.get(field2).value ) {
      return {
        matchingFields: true
      };
    }
    
    return null;
  };
}

export function requiredConditionalValidator(field: any, isToggleChecked: boolean): ValidatorFn {
  return (fg: FormGroup): { [key: string]: boolean } | null => {
    const value = fg?.parent?.get(field)?.value ?? isToggleChecked;
    if ( value === false ) {
      return null;
    }

    if ( value === true && ( fg.value === '' || fg.value === null || typeof fg.value === 'undefined' )) {
      return { 
        required: true
      };
    }
    
    return null;
  };
}

export function lengthConditionalValidator(field: any, isToggleChecked: boolean, minLength?: number, maxLength?: number) {
  return (fg: FormGroup): { [key: string]: any } | null => {
    const value = fg?.parent?.get(field)?.value ?? isToggleChecked;
    if ( value === false ) {
      return null;
    }

    if ( value === true && ( minLength !== null && fg.value.length < minLength )) {
      return { 
        minlength: {
          requiredLength: minLength
        }
      };
    }

    if ( value === true && ( maxLength !== null && fg.value.length > maxLength )) {
      return { 
        maxlength: {
          requiredLength: maxLength
        }
      };
    }
    
    return null;
  };
}

export function patternConditionalValidator(field: any, isToggleChecked: boolean, pattern: RegExp) {
  return (fg: FormGroup): { [key: string]: any } | null => {
    const value = fg?.parent?.get(field)?.value ?? isToggleChecked;
    if ( value === false ) {
      return null;
    }

    if ( !fg.value.match(pattern) ) {
      return {
        pattern
      };
    }
    
    return null;
  };
}


export function requieIfEnabledValidator(c: AbstractControl): { [key: string]: boolean } | null {
  if ( c.enabled && (c.value === '' || c.value === null || typeof c.value === 'undefined') ) {
    return {
      required: true
    };
  }

  return null;
}

export function lengthIfEnabledValidator(minLength?: number, maxLength?: number) {
  return (fg: FormGroup): { [key: string]: any } | null => {
    if ( fg.enabled ) {
      if ( fg.value === '' || fg.value === null || typeof fg.value === 'undefined' ) {
        return {
          required: true
        };
      }

      if ( minLength !== null && fg.value.length < minLength ) {
        return { 
          minlength: {
            requiredLength: minLength
          }
        };
      }

      if ( maxLength !== null && fg.value.length > maxLength ) {
        return { 
          maxlength: {
            requiredLength: maxLength
          }
        };
      }
    }

    return null;
  };
}

export function patternIfEnabledValidator(pattern: RegExp) {
  return (fg: FormGroup): { [key: string]: any } | null => {
    if ( fg.enabled ) {
      if ( fg.value === '' || fg.value === null || typeof fg.value === 'undefined' ) {
        return {
          required: true
        };
      }

      if ( !fg.value.match(pattern) ) {
        return {
          pattern
        };
      }
    }

    return null;
  };
}
