import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalStateHelper } from '@helpers/GlobalStateHelper';
import ConfigService from '@config/ConfigService';
import { RouteMapHelper } from '@helpers/RouteMapHelper';

@Injectable({
  providedIn: 'root'
})
export class ActivateAccountScreenGuard implements CanActivate {
  private currentLang: string;

  constructor(
    private router: Router,
    private globalStateHelper: GlobalStateHelper,
    private routeMapHelper: RouteMapHelper
  ) {
    this.currentLang = ConfigService.getCurrentLang();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if( this.globalStateHelper.unlockActivateAccountPopup ) {
      return true;
    } else {
      this.globalStateHelper.unlockActivateAccountPopup = false;

      this.router.navigate(
        [
          `${this.currentLang}/${this.routeMapHelper.getRoute('blogostan', this.currentLang)}`
        ])
      return false;
    }
  }

}
