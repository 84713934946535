import { Component, OnInit } from '@angular/core';
import {IImage} from '@interfaces/IImage';
import {IConfiguration} from '@interfaces/IConfiguration';
import ApiConfigurationEndpointEnum from '@enums/ApiConfigurationEndpointEnum';
import {takeUntil} from 'rxjs/operators';
import {catchError} from 'rxjs/internal/operators';
import {ConfigurationService} from '@services/configuration.service';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import { IBookingType } from 'src/app/_subprojects/reservation/_interfaces/IBookingType';

@Component({
  selector: 'app-hungry',
  templateUrl: './hungry.component.html',
  styleUrls: ['./hungry.component.scss']
})
export class HungryComponent extends SubscribeHelper implements OnInit {
  galleryImages: IImage[] = [];
  alaCarteMenuFile: IConfiguration = null;

  bookingType: IBookingType[] = [
    {
      id: 2,
      label: 'ask_table_availability',
      email: 'recepcja@shuumhotel.pl',
      default: true,
      time: {
        from: '11:00:00',
        to: '22:29:59'
      }
    }
  ];

  constructor(
    private apiConfiguration: ConfigurationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setGalleryImages();
    this.getAlaCarteMenuFile();
  }

  private setGalleryImages() {
    const path = '../../../assets/images/reservations/loyalty-program/hungry';

    for (let i = 1; i <= 4; i++) {
      this.galleryImages.push({
        image: `${path}/image_0${i}.jpg`,
        image2x: `${path}/image_0${i}@2x.jpg`,
      });
    }
  }

  getAlaCarteMenuFile(): void {
    this.apiConfiguration
      .getConfiguration(ApiConfigurationEndpointEnum.AlaCarteMenu)
      .pipe(
        takeUntil(this.componentDestroyed$),
        catchError(err => {
          this.alaCarteMenuFile = { id: null, value: '', iso: '', name: '' };
          return err;
        })
      )
      .subscribe((res: IConfiguration) => {
        this.alaCarteMenuFile = res;
      });
  }

}
