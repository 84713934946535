<div class="counter-container text-center" [ngClass]="class">
  <div
    class="counter-text"
    *ngIf="text"
  >
    {{ text | translate }}
  </div>

  <div
    class="counter d-flex justify-content-center align-items-center"
  >
    <div
      class="action minus"
      [ngClass]="{ 'disabled': minValue === counter }"
      (click)="decrement()"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="7.464" height="1.296" viewBox="0 0 7.464 1.296">
        <path id="Path_618" data-name="Path 618" d="M1.44-6.816v1.3H8.9v-1.3Z" transform="translate(-1.44 6.816)" fill="#fff"/>
      </svg>
    </div>

    <span>{{ counter }}</span>

    <div
      class="action plus"
      [ngClass]="{ 'disabled': maxValue === counter }"
      (click)="increment()"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="11.052" height="11.34" viewBox="0 0 11.052 11.34">
        <path id="Path_619" data-name="Path 619" d="M6.786-11.34v4.878H1.89v1.278h4.9V0H8.064V-5.184h4.878V-6.462H8.064V-11.34Z" transform="translate(-1.89 11.34)" fill="#fff"/>
      </svg>
    </div>
  </div>
</div>

