import { Component, OnInit } from '@angular/core';
import {GlobalStateHelper} from '@helpers/GlobalStateHelper';

@Component({
  selector: 'app-blogostan-activate-account',
  templateUrl: './blogostan-activate-account.component.html',
  styleUrls: ['./blogostan-activate-account.component.scss']
})
export class BlogostanActivateAccountComponent implements OnInit {

  constructor(
    private globalStateHelper: GlobalStateHelper
  ) { }

  ngOnInit(): void {
    this.globalStateHelper.unlockActivateAccountPopup = false;
  }

}
