import { Component, OnInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-cookie-bar',
  templateUrl: './cookie-bar.component.html',
  styleUrls: ['./cookie-bar.component.scss']
})
export class CookieBarComponent implements OnInit {
  public cookieDisplay: boolean = false;
  public cookieHide: boolean = false;

  public modalDisplay: boolean = false;
  public modalHide: boolean = false;

  private cookieName: string = 'shuum-cookie-accept';
  private scrollTopPosition = 0;

  constructor(
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    if(isPlatformBrowser(this.platformId)) {
      const cookie = this.cookieService.get(this.cookieName);

      if (!cookie) {
        this.cookieDisplay = true;
      }
    }
  }

  cookieAgree() {
    const expire = new Date();
    expire.setDate(expire.getDate() + 365);
    this.cookieService.put(this.cookieName, 'true', { expires: expire });
    this.cookieHide = true;

    this.hideModal();

    if(isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.cookieDisplay = false;
        this.cookieHide = false;
      }, 500);
    }
  }

  showModal() {
    if ( isPlatformBrowser(this.platformId) ) {
      this.modalDisplay = true;

      this.scrollTopPosition = window.pageYOffset;
      this.renderer.setStyle(this.document.body, 'top', -(this.scrollTopPosition) + 'px')
      this.renderer.addClass(this.document.body, 'noscroll');
    }
  }

  modalClickHandler(e) {
    if (e.target.classList.contains('cookie-modal-container')) {
      this.hideModal();
    }
  }

  hideModal() {
    if ( isPlatformBrowser(this.platformId) ) {
      if (this.modalDisplay) {
        this.modalHide = true;

        this.renderer.removeClass(this.document.body, 'noscroll');
        this.renderer.setStyle(this.document.body, 'top', null);
        window.scrollTo(0, this.scrollTopPosition);

        if(isPlatformBrowser(this.platformId)) {
          setTimeout(() => {
            this.modalDisplay = false;
            this.modalHide = false;
          }, 500);
        }
      }
    }
  }
}
