import IReservationDate from '../_interfaces/IReservationDate';
import {BehaviorSubject, Subject} from 'rxjs';
import IReservationRoom from '../_interfaces/IReservationRoom';
import IReservationExtraNew from '../_interfaces/IReservationExtraNew';
import {IReservationExistingExtra} from '../_interfaces/IReservationExistingExtra';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {environment} from '../../../../environments/environment';
import { ReservationErrorModalEnum } from '../_enums/ReservationErrorModalEnum';
import * as cloneDeep from 'lodash/cloneDeep';
import { IReservationOffer } from '../_interfaces/IReservationOffer';

export class ReservationData {
  date$: BehaviorSubject<IReservationDate> = new BehaviorSubject<IReservationDate>({
    from: null,
    to: null
  });
  rooms$: BehaviorSubject<IReservationRoom[]> = new BehaviorSubject<IReservationRoom[]>([]);
  coupon$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  pointsUsed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  reservationOffer$: BehaviorSubject<IReservationOffer> = new BehaviorSubject<IReservationOffer>(null);
  shuummePointsPercent$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  roomsSelectedExtras$: BehaviorSubject<IReservationExtraNew[]> = new BehaviorSubject<IReservationExtraNew[]>([]);
  existingExtras$: BehaviorSubject<IReservationExistingExtra[]> = new BehaviorSubject<IReservationExistingExtra[]>([]);
  refreshExtras$: Subject<{ force: boolean, delay: number }> = new Subject<{ force: boolean, delay: number }>();
  replaceDataAfterLangChanged$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  rooms: IReservationRoom[] = [];
  errors$: Subject<ReservationErrorModalEnum> = new Subject<ReservationErrorModalEnum>();

  /**
   * Po zmianie liczby gości w pokoju, standardy pokojów
   * są usuwane, ale kopia pokojów jest zapisywana tutaj.
   * Następnie wykonywane jest zapytanie do API czy sekwencja
   * z takimi pokojami jest dalej możliwa - jeśli tak,
   * to standardy są z powrotem przypisywane do pokojów
   */
  tempRooms$: BehaviorSubject<IReservationRoom[]> = new BehaviorSubject<IReservationRoom[]>([]);

  constructor() { }

  setData( data ) {
    if ( data ) {
      if ( typeof data.date !== 'undefined' ) {
        if ( data.date ) {
          this.dateValidation( data.date );
        }
      }
      if ( typeof data.rooms !== 'undefined' ) {

        /**
         * @todo sprawdzic czy ma wszystkie pola które powinno miec
         */
        this.rooms$.next( data.rooms );
        this.rooms = data.rooms;

        this.tempRooms$.next( cloneDeep(data.rooms) );
      }
      if ( typeof data.coupon !== 'undefined' ) {
        this.coupon$.next( data.coupon );
      }
      if ( typeof data.pointsUsed !== 'undefined' ) {
        this.pointsUsed$.next( data.pointsUsed );
      }
      if ( typeof data.reservationOffer !== 'undefined' ) {
        this.reservationOffer$.next( data.reservationOffer );
      }
      if ( typeof data.shuummePointsPercent !== 'undefined' ) {
        this.shuummePointsPercent$.next( data.shuummePointsPercent );
      }
    }
  }

  /**
   * Walidacja daty, warunki:
   * - dzisiejsza data musi byc mniejsza lub równa od daty startu rezerwacji
   *
   * @param date - IReservationDate
   */
  dateValidation( date: IReservationDate ): void {
    if ( typeof date.from !== 'undefined' && typeof date.to !== 'undefined' ) {
      const todayMoment = moment();
      const today = new NgbDate(
        parseInt(todayMoment.format('YYYY'), 10),
        parseInt(todayMoment.format('M'), 10),
        parseInt(todayMoment.format('D'), 10)
      );

      if ( today.before(date.from) || today.equals(date.from) ) {
        this.date$.next( date );
      }
      else if ( date.from === null && date.to === null ) {

      }
      else {
        try {
          if ( sessionStorage ) {
            const nullDate = { from: null, to: null };

            this.errors$.next(ReservationErrorModalEnum.DateExpired);

            sessionStorage.setItem(environment.reservationStorageKey, JSON.stringify({
              ...JSON.parse(sessionStorage.getItem(environment.reservationStorageKey)),
              date: nullDate
            }));
          }
        }
        catch (e) {

        }
      }
    }
  }
}
