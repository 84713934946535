import {animate, state, style, transition, trigger, query, stagger, group, sequence, animateChild} from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);

export const fadeInAnimation = trigger('fadeInAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(-1%)' }), animate('800ms ease', style({ opacity: 1, transform: 'translateX(0)' }))]
  )
]);

export const longFadeAnimation = trigger('longFadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('700ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('700ms', style({ opacity: 0 }))]
  )
]);

export const fadeDelayAnimation = trigger('fadeDelayAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [
      style({ opacity: 1 }),
      animate('300ms 0.5s ease', style({ opacity: 0 }))
    ]
  )
]);

export const halfFadeDelayAnimation = trigger('halfFadeDelayAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 0.5 }))]
  ),
  transition(':leave',
    [
      style({ opacity: 0.5 }),
      animate('300ms 0.5s ease', style({ opacity: 0 }))
    ]
  )
]);

export const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true}
    )
  ])
]);

export const activeAnimation = trigger('activeAnimation',[
  state('inactive', style({ 'opacity': '0', 'display': 'none' })),
  state('active', style({ 'opacity': '1' })),

  transition('inactive => active', [
    style({ 'display': 'block' }),
    animate('500ms ease-in')
  ]),
  transition('active => inactive', [
    animate('500ms ease-in')
  ])
]);

export const routeAnimation = trigger('routeAnimation', [
  transition( '* => *', [
    query(':enter',
      [
        style({ opacity: 0 })
      ],
      { optional: true }
    ),
    query(':leave',
      [
        style({ opacity: 1 }),
        animate('0.5s', style({ opacity: 0 }))
      ],
      { optional: true }
    ),
    query(':enter',
      [
        style({ opacity: 0 }),
        animate('0.8s 70ms', style({ opacity: 1 }))
      ],
      { optional: true }
    ),
    query(':scope > :leave router-outlet ~ *', [
      style({ opacity: .99999 }),
      animate('0.5s', style({ opacity: 1 }))
    ], { optional: true })
  ])
]);

export const sliderAnimation = trigger('slideAnimation', [
  transition(':increment', group([
    query(':enter', [
      sequence([
        style({ transform: 'translateX(100%)' }),
        group([
          animate(
            '500ms ease',
            style({ transform: 'translateX(0)' })
          ),
        ]),
      ])
    ],
    { optional: true }
    ),
    query(':leave', [
      sequence([
        group([
          animate(
            '500ms ease',
            style({ transform: 'translateX(-100%)' })
          ),
        ])
      ])
    ],
    { optional: true }
    ),
  ])),
  transition(':decrement', group([
    query(':enter', [
      sequence([
        style({ transform: 'translateX(-100%)' }),
        group([
          animate(
            '500ms ease',
            style({ transform: 'translateX(0)' })
          ),
        ]),
      ])
    ],
    { optional: true },
    ),
    query(':leave', [
      sequence([
        group([
          animate(
            '500ms ease',
            style({ transform: 'translateX(100%)' })
          ),
        ])
      ])
    ],
    { optional: true }
    ),
  ]))
]);

export const tearsAnimation = trigger('tearsAnimation',[
  state('appear', style({ transform: 'translateY(-100%)' })),
  state('static', style({ transform: 'translateY(0)' })),
  state('disappear', style({ transform: 'translateY(100%)' })),

  transition('static => disappear', [
    animate('1000ms cubic-bezier(0.76, 0, 0.3, 1)')
  ]),
  transition('disappear => appear', [
    animate('0ms')
  ]),
  transition('appear => static', [
    animate('1000ms cubic-bezier(0.76, 0, 0.3, 1)')
  ]),
  transition('disappear => static', [
    animate('500ms cubic-bezier(0.76, 0, 0.3, 1)')
  ]),
]);

export const tearsHorizontalAnimation = trigger('tearsHorizontalAnimation',[
  state('appear', style({ transform: 'translateX(-100%)' })),
  state('static', style({ transform: 'translateX(0)' })),
  state('disappear', style({ transform: 'translateX(100%)' })),

  transition('static => disappear', [
    animate('1000ms cubic-bezier(0.76, 0, 0.3, 1)')
  ]),
  transition('disappear => appear', [
    animate('0ms')
  ]),
  transition('appear => static', [
    animate('1000ms cubic-bezier(0.76, 0, 0.3, 1)')
  ]),
  transition('disappear => static', [
    animate('500ms cubic-bezier(0.76, 0, 0.3, 1)')
  ]),
]);

export const foldAnimation = trigger('foldAnimation', [
  transition(':enter', [style({height: 0, overflow: 'hidden'}), animate('.3s ease', style({height: '*'}))]),
  transition(':leave', [style({height: '*', overflow: 'hidden'}), animate('.3s ease', style({height: 0}))])
]);
