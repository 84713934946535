import {Component, Input, OnInit} from '@angular/core';
import IPrice from '@interfaces/IPrice';

@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss']
})
export class DiscoverComponent implements OnInit {

  @Input() header: string;
  @Input() name: string;
  @Input() description: string;
  @Input() price: IPrice;
  @Input() link: string;

  constructor() { }

  ngOnInit() {
  }

}
