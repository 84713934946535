import {Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from '@services/layout.service';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {delay, filter, map, mergeMap, takeUntil} from 'rxjs/operators';
import {ReservationStepService} from '@services/reservations/reservation-step.service';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {routeAnimation} from '@helpers/animations';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-reservation-form',
  templateUrl: './reservation-form.component.html',
  styleUrls: ['./reservation-form.component.scss'],
  animations: [
    routeAnimation
  ]
})
export class ReservationFormComponent extends SubscribeHelper implements OnInit, OnDestroy {
  layoutExtrasVisible = false;
  isSideSummaryVisible: boolean
  constructor(
    private layoutService: LayoutService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private reservationStep: ReservationStepService
  ) {
    super();
  }

  ngOnInit(): void {
    this.layoutService.isBannerLoaded$.next(true);
    this.layoutService.setShortNav( true );
    this.layoutService.getSideSummaryVisible()
    .pipe(
      delay(0),
      takeUntil(this.componentDestroyed$)
    ).subscribe(val => this.isSideSummaryVisible = val);

    this.setActiveStep();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.layoutService.setShortNav( false );
  }

  setActiveStep(): void {
    this.activatedRoute.url.pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe( () => {
        try {
          const stepNo = this.activatedRoute.snapshot.firstChild.data.stepNo;

          if ( typeof stepNo === 'undefined' || stepNo === null ) {
            this.layoutExtrasVisible = false;
          } else {
            this.layoutExtrasVisible = true;
            this.reservationStep.setActiveStep(stepNo);
          }
        }
        catch ( e ) {

        }
      });

    this.router.events
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )
      .subscribe( data => {
        try {
          if ( typeof data.stepNo === 'undefined' || data.stepNo === null ) {
            this.layoutExtrasVisible = false;
          } else {
            this.layoutExtrasVisible = true;
            this.reservationStep.setActiveStep(data.stepNo);
          }
        }
        catch ( e ) {

        }
      });
  }
}
