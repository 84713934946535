import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import GlobalFunctions from '@helpers/GlobalFunctions';
import { ISharedLink } from '../_interfaces/ISharedLink';
import { IReservationShared } from '../_interfaces/IReservation';
import {finalize, map} from 'rxjs/operators';

/**
 * Linki do rezerwacji zostały przeniesione
 * do innego serwisu
 */
@Injectable()
export class ReservationLinkService {
  previousReservationData = null;
  previousLink = null;

  constructor(
    protected http: HttpClient
  ) {}

  getSharedReservation( token: string ): Observable<IReservationShared> {
    return this.http.get<IReservationShared>(
      `${environment.shuumApiHost}/booking/share/${token}`
    ).pipe(map( data => {
      let jsonData: any = JSON.stringify(data);

      // zamieniamy stringi na inty, "true" na true itd
      jsonData = JSON.parse(jsonData, (k, v) => {
        const numb = Number(v);
        if ( !isNaN(numb) && !Array.isArray(v) ) {
          v = numb;
        }

        v = v === 'true' ? true : v === 'false' ? false : v;
        return v;
      });

      /**
       * uzupelnij tablice childrenAges nullami
       * prevArray -> jeśli podany był wiek drugiego lub trzeciego
       * dziecka, a pusty pierwszego, to dane w childrenAges były zwracane
       * jako obiekt w formie childrenAges: { 2: '9' }
       */
      jsonData.data.rooms.forEach(room => {
        const n = room.children;
        let prevArray = null;

        if ( n ) {
          if ( !room.childrenAges || !Array.isArray(room.childrenAges) ) {
            prevArray = room.childrenAges;
            room.childrenAges = [];
          }

          for ( let i = 0; i < n; i++ ) {
            if ( room.childrenAges && typeof room.childrenAges[i] === 'undefined' ) {
              if ( prevArray && prevArray[i] ) {
                room.childrenAges.push(prevArray[i]);
              } else {
                room.childrenAges.push(null);
              }
            }
          }
        }
      });

      if ( sessionStorage ) {
        sessionStorage.setItem(environment.reservationStorageKey, JSON.stringify(jsonData.data));
      }

      return jsonData;
    }));
  }

  copyLinkToReservation(): Observable<ISharedLink> {
    const data = JSON.parse(sessionStorage.getItem(environment.reservationStorageKey));
    data.rooms.forEach(room => {
      if ( room.guestData ) {
        delete room.guestData;
      }
    });

    const fd = GlobalFunctions.convertToFd(new FormData(), data);

    if (
      this.previousLink
      && this.previousReservationData
      && sessionStorage.getItem(environment.reservationStorageKey) === this.previousReservationData
    ) {
      return new Observable(observer => {
        const msg: ISharedLink = { url: this.previousLink };
        observer.next(msg);
        observer.complete();
      });
    }

    this.previousReservationData = sessionStorage.getItem(environment.reservationStorageKey);

    return this.http.post<ISharedLink>(
      `${environment.shuumApiHost}/booking/share`, fd
    ).pipe(map(res => {
      this.previousLink = res.url;

      return res;
    }));
  }
}
