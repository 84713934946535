<app-popup-content
  #popupLoyaltyProgram
  [hideSecondPart]="true"
  [inPlace]="true"
  [borderRadius]="true"
  [colorsReversed]="true"
  [popupParentClasses]="'col-12 col-xl-8 offset-xl-2'"
  [rwdClasses]="'col-12 pl-mega pr-mega'"
  [centerContent]="false"
  (closeOutput)="onCloseModal()"
>
  <div content1>
    <h3 class="text-loyalty-gray weight-200" [innerHTML]="'loyalty_program_popup_header'|translate"></h3>
    <p class="smaller-text pt-deca" [innerHTML]="'loyalty_program_popup_content'|removeWidows|newLineBreak|translate"></p>
    <div class="d-flex justify-content-center mt-kilo">
      <a
        class="btn btn-bordered on-white"
        [routerLink]="'/:lang/my-reservation/shuumme-panel-group'|i18nRoute"
        fragment="account_details"
        (click)="closeModal()"
      >{{ 'know_more'|translate }}</a>
    </div>
  </div>
</app-popup-content>
