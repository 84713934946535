import { Injectable } from '@angular/core';
import IActivity from '@interfaces/IActivity';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  private readonly path = '../../../assets/images/wellness_page/yoga/';

  private readonly activities: IActivity[] = [
    {
      header: 'yoga',
      formLabel: 'yoga_label',
      description: 'yoga_description',
      note: 'yoga_description_note',
      images: {
        smallImage: {
          image: this.path + 'joga_02_tiny.jpg',
          image2x: this.path + 'joga_02@2x_tiny.jpg'
        },
        bigImage: {
          image: this.path + 'joga_01_tiny.jpg',
          image2x: this.path + 'joga_01@2x_tiny.jpg',
          rwd: [
            {
              image: this.path + 'joga_01_mobile.jpg'
            }
          ]
        }
      }
    },
    {
      header: 'pilates',
      formLabel: 'pilates_label',
      description: 'pilates_description',
      images: {
        smallImage: {
          image: this.path + 'pilates_small_tiny.jpg',
          image2x: this.path + 'pilates_small@2x_tiny.jpg'
        },
        bigImage: {
          image: this.path + 'pilates_big_tiny.jpg',
          image2x: this.path + 'pilates_big@2x_tiny.jpg',
          rwd: [
            {
              image: this.path + 'pilates_01_mobile.jpg'
            }
          ]
        }
      }
    },
    {
      header: 'aquafitness',
      formLabel: 'aquafitness_label',
      description: 'aquafitness_description',
      images: {
        smallImage: {
          image: this.path + 'aguafitness_02_tiny.jpg',
          image2x: this.path + 'aguafitness_02@2x_tiny.jpg'
        },
        bigImage: {
          image: this.path + 'aguafitness_01_tiny.jpg',
          image2x: this.path + 'aguafitness_01@2x_tiny.jpg',
          rwd: [
            {
              image: this.path + 'aquafitness_01_mobile.jpg'
            }
          ]
        }
      }
    },
    // {
    //   header: 'nordic_walking',
    //   formLabel: 'nordic_walking_label',
    //   description: 'nordic_walking_description',
    //   images: {
    //     smallImage: {
    //       image: this.path + 'nordic_walking_small_tiny.jpg',
    //       image2x: this.path + 'nordic_walking_small@2x_tiny.jpg'
    //     },
    //     bigImage: {
    //       image: this.path + 'nordic_walking_big_tiny.jpg',
    //       image2x: this.path + 'nordic_walking_big@2x_tiny.jpg',
    //       rwd: [
    //         {
    //           image: this.path + 'nordic_walking_01_mobile.jpg'
    //         }
    //       ]
    //     }
    //   }
    // }
  ];

  constructor() { }

  getActivities(): IActivity[] {
    return this.activities;
  }
}
