import { Component, OnInit } from '@angular/core';
import {LayoutService} from '@services/layout.service';
import {halfFadeDelayAnimation} from '@helpers/animations';

@Component({
  selector: 'app-item-loader',
  templateUrl: './item-loader.component.html',
  styleUrls: ['./item-loader.component.scss'],
  animations: [
    halfFadeDelayAnimation
  ]
})
export class ItemLoaderComponent implements OnInit {
  public showLoader: boolean;
  public counter = 0;

  constructor(
    private layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    this.layoutService.getItemLoader().subscribe( value => {
      if ( value ) {
        this.counter++;
      }
      else {
        this.counter--;

        if ( this.counter < 0 ) {
          this.counter = 0;
        }
      }

      if ( !value && this.counter === 0 ) {
        this.showLoader = false;
      }
      else {
        this.showLoader = true;
      }
    });

    this.layoutService.getItemLoaderForce().subscribe(val => {
      this.counter = val;
    });
  }

}
