<app-popup-content
  [colorsReversed]="true"
  #popup
>
  <div content1>
    <app-sign-up-content
      header="create_account"
      (formSent)="formSent($event)"
      [popup]="popup"
    ></app-sign-up-content>
  </div>
  <div content2>
    <h3 class="pb-mega text-center">{{ 'have_account'|translate }}</h3>
    <div> 
      <a
        (click)="redirectWithBlockedScroll($event, login.getAttribute('href'))"
        [href]="'/:lang/my-reservation/authorization-group/sign-in-group'|i18nRoute"
        #login
        class="btn btn-bordered w-100"
      >
        {{ 'log_in'|translate }}
      </a>
    </div>
  </div>
</app-popup-content>
