<app-banner
  header="lobby_bar"
  description="lobby_bar_description"
  background="/assets/images/food_and_wine/lobby_bar/banner.jpg"
  background2x="/assets/images/food_and_wine/lobby_bar/banner@2x.jpg"
>
  <app-line-btn
    class="h4"
    scrollTo="lobby-bar"
    [noOffset]="true"
    bottomButton
  >
    <span>{{ 'know_more'|translate }}</span>
  </app-line-btn>
</app-banner>

<div id="lobby-bar" class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-text-simple-centered
      header="{{ 'lobby_bar_h_01'|translate}}"
    >
      <p class="p-responsive" [innerHTML]="'lobby_bar_c_01'|translate|newLineBreak|removeWidows"></p>
    </app-text-simple-centered>
  </div>
</div>

<app-after-before-grey
  width="23%"
  height="89%"
  top="47%"
  right="0"
  additionalClasses="thick-no-responsive to-top-right"
>
  <div class="container mobile-padding">
    <div class="pt-peta pt-md-zetta pt-lg-200">
      <app-image-additional
        [topHeader]="'lobby_bar'"
        image="/assets/images/food_and_wine/lobby_bar/section-1-img3.jpg"
        image2x="/assets/images/food_and_wine/lobby_bar/section-1-img3@2x.jpg"
        imageAlt="Caffe and tea additional image"
      >
        <div class="col-8 col-sm-7 offset-sm-1 offset-md-0 col-lg-4 offset-xl-1 order-0 order-lg-0">
          <div class="w-100">
            <app-images-collage
              [images]="imagesCollage"
              [type]="collageEnum.LargeTopSmall2ColBottomRight"
            ></app-images-collage>
          </div>
        </div>

        <div class="col-lg-5 col-xl-4 order-2 order-lg-1">
          <app-indent-content
            [header]="'lobby_bar_h_02'|translate|newLineBreak|removeWidows"
          >
            <p class="small pt-lg-kilo pt-hecto" [innerHTML]="'lobby_bar_c_02'|translate|newLineBreak|removeWidows"></p>
          </app-indent-content>
        </div>
      </app-image-additional>
    </div>
  </div>
</app-after-before-grey>

<app-after-before-grey
  width="23%"
  height="23%"
  top="57%"
  right="0"
  additionalClasses="thick-no-responsive to-top-right"
>
  <div class="container mobile-padding">
    <app-indent-content-between-images
      [header]="'lobby_bar_h_03'"
      [topHeader]="'lobby_bar'"
      [smallImage]="{ image: 'assets/images/food_and_wine/lobby_bar/section-2-img1.jpg', image2x: 'assets/images/food_and_wine/lobby_bar/section-2-img1@2x.jpg' }"
      [bigImage]="{ image: 'assets/images/food_and_wine/lobby_bar/section-2-img2.jpg', image2x: 'assets/images/food_and_wine/lobby_bar/section-2-img1@2x.jpg' }"
    >
      <p
        class="small pb-exa"
        [innerHTML]="'lobby_bar_c_03'|translate|newLineBreak|removeWidows"
      >
      </p>
    </app-indent-content-between-images>

    <div class="pt-peta pt-md-zetta pt-lg-200">
        <app-image-additional
          [topHeader]="'lobby_bar'"
          image="/assets/images/food_and_wine/lobby_bar/section-3-img3.jpg"
          image2x="/assets/images/food_and_wine/lobby_bar/section-3-img3@2x.jpg"
          imageAlt="Other alcohols additional image"
        >
          <div class="col-8 col-sm-7 offset-sm-1 offset-md-0 col-lg-4 offset-xl-1 order-0 order-lg-0">
            <div class="w-100">
              <app-images-collage
                [images]="imagesCollage2"
                [type]="collageEnum.LargeTopSmall2ColBottomRight"
              ></app-images-collage>
            </div>
          </div>

          <div class="col-lg-5 col-xl-4 order-2 order-lg-1">
            <app-indent-content
              [header]="'lobby_bar_h_04'|translate|newLineBreak|removeWidows"
            >
              <p class="small pt-lg-kilo pt-hecto" [innerHTML]="'lobby_bar_c_04'|translate|newLineBreak|removeWidows"></p>
            </app-indent-content>
          </div>
        </app-image-additional>
    </div>

<!--    <app-indent-content-between-images-->
<!--      [header]="'lobby_bar_h_05'|translate|removeWidows"-->
<!--      [topHeader]="'lobby_bar'"-->
<!--      [smallImage]="{ image: 'assets/images/food_and_wine/lobby_bar/section-4-img1.jpg', image2x: 'assets/images/food_and_wine/lobby_bar/section-4-img1@2x.jpg' }"-->
<!--      [bigImage]="{ image: 'assets/images/food_and_wine/lobby_bar/section-4-img2.jpg', image2x: 'assets/images/food_and_wine/lobby_bar/section-4-img1@2x.jpg' }"-->
<!--    >-->
<!--      <p-->
<!--        class="small pb-kilo"-->
<!--        [innerHTML]="'lobby_bar_c_05'|translate|newLineBreak|removeWidows"-->
<!--      >-->
<!--      </p>-->
<!--      <div class="actions pt-deca pb-tera">-->
<!--        <a [href]="'/:lang/food/wine-evenings-child'|i18nRoute" class="btn btn-bordered">{{ 'unravel_more'|translate }}</a>-->
<!--      </div>-->
<!--    </app-indent-content-between-images>-->



    <div class="pt-peta pt-md-zetta pt-lg-200">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 offset-xl-1">
          <div class="out-mobile-padding">
            <app-images-carousel [images]="carouselImages"> </app-images-carousel>
          </div>
        </div>
        <div class="col-12 col-lg-5 col-xl-4 offset-lg-1">
          <div class="pt-kilo pt-md-giga pt-lg-zero">
            <app-indent-content [header]="'our_team'">
              <p
                class="small"
                [innerHTML]="
                  'food_and_wine_our_team'
                    | translate
                    | newLineBreak
                    | removeWidows
                "
              ></p>

              <div class="actions pt-mega">
                <a
                  [routerLink]="'/:lang/career' | i18nRoute"
                  class="btn btn-bordered"
                  >{{ "join_us" | translate }}</a
                >
              </div>
            </app-indent-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-after-before-grey>

<app-after-before-grey
  width="48%"
  height="127%"
  top="-6%"
  left="0"
  additionalClasses="no-responsive"
>
  <div class="container mobile-padding">
    <div class="pt-peta pt-md-zetta pt-lg-200">
      <div class="row">
        <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 offset-md-1 offset-lg-2">
          <app-indent-content
            [header]="'opening_hours'"
            headerClass="light"
            wider
          >
            <div class="opening-hours pb-deca pb-md-hecto">
              <h4 class="h1">11:00<span> - </span>23:00</h4>
            </div>
            <p>{{ 'every_day'|translate }}</p>
          </app-indent-content>
        </div>
      </div>
    </div>
  </div>
</app-after-before-grey>

<div class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-gallery
      [header]="'food_and_wine'"
      [description]="'food_and_wine_gallery_description'"
      [images]="galleryImages"
    ></app-gallery>
  </div>

<!--  <div class="pt-peta pt-md-zetta pt-lg-200">-->
<!--    <app-discover-->
<!--      [header]="'discover_also'"-->
<!--      [name]="'Intense Repair\nShea Butter'"-->
<!--      [description]="'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit in voluptate velit esse.'"-->
<!--      [price]="{ value: 49.99, currency: 'PLN' }"-->
<!--      [link]="''"-->
<!--    ></app-discover>-->
<!--  </div>-->
</div>

<app-footer></app-footer>
