<div class="cookie-modal-container cookie-modal-overlay" *ngIf="cookieDisplay" [ngClass]="{'hidden': cookieHide}">
  <div class="cookie-container">
    <div class="container mobile-padding pt-hecto pb-hecto pl-hecto pr-hecto">
      <div class="row">
        <div class="col-12">
          <p [innerHtml]="'cookie_message'|translate|removeWidows" class="regular-hind text-center pb-deca"></p>
          <div class="text-center text-md-left button-wrapper d-flex align-items-center justify-content-center">
            <button class="btn btn-bordered on-white" (click)="cookieAgree()">{{ 'agree'|translate }}</button>
            <div class="flex-grow-0 pl-hecto d-flex align-items-center">
              <button class="btn btn-underlined" (click)="showModal()">{{'read_more'|translate}}</button></div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="cookie-modal-container" [ngClass]="{'hidden': modalHide}" *ngIf="modalDisplay" (click)="modalClickHandler($event)">
  <div class="cookie-modal-content pt-deca pb-deca pt-md-hecto pb-md-hecto pl-hecto pr-hecto pl-md-giga pr-md-giga">
    <h3 class="pb-deci pb-md-hecto content-center text-center">{{ 'privacy_policy_header'|translate }}</h3>
    <div class="text-container">
      <p class="very-small-text" [innerHTML]="'privacy_policy_desc'|translate|hyphenToLi|removeWidows|newLineBreak "></p>
    </div>
    <div class="pt-deci pt-md-hecto text-center">
      <button class="btn btn-bordered on-white" (click)="cookieAgree()">{{ 'agree'|translate|newLineBreak }}</button>
    </div>
  </div>
</div>
