import {NgModule, Injectable, APP_INITIALIZER} from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { I18nModule } from './i18n/i18n.module';
import {AppRoutingModule} from './app-routing.module';
import {DefaultComponent} from '@pages/default/default.component';
import {LangGuard} from '@guards/LangGuard';
import {RouteMapHelper} from '@helpers/RouteMapHelper';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RequestsInterceptor} from './_interceptors/RequestsInterceptor';
import {MetaService} from '@services/meta.service';
import {NavigationComponent} from '@shared/navigation/navigation.component';
import {HamburgerComponent} from '@shared/navigation/hamburger/hamburger.component';
import {SharedButtonsModule} from './_shared-modules/shared-buttons.module';
import {SharedPipesModule} from './_shared-modules/shared-pipes.module';
import {SharedErrorsModule} from './_shared-modules/shared-errors.module';
import { CurrentRouteHelper } from '@helpers/CurrentRouteHelper';
import { CookieModule } from 'ngx-cookie';
import {SharedLoaderModule} from './_shared-modules/shared-loader.module';
import { SharedStaticPopupsModule } from './_shared-modules/shared-static-popups.module';
import {CommonModule} from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserAuthHelper } from '@helpers/UserAuthHelper';
import {ItemLoaderComponent} from '@pages/default/_shared/item-loader/item-loader.component';
import {DefaultLangGuard} from '@guards/DefaultLangGuard';
import {BearerAuthInterceptor} from './_interceptors/auth/BearerAuthInterceptor';
import { I18nRoutePipe } from './_pipes/i18n-route.pipe';
import { SharedPageLoaderModule } from '@pages/default/shared-page-loader.module';
import { SharedPopupsUniversalModule } from './_subprojects/reservation/_shared-modules/shared-popups-universal.module';
import { RedirectorComponent } from '@pages/redirector/redirector.component';
import { ReservationLinkService } from './_subprojects/reservation-form/_services/reservation-link.service';
import {ApiUrlPipe} from './_pipes/api-url.pipe';
import { ScrollToTopComponent } from '@shared/scroll-to-top/scroll-to-top.component';
import {NavNotificationsComponent} from '@shared/navigation/nav-notifications/nav-notifications.component';
import {NotificationPopupComponent} from './_subprojects/reservation/_pages/popups/notification-popup/notification-popup.component';
import {NavUserComponent} from '@shared/navigation/nav-user/nav-user.component';
import {LoyaltyProgramPopupComponent} from './_subprojects/reservation/_pages/popups/loyalty-program-popup/loyalty-program-popup.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { BookingBarModule } from '@shared/booking-bar/booking-bar.module';



@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'pan-y'
    });

    return mc;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    DefaultComponent,
    NavigationComponent,
    HamburgerComponent,
    NavNotificationsComponent,
    NavUserComponent,
    ItemLoaderComponent,
    RedirectorComponent,
    ScrollToTopComponent,
    NotificationPopupComponent,
    LoyaltyProgramPopupComponent
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    SharedStaticPopupsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    I18nModule,
    AppRoutingModule,
    SharedButtonsModule,
    SharedPipesModule,
    SharedErrorsModule,
    SharedLoaderModule,
    CookieModule.forRoot(),
    NgbModule,
    HammerModule,
    SharedPopupsUniversalModule,
    SharedPageLoaderModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
    ReactiveFormsModule,
    BookingBarModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RequestsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BearerAuthInterceptor, multi: true },
    LangGuard,
    DefaultLangGuard,
    RouteMapHelper,
    MetaService,
    CurrentRouteHelper,
    UserAuthHelper,
    I18nRoutePipe,
    ReservationLinkService,
    ApiUrlPipe,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
