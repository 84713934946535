<app-step-header
  [header]="extras?.meta?.header || 'reservation_step_3_header'"
  [description]="(extras?.meta?.description | stripParagraph) || 'reservation_step_3_description'"
  [showDate]="true"
></app-step-header>

<div class="reservation-step">
  <div class="rooms">
    <ng-container *ngFor="let room of rooms;index as i">
      <app-collapse
        [index]="i"
        [header]="('room'|translate) + ' ' + (i + 1) + (room?.name ? ' <span class=\'weight-300\'>&nbsp;(' + room?.name + ')</span>' : '')"
        [offerName]="room?.offer?.name"
        [isActive]="activeRoomIndex === i"
        [showFullBottomLine]="true"
        (onTabClick)="setActiveRoomIndex($event)"
      >
        <app-tabs
          [roomIndex]="i"
          [activeIndex]="roomsTabsIndexes[i]||null"
          [tabs]="extras?.extras || []"
          [withDinner]="rooms[i]?.withDinner"
          (activeTabIndex)="setActiveRoomTabIndex(i, $event)"
        ></app-tabs>

      </app-collapse>
    </ng-container>
  </div>

  <p class="validation-msg-above" *ngIf="nextStepMsg">{{ nextStepMsg|translate }}</p>

  <app-booking-nav
    [backLink]="'/:lang/reservation/step-2'"
    [nextLink]="'/:lang/reservation/step-4'"
    [nextText]="'next_step'"
  ></app-booking-nav>

  <app-footer></app-footer>
</div>