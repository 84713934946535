import { Component, OnInit } from '@angular/core';
import CollageImageEnum from '@enums/CollageImageEnum';
import IImageCollage from '@interfaces/IImageCollage';
import {IImage} from '@interfaces/IImage';
import ICarouselImages from '@interfaces/ICarouselImages';
import CarouselImageEnum from '@enums/CarouselImageEnum';
import ICardIcon from '@interfaces/cards/ICardIcon';
import AssetTypeEnum from '@enums/AssetTypeEnum';

@Component({
  selector: 'app-saunas-and-pool',
  templateUrl: './saunas-and-pool.component.html',
  styleUrls: ['./saunas-and-pool.component.scss']
})
export class SaunasAndPoolComponent implements OnInit {

  collageEnum: typeof CollageImageEnum;
  imageCollage: IImageCollage;
  imageCollage2: IImageCollage;
  galleryImages: IImage[] = [];
  carouselImages: ICarouselImages[] = [];
  icons: ICardIcon[] = [];

  constructor() { }

  ngOnInit() {
    this.collageEnum = CollageImageEnum;

    this.setImageCollages();
    this.setGalleryImages();
    this.setCarouselImages();
    this.setIcons();
  }

  setImageCollages() {
    const path = '../../../assets/images/wellness_page/';

    this.imageCollage = {
      smallImage: {
        image: path + 'sauny/sauny_02_tiny.jpg',
        image2x: path + 'sauny/sauny_02@2x_tiny.jpg'
      },
      bigImage: {
        image: path + 'sauny/sauny_01_tiny.jpg',
        image2x: path + 'sauny/sauny_01@2x_tiny.jpg'
      }
    };

    this.imageCollage2 = {
      smallImage: {
        image: path + 'pool/pool_02_tiny.jpg',
        image2x: path + 'pool/pool_02@2x_tiny.jpg'
      },
      bigImage: {
        image: path + 'pool/pool_01_tiny.jpg',
        image2x: path + 'pool/pool_01@2x_tiny.jpg'
      }
    };
  }

  setGalleryImages() {
    const path = '../../../assets/images/gallery/wellness_spa';

    for (let i = 1; i < 7; i++) {
      for (let j = 1; j < 6; j++) {
        this.galleryImages.push({
          image: `${path}/${i}_slide/${j}.jpg`,
          image2x: `${path}/${i}_slide/${j}.jpg`,
        });
      }
    }
  }

  setCarouselImages() {
    const path = '../../../assets/images/carousel/wellness/';

    this.carouselImages.push({
      image: {
        image: path + '01.jpg',
        image2x: path + '01@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '02.jpg',
        image2x: path + '02@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '03.jpg',
        image2x: path + '03@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    });
  }

  private setIcons() {
    const numberOfIcons = 11;
    const icons: ICardIcon[] = [];

    for ( let i = 1; i <= numberOfIcons; i++ ) {
      const iconNo = i.toString().padStart(2, '0');

      icons.push({
        asset: {
          name: `saunas_and_pool_rule_${iconNo}_title`,
          path: `/assets/images/wellness_page/pool/rules/rule_${iconNo}.svg`,
          type: AssetTypeEnum.Image,
          alt: `saunas_and_pool_rule_${iconNo}_title`,
          isLocal: true
        },
        content: `saunas_and_pool_rule_${iconNo}_description`,
        title: `saunas_and_pool_rule_${iconNo}_title`,
        translatable: true,
        smallTitle: true
      });
    }

    this.icons = icons;
  }
}
