<div class="row">
  <div class="col-md-6 offset-sm-1">
    <h2 class="pb-mega">{{ header|translate }}</h2>
  </div>
  <div class="col-12">
    <div class="bg-gradient pt-hecto pb-hecto">
      <div class="row position-relative">
        <div class="col-10 col-md-5 offset-1 order-2 order-md-1">
          <p [innerHTML]="description|translate|removeWidows" class="small pb-kilo"></p>
          <a [routerLink]="'/:lang/gift-cards-group'|i18nRoute" class="btn btn-bordered">{{ 'choose_card'|translate }}</a>
        </div>
        <div class="col-10 col-md-5 offset-1 offset-md-0 img-container order-1 order-md-2 pb-kilo pb-md-zero">
          <img
            [defaultImage]="'/assets/images/placeholders/placeholder-t546x320.png'"
            [useSrcset]="hasSrcSetSupport"
            [lazyLoad]="hasSrcSetSupport ? '/assets/images/gift_card/gift-card.png 1x, /assets/images/gift_card/gift-card@2x.png 2x' : '/assets/images/gift_card/gift-card.png'"
            [alt]="header|translate"
          />
        </div>
      </div>
    </div>
  </div>
</div>
