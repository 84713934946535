import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import INavigation from '@interfaces/INavigation';
import {CarouselComponent, OwlOptions} from 'ngx-owl-carousel-o';
import {GlobalStateHelper} from '@helpers/GlobalStateHelper';

@Component({
  selector: 'app-loyalty-program-nav',
  templateUrl: './loyalty-program-nav.component.html',
  styleUrls: ['./loyalty-program-nav.component.scss']
})
export class LoyaltyProgramNavComponent implements OnInit, AfterViewInit {
  public activeLink = 1;
  public navigation: INavigation[] = [];

  @ViewChild('owlNav', { static: false }) owlNav: CarouselComponent;

  public options: OwlOptions = {
    touchDrag: true,
    dots: false,
    nav: false,
    margin: 20,
    navText: [
      '<img src="../../../assets/images/forward_arrow.svg" alt="backward">',
      '<img src="../../../assets/images/forward_arrow.svg" alt="forward">'
    ],
    responsive: {
      0: {
        items: 2,
        margin: 10,
        nav: true
      },
      576: {
        items: 3,
        nav: true
      },
      740: {
        items: 4,
        nav: true
      },
      992: {
        items: 5,
        mouseDrag: false,
      }
    }
  };

  constructor(
    private globalStateHelper: GlobalStateHelper
  ) { }

  ngOnInit(): void {
    this.navigation = [
      {
        name: 'discover_relax_and_beauty',
        route: '/:lang/my-reservation/shuumme-panel-group/treatments-massages-group',
        image: '/assets/images/reservations/loyalty-program/nav/discover-relax-and-beauty.png'
      },
      {
        name: 'hungry',
        route: '/:lang/my-reservation/shuumme-panel-group/restaurant-bar-group',
        image: '/assets/images/reservations/loyalty-program/nav/hungry.png'
      },
      {
        name: 'restore_vital_forces',
        route: '/:lang/my-reservation/shuumme-panel-group/fitness-group',
        image: '/assets/images/reservations/loyalty-program/nav/restore-vital-forces.png'
      },
      {
        name: 'facilities',
        route: '/:lang/my-reservation/shuumme-panel-group/facilities-group',
        image: '/assets/images/reservations/loyalty-program/nav/facilities.png'
      },
      {
        name: 'useful_information',
        route: '/:lang/my-reservation/shuumme-panel-group/useful-information-group',
        image: '/assets/images/reservations/loyalty-program/nav/useful-information.png'
      }
    ];

    this.activeLink = this.globalStateHelper.especiallyForYouNavigation;
  }

  ngAfterViewInit() {
    this.slideTo(`${this.activeLink - 1}`);
  }

  slideTo(id: string) {
    this.owlNav.to(`item-${id}`);
  }

}
