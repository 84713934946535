<div class="input-container">
  <div
    class="form-group"
    [formGroup]="group"
    [ngClass]="
      {
        'invalid': (group?.controls[control]?.errors && group?.controls[control]?.touched) || apiValidationMsg,
        'on-dark': onDark,
        'disabled': disabled
      }"
  >
    <label *ngIf="fieldName" [for]="inputId">
      {{ fieldName|translate }}
      <span *ngIf="fieldNameSecondary" class="field-name-secondary">{{ fieldNameSecondary|translate }}</span>
    </label>
    <div class="form-input">
      <input
        type="text"
        [formControlName]="control"
        (keyup)="changeValidationMsg( group?.controls[control]?.errors )"
        (blur)="onBlur( group?.controls[control]?.errors )"
        [id]="inputId"
        [placeholder]="placeholder|translate"
      />
    </div>
    <p class="validation-msg">
      <span *ngFor="let msg of validationMsg">
        {{ msg|translate }}
      </span>
      <span class="text-right d-block w-100">{{apiValidationMsg}}</span>
    </p>
  </div>
</div>
