<div class="text-centered-image">
  <div class="img-wrapper" [ngStyle]="{'height' : imgMaxHeight }">
    <img
      [defaultImage]="'/assets/images/placeholders/placeholder119x120.png'"
      [lazyLoad]="hasSrcSetSupport ? (image + ' 1x, ' + image2x + ' 2x') : image"
      [useSrcset]="hasSrcSetSupport"
      [alt]="imageAlt"
      [ngStyle]="{'max-height' : imgMaxHeight }"/>
  </div>
  <h4 class="pt-kilo pt-sm-mega pt-md-giga" [innerHTML]='header'></h4>
  <div class="pt-centi">
    <p class="small text-color-gray-var">{{description}}</p>
  </div>
</div>
