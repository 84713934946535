<app-step-header
  [header]="offer?.header || 'reservation_step_1_header'"
  [description]="(offer?.description | stripParagraph) || 'reservation_step_1_description'"
></app-step-header>

<div class="reservation-step">
  <div class="pb-giga pb-md-tera">
    <div class="pb-deca pb-md-kilo">
      <h5 class="semibold">{{ 'date_of_stay'|translate }}</h5>
    </div>

    <div class="row">
      <div class="col-12 col-xl-10">
        <div id="date-of-stay" class="date-of-stay">
          <button
            class="btn btn-dark w-100"
            (click)="toggleCalendar($event)"
          >
            <ng-container *ngIf="fromDate && toDate">
              {{ i18n.getFullDateAsString(fromDate) }} <span class="d-none d-sm-inline">&nbsp;&nbsp;&nbsp;&nbsp;</span>–<span class="d-none d-sm-inline">&nbsp;&nbsp;&nbsp;&nbsp;</span> {{ i18n.getFullDateAsString(toDate) }}
              <small class="d-block">
                <span>{{ 'calendar_stay_for'|translate }} </span>
                <app-stay-for-nights [nights]="nightsOfStay"></app-stay-for-nights>
              </small>
            </ng-container>
            <ng-container *ngIf="fromDate == null || toDate == null">
              {{ 'choose_arrival_date'|translate }}
            </ng-container>

          </button>
        </div>
      </div>
    </div>
    <div
      class="text-center"
      *ngIf="showCalendar"
      [@fadeAnimation]
    >
      <app-datepicker
        [maxDate]="maxDate"
        [prices]="prices"
        [offer]="offer"
        (dateSelected)="dateSelected($event)"
        (clickOutside)="hideCalendar($event)"
      ></app-datepicker>
    </div>
  </div>

  <div class="pb-deca pb-md-kilo">
    <div class="pb-deca pb-md-kilo">
      <h5 class="semibold">{{ 'rooms'|translate }}</h5>
    </div>

    <app-room-selector [offer]="offer"></app-room-selector>
  </div>

  <app-discount-code></app-discount-code>

  <p class="validation-msg-above" *ngIf="nextStepMsg">{{ nextStepMsg|translate }}</p>

  <app-booking-nav
    [nextLink]="'/:lang/reservation/step-2'"
    [blocked]="buttonBlocked"
    [nextText]="'next_step'"
  ></app-booking-nav>

  <app-footer></app-footer>
</div>