<div class="gallery-container">
  <div
    class="gallery full-out-mobile-padding"
    [ngClass]="{'blur': activeImageIndex !== null }"
  >
    <div
      class="overlay"
      (click)="closeImage($event)"
      *ngIf="showOverlay"
    ></div>
    <div
      class="gallery-animation"
      [@slideAnimation]="currentPage"
      #gallery
    >
      <ng-container *ngFor="let CImages of chunkedImages;index as i">
        <div
          class="images-page"
          *ngIf="i === currentPage"
          (swipeLeft)="goToNextPage($event)"
          (swipeRight)="goToPrevPage($event)"
        >
          <div class="row">
            <div class="col-5 col-md-3 right-gutter">
              <div class="images first">
                <div
                  class="image"
                  *ngIf="CImages[0].image"
                >
                  <div class="containable-img">
                    <img
                      [defaultImage]="'/assets/images/placeholders/placeholder582x740.png'"
                      [lazyLoad]="CImages[0].image"
                      [useSrcset]="hasSrcSetSupport"
                      alt="gallery image 1"
                    />
                  </div>
                  <div
                    class="containable-img viewable-img top-left pointer containable-img-1"
                    [ngClass]="{'active': activeImageIndex === 0}"
                  >
                    <span
                      class="close-bg"
                      (click)="closeImage($event)"
                    >
                      <span class="close-icon"></span>
                    </span>

                    <img
                      [defaultImage]="'/assets/images/placeholders/placeholder582x740.png'"
                      [lazyLoad]="CImages[0].image"
                      [useSrcset]="hasSrcSetSupport"
                      (click)="onImageClick($event, 0)"
                      class="viewable"
                      alt="gallery image 1"
                    />
                  </div>
                </div>
                <div
                  class="image image-small"
                  *ngIf="CImages[1].image"
                >
                  <div class="containable-img">
                    <img
                      [defaultImage]="'/assets/images/placeholders/placeholder754x740.png'"
                      [lazyLoad]="CImages[1].image"
                      [useSrcset]="hasSrcSetSupport"
                      alt="gallery image 2"
                    />
                  </div>
                  <div
                    class="containable-img viewable-img bottom-left pointer containable-img-2"
                    [ngClass]="{'active': activeImageIndex === 1}"
                  >
                    <span
                      class="close-bg"
                      (click)="closeImage($event)"
                    >
                      <span class="close-icon"></span>
                    </span>

                    <img
                      [defaultImage]="'/assets/images/placeholders/placeholder754x740.png'"
                      [lazyLoad]="CImages[1].image"
                      [useSrcset]="hasSrcSetSupport"
                      (click)="onImageClick($event, 1)"
                      class="viewable"
                      alt="gallery image 1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-7 col-md-4 both-gutter">
              <div class="images first">
                <div
                  class="image"
                  *ngIf="CImages[2].image"
                >
                  <div class="containable-img">
                    <img
                      [defaultImage]="'/assets/images/placeholders/placeholder433x758.png'"
                      [lazyLoad]="CImages[2].image"
                      [useSrcset]="hasSrcSetSupport"
                      alt="gallery image 3"
                    />
                  </div>
                  <div
                    class="containable-img viewable-img top-left containable-img-3"
                    [ngClass]="{'active': activeImageIndex === 2}"
                  >
                    <span
                      class="close-bg"
                      (click)="closeImage($event)"
                    >
                      <span class="close-icon"></span>
                    </span>

                    <img
                      [defaultImage]="'/assets/images/placeholders/placeholder433x758.png'"
                      [lazyLoad]="CImages[2].image"
                      [useSrcset]="hasSrcSetSupport"
                      class="viewable"
                      alt="gallery image 3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5 left-gutter">
              <div class="images">
                <div
                  class="image"
                  *ngIf="CImages[3].image"
                >
                  <div class="containable-img">
                    <img
                      [defaultImage]="'/assets/images/placeholders/placeholder1344x740.png'"
                      [lazyLoad]="CImages[3].image"
                      [useSrcset]="hasSrcSetSupport"
                      alt="gallery image 4"
                    />
                  </div>
                  <div
                    class="containable-img viewable-img top-right pointer containable-img-4"
                    [ngClass]="{'active': activeImageIndex === 3}"
                  >
                    <span
                      class="close-bg"
                      (click)="closeImage($event)"
                    >
                      <span class="close-icon"></span>
                    </span>

                    <img
                      [defaultImage]="'/assets/images/placeholders/placeholder1344x740.png'"
                      [lazyLoad]="CImages[3].image"
                      [useSrcset]="hasSrcSetSupport"
                      (click)="onImageClick($event, 3)"
                      class="viewable"
                      alt="gallery image 4"
                    />
                  </div>
                </div>
                <div
                  class="image"
                  *ngIf="CImages[4].image"
                >
                  <div class="containable-img">
                    <img
                      [defaultImage]="'/assets/images/placeholders/placeholder971x740.png'"
                      [lazyLoad]="CImages[4].image"
                      [useSrcset]="hasSrcSetSupport"
                      alt="gallery image 5"
                    />
                  </div>
                  <div
                    class="containable-img viewable-img bottom-right pointer containable-img-5"
                    [ngClass]="{'active': activeImageIndex === 4}"
                  >
                    <span
                      class="close-bg"
                      (click)="closeImage($event)"
                    >
                      <span class="close-icon"></span>
                    </span>

                    <img
                      [defaultImage]="'/assets/images/placeholders/placeholder971x740.png'"
                      [lazyLoad]="CImages[4].image"
                      [useSrcset]="hasSrcSetSupport"
                      (click)="onImageClick($event, 4)"
                      class="viewable"
                      alt="gallery image 5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-lg-11">
      <div class="pt-hecto pb-hecto pb-md-zero pt-md-zero">
        <div class="position-relative">
          <div class="gallery-arrows">
            <a
              href="#"
              class="gallery-arrow arrow-left"
              [ngClass]="{ 'disabled': currentPage <= 0 }"
              (click)="goToPrevPage( $event )"
            >
              <img src="../../../assets/images/forward_arrow.svg" alt="backward" />
            </a>
            <a
              href="#"
              class="gallery-arrow"
              [ngClass]="{ 'disabled': isLastPage }"
              (click)="goToNextPage( $event )"
            >
              <img src="../../../assets/images/forward_arrow.svg" alt="forward" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="header && description">
    <div class="row">
      <div class="col-12 col-md-10 offset-lg-1 content-header">
        <div
          class="header"
        >
          <h6
            class="the-biggest"
            [ngClass]="{ 'grayHeader': class }"
            [innerHTML]="header|translate|removeWidows"
          ></h6>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-8 col-lg-7 col-xl-7 col-xxl-6 offset-lg-1 content-text">
        <div class="pt-hecto pt-md-kilo pb-centi pb-md-hecto">
          <p
            class="small text-gray"
            [innerHTML]="description|translate|removeWidows"
          ></p>
        </div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="chunkedImages.length > 0" class="multiple-description-wrapper">
    <ng-container *ngFor="let chunkedImage of chunkedImages; let i = index">
      <div
        class="multiple-description-element"
        *ngIf="chunkedImage[0].header"
        [ngClass]="{
          'visible': i === currentPage,
          'fadeIn': chunkedImages[currentPage][0].header !== chunkedImages[previousPage][0].header && i === currentPage,
          'fadeOut': chunkedImages[currentPage][0].header !== chunkedImages[previousPage][0].header && i !== currentPage
        }">
        <div class="row">
          <div class="col-12 col-md-10 offset-lg-1 content-header">
            <div
              class="header"
            >
              <h6
                class="the-biggest"
                [innerHTML]="chunkedImage[0].header|translate|removeWidows"
              ></h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-8 col-lg-7 col-xl-6 col-xxl-6 offset-lg-1 content-text">
            <div class="pt-hecto pt-md-mega pb-centi pb-md-hecto">
              <p
                class="small"
                [class]="descriptionClass"
                [innerHTML]="chunkedImage[0].description|translate|removeWidows"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

</div>


