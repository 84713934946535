import {Injectable} from '@angular/core';
import IReservationStep from '../../_subprojects/reservation-form/_interfaces/IReservationStep';
import ReservationStepEnum from '../../_subprojects/reservation-form/_enums/ReservationStepEnum';
import {BehaviorSubject, Subject, Observable} from 'rxjs';
import * as findIndex from 'lodash/findIndex';
import IReservationRoom from '../../_subprojects/reservation-form/_interfaces/IReservationRoom';
import IReservationDate from '../../_subprojects/reservation-form/_interfaces/IReservationDate';
import {FormsDataValidationService} from './forms-data-validation.service';
import {UserService} from '@services/auth/user.service';
import {first} from 'rxjs/operators';
import GlobalFunctions from '@helpers/GlobalFunctions';
import ReservationPriceTypeEnum from '../../_subprojects/reservation-form/_enums/ReservationPriceTypeEnum';
import { IReservationOffer } from 'src/app/_subprojects/reservation-form/_interfaces/IReservationOffer';
import * as moment from 'moment';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ReservationStepService {
  private steps$: BehaviorSubject<IReservationStep[]> = new BehaviorSubject<IReservationStep[]>([]);
  public steps: IReservationStep[] = [];
  private activeStep: IReservationStep = null;
  public stepsSubj$: Subject<IReservationStep[]> = new Subject<IReservationStep[]>();

  constructor(private formsDataValidationService: FormsDataValidationService, private userService: UserService) {
    this.steps = [
      {
        id: ReservationStepEnum.I,
        name: 'reservation_step_1_name',
        route: '/:lang/reservation',
        isActive: false,
        isValid: false
      },
      {
        id: ReservationStepEnum.II,
        name: 'reservation_step_2_header',
        route: '/:lang/reservation/step-2',
        isActive: false,
        isValid: false
      },
      {
        id: ReservationStepEnum.III,
        name: 'reservation_step_3_header',
        route: '/:lang/reservation/step-3',
        isActive: false,
        isValid: true
      },
      {
        id: ReservationStepEnum.IV,
        name: 'fill_data',
        route: '/:lang/reservation/step-4',
        isActive: false,
        isValid: false
      },
      {
        id: ReservationStepEnum.V,
        name: 'make_payment2',
        route: '/:lang/reservation/step-5',
        isActive: false,
        isValid: false
      },
    ];

    this.setSteps(this.steps);
  }

  private setSteps(steps: IReservationStep[]): void {
    this.steps$.next(steps);
    this.stepsSubj$.next(steps);
  }

  public getSteps(): BehaviorSubject<IReservationStep[]> {
    return this.steps$;
  }

  public getStepsSubj(): Subject<IReservationStep[]> {
    return this.stepsSubj$;
  }

  setActiveStep(step: ReservationStepEnum) {
    const nextIndex = findIndex(this.steps, {id: step});

    if (nextIndex !== -1) {
      if (this.activeStep !== null) {
        this.steps[this.activeStep.id].isActive = false;
      }

      this.activeStep = this.steps[nextIndex];
      this.steps[nextIndex].isActive = true;

      this.setSteps(this.steps);
    }
  }

  setStepValidity(stepId: ReservationStepEnum, valid: boolean, errorMessage?: string) {
    const [ step ] = this.steps.filter(step => step.id === stepId);
    step.isValid = valid;
    step.errorMessage = errorMessage;
    this.steps$.next(this.steps);
  }

  /**
   * Sprawdzanie, czy wybrano datę,
   * oraz czy wszystkim dzieciom został wybrany wiek
   * @param rooms - pokoje, dla których będzie sprawdzany wiek dzieci
   * @param date - data zameldowania
   */
  validateFirstStep(rooms: IReservationRoom[], date: IReservationDate, nightsOfStay?: number, offer?: IReservationOffer) {
    if (this.formsDataValidationService.areChildrenFormsEmpty()) {
      this.formsDataValidationService.setupChildrenForms(rooms);
    }
    let roomsValid = true;

    this.formsDataValidationService.getChildrenForms().forEach(form => {
      if (!form.valid) {
        roomsValid = false;
      }
    });

    let offerDaysValid = true;
    let errorMessage = null;

    if (offer?.minDays && nightsOfStay < offer?.minDays || offer?.maxDays && nightsOfStay > offer?.maxDays) {
      offerDaysValid = false;
      errorMessage = 'reservation_error_step_1';
    }

    let requiredDaydValid = true;
    let requiredStartingDayValid = true;
    const fromDateMoment = moment([date?.from?.year, date?.from?.month - 1, date?.from?.day]);
    const toDateMoment = moment([date?.to?.year, date?.to?.month - 1, date?.to?.day]);

    if (offer && offer?.requiredStartingDay?.length && !(fromDateMoment.isSameOrAfter(offer?.minDate) && fromDateMoment.isSameOrBefore(offer?.maxDate) && offer?.requiredStartingDay.includes(fromDateMoment.day()))) {
      requiredStartingDayValid = false;
      errorMessage = 'reservation_error_required_starting_day_step_1';
    }

    const selectedDays = [];

    for (let m = fromDateMoment; m.isSameOrBefore(toDateMoment); m.add(1, 'days')) {
      const day = moment(m, "MM-DD-YYYY").day();
      selectedDays.push(day);
    }

    if (offer && !offer?.requiredDays.every(r => selectedDays.includes(r))) {
      requiredDaydValid = false;
      errorMessage = 'reservation_error_required_day_step_1';
    }

    this.setStepValidity(ReservationStepEnum.I, date && date.from && date.to && roomsValid && offerDaysValid && requiredDaydValid && requiredStartingDayValid, errorMessage);
  }

  /**
   * Sprawdzanie, czy dla każdego pokoju wybrano standard
   * @param rooms - pokoje, które są sprawdzane
   */
  validateSecondStep(rooms: IReservationRoom[]) {
    let valid = true;


    let allRoomsSamePriceTypeStandard;
    let allRoomsSamePriceTypeStandardFirstMinute;
    let allRoomsSamePriceTypeStandardLastMinute;
    let allRoomsSamePriceTypeOffer;
    let allRoomsSamePriceTypeOffeFirstMinute;
    let allRoomsSamePriceTypeOfferLastMinute;

    const checkIfAllRoomsPriceTypeIsStandard = () => {
      allRoomsSamePriceTypeStandard = rooms.every(obj => obj.priceType === ReservationPriceTypeEnum.standard);
    };
    const checkIfAllRoomsPriceTypeIsStandardFirstMinute = () => {
      allRoomsSamePriceTypeStandardFirstMinute = rooms.every(obj => obj.priceType === ReservationPriceTypeEnum.standardFirstMinute);
    };
    const checkIfAllRoomsPriceTypeIsStandardLastMinute = () => {
      allRoomsSamePriceTypeStandardLastMinute = rooms.every(obj => obj.priceType === ReservationPriceTypeEnum.standardLastMinute);
    };
    const checkIfAllRoomsPriceTypeIsOffer = () => {
      const allRoomsSamePriceType = rooms.every(obj => obj.priceType === ReservationPriceTypeEnum.offer);
      const allRoomsSamePriceTypeOfferId = rooms.map(room => room?.offer?.id).every((id, i, arr) => id === arr[0]);
      // const allRoomsSamePriceTypeOfferId = !!rooms.map(room => room?.offer?.id).reduce((a, b) => (a === b) ? a : NaN);

      allRoomsSamePriceTypeOffer = allRoomsSamePriceType && allRoomsSamePriceTypeOfferId;
    };
    const checkIfAllRoomsPriceTypeIsOfferFirstMinute = () => {
      const allRoomsSamePriceType = rooms.every(obj => obj.priceType === ReservationPriceTypeEnum.offerFirstMinute);
      const allRoomsSamePriceTypeOfferId = rooms.map(room => room?.offer?.id).every((id, i, arr) => id === arr[0]);
      // const allRoomsSamePriceTypeOfferId = !!rooms.map(room => room?.offer?.id).reduce((a, b) => (a === b) ? a : NaN);

      allRoomsSamePriceTypeOffeFirstMinute = allRoomsSamePriceType && allRoomsSamePriceTypeOfferId;
    }
    const checkIfAllRoomsPriceTypeIsOfferLastMinute = () => {
      const allRoomsSamePriceType = rooms.every(obj => obj.priceType === ReservationPriceTypeEnum.offerLastMinute);
      const allRoomsSamePriceTypeOfferId = rooms.map(room => room?.offer?.id).every((id, i, arr) => id === arr[0]);
      // const allRoomsSamePriceTypeOfferId = !!rooms.map(room => room?.offer?.id).reduce((a, b) => (a === b) ? a : NaN);

      allRoomsSamePriceTypeOfferLastMinute = allRoomsSamePriceType && allRoomsSamePriceTypeOfferId;
    }

    checkIfAllRoomsPriceTypeIsStandard();
    checkIfAllRoomsPriceTypeIsStandardFirstMinute();
    checkIfAllRoomsPriceTypeIsStandardLastMinute();
    checkIfAllRoomsPriceTypeIsOffer();
    checkIfAllRoomsPriceTypeIsOfferFirstMinute();
    checkIfAllRoomsPriceTypeIsOfferLastMinute();

    rooms.forEach(room => {
      if (!room.standardId
          || (!allRoomsSamePriceTypeStandard
          && !allRoomsSamePriceTypeStandardFirstMinute
          && !allRoomsSamePriceTypeStandardLastMinute
          && !allRoomsSamePriceTypeOffer
          && !allRoomsSamePriceTypeOffeFirstMinute
          && !allRoomsSamePriceTypeOfferLastMinute
        ) ) {
        valid = false;
      }
    });

    this.setStepValidity(ReservationStepEnum.II, valid);
  }

  /**
   * Sprawdzanie, czy cały formularz z danymi do rezerwacji
   * został poprawnie wypełniony
   * @param rooms - pokoje, które są sprawdzane
   */
  validateFourthStep(values: any): Observable<any> {
    const data = GlobalFunctions.getUserDataToApi(values, this.userService.userValue?.complete);
    return this.userService.reservationUserDataCheck(data);
  }
}
