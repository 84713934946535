<app-banner
  [header]="loyaltyProgram ? null : 'reservation_banner_title'"
  [description]="loyaltyProgram ? null: 'reservation_banner_desc'"
  [background]="loyaltyProgram ? '/assets/images/banner/home.jpg' : '/assets/images/reservations/banner/home.jpg'"
  [background2x]="loyaltyProgram ? '/assets/images/banner/home@2x.jpg' : '/assets/images/reservations/banner/home@2x.jpg'"
  [center]="loyaltyProgram"
  [fullWidth]="loyaltyProgram"
>
  <div *ngIf="loggedIn && loyaltyProgram && userBanner" class="user-banner" userBanner>
    <ng-container [ngSwitch]="userBanner?.banner_type">
      <ng-container *ngSwitchCase="bannerType.BOOKING_ARRIVAL_SOON">
        <h1 class="mb-deci" [innerHTML]="'see_you_in_shuum' | translate | removeWidows"></h1>
        <p>{{ 'time_remaining'|translate }}</p>
        <div class="time-remaining d-flex justify-content-between mx-auto mt-kilo mt-md-deca">
          <div class="time-remaining-number d-flex flex-column">{{ userBanner?.time_remaining?.days }}<span>{{ 'time_remaining_days' | translate }}</span></div>
          <div class="time-remaining-number d-flex flex-column">{{ userBanner?.time_remaining?.hours }}<span>{{ 'time_remaining_hours' | translate }}</span></div>
          <div class="time-remaining-number d-flex flex-column">{{ userBanner?.time_remaining?.minutes }}<span>{{ 'time_remaining_minutes' | translate }}</span></div>
          <div class="time-remaining-number d-flex flex-column">{{ userBanner?.time_remaining?.seconds  }}<span>{{ 'time_remaining_seconds' | translate }}</span></div>
        </div>
        <a
          class="btn btn-white btn-bordered btn-map mt-kilo mt-md-deca"
          target="_blank"
          href="https://www.google.com/maps/place/Shuum+Boutique+Wellness+Hotel/@54.1859257,15.5895061,15z/data=!4m2!3m1!1s0x0:0x7cef16a3855cc512?sa=X&ved=2ahUKEwjtzdey1Mz-AhVllYsKHf-DAqUQ_BJ6BAhgEAc"
        >
          {{ 'choose_route'|translate }}
        </a>
      </ng-container>
      <ng-container *ngSwitchCase="bannerType.BOOKING_ARRIVAL_NOW">
        <h1 class="mb-deci">{{ user.name }}, <span [innerHTML]="'welcome_to_shuum'|translate|removeWidows"></span><br/> {{ 'check_out_our_offer' | translate }}</h1>
        <p>{{ 'your_room_number_is'|translate }}:</p>
        <div class="room-number mt-kilo mt-md-deca"><span>{{ userBanner?.room_number }}</span></div>
      </ng-container>
      <ng-container *ngSwitchCase="bannerType.BOOKING_ARRIVAL_AFTER">
        <h1 class="mb-deci">{{ 'thank_for_coming'|translate }}</h1>
        <p class="mb-deca">{{ 'share_your_opinion'|translate }}</p>
        <a
          class="btn btn-white btn-bordered"
          target="_blank"
          [href]="'https://www.google.com/travel/hotels/shuum%20hotel/entity/CgoIkorzqrjUxfd8EAE/reviews?q=shuum%20hotel&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4597339%2C4754388%2C4757164%2C4814050%2C4850738%2C4861688%2C4864715%2C4874190%2C4886480%2C4893075%2C4920132%2C4924070%2C4935108%2C4936396%2C4965990%2C4968087%2C4972345%2C4977499%2C4977987%2C4987686%2C4991446%2C4992509%2C47067249&hl=pl-PL&gl=pl&cs=1&ssta=1&rp=EJKK86q41MX3fBCSivOquNTF93w4AkAASAHAAQKaAgIIAA&ictx=1&sa=X&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESABpJCisSJzIlMHg0NzAwMTU4YzA1MmZlNjFmOjB4N2NlZjE2YTM4NTVjYzUxMhoAEhoSFAoHCOcPEAQYExIHCOcPEAQYFBgBMgIQACoJCgU6A1BMThoA'"
        >
          {{ 'share_opinion'|translate }}
        </a>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <h1 class="mb-deca">{{ 'user_banner_default'|translate }}</h1>
        <a
          class="btn btn-white btn-bordered"
          [routerLink]="'/:lang/reservation'|i18nRoute"
        >
          {{ 'form_book'|translate }}
        </a>
      </ng-container>
    </ng-container>
  </div>
  <app-line-btn
    *ngIf="loggedIn && !loyaltyProgram"
    class="h4"
    scrollTo="account_details"
    [noOffset]="true"
    bottomButton
  >
    <span>{{ textToDisplay|translate }}</span>
  </app-line-btn>
  <div
    *ngIf="!loggedIn"
    bottomButton
  >
    <div class="pb-tera d-flex flex-column align-items-center w-100">
      <a
        class="btn btn-bordered btn-white d-block mb-hecto btn-banner-wide not-positioned"
        [routerLink]="'/:lang/my-reservation/authorization-group/sign-up-group'|i18nRoute"
        #createAccount
      >
        {{ 'create_account'|translate }}
      </a>
      <p class="text-white tiny text-center">{{ 'sign_in_desc'|translate }}</p>
    </div>
  </div>
</app-banner>
<div id="account_details">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
