import {Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {finalize, map, takeUntil} from 'rxjs/operators';
import {UserService} from '@services/auth/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LayoutService} from '@services/layout.service';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {Router} from '@angular/router';
import {I18nRoutePipe} from '../../../../../../_pipes/i18n-route.pipe';
import RuleEnum from '@enums/RuleEnum';
import {RuleService} from '@services/auth/rule.service';
import { InputField } from 'src/app/_subprojects/reservation/_helpers/InputField';

@Component({
  selector: 'app-loyalty-program',
  templateUrl: './loyalty-program.component.html',
  styleUrls: ['./loyalty-program.component.scss']
})
export class LoyaltyProgramComponent extends SubscribeHelper implements OnInit, OnDestroy {

  @ViewChildren('input') input: QueryList<InputField>;
  
  loyaltyProgramForm: FormGroup;
  rules: number[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private layoutService: LayoutService,
    private router: Router,
    private i18nRoute: I18nRoutePipe,
    private ruleService: RuleService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loyaltyProgramForm = this.formBuilder.group({
      loyaltyProgram: ['', [Validators.requiredTrue]],
    });

    this.getRules();
  }

  get getLoyaltyProgram() {
    return this.loyaltyProgramForm.get('loyaltyProgram');
  }

  getRules() {
    this.ruleService.getRules(RuleEnum.Registration)
      .pipe(
        map(data => data?.rules),
        takeUntil(this.componentDestroyed$))
      .subscribe(rules => {
        this.rules = rules.map(rule => rule.id);
      });
  }

  onSubmit(): void {
    if (this.loyaltyProgramForm.valid) {
      this.layoutService.setItemLoader(true);

      const data = {
        ...this.userService.userValue,
        rules: this.rules,
        ...this.loyaltyProgramForm.value
      };

      this.userService.editUser( data )
      .pipe(
        takeUntil(this.componentDestroyed$),
        finalize(() => {
          this.layoutService.setItemLoader(false);
        })
      )
      .subscribe(_ => {
        this.router.navigate([
            this.i18nRoute.transform('/:lang/my-reservation/shuumme-panel-group/treatments-massages-group')
        ]);
      });
      } else {
        this.input.toArray().forEach(field => {
          field.validateField();
        });
      }    
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
