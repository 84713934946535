<div class="container pt-zetta mobile-padding text-simple-centered">
  <app-text-simple-centered>
    <p class="p-responsive" [innerHTML]="'medical_wellness_text_centered_c_02'|translate|newLineBreak|removeWidows"></p>
    <div class="actions pt-mega pb-md-kilo">
      <app-loader [size]="30" [opacity]="'0.7'" [center]="true" *ngIf="medicalWellnessMenuFile === null"></app-loader>

      <ng-container *ngIf="medicalWellnessMenuFile?.value">
        <a [href]="medicalWellnessMenuFile?.value|apiUrl" target="_blank" class="btn btn-bordered">{{ 'download_medical_wellness_menu'|translate }}</a>
      </ng-container>
      <ng-container *ngIf="medicalWellnessMenuFile !== null && !medicalWellnessMenuFile?.value">
        <span class="small">{{ 'menu_is_currently_unavailable'|translate }}</span>
      </ng-container>
    </div>
  </app-text-simple-centered>
</div>

<div class="container mobile-padding">
  <div class="row mt-5">
    <div class="col-12 col-md-10 offset-md-1">
      <div class="out-mobile-padding">
        <app-filters
          [center]="true"
          [categories]="medicalWellnessCategories"
          (values)="filterValues($event)"
          [hideSearchfield]="true"
          [showLoader$]="showCategoryLoader$"
        ></app-filters>
      </div>
    </div>
  </div>
</div>

<app-after-before-grey
  width="33%"
  height="1174px"
  top="555px"
  left="0"
  additionalClasses="to-top"
>
  <div class="container mobile-padding pt-peta pt-xl-zetta text-simple-centered" *ngIf="list.length > 0">
    <div class="row">
      <ng-container *ngFor="let item of list;index as i">
        <div
          class="col-12 pt-giga"
          [ngClass]="{
            'col-lg-12 col-xl-10': item.isSingledOut,
            'col-lg-6 col-xl-5': !item.isSingledOut,
            'offset-xl-1': ((i - (isFirstSingledOut?1:0)) % 2 === 0 && !item.isSingledOut) || item.isSingledOut,
            'pt-0': item.isSingledOut
          }"
        >
          <div class="full-out-mobile-padding">
            <app-text-offer
              [image]="item?.photo?.path|apiUrl"
              [imageAlt]="item?.photo?.alt"
              [isSingledOut]="item.isSingledOut"
              [isBestseller]="item.isBestseller"
              [isNew]="item.isNew"
              [link]="'/:lang/wellness/medical-wellness/' + item?.slug|i18nRoute"
              [itemId]="item?.id"
              [onLinkClick]="setElementInfo"
              isWellness
            >
              <div>
                <h2
                  *ngIf="item.isSingledOut"
                  [innerHTML]="item?.name|removeWidows"
                ></h2>
                <h3
                  *ngIf="!item.isSingledOut"
                  [innerHTML]="item?.name|removeWidows"
                ></h3>
                <p
                  [ngClass]="{
                    'pt-hecto small': item.isSingledOut,
                    'pt-deci tiny': !item.isSingledOut
                  }"
                  [innerHTML]="item?.description|removeWidows"
                ></p>
              </div>
              <div class="pt-hecto">
                <a
                  (click)="setElementInfo(item?.id)"
                  [routerLink]="'/:lang/wellness/medical-wellness/' + item?.slug|i18nRoute"
                  class="btn btn-bordered on-white"
                >
                  {{ 'check_details'|translate }}
                </a>
              </div>
            </app-text-offer>
          </div>
        </div>
      </ng-container>
      <div class="col-12 text-center pt-giga content-center" *ngIf="responseCurrent < responseMax && !loading">
        <button class="btn btn-collapse" (click)="loadMore()">{{ 'see_more'|translate }}</button>
      </div>
      <div class="col-12 pt-giga" *ngIf="loading">
        <app-loader [size]="30" [opacity]="'0.7'" [center]="true"></app-loader>
      </div>
    </div>
  </div>

  <div class="container mobile-padding" *ngIf="list.length === 0 && !loading">
    <div class="row">
      <div class="col pt-giga pt-xl-peta text-center">
        <p
          class="text-light-gray h3"
          *ngIf="currentLang == 'pl'"
        >
          {{'no_offers_available'|translate|removeWidows}}
        </p>

        <ng-container *ngIf="currentLang == 'de' || currentLang == 'en'">
          <p
            class="text-light-gray h3"
            *ngIf="currentLang == 'de'"
          >
            Bitte kontaktieren Sie uns, um einen Termin zu vereinbaren:
          </p>
          <p
            class="text-light-gray h3"
            *ngIf="currentLang == 'en'"
          >
            Please contact us to make an appointment:
          </p>
          <p class="pt-deca pb-centi"><a href="tel:+48943554050" class="text-decoration-none">+48 94 355 4050</a></p>
          <p><a href="mailto:wellness@shuumhotel.pl" class="text-decoration-none">wellness@shuumhotel.pl</a></p>
        </ng-container>

      </div>
    </div>
  </div>
</app-after-before-grey>

<div id="wellness" class="container pt-zetta mobile-padding text-simple-centered pb-xxl-peta">
  <app-text-simple-centered
    header="{{ 'medical_wellness_text_centered_h'|translate}}"
  >
    <p class="p-responsive" [innerHTML]="'medical_wellness_text_centered_c'|translate|newLineBreak|removeWidows"></p>
  </app-text-simple-centered>
</div>

<div class="container wide mobile-padding position-relative">
  <div class="pt-peta pt-md-zetta pt-lg-200 pb-xxl-peta">
    <div class="row align-items-center flex-column-reverse flex-md-row">
      <div class="container mobile-padding">
        <div class="col-md-6 col-xl-5 col-xxl-4 offset-lg-1 offset-xl-2 offset-xxl-2 offset-0 pt-giga pt-md-zero padding-sm-zero">
          <p class="small" [innerHTML]="'medical_wellness_c_01'|translate|newLineBreak|removeWidows"></p>
          <div class="pt-hecto pt-md-mega">
            <img src="/assets/images/wellness_page/medical/podpis.svg" />
          </div>
          <p class="pt-deca">{{ 'medical_wellness_signature'|translate }}</p>
        </div>
      </div>

      <div class="col-md-5 col-xl-4 col-xxl-5 col-8 offset-md-1 absolute-right-element padding-sm-zero">
        <app-content-images-overlapping-right
          imageFront="/assets/images/wellness_page/medical/double_image_left.jpg"
          imageFront2x="/assets/images/wellness_page/medical/double_image_left@2x.jpg"
          imageFrontAlt="Double Image Left"
          imageBack="/assets/images/wellness_page/medical/double_image_right.jpg"
          imageBack2x="/assets/images/wellness_page/medical/double_image_right@2x.jpg"
          imageBackAlt="Double Image Right"
          classes="medical-wellness"
        ></app-content-images-overlapping-right>
      </div>
    </div>
  </div>
</div>

<app-after-before-grey
  width="32%"
  height="962px"
  top="3%"
  left="0"
  additionalClasses="to-top-left"
>
  <div class="container mobile-padding">
    <div class="pt-peta pt-md-zetta pt-lg-200">
      <app-single-image-text
        image="/assets/images/wellness_page/medical/single_image.jpg"
        image2x="/assets/images/wellness_page/medical/single_image@2x.jpg"
        imageAlt="Single image 1"
      >
        <h3 class="text-light-gray" [innerHTML]="'medical_wellness_h_02'|translate|newLineBreak|removeWidows"></h3>
        <p class="pt-lg-kilo pt-hecto small"
           [innerHTML]="'medical_wellness_c_02'|translate|newLineBreak|removeWidows"></p>
      </app-single-image-text>
    </div>
  </div>
</app-after-before-grey>

<div class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-single-image-text
      image="/assets/images/wellness_page/medical/single_image2.jpg"
      image2x="/assets/images/wellness_page/medical/single_image2@2x.jpg"
      imageAlt="Single image 2"
      theme="reversed"
    >
      <h3 class="text-light-gray" [innerHTML]="'medical_wellness_h_03'|translate|newLineBreak|removeWidows"></h3>
      <p class="pt-lg-kilo pt-hecto small"
         [innerHTML]="'medical_wellness_c_03'|translate|newLineBreak|removeWidows"></p>
    </app-single-image-text>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-single-image-text
      image="/assets/images/wellness_page/medical/single_image3.jpg"
      image2x="/assets/images/wellness_page/medical/single_image3@2x.jpg"
      imageAlt="Single image 3"
    >
      <h3 class="text-light-gray" [innerHTML]="'medical_wellness_h_04'|translate|newLineBreak|removeWidows"></h3>
      <p class="pt-lg-kilo pt-hecto small"
         [innerHTML]="'medical_wellness_c_04'|translate|newLineBreak|removeWidows"></p>
    </app-single-image-text>
  </div>

  <app-indent-content-between-images
    [header]="'medical_wellness_h_05'"
    [smallImage]="{ image: '../../../assets/images/wellness_page/medical/double_section_left.jpg', image2x: '../../../assets/images/wellness_page/medical/double_section_left@2x.jpg' }"
    [bigImage]="{ image: '../../../assets/images/wellness_page/medical/double_section_right.jpg', image2x: '../../../assets/images/wellness_page/medical/double_section_right@2x.jpg' }"
  >
    <p
      class="small"
      [innerHTML]="'medical_wellness_c_05'|translate|newLineBreak|removeWidows"
    >
    </p>

    <div class="actions pt-mega pb-md-kilo">
      <a href="/assets/files/Medical_Wellness.pdf" target="_blank" class="btn btn-bordered">{{ 'know_offer'|translate }}</a>
    </div>
  </app-indent-content-between-images>
</div>

<app-after-before-grey
  width="50%"
  height="542px"
  top="-50%"
  right="0"
  additionalClasses="to-top-right"
>
  <div class="container mobile-padding">
    <div class="row pt-peta pt-md-zetta align-items-center">
      <div class="col-12 col-md-5 col-xl-4 offset-lg-1 offset-xxl-2">
        <h2 class="text-light-gray">{{ 'reservation_single'|translate }}</h2>
      </div>
      <div class="col-12 col-md-6 col-lg-5 offset-0 offset-md-1">
        <h3 class="text-light-gray pt-kilo pt-md-zero sm-font-22">{{ 'wellness_and_spa'|translate }}</h3>
        <p class="pt-kilo text-gray-main">{{ 'contact_tel'|translate }}  <a class="text-decoration-none" href="tel:+48943554050">+48 94 355 4050</a></p>
        <p class="text-gray-main">{{ 'contact_email'|translate }}: <a class="text-decoration-none" href="mailto:wellness@shuumhotel.pl">wellness@shuumhotel.pl</a></p>
      </div>
    </div>
  </div>
</app-after-before-grey>

<div class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-gallery
      [header]="'wellness_and_spa'"
      [description]="'wellness_and_spa_gallery_description'"
      [images]="galleryImages"
    ></app-gallery>
  </div>

<!--    <div class="pt-peta pt-md-zetta pt-lg-200">-->
<!--      <app-discover-->
<!--        [header]="'discover_also'"-->
<!--        [name]="'Intense Repair\nShea Butter'"-->
<!--        [description]="'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit in voluptate velit esse.'"-->
<!--        [price]="{ value: 49.99, currency: 'PLN' }"-->
<!--        [link]="''"-->
<!--      ></app-discover>-->
<!--    </div>-->

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 offset-xl-1">
        <div class="out-mobile-padding">
          <app-images-carousel
            [images]="carouselImages"
          >
          </app-images-carousel>
        </div>
      </div>
      <div class="col-12 col-lg-5 col-xl-4 offset-lg-1">
        <div class="pt-kilo pt-md-giga pt-lg-zero">
          <app-indent-content
            [header]="'our_team'"
          >
            <p
              class="small"
              [innerHTML]="'our_team_description'|translate|newLineBreak|removeWidows"
            >
            </p>

            <div class="actions pt-mega pb-deca">
              <a [routerLink]="'/:lang/career'|i18nRoute"
                 class="btn btn-bordered mb-deci">{{ 'join_us'|translate }}</a>
            </div>
          </app-indent-content>
        </div>
      </div>
    </div>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-end">
      <div class="col-10 offset-lg-1 offset-xl-2">
        <h3 class="text-light-gray" [innerHTML]="'certifications'|translate|removeWidows"></h3>
      </div>
      <div class="col-12 col-md-6 col-lg-5 col-xl-4 offset-lg-1 offset-xl-2 order-1 order-md-0">
        <p
          class="pt-giga pt-lg-peta small"
          [innerHTML]="'certifications_description'|translate|newLineBreak|removeWidows"
        ></p>
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-3 offset-lg-1 order-0 order-md-1">
        <div class="pt-giga pt-md-zero">
          <a
            href="https://www.wellnessverband.de/wellness-freunde/anbieter/detail.php?we_oid=245"
            target="_blank"
            class="opacity-link"
          >
            <app-text-centered-image
              header="{{ 'award_h_04'|translate}}"
              image="/assets/images/wellness_page/certificate.png"
              image2x="/assets/images/wellness_page/certificate@2x.png"
              imgMaxHeight="118px"
              imageAlt="certificate logo"
            ></app-text-centered-image>
          </a>
        </div>
      </div>
    </div>
  </div>
<!--  </div>-->
</div>

<app-footer></app-footer>
