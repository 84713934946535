import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import ConfigService from '@config/ConfigService';
import { RouteMapHelper } from '../../../_helpers/RouteMapHelper';
import { ReservationStepService } from '@services/reservations/reservation-step.service';
import { ReservationDataService } from '@services/reservations/reservation-data.service';
import { I18nRoutePipe } from 'src/app/_pipes/i18n-route.pipe';
import { environment } from 'src/environments/environment';
import { ValidationEmitterService } from 'src/app/_subprojects/reservation-form/_services/validation-emitter.service';
import { isPlatformBrowser } from '@angular/common';
import ReservationStepEnum from '../_enums/ReservationStepEnum';
import { first } from 'rxjs/operators';
import { ReservationService } from '../_services/reservation.service';
import GlobalFunctions from '@helpers/GlobalFunctions';

@Injectable({
  providedIn: 'root'
})
export class StepGuard implements CanActivate {
  private segmentTranslation: string;

  constructor(
    private router: Router,
    private routeMapHelper: RouteMapHelper,
    private reservationService: ReservationService,
    private reservationStepService: ReservationStepService,
    private reservationDataService: ReservationDataService,
    private i18nRoute: I18nRoutePipe,
    private validationEmitterService: ValidationEmitterService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.reservationDataService.validate();
    this.segmentTranslation = this.routeMapHelper.getRoute('reservation', ConfigService.getCurrentLang());
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if ( isPlatformBrowser(this.platformId) ) {
      let valid = true;
      const steps = this.reservationStepService.getSteps().value;

      const stepsAfterFour = [ 'step-5', 'error-page', 'success-page' ];

      /**
       * W przypadku odświeżenia strony na kroku 5 lub dalej,
       * brak walidacji na kroku 4, użytkownik jest wpuszczany
       * na stronę i po wykonaniu requestu do API następuje
       * ewentualne przekierowanie do kroku 4
       *
       * W każdym innym przypadku sprawdzanie poprawności
       * wszystkich poprzednich kroków
       */
      if ( this.router.url === '/' && stepsAfterFour.includes(next.routeConfig.data.group) ) {
        steps.forEach(step => {
          if ( step.id < 3 && !step.isValid ) {
            valid = false;
          }
        });

        if ( isPlatformBrowser(this.platformId) ) {
          this.reservationDataService.validateFourth().pipe( first() ).subscribe(_ => {
            this.reservationStepService.setStepValidity(ReservationStepEnum.IV, true);
          }, _ => {
            this.reservationStepService.setStepValidity(ReservationStepEnum.IV, false);
            this.router.navigate(
              [ this.i18nRoute.transform('/:lang/reservation/step-4') ]
            );
          });
        }

      } else {
        steps.forEach(step => {
          if ( step.id < next.data.stepNo && !step.isValid ) {
            valid = false;
          }
        });
      }

      /**
       * Jeśli użytkownik jest na kroku 1 i nie może przejść dalej,
       * (Wieki dzieci lub data nie jest wypelniona),
       * uruchom walidację kroku 1
       */
      const last = this.router.url.split('/').pop().split('?')[0];
      if ( !valid && this.routeMapHelper.getGroup(last) === 'reservation' ) {
        const step = steps.find(step => step.id === ReservationStepEnum.I);
        if ( !step.isValid ) {
          this.validationEmitterService.setFirstStepValidationToggle(step.errorMessage ? step.errorMessage : 'reservation_error_text_1');
        } else {
          this.validationEmitterService.setFirstStepValidationToggle('go_to_next_step');
        }
      }

      /**
       * Jeśli użytkownik jest na kroku 2 i nie może przejść dalej,
       * (Pokój nie jest wybrany),
       * uruchom walidację kroku 2
       */
      if ( !valid && this.routeMapHelper.getGroup(last) === 'reservation_step-2' ) {
        if ( !steps.find(step => step.id === ReservationStepEnum.II).isValid ) {
          this.validationEmitterService.setSecondStepValidationToggle('reservation_error_text_2');
        } else {
          this.validationEmitterService.setSecondStepValidationToggle('go_to_next_step');
        }
      }

      /**
       * Walidacja na kroku 3
       */
      if ( !valid && this.routeMapHelper.getGroup(last) === 'reservation_step-3' ) {
        this.validationEmitterService.setThirdStepValidationToggle('go_to_next_step');
      }

      /**
       * Jeśli użytkownik jest na kroku 4 i nie może przejść dalej,
       * (formularz jest niewypełniony),
       * uruchom walidację formularza rezerwacji
       */
      if ( !valid && this.routeMapHelper.getGroup(last) === 'reservation_step-4' ) {
        this.validationEmitterService.setFourthStepValidationToggle('reservation_error_text_4');
      }

      /**
       * Jesli uzytkownik znajduje się na innej stronie niż rezerwacje,
       * lub właśnie odświeżył stronę,
       * przekieruj go na krok 1
       */
      const pathSegment = this.router.url.split('/')[2];
      if ( !valid ) {
        if ( !pathSegment || pathSegment !== this.segmentTranslation ) {
          const queryParams = GlobalFunctions.queryStringToObject(this.router.url);

          this.router.navigate(
            [ this.i18nRoute.transform('/:lang/reservation') ], {
              queryParams
            }
          );
        }

        return false;
      }
    }

    return true;
  }
}
