import {Component, HostListener, Input, OnInit, OnChanges, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {interval, Subject, fromEvent} from 'rxjs';
import {debounce, takeUntil} from 'rxjs/operators';
import GlobalFunctions from '@helpers/GlobalFunctions';
import {IImage, IGalleryImage} from '@interfaces/IImage';
import * as chunk from 'lodash/chunk';
import {sliderAnimation} from '@helpers/animations';
import { GlobalStateHelper } from '@helpers/GlobalStateHelper';
import ConfigService from '@config/ConfigService';
import { SubscribeHelper } from '@helpers/SubscribeHelper';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  animations: [
    sliderAnimation
  ]
})
export class GalleryComponent extends SubscribeHelper implements OnInit, OnChanges {

  showOverlay: boolean;
  activeImageIndex: number = null;
  resize$ = new Subject<number>();
  innerWidth: number;
  actualBootstrapBreakPoint: string;
  maxPerPage = 5;
  hasSrcSetSupport: boolean;

  pages = 0;
  currentPage = 0;
  previousPage = 0;
  chunkedImages: IImage[] = [];
  isLastPage = false;

  @Input() header?: string;
  @Input() description?: string;
  @Input() descriptionClass = 'text-main';
  @Input() images?: IImage[] = [];
  @Input() imagesWithChangingDesc?: IGalleryImage[] = [];
  @Input() class?: string;

  @ViewChild('gallery', {static: true}) gallery: ElementRef;

  constructor(
    private globalStateService: GlobalStateHelper
  ) {
    super();
    this.hasSrcSetSupport = ConfigService.getHasSrcSetSupport();
  }

  ngOnInit() {

    fromEvent(document, 'keyup')
      .pipe(
        debounce(() => interval(150)),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((e: KeyboardEvent) => { this.keyUpListener(e) });

    this.resize$
      .pipe(
        debounce(() => interval(300)),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        innerWidth => {
          this.innerWidth = innerWidth;
          this.actualBootstrapBreakPoint = GlobalFunctions.getActualBootstrapBreakPointName( innerWidth );

          this.resetViewableImagesOnResize();
        }
      );

    if ( window ) {
      this.innerWidth = window.innerWidth;
      this.actualBootstrapBreakPoint = GlobalFunctions.getActualBootstrapBreakPointName( this.innerWidth );
    }

    this.resetGallery();
  }

  ngOnChanges() {
    this.goToIndex(0);
    this.resetGallery();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize$.next(event.target.innerWidth);
  }

  onImageClick( e: MouseEvent, index: number ) {
    e.preventDefault();

    if ( this.canOpenImage( index ) ) {
      this.activeImageIndex = index;
      this.showOverlay = true;
    }

    this.hideMenu();
  }

  closeImage( e: MouseEvent ) {
    e.preventDefault();

    this.activeImageIndex = null;
    this.showOverlay = false;

    this.hideMenu();
  }

  resetViewableImagesOnResize() {
    if ( this.actualBootstrapBreakPoint  === 'sm' || this.actualBootstrapBreakPoint  === 'xs' ) {
      if ( this.activeImageIndex !== null ) {
        if ( this.activeImageIndex > 2 ) {
          this.activeImageIndex = null;
          this.showOverlay = false;
        }
      }
    }
  }

  canOpenImage( index: number ) {
    if ( this.actualBootstrapBreakPoint  === 'sm' || this.actualBootstrapBreakPoint  === 'xs' ) {
      if ( index > 2 ) {
        return false;
      }
    }

    return true;
  }

  setImages( page: number ) {
    this.isLastPage = page + 1 >= this.pages;

    if ( this.chunkedImages.length > 0 ) {
      if ( typeof this.chunkedImages[page] !== 'undefined' ) {
        this.currentPage = page;
      }
      else {
        if ( !this.isLastPage ) {
          this.currentPage = 0;
        }
      }
    }
  }

  goToIndex( index: number ) {
    if ( index < 0 || index >= this.pages ) {
      index = this.currentPage;
    }

    this.previousPage = this.currentPage;
    this.currentPage = index;
    this.activeImageIndex = null;
    this.showOverlay = false;

    this.setImages( index );
  }

  goToNextPage( e?: MouseEvent ) {
    if( e ) {
      e.preventDefault();
    }

    this.goToIndex( this.currentPage + 1 );
    this.hideMenu();
  }

  goToPrevPage( e?: MouseEvent ) {
    if( e ) {
      e.preventDefault();
    }

    this.goToIndex( this.currentPage - 1 );
    this.hideMenu();
  }

  hideMenu() {
    this.globalStateService.setDisableMenuAppear(true);
    this.globalStateService.setDisableMenuAppear(false);
  }

  resetGallery() {
    this.currentPage = 0;
    this.previousPage = 0;

    if ( this.images && this.images.length > 0 ) {
      this.pages = Math.ceil(this.images.length / this.maxPerPage);
      this.chunkedImages = chunk( this.images, this.maxPerPage);
    } else if ( this.imagesWithChangingDesc ) {
      this.pages = Math.ceil(this.imagesWithChangingDesc.length / this.maxPerPage);
      this.chunkedImages = chunk( this.imagesWithChangingDesc, this.maxPerPage);
    }

    this.setImages( 0 );
  }

  keyUpListener(e: KeyboardEvent) {
    try {
      const key = e.key;

      if( GlobalFunctions.isElementInViewport(this.gallery.nativeElement) ) {
        if( key === 'ArrowLeft' ) {
          this.goToPrevPage();
        } else if( key === 'ArrowRight' ) {
          this.goToNextPage();
        }
      }
    } catch(e) { }
  }
}
