import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalStateHelper } from '@helpers/GlobalStateHelper';
import { FormBuilder } from '@angular/forms';
import { PopupContentComponent } from '../../../_shared/popup-content/popup-content.component';

@Component({
  selector: 'app-logout-popup',
  templateUrl: './logout-popup.component.html',
  styleUrls: ['./logout-popup.component.scss']
})
export class LogoutPopupComponent implements OnInit {
  @ViewChild('popup') popup: PopupContentComponent;
  
  constructor(
    private globalStateHelper: GlobalStateHelper
  ) { }

  ngOnInit(): void {
  }

  redirectWithBlockedScroll($e: MouseEvent, link: string): void {
    $e.preventDefault();
    this.globalStateHelper.showPopupWithoutAnimation = true;
    this.popup.redirectWithoutHiding(link);
  }
}
