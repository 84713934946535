import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {I18nModule} from '../i18n/i18n.module';

import {SharedButtonsModule} from './shared-buttons.module';
import {Error404Component} from '@shared/errors/error404/error404.component';
import {SharedBannerModule} from './shared-banner.module';
import {SharedPipesModule} from './shared-pipes.module';


@NgModule({
  declarations: [
    Error404Component,
  ],
  imports: [
    CommonModule,
    I18nModule,
    SharedBannerModule,
    SharedButtonsModule,
    SharedPipesModule
  ],
  providers: [

  ],
  bootstrap: [],
  exports: [
    Error404Component,
  ]
})
export class SharedErrorsModule { }
