import { Component, OnInit, OnDestroy } from '@angular/core';
import {CareerService} from '../../../../_services/career.service';
import {ActivatedRoute} from '@angular/router';
import IJobOfferDetails from '../../../../_interfaces/IJobOfferDetails';
import IJobOfferShort from '../../../../_interfaces/IJobOfferShort';
import IJobOfferContent from '../../../../_interfaces/IJobOfferContent';
import IPhoto from '../../../../_interfaces/IPhoto';
import {MetaService} from '@services/meta.service';
import {I18nRoutePipe} from '../../../../_pipes/i18n-route.pipe';
import { CurrentRouteHelper } from '@helpers/CurrentRouteHelper';
import { GlobalStateHelper } from '@helpers/GlobalStateHelper';
import { HttpResponse } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SubscribeHelper } from '@helpers/SubscribeHelper';

@Component({
  selector: 'app-career-details',
  templateUrl: './career-details.component.html',
  styleUrls: ['./career-details.component.scss']
})
export class CareerDetailsComponent extends SubscribeHelper implements OnInit {
  public jobOffer: IJobOfferDetails;
  public jobContentInOrder: IJobOfferContent[] = [];
  public jobOffers: IJobOfferShort[] = [];

  private quotes: IJobOfferContent[];
  private images: IJobOfferContent[];
  private textfields: IJobOfferContent[];
  private offerId: string;

  private page: number = 1;

  public responseMax: number = 0;
  public responseCurrent: number = 0;
  public loading: boolean = false;

  constructor(
    private careerService: CareerService,
    private route: ActivatedRoute,
    private metaService: MetaService,
    private i18nRoute: I18nRoutePipe,
    private currentRouteHelper: CurrentRouteHelper,
    private globalStateService: GlobalStateHelper
  ) {
    super();
  }

  ngOnInit() {
    this.globalStateService.setIsBtnSticked(false);

    this.route.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.offerId = params.slug;
        this.getRouteAndData();
      });
  }

  ngOnDestroy() {
    this.currentRouteHelper.removeLastGroupTranslation();
    super.ngOnDestroy();
  }

  private getRouteAndData() {
    this.careerService.getCareerDetails(this.offerId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((res: IJobOfferDetails) => {
        const r = Object.assign({}, res);

        this.jobContentInOrder = [];
        this.jobOffer = r;

        this.currentRouteHelper.setLastGroupTranslation(this.jobOffer.trans)
        // this.jobOffer.photo.path = `${environment.imageHost}${this.jobOffer.photo.path}`;

        this.metaService.setAlternateURLWithITrans( r.trans, r.slug, this.i18nRoute );
        this.setMetaData( r );
        this.setContentInOrder( r );
      }, error => {
        console.log('Error! ' + error);
      });

    this.careerService.getCareerListExtended([], [], 6, this.page)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((res: HttpResponse<IJobOfferShort[]>) => {
        let r = Object.assign([], res.body);

        r = r.filter(el => el.slug !== this.offerId, 10);

        this.responseMax = JSON.parse(res.headers.get('Query-Count'));
        this.responseCurrent += res.body.length;
        this.page += 1;
        this.loading = false;

        this.jobOffers = r;
      }, (err) => {
        console.log('Error!' + err);
      });
  }

  private filterContentByType(type): IJobOfferContent[] {
    return this.jobOffer.content.filter((el) => (el.type === type));
  }

  private addFirstAvailableTextfield() {
    if (this.textfields[0]) {
      const textfield = this.textfields.shift();

      const index = this.jobOffer.content.indexOf(textfield);
      if (index !== -1) {
        this.jobOffer.content.splice(index, 1);
      }

      this.jobContentInOrder.push(textfield);
    } else {
      this.jobContentInOrder.push(this.jobOffer.content.shift());
    }
  }

  private addFirstAvailableQuote() {
    if (this.quotes[0]) {
      const quote = this.quotes.shift();

      const index = this.jobOffer.content.indexOf(quote);
      if (index !== -1) {
        this.jobOffer.content.splice(index, 1);
      }

      this.jobContentInOrder.push(quote);
    } else if (this.images[0]) {
      const image = this.images.shift();

      const index = this.jobOffer.content.indexOf(image);
      if (index !== -1) {
        this.jobOffer.content.splice(index, 1);
      }

      this.jobContentInOrder.push(image);
    } else {
      this.jobContentInOrder.push(this.jobOffer.content.shift());
    }
  }

  private addRestOfContent() {
    this.jobOffer.content.forEach(el => {
      this.jobContentInOrder.push(el);
    });
  }

  private setContentInOrder(data: IJobOfferDetails) {
    data.content.filter((el: IJobOfferContent) => {
      if (el.type === 1) {
        el.content = el.content as IPhoto;
        // el.content.path = `${environment.imageHost}${el.content.path}`;
      }
    });

    this.textfields = this.filterContentByType(0);
    this.images = this.filterContentByType(1);
    this.quotes = this.filterContentByType(2);

    this.addFirstAvailableTextfield();
    this.addFirstAvailableQuote();
    this.jobContentInOrder.push(
      {
        type: 3,
        content: ''
      });
    this.addFirstAvailableTextfield();
    this.addFirstAvailableTextfield();
    this.addRestOfContent();
  }

  setMetaData( item: IJobOfferDetails ) {
    this.metaService.appendTitle( item.title );
    this.metaService.setDescription( item.metaDescription );
  }

  loadMore(): void {
    this.loading = true;

    this.careerService.getList([], [], 6, this.page)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((list: any) => {
        this.jobOffers.push.apply(this.jobOffers, list);

        this.responseCurrent += list.length;
        this.page += 1;
        this.loading = false;
      });
  }
}
