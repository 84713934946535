import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginComponent } from './login/login.component';
import { LangGuard } from '@guards/LangGuard';
import { UserPanelComponent } from './user-panel/user-panel.component';
import { LogoutUserGuard } from '../_guards/logout-user.guard';
import { ReservationsComponent } from './reservations.component';
import { LogInPopupComponent } from './popups/log-in-popup/log-in-popup.component';
import { SignUpPopupComponent } from './popups/sign-up-popup/sign-up-popup.component';
import { PasswordRecoveryPopupComponent } from './popups/password-recovery-popup/password-recovery-popup.component';
import { MyReservationsComponent } from './user-panel/_pages/user-reservations/_pages/my-reservations/my-reservations.component';
import { MyAccountComponent } from './user-panel/_pages/user-account/_pages/my-account/my-account.component';
import { EditAccountComponent } from './user-panel/_pages/user-account/_pages/edit-account/edit-account.component';
import { UserAccountComponent } from './user-panel/_pages/user-account/user-account.component';
import { UserReservationsComponent } from './user-panel/_pages/user-reservations/user-reservations.component';
import { MyReservationsDetailsComponent } from './user-panel/_pages/user-reservations/_pages/my-reservations-details/my-reservations-details.component';
import { ChangeReservationPopupComponent } from './popups/change-reservation-popup/change-reservation-popup.component';
import { LogoutPopupComponent } from './popups/logout-popup/logout-popup.component';
import { ActivateAccountPopupComponent } from './popups/activate-account-popup/activate-account-popup.component';
import { NewPasswordPopupComponent } from './popups/new-password-popup/new-password-popup.component';
import { LogoutScreenGuard } from '../_guards/logout-screen.guard';
import { AuthGuard } from '@guards/AuthGuard';
import { ActivateAccountScreenGuard } from '../_guards/activate-account-screen.guard';
import { ActivateAccountMailPopupComponent } from './popups/activate-account-mail-popup/activate-account-mail-popup.component';
import {
  DiscoverRelaxAndBeautyComponent
} from './user-panel/_pages/loyalty-program/_pages/discover-relax-and-beauty/discover-relax-and-beauty.component';
import {HungryComponent} from './user-panel/_pages/loyalty-program/_pages/hungry/hungry.component';
import {
  RestoreVitalForcesComponent
} from './user-panel/_pages/loyalty-program/_pages/restore-vital-forces/restore-vital-forces.component';
import {FacilitiesComponent} from './user-panel/_pages/loyalty-program/_pages/facilities/facilities.component';
import {UsefulInformationComponent} from './user-panel/_pages/loyalty-program/_pages/useful-information/useful-information.component';
import {NotificationsComponent} from './user-panel/_pages/notifications/notifications.component';
import {LoyaltyProgramComponent} from './user-panel/_pages/loyalty-program/loyalty-program.component';
import {LoyaltyProgramGuardGuard} from '../_guards/loyalty-program-guard.guard';
import {LoyaltyProgramScreenGuardGuard} from '../_guards/loyalty-program-screen-guard.guard';
import { ShuummeComponent } from './user-panel/_pages/loyalty-program/shuumme/shuumme.component';
// import {environment} from '../../../environments/environment';
// import {LangGuard} from '../../_guards/LangGuard';

const defaultRoute = '/' + environment.defaultLanguage;

/**
 * !!! DONT FORGET TO UPDATE RouteMapHelper
 */
const routes: Routes = [
  {
    path: '',
    component: ReservationsComponent,
    data: {
      forLanguages: ['pl', 'de', 'en']
    },
    children: [
      {
        path: 'panel-logowania',
        component: LoginComponent,
        canActivate: [LangGuard, LogoutUserGuard],
        data: {
          forLanguages: ['pl'],
          group: 'authorization-group',
          meta: {
            title: 'login_panel',
            description: 'login_panel_description',
            keywords: 'login_panel_keywords'
          }
        },
        children: [
          {
            path: 'zaloguj-sie',
            component: LogInPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['pl'],
              group: 'sign-in-group',
              meta: {
                title: 'log_in'
              }
            }
          },
          {
            path: 'zarejestruj-sie',
            component: SignUpPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['pl'],
              group: 'sign-up-group',
              meta: {
                title: 'join_us'
              }
            }
          },
          {
            path: 'odzyskaj-haslo',
            component: PasswordRecoveryPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['pl'],
              group: 'recover-password-group',
              meta: {
                title: 'recover_password_short'
              }
            }
          },
          {
            path: 'wylogowanie',
            component: LogoutPopupComponent,
            canActivate: [LangGuard, LogoutScreenGuard, LogoutUserGuard],
            data: {
              forLanguages: ['pl'],
              group: 'logout-group',
              meta: {
                title: 'logout_header'
              }
            }
          },
          {
            path: 'aktywuj-konto',
            component: ActivateAccountPopupComponent,
            canActivate: [LangGuard, ActivateAccountScreenGuard, LogoutUserGuard],
            data: {
              forLanguages: ['pl'],
              group: 'activate-account-group',
              meta: {
                title: 'activate_account'
              }
            }
          },
          {
            path: 'nowe-haslo',
            component: NewPasswordPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['pl'],
              group: 'new-password-group',
              meta: {
                title: 'add_new_password_title'
              }
            }
          },
          {
            path: 'konto-zostalo-aktywowane',
            component: ActivateAccountMailPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['pl'],
              group: 'activate-account-mail-group',
              meta: {
                title: 'account_activated'
              }
            }
          },
        ]
      },
      {
        path: 'login-panel',
        component: LoginComponent,
        canActivate: [LangGuard, LogoutUserGuard],
        data: {
          forLanguages: ['en'],
          group: 'authorization-group',
          meta: {
            title: 'login_panel',
            description: 'login_panel_description',
            keywords: 'login_panel_keywords'
          }
        },
        children: [
          {
            path: 'sign-in',
            component: LogInPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['en'],
              group: 'sign-in-group',
              meta: {
                title: 'log_in'
              }
            }
          },
          {
            path: 'sign-up',
            component: SignUpPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['en'],
              group: 'sign-up-group',
              meta: {
                title: 'join_us'
              }
            }
          },
          {
            path: 'recover-password',
            component: PasswordRecoveryPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['en'],
              group: 'recover-password-group',
              meta: {
                title: 'recover_password_short'
              }
            }
          },
          {
            path: 'logout',
            component: LogoutPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['en'],
              group: 'logout-group',
              meta: {
                title: 'logout_header'
              }
            }
          },
          {
            path: 'activate-account',
            component: ActivateAccountPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['en'],
              group: 'activate-account-group',
              meta: {
                title: 'activate_account'
              }
            }
          },
          {
            path: 'new-password',
            component: NewPasswordPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['en'],
              group: 'new-password-group',
              meta: {
                title: 'add_new_password_title'
              }
            }
          },
          {
            path: 'account-activated',
            component: ActivateAccountMailPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['en'],
              group: 'activate-account-mail-group',
              meta: {
                title: 'account_activated'
              }
            }
          },
        ]
      },
      {
        path: 'anmelden-panel',
        component: LoginComponent,
        canActivate: [LangGuard, LogoutUserGuard],
        data: {
          forLanguages: ['de'],
          group: 'authorization-group',
          meta: {
            title: 'login_panel',
            description: 'login_panel_description',
            keywords: 'login_panel_keywords'
          }
        },
        children: [
          {
            path: 'anmelden',
            component: LogInPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['de'],
              group: 'sign-in-group',
              meta: {
                title: 'log_in'
              }
            }
          },
          {
            path: 'registrieren',
            component: SignUpPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['de'],
              group: 'sign-up-group',
              meta: {
                title: 'join_us'
              }
            }
          },
          {
            path: 'passwort-wiederherstellen',
            component: PasswordRecoveryPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['de'],
              group: 'recover-password-group',
              meta: {
                title: 'recover_password_short'
              }
            }
          },
          {
            path: 'ausloggen',
            component: LogoutPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['de'],
              group: 'logout-group',
              meta: {
                title: 'logout_header'
              }
            }
          },
          {
            path: 'konto-aktivieren',
            component: ActivateAccountPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['de'],
              group: 'activate-account-group',
              meta: {
                title: 'activate_account'
              }
            }
          },
          {
            path: 'neues-passwort',
            component: NewPasswordPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['de'],
              group: 'new-password-group',
              meta: {
                title: 'add_new_password_title'
              }
            }
          },
          {
            path: 'das-konto-wurde-aktiviert',
            component: ActivateAccountMailPopupComponent,
            canActivate: [LangGuard, LogoutUserGuard],
            data: {
              forLanguages: ['de'],
              group: 'activate-account-mail-group',
              meta: {
                title: 'account_activated'
              }
            }
          },
        ]
      },
      {
        path: '',
        component: UserPanelComponent,
        canActivate: [LangGuard, AuthGuard],
        data: {
          forLanguages: ['pl', 'de', 'en'],
          myAccountLinkActive: 3,
          isMyReservationsRoute: true
        },
        children: [
          {
            path: '',
            component: UserAccountComponent,
            canActivate: [LangGuard],
            data: {
              forLanguages: ['pl', 'de', 'en'],
              myAccountLinkActive: 3
            },
            children: [
              {
                path: '',
                component: MyAccountComponent,
                canActivate: [LangGuard],
                data: {
                  forLanguages: ['pl', 'de', 'en'],
                  myAccountLinkActive: 3,
                  meta: {
                    title: 'my_account',
                  }
                },
              },
              {
                path: 'edycja',
                component: EditAccountComponent,
                canActivate: [LangGuard],
                data: {
                  forLanguages: ['pl'],
                  group: 'edit-account-group',
                  myAccountLinkActive: 3,
                  meta: {
                    title: 'edit_account'
                  }
                },
              },
              {
                path: 'edit',
                component: EditAccountComponent,
                canActivate: [LangGuard],
                data: {
                  forLanguages: ['en'],
                  group: 'edit-account-group',
                  myAccountLinkActive: 3,
                  meta: {
                    title: 'edit_account'
                  }
                },
              },
              {
                path: 'bearbeiten',
                component: EditAccountComponent,
                canActivate: [LangGuard],
                data: {
                  forLanguages: ['de'],
                  group: 'edit-account-group',
                  myAccountLinkActive: 3,
                  meta: {
                    title: 'edit_account'
                  }
                },
              }
            ]
          },
          {
            path: 'rezerwacje',
            component: UserReservationsComponent,
            canActivate: [LangGuard],
            data: {
              forLanguages: ['pl'],
              group: 'reservation-panel-group',
              myAccountLinkActive: 2
            },
            children: [
              {
                path: '',
                component: MyReservationsComponent,
                data: {
                  myAccountLinkActive: 2,
                  meta: {
                    title: 'my_reservations'
                  }
                }
              },
              {
                path: ':id',
                component: MyReservationsDetailsComponent,
                data: {
                  group: '',
                  myAccountLinkActive: 2,
                  meta: {
                    title: 'reservation_details'
                  }
                },
              },
            ]
          },
          {
            path: 'reservations',
            component: UserReservationsComponent,
            canActivate: [LangGuard],
            data: {
              group: 'reservation-panel-group',
              forLanguages: ['en'],
              myAccountLinkActive: 2
            },
            children: [
              {
                path: '',
                component: MyReservationsComponent,
                data: {
                  myAccountLinkActive: 2,
                  meta: {
                    title: 'my_reservations'
                  }
                }
              },
              {
                path: ':id',
                component: MyReservationsDetailsComponent,
                data: {
                  group: '',
                  myAccountLinkActive: 2,
                  meta: {
                    title: 'reservation_details'
                  }
                },
              },
            ]
          },
          {
            path: 'reservierungen',
            component: UserReservationsComponent,
            canActivate: [LangGuard],
            data: {
              forLanguages: ['de'],
              group: 'reservation-panel-group',
              myAccountLinkActive: 2
            },
            children: [
              {
                path: '',
                component: MyReservationsComponent,
                data: {
                  myAccountLinkActive: 2,
                  meta: {
                    title: 'my_reservations'
                  }
                }
              },
              {
                path: ':id',
                component: MyReservationsDetailsComponent,
                data: {
                  group: '',
                  myAccountLinkActive: 2,
                  meta: {
                    title: 'reservation_details'
                  }
                },
              },
            ]
          },
          {
            path: 'shuumme',
            canActivate: [LangGuard],
            data: {
              forLanguages: ['pl'],
              group: 'shuumme-panel-group',
              myAccountLinkActive: 1
            },
            children: [
              {
                path: '',
                component: LoyaltyProgramComponent,
                canActivate: [LangGuard, LoyaltyProgramScreenGuardGuard],
                data: {
                  forLanguages: ['pl'],
                  group: 'shuumme-panel-group',
                  myAccountLinkActive: 1,
                  meta: {
                    title: 'shuum_me',
                  }
                }
              },
              {
                path: '',
                component: ShuummeComponent,
                canActivate: [LoyaltyProgramGuardGuard],
                children: [
                  {
                    path: 'zabiegi-masaze',
                    component: DiscoverRelaxAndBeautyComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['pl'],
                      group: 'treatments-massages-group',
                      myAccountLinkActive: 1,
                      especiallyForYouLinkActive: 1,
                      meta: {
                        title: 'discover_relax_and_beauty',
                      }
                    },
                  },
                  {
                    path: 'restauracja-bar',
                    component: HungryComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['pl'],
                      group: 'restaurant-bar-group',
                      myAccountLinkActive: 1,
                      especiallyForYouLinkActive: 2,
                      meta: {
                        title: 'hungry',
                      }
                    },
                  },
                  {
                    path: 'fitness',
                    component: RestoreVitalForcesComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['pl'],
                      group: 'fitness-group',
                      myAccountLinkActive: 1,
                      especiallyForYouLinkActive: 3,
                      meta: {
                        title: 'restore_vital_forces',
                      }
                    },
                  },
                  {
                    path: 'udogodnienia',
                    component: FacilitiesComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['pl'],
                      group: 'facilities-group',
                      myAccountLinkActive: 1,
                      especiallyForYouLinkActive: 4,
                      meta: {
                        title: 'facilities_title',
                      }
                    },
                  },
                  {
                    path: 'niezbednik',
                    component: UsefulInformationComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['pl'],
                      group: 'useful-information-group',
                      myAccountLinkActive: 1,
                      especiallyForYouLinkActive: 5,
                      meta: {
                        title: 'useful_information',
                      }
                    },
                  },
                  {
                    path: 'powiadomienia',
                    component: NotificationsComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['pl'],
                      group: 'notifications-panel-group',
                      myAccountLinkActive: 1,
                      meta: {
                        title: 'notifications'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'loyalty-program',
            canActivate: [LangGuard],
            data: {
              forLanguages: ['en'],
              group: 'shuumme-panel-group',
              myAccountLinkActive: 1
            },
            children: [
              {
                path: '',
                component: LoyaltyProgramComponent,
                canActivate: [LangGuard, LoyaltyProgramScreenGuardGuard],
                data: {
                  forLanguages: ['en'],
                  group: 'shuumme-panel-group',
                  myAccountLinkActive: 1,
                  meta: {
                    title: 'loyalty_program',
                  }
                }
              },
              {
                path: '',
                component: ShuummeComponent,
                canActivate: [LoyaltyProgramGuardGuard],
                children: [
                  {
                    path: 'treatments-massages',
                    component: DiscoverRelaxAndBeautyComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['en'],
                      group: 'treatments-massages-group',
                      myAccountLinkActive: 1,
                      especiallyForYouLinkActive: 1,
                      meta: {
                        title: 'discover_relax_and_beauty',
                      }
                    },
                  },
                  {
                    path: 'restaurant-bar',
                    component: HungryComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['en'],
                      group: 'restaurant-bar-group',
                      myAccountLinkActive: 1,
                      especiallyForYouLinkActive: 2,
                      meta: {
                        title: 'hungry',
                      }
                    },
                  },
                  {
                    path: 'fitness',
                    component: RestoreVitalForcesComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['en'],
                      group: 'fitness-group',
                      myAccountLinkActive: 1,
                      especiallyForYouLinkActive: 3,
                      meta: {
                        title: 'restore_vital_forces',
                      }
                    },
                  },
                  {
                    path: 'amenities',
                    component: FacilitiesComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['en'],
                      group: 'facilities-group',
                      myAccountLinkActive: 1,
                      especiallyForYouLinkActive: 4,
                      meta: {
                        title: 'facilities_title',
                      }
                    },
                  },
                  {
                    path: 'essentials',
                    component: UsefulInformationComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['en'],
                      group: 'useful-information-group',
                      myAccountLinkActive: 1,
                      especiallyForYouLinkActive: 5,
                      meta: {
                        title: 'useful_information',
                      }
                    },
                  },
                  {
                    path: 'notifications',
                    component: NotificationsComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['en'],
                      group: 'notifications-panel-group',
                      myAccountLinkActive: 1,
                      meta: {
                        title: 'notifications'
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'treueprogramm',
            canActivate: [LangGuard],
            data: {
              forLanguages: ['de'],
              group: 'shuumme-panel-group',
              myAccountLinkActive: 1
            },
            children: [
              {
                path: '',
                component: LoyaltyProgramComponent,
                canActivate: [LangGuard, LoyaltyProgramScreenGuardGuard],
                data: {
                  forLanguages: ['de'],
                  group: 'shuumme-panel-group',
                  myAccountLinkActive: 1,
                  meta: {
                    title: 'loyalty_program',
                  }
                }
              },
              {
                path: '',
                component: ShuummeComponent,
                canActivate: [LoyaltyProgramGuardGuard],
                children: [
                  {
                    path: 'behandlungen-massagen',
                    component: DiscoverRelaxAndBeautyComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['de'],
                      group: 'treatments-massages-group',
                      myAccountLinkActive: 1,
                      especiallyForYouLinkActive: 1,
                      meta: {
                        title: 'discover_relax_and_beauty',
                      }
                    },
                  },
                  {
                    path: 'restaurant-bar',
                    component: HungryComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['de'],
                      group: 'restaurant-bar-group',
                      myAccountLinkActive: 1,
                      especiallyForYouLinkActive: 2,
                      meta: {
                        title: 'hungry',
                      }
                    },
                  },
                  {
                    path: 'fitness',
                    component: RestoreVitalForcesComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['de'],
                      group: 'fitness-group',
                      myAccountLinkActive: 1,
                      especiallyForYouLinkActive: 3,
                      meta: {
                        title: 'restore_vital_forces',
                      }
                    },
                  },
                  {
                    path: 'erleichterungen',
                    component: FacilitiesComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['de'],
                      group: 'facilities-group',
                      myAccountLinkActive: 1,
                      especiallyForYouLinkActive: 4,
                      meta: {
                        title: 'facilities_title',
                      }
                    },
                  },
                  {
                    path: 'wichtige-informationen',
                    component: UsefulInformationComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['de'],
                      group: 'useful-information-group',
                      myAccountLinkActive: 1,
                      especiallyForYouLinkActive: 5,
                      meta: {
                        title: 'useful_information',
                      }
                    },
                  },
                  {
                    path: 'benachrichtigungen',
                    component: NotificationsComponent,
                    canActivate: [LangGuard],
                    data: {
                      forLanguages: ['de'],
                      group: 'notifications-panel-group',
                      myAccountLinkActive: 1,
                      meta: {
                        title: 'notifications'
                      }
                    }
                  }
                ]
              }
            ]
          }
        ]
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class HomeReservationsRoutingModule { }

export {
  routes
};
