import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'hyphenToLi'
})
export class HyphenToLi implements PipeTransform {

  transform(value: string): string {
    if ( value ) {
      let newValue = value.replace(/(^- .+)(\n)?/gm, '<li>$1</li>'); // "- " na <li>- </li>
      newValue = newValue.replace(/<li>- /gm, '<li>'); // <li>- </li> na <li></li>

      let valueWithUL = newValue.replace(/(\n<li>.+)/gm, '<ul>$1'); // dodajemy ul na poczatku pierwszego li
      valueWithUL = valueWithUL.replace(/<ul>\n/gm, '<ul>'); // usunięcie znaku nowej lini
      valueWithUL = valueWithUL.replace(/<\/li>\n/gm, '</li></ul>\n\n'); // zakonczenie ul

      return valueWithUL;
    }

    return value;
  }

}
