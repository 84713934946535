import { Injectable } from '@angular/core';
import { ICertificate } from '@interfaces/ICertificate';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {
  private baseCertificates: ICertificate[] = [];

  private foodCertificates: ICertificate[] = [];
  private carrerCertificates: ICertificate[] = [];

  constructor() {
    this.baseCertificates.push({
      image: {
        image: '/assets/images/certificates/lart-2015.png',
        image2x: '/assets/images/certificates/lart-2015.png',
        alt: 'certificates_l_art'
      },
      title: 'certificates_l_art',
      description: 'certificates_third_place',
      years: [ '2015' ]
    }, {
      image: {
        image: '/assets/images/certificates/lart-2014.png',
        image2x: '/assets/images/certificates/lart-2014.png',
        alt: 'certificates_l_art'
      },
      title: 'certificates_l_art',
      description: 'certificates_first_place',
      years: [ '2014' ]
    }, {
      image: {
        image: '/assets/images/certificates/kulinarny-pucha.png',
        image2x: '/assets/images/certificates/kulinarny-pucha@2x.png',
        alt: 'certificates_culinary'
      },
      title: 'certificates_culinary',
      description: 'certificates_second_place',
      years: [ '2014' ]
    }, {
      image: {
        image: '/assets/images/certificates/wine_spectator.png',
        image2x: '/assets/images/certificates/wine_spectator@2x.png',
        alt: 'certificates_wine_spectator'
      },
      title: 'certificates_wine_spectator',
      description: 'certificates_wine_spectator_desc',
      years: [ '2018' ]
    }, {
      image: {
        image: '/assets/images/certificates/poland100best.png',
        image2x: '/assets/images/certificates/poland100best@2x.png',
        alt: 'certificates_poland_best'
      },
      title: 'certificates_poland_best',
      description: 'certificates_poland_best_desc',
      years: [ '2015' ]
    }, {
      image: {
        image: '/assets/images/certificates/chef_culinary.png',
        image2x: '/assets/images/certificates/chef_culinary@2x.png',
        alt: 'certificates_culinary'
      },
      title: 'certificates_culinary',
      description: 'certificates_third_place',
      years: [ '2015' ]
    }, {
      image: {
        image: '/assets/images/certificates/gault_millau_double.png',
        image2x: '/assets/images/certificates/gault_millau_double@2x.png',
        alt: 'certificates_gault'
      },
      title: 'certificates_gault',
      description: 'certificates_gault_double_desc',
      years: [ '2017', '2018', '2019', '2020' ]
    }, {
      image: {
        image: '/assets/images/certificates/gault_millau.png',
        image2x: '/assets/images/certificates/gault_millau@2x.png',
        alt: 'certificates_gault'
      },
      title: 'certificates_gault',
      description: 'certificates_gault_desc',
      years: [ '2016' ]
    }, {
      image: {
        image: '/assets/images/certificates/wine_food_noble.png',
        image2x: '/assets/images/certificates/wine_food_noble@2x.png',
        alt: 'certificates_wine'
      },
      title: 'certificates_wine',
      description: 'certificates_wine_desc',
      years: [ '2018' ]
    }, {
      image: {
        image: '/assets/images/certificates/shuum_psa.png',
        image2x: '/assets/images/certificates/shuum_psa@2x.png',
        alt: 'certificates_psa'
      },
      title: 'certificates_psa',
      description: '',
      years: [ '2023' ]
    });

    this.foodCertificates = this.baseCertificates;

    this.carrerCertificates.push({
      image: {
        image: '/assets/images/certificates/travelers.png',
        image2x: '/assets/images/certificates/travelers@2x.png',
        alt: 'certificates_travelers'
      },
      title: 'certificates_travelers',
      years: [ '2019' ]
    }, {
      image: {
        image: '/assets/images/wellness_page/certificate.png',
        image2x: '/assets/images/wellness_page/certificate@2x.png',
        alt: 'certificates_wellness'
      },
      title: 'certificates_wellness',
      description: 'certificates_wellness_desc'
    }, {
      image: {
        image: '/assets/images/certificates/slow-road.png',
        image2x: '/assets/images/certificates/slow-road@2x.png',
        alt: 'certificates_slow_road'
      },
      title: 'certificates_slow_road',
      description: 'certificates_slow_road_desc'
    });

    this.carrerCertificates = this.carrerCertificates.concat(this.baseCertificates);
  }

  public getFoodCertificates(): ICertificate[] {
    return this.foodCertificates;
  }

  public getCareerCertificates(): ICertificate[] {
    return this.carrerCertificates;
  }
}
