import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import IPrice from '@interfaces/IPrice';
import ConfigService from '@config/ConfigService';
import IPriceWithAdvance from '@interfaces/IPriceWithAdvance';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit, OnChanges {

  priceValue: IPrice;
  
  @Input() price: IPriceWithAdvance;
  @Input() addPrice?: IPrice = null;
  @Input() size?: string;
  @Input() small?: boolean;
  @Input() medium?: boolean;
  @Input() verySmall?: boolean;
  @Input() priceFrom?: boolean;
  @Input() from?: boolean;
  @Input() regularPrice?: number;

  constructor() { }

  ngOnInit() {
    if ( typeof this.small !== 'undefined' ) {
      if ( this.small !== false ) {
        this.small = true;
      }
    }

    if ( typeof this.medium !== 'undefined' ) {
      if ( this.medium !== false ) {
        this.medium = true;
      }
    }

    if ( typeof this.priceFrom !== 'undefined' ) {
      if ( this.priceFrom !== false ) {
        this.priceFrom = true;
      }
    }

    if ( typeof this.from !== 'undefined' ) {
      if ( this.from !== false ) {
        this.from = true;
      }
    }

    if ( typeof this.price !== 'undefined' ) {
      if ( this.price ) {
        if ( !this.price.currency ) {
          this.price.currency = ConfigService.getCurrentCurrency();
        }
      }
    }

    // this.addPriceToPrice();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( typeof changes.price !== 'undefined' ) {
      if ( typeof this.price !== 'undefined' ) {
        if ( this.price ) {
          if ( !this.price.currency ) {
            this.price.currency = ConfigService.getCurrentCurrency();
          }
          this.priceValue = this.price;
          this.addPriceToPrice();
        }
      }
    }
  }

  addPriceToPrice(): void {
    if ( typeof this.addPrice !== 'undefined' && typeof this.price !== 'undefined' ) {
      if ( this.addPrice && this.price ) {
        if ( typeof this.addPrice.value !== 'undefined' && typeof this.price.value !== 'undefined' ) {
          // @ts-ignore
          this.priceValue.value = this.price.value + this.addPrice.value;
        }
      }
    }
  }
}
