import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import IOrder from '@interfaces/IOrder';
import IFilter from '@interfaces/IFilter';

export abstract class MainService {
  listUrl = '';
  detailsUrl = '';

  protected constructor( protected http: HttpClient ) {}

  public getList( filters: IFilter[] = [], orders: IOrder[] = [], limit: number = 20, page?: number, getHeaders: boolean = false ): Observable<any> {
    let params = new HttpParams();
    let headers = new HttpHeaders();
    let observe = null;

    params = this.setFilters( params, filters );
    params = this.setLimit( params, limit, page );

    headers = this.setOrder( headers, orders );

    if (getHeaders) {
      observe = 'response';
    }

    return this.http.get(
      `${environment.shuumApiHost}${this.listUrl}`, {
        headers,
        params,
        observe
      }
    );
  }

  public getDetails( slug: string ): Observable<any> {
    return this.http.get(
      `${environment.shuumApiHost}${this.detailsUrl}/${slug}`
    );
  }

  public setLimit( params: HttpParams, limit: number, page?: number ): HttpParams {
    params = params.set('limit', limit.toString());

    if ( page ) {
      params = params.set('page', page.toString());
    }

    return params;
  }

  public setFilters( params: HttpParams, filters: IFilter[] = [] ): HttpParams {

    filters.forEach( filter => {
      let value = filter.value;

      if ( typeof value === 'object' ) {
        let arrayPostFix = '';

        if ( value.length > 1 ) {
          arrayPostFix = '[]';
        }

        value.forEach( v => {
          if ( typeof v === 'number' ) {
            v = v.toString();
          }

          params = params.append( filter.key + arrayPostFix, v);
        });
      }
      else {
        if ( typeof value === 'number' ) {
          value = value.toString();
        }

        params = params.set( filter.key, value);
      }
    });

    return params;
  }

  public setOrder(headers: HttpHeaders, orders: IOrder[] = [] ): HttpHeaders {
    const orderFields = [];
    const orderTypes = [];

    orders.forEach( order => {
      orderFields.push( order.field );
      orderTypes.push( order.type );
    });

    headers = headers.set('Order-Field', orderFields.join(', '));
    headers = headers.set('Order-Type', orderTypes.join(', '));

    return headers;
  }
}
