<app-section-introduction [text]="'loyalty_program_desc'"></app-section-introduction>
<div class="container mobile-padding">
  <div class="pt-tera">
    <app-simple-gallery [images]="galleryImages"></app-simple-gallery>
  </div>
  <div class="row pt-kilo pb-giga">
    <div class="col-xl-10 offset-xl-1">
      <app-section-header header="hungry" subHeader="check_menu_and_book_table"></app-section-header>
    </div>
    <div class="col-md-5 col-xl-4 offset-xl-1 pt-deca">
      <app-text-box [header]="'ala_cart_opening_hours' | translate" [button]="{ url: alaCarteMenuFile?.value|apiUrl, text: 'see_menu' }">
        <p>13:00 - 23:00</p>
        <p>{{ 'accepting_orders_to' | translate }} 22:30</p>
        <p>{{ 'tel' | translate }} <a href="tel:+48943554020">+48 94 355 4020</a></p>
        <p>Tadeusza Kościuszki 17,</p>
        <p>78-100 Kołobrzeg</p>
      </app-text-box>
    </div>
    <div class="col-md-4 col-xl-3 pt-kilo pt-md-deca">
      <app-text-box [header]="'lobby_bar' | translate" [button]="{ link: '/assets/files/lobby_bar.pdf', target: '_blank', text: 'see_menu' }">
        <p>11:00 - 23:00</p>
        <p>{{ 'every_day' | translate }}</p>
        <p>{{ 'tel' | translate }} <a href="tel:+48943554000">+48 94 355 4000</a></p>
        <p>Tadeusza Kościuszki 17,</p>
        <p>78-100 Kołobrzeg</p>
      </app-text-box>
    </div>
    <div class="col-md-3 pt-kilo pt-md-deca">
      <app-text-box [header]="'room_service' | translate">
        <p>7:30 - 22:00</p>
        <p>{{ 'every_day' | translate }}</p>
        <p>{{ 'tel' | translate }} <a href="tel:+48943554020">+48 94 355 4020</a>, {{ 'room_service_tel_ext' | translate }}</p>
      </app-text-box>
    </div>
  </div>
  <div class="row pb-giga">
    <div class="col-xl-10 offset-xl-1">
      <app-menu>
        <h4 class="font-14 font-300 mobile-padding" header>{{ 'proposed' | translate }}</h4>
      </app-menu>
    </div>
  </div>
</div>

<app-contact-form [bookingType]="bookingType"></app-contact-form>
