import {Component, Input, OnInit} from '@angular/core';
import ConfigService from "@config/ConfigService";

@Component({
  selector: 'app-text-centered-image',
  templateUrl: './text-centered-image.component.html',
  styleUrls: ['./text-centered-image.component.scss']
})
export class TextCenteredImageComponent implements OnInit {
  public hasSrcSetSupport: boolean;

  @Input() header: string;
  @Input() image: string;
  @Input() image2x: string;
  @Input() imageAlt: string;
  @Input() imgMaxHeight: string;
  @Input() description: string;

  constructor() {
    this.hasSrcSetSupport = ConfigService.getHasSrcSetSupport();
  }

  ngOnInit() {
  }

}
