import { Component, Input, OnInit } from '@angular/core';
import ConfigService from '@config/ConfigService';

@Component({
  selector: 'app-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.scss']
})
export class GiftCardComponent implements OnInit {
  hasSrcSetSupport: boolean;

  @Input() header: string;
  @Input() description: string;

  constructor() { }

  ngOnInit(): void {
    this.hasSrcSetSupport = ConfigService.getHasSrcSetSupport();
  }

}
