import {Component, Input, OnInit, Inject, PLATFORM_ID, OnDestroy} from '@angular/core';
import ICarouselImages from '@interfaces/ICarouselImages';
import CarouselImageEnum from '@enums/CarouselImageEnum';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-images-carousel',
  templateUrl: './images-carousel.component.html',
  styleUrls: ['./images-carousel.component.scss']
})
export class ImagesCarouselComponent implements OnInit, OnDestroy {
  activeIndex = 0;
  maxIndexNo = 0;
  carouselImageEnum: typeof CarouselImageEnum;

  private interval: any;
  private intervalTiming = 3000;

  @Input() images: ICarouselImages[] = [];

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  ngOnInit() {
    this.carouselImageEnum = CarouselImageEnum;

    if (this.images.length > 0) {
      this.maxIndexNo = this.images.length - 1;
    }

    if ( isPlatformBrowser(this.platformId) ) {
      this.clearAndSetInterval();
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  activateIndex(e: MouseEvent, index: number) {
    e.preventDefault();
    this.clearAndSetInterval();

    this.activeIndex = index;
  }

  activatePrevIndex() {
    this.clearAndSetInterval();

    if (this.activeIndex === 0) {
      this.activeIndex = this.maxIndexNo;
    } else {
      this.activeIndex--;
    }
  }

  activateNextIndex() {
    this.clearAndSetInterval();

    if (this.activeIndex === this.maxIndexNo) {
      this.activeIndex = 0;
    } else {
      this.activeIndex++;
    }
  }

  clearAndSetInterval() {
    clearInterval(this.interval);

    this.interval = setInterval(() => {
      this.activateNextIndex();
    }, this.intervalTiming);
  }
}
