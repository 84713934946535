import { Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

export class InputField implements OnInit, OnChanges {
  @Input() group: any;
  @Input() control: any;
  @Input() inputId: any;
  @Input() fieldName?: string;
  @Input() apiValidationMsg: string;
  @Input() disabled = false;
  @Input() validationMsg: string[] = [];
  @Input() fieldNameSecondary?: string;
  @Input() placeholder = '';

  @Output() blur?: EventEmitter<null> = new EventEmitter();

  validateField() {
    const field = this.group.get(this.control);
    field.markAsTouched({ onlySelf: true });
    field.updateValueAndValidity();

    this.changeValidationMsg(this.group.get(this.control).errors);
  }

  onBlur( error: any ) {
    if ( this.blur ) {
      this.blur.emit();
    }

    this.changeValidationMsg(error);
  }

  ngOnInit() {
    /**
     * timeout potrzebny w sytuacji gdy
     * użytkownik zmienia vat na innym urzadzeniu
     */
    setTimeout(() => {
      if ( this.disabled ) {
        this.group.get(this.control).disable();
      } else {
        this.group.get(this.control).enable();
    }});
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      if ( this.disabled && !this.group.get(this.control).disabled ) {
        this.group.get(this.control).disable();
      } else if ( !this.disabled && this.group.get(this.control).disabled ) {
        this.group.get(this.control).enable();
      }
    });
  }

  changeValidationMsg(error: any) {
    this.apiValidationMsg = '';

    if ( error ) {
      if ( error.required || error.checkbox ) {
        this.validationMsg = [ 'validation_required' ];
        return;
      } else if ( error.minlength ) {
        this.validationMsg = [ 'validation_min_signs', error.minlength.requiredLength.toString() ];
        return;
      } else if ( error.pattern ) {
        this.validationMsg = [ 'validation_invalid' ];
      } else if ( error.maxlength ) {
        this.validationMsg = [ 'validation_max_signs', error.maxlength.requiredLength.toString() ];
      } else {
        this.validationMsg = error;
      }

      return;
    }

    this.validationMsg = [];
  }
}
