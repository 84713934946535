import {Component, Input, OnInit} from '@angular/core';
import ConfigService from '../../_config/ConfigService';
import {RouteMapHelper} from '../../_helpers/RouteMapHelper';
import { Router } from '@angular/router';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {
  public currentLang: string;
  public routeLang: string;

  @Input() to: string;
  @Input() class: string;
  @Input() scrollToElem?: string = "";

  constructor( private routeHelper: RouteMapHelper, private router: Router, private scrollToService: ScrollToService ) {
    this.currentLang = ConfigService.getCurrentLang();
  }

  ngOnInit() {
    this.to = this.to.replace(':lang', this.currentLang);

    const splitedTo = this.to.split('/');
    splitedTo.forEach( value => {
      if ( value ) {
        const route = this.routeHelper.getRoute(value, this.currentLang);

        if (route && value) {
          this.to = this.to.replace(value, route);
        }
      }
    });
  }

  goTo(e) {
    e.preventDefault();
    
    if(this.scrollToElem && typeof this.scrollToElem !== 'undefined' && this.scrollToElem.length > 0) {
      this.scrollToService.scrollTo({target: this.scrollToElem})
    } else {
      this.router.navigate([this.to])
    }
  }

}
