<app-navigation></app-navigation>
<app-scroll-to-top></app-scroll-to-top>
<router-outlet></router-outlet>
<app-page-loader [show]="isSpinnerVisibile$"></app-page-loader>
<app-item-loader></app-item-loader>
<app-appla></app-appla>
<app-cookie-bar></app-cookie-bar>
<app-popup-content
  #popupError500
  [hideSecondPart]="true"
  [inPlace]="true"
>
  <div content1>
    <h3 class="pb-mega pt-deci text-center">{{ 'error_500_header'|translate }}</h3>
    <p class="pb-deci smaller-text weight-300 text-center inside-links" [innerHTML]="'error_500_description'|translate"></p>
  </div>
</app-popup-content>
<app-notification-popup></app-notification-popup>
<app-loyalty-program-popup></app-loyalty-program-popup>
<app-booking-bar></app-booking-bar>