<div class="pt-xl-zetta pt-xl-yotta container wide">
  <div class="address row pb-mega">
    <div class="container mobile-padding">
      <div class="offset-xl-1">
        <h2 class="pt-exa heading">{{header|translate}}</h2>
        <div class="pt-giga">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
    <div class="map col-xl-6 before-text pt-peta pt-sm-exa pt-xl-zero">
      <h6 class="text-acumin-thin">{{textAboveMap}}</h6>
      <agm-map [latitude]="lat" [longitude]="long" [zoom]="zoom" [styles]="styles" [disableDefaultUI]="true">
        <agm-marker
          [latitude]="lat"
          [longitude]="long"
          [iconUrl]="'/assets/images/map_pin_big.svg'"
        ></agm-marker>
      </agm-map>
    </div>
  </div>
</div>
