<app-banner
  header="career"
  description="career_description"
  background="/assets/images/career_page/IMG_4032.jpg"
  background2x="/assets/images/career_page/IMG_4032@2x.jpg"
  isRightStickedBtn="true"
  btnScrollTo="right-sticked-btn-scroll"
>
  <app-line-btn
    class="h4"
    scrollTo="career"
    bottomButton
  >
    <span>{{ 'know_more'|translate }}</span>
  </app-line-btn>
  <button
    class="btn btn-white-background mt-hecto mt-sm-zero"
    rightStickedButton
  >
    <span>{{ 'career_check_offers'|translate }}</span>
  </button>
</app-banner>

<div id="career" class="position-relative" [@routeAnimation]="o.isActivated ? o.activatedRoute : ''">
  <router-outlet #o="outlet"></router-outlet>
</div>
