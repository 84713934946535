import {Component, Input, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {fadeDelayAnimation, fadeAnimation} from '@helpers/animations';
import {LayoutService} from '@services/layout.service';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
  animations: [
    fadeAnimation,
    fadeDelayAnimation
  ]
})
export class PageLoaderComponent extends SubscribeHelper implements OnInit {
  public showLoader: boolean;
  public isBannerLoaded = false;

  @Input() show: Observable<boolean>;

  constructor(
    private layoutService: LayoutService
  ) {
    super();
  }

  ngOnInit() {
    this.show
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe( val => {
        this.showLoader = val;
      });

    this.layoutService.isBannerLoaded$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe( val => {
        this.isBannerLoaded = val;
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
