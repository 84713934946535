import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-after-before-grey',
  templateUrl: './after-before-grey.component.html',
  styleUrls: ['./after-before-grey.component.scss']
})
export class AfterBeforeGreyComponent implements OnInit {
  @Input() width: string;
  @Input() height: string;
  @Input() left: string;
  @Input() right: string;
  @Input() top: string;
  @Input() bottom: string;
  @Input() background: string;
  @Input() additionalClasses: string;

  constructor() { }

  ngOnInit() {
  }

}
