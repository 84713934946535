<div id="banner" class="banner" #banner>
  <div id="scroll-trigger" class="scroll-trigger"></div>
  <div
    *ngIf="bg"
    class="banner-image scroll-banner"
    [ngClass]="{ 'with-overlay': withOverlay }"
    [style.background-image]="bg ? 'url('+ bg +')' : ''"
  >
    <img *ngIf="bg" [src]="bg" alt="banner" class="d-none" (load)="imgLoaded($event)" />
  </div>
  <div
    *ngIf="video"
    class="banner-video"
    [ngClass]="{ 'with-overlay': withOverlay }"
  >
    <video
      preload="none"
      [autoplay]="true"
      [muted]="true"
      webkit-playsinline="true"
      playsinline="true"
      [loop]="true"
      [poster]="video?.fallbackImage">
      <source [src]="video?.webm" type="video/webm">
      <source [src]="video?.mp4" type="video/mp4">
      <source [src]="video?.ogg" type="video/ogg">
      Your browser does not support the video tag.
    </video>
  </div>
  <!--  <div class="mobile-padding-container">-->
  <div class="container h-100 mobile-padding">
    <div
      class="row h-100 align-content-center align-items-center banner-text-container"
      [ngClass]="{ 'justify-content-center': center }"
    >
      <div
        [ngClass]="{ 'offset-md-1': !center }"
        class="col-12 col-md-8 col-lg-7 col-xl-6"
      >
        <div
          [class]="'banner-content ' + textColor "
          [ngClass]="{ 'text-center mx-auto': center, 'fullWidth': fullWidth }"
        >
          <h1
            *ngIf="header"
            [innerHTML]="header|translate|newLineBreak|removeWidows"
          ></h1>
          <p
            class="pb-hecto"
            [class]="(header ? 'pt-hecto' : '') + (isTextLong ? ' text-smaller' : '')"
            [innerHTML]="description|translate|newLineBreak|removeWidows"
            *ngIf="description"
          ></p>

          <div class="actions">
            <ng-content></ng-content>
          </div>

          <ng-content select="[userBanner]"></ng-content>
        </div>
      </div>
      <!--      </div>-->
      <div class="line-btn bottom-btn">
        <ng-content select="[bottomButton]"></ng-content>
      </div>

      <div class="right-sticked-btn" id="right-sticked-btn" #rightBtn>
        <div
          *ngIf="isButton"
          (click)="scrollToTarget($event)"
        >
          <ng-content select="[rightStickedButton]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="scroll-to-banner" #scrollToBanner></div>
