import { Component, OnInit } from '@angular/core';
import {I18nRoutePipe} from '../../../../_pipes/i18n-route.pipe';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '@services/auth/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blogostan-login',
  templateUrl: './blogostan-login.component.html',
  styleUrls: ['./blogostan-login.component.scss']
})
export class BlogostanLoginComponent implements OnInit {
  googleRedirect: string;

  constructor(
    private router: Router,
    private i18nRoute: I18nRoutePipe,
    private userService: UserService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams.type === 'buy-access') {
      this.googleRedirect = ':lang/blogostan&type=buy-access';
    } else {
      this.googleRedirect = ':lang/blogostan';
    }
  }

  back() {
    window.location.href = environment.blogostanUrl;
  }

  onLogin() {
    if (this.route.snapshot.queryParams.type === 'buy-access') {
      const linkTo = this.i18nRoute.transform('/:lang/blogostan');
      this.router.navigate([linkTo]);
    } else {
      if ( typeof window !== 'undefined' ) {
        if (window.parent) {
          const user = this.userService.userValue;
          window.parent.location.href = `${environment.blogostganAuthUrl}${user.token}`;
        }
      }
    }
  }

}
