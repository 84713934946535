import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newLineBreak'
})
export class NewLineBreakPipe implements PipeTransform {

  transform(value: any): string {
    if ( value ) {
      return value.replace(/\n/g, '<br/>');
    }

    return value;
  }

}
