import {Component, OnDestroy, OnInit} from '@angular/core';
import ICarouselImages from '@interfaces/ICarouselImages';
import CarouselImageEnum from '@enums/CarouselImageEnum';
import {IImage} from '@interfaces/IImage';
import ConfigService from '@config/ConfigService';
import { CertificateService } from '@services/certificate.service';
import { ICertificate } from '@interfaces/ICertificate';
import ApiConfigurationEndpointEnum from '@enums/ApiConfigurationEndpointEnum';
import {takeUntil} from 'rxjs/operators';
import {catchError} from 'rxjs/internal/operators';
import {IConfiguration} from '@interfaces/IConfiguration';
import {ConfigurationService} from '@services/configuration.service';
import {SubscribeHelper} from '@helpers/SubscribeHelper';

@Component({
  selector: 'app-restaurant-a-la-carte',
  templateUrl: './restaurant-a-la-carte.component.html',
  styleUrls: ['./restaurant-a-la-carte.component.scss']
})
export class RestaurantALaCarteComponent extends SubscribeHelper implements OnInit, OnDestroy {
  public hasSrcSetSupport: boolean;
  galleryImages: IImage[] = [];
  carouselImages: ICarouselImages[] = [];
  history: any[];
  foodCertificates: ICertificate[] = [];
  alaCarteMenuFile: IConfiguration = null;

  constructor(
    private certificateService: CertificateService,
    private apiConfiguration: ConfigurationService
  ) {
    super();

    this.hasSrcSetSupport = ConfigService.getHasSrcSetSupport();
  }

  ngOnInit() {
    this.foodCertificates = this.certificateService.getFoodCertificates();

    this.setCarouselImages();
    this.setGalleryImages();
    this.getAlaCarteMenuFile();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  setCarouselImages() {
    const path = '../../../assets/images/carousel/food/';

    this.carouselImages.push({
      image: {
        image: path + '01.jpg',
        image2x: path + '01@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '02.jpg',
        image2x: path + '02@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '03.jpg',
        image2x: path + '03@2x.jpg',
      },
      type: CarouselImageEnum.Wide
    });
  }

  setGalleryImages() {
    const path = '../../../assets/images/gallery/food_wine';

    for (let i = 1; i < 6; i++) {
      for (let j = 1; j < 6; j++) {
        this.galleryImages.push({
          image: `${path}/${i}_slide/${j}.jpg`,
          image2x: `${path}/${i}_slide/${j}.jpg`,
        });
      }
    }
  }

  getAlaCarteMenuFile(): void {
    this.apiConfiguration
      .getConfiguration(ApiConfigurationEndpointEnum.AlaCarteMenu)
      .pipe(
        takeUntil(this.componentDestroyed$),
        catchError(err => {
          this.alaCarteMenuFile = { id: null, value: '', iso: '', name: '' };
          return err;
        })
      )
      .subscribe((res: IConfiguration) => {
        this.alaCarteMenuFile = res;
      });
  }
}
