<app-section-introduction [text]="'loyalty_program_desc'"></app-section-introduction>
<div class="container mobile-padding">
  <div class="row">
    <div class="col-xl-10 offset-xl-1">
      <app-section-header header="discover_relax_and_beauty_header" subHeader="discover_relax_and_beauty_desc"></app-section-header>
      <div *ngIf="treatments?.proposed.length > 0"  class="discover-relax-and-beauty-slider pt-kilo">
        <app-item-slider [itemTpl]="itemTpl" [items]="treatments?.proposed" [itemsPerPage]="1" [constantItemsPerPage]="true"
                         [loop]="true" [columnClass]="'col-12 mobile-slider'" [arrowsHorizontalPosition]="6" [itemPadding]="'55px'">
        </app-item-slider>

        <ng-template let-item #itemTpl>
          <app-text-offer
            [image]="item?.photo?.path|apiUrl"
            [imageAlt]="item?.photo?.alt"
            [isBestseller]="item.isBestseller"
            [isNew]="item.isNew"
            [link]="'/:lang/wellness/relax/' + item?.slug|i18nRoute"
            [itemId]="item?.id"
            isWellness
          >
            <div>
              <h3
                [innerHTML]="item?.name|removeWidows"
              ></h3>
              <p
                class="pt-deci tiny"
                [innerHTML]="item?.description|removeWidows"
              ></p>
            </div>
            <div class="pt-hecto">
              <a
                (click)="setElementInfo($event, item?.name)"
                class="btn btn-bordered on-white mt-2"
              >
                {{ 'make_appointment'|translate }}
              </a>
            </div>
          </app-text-offer>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row discover-relax-and-beauty-treatments">
    <ng-container *ngIf="treatments?.bestseller as bestseller">
      <div
        class="col-12 col-lg-6 col-xl-5 offset-xl-1 pt-kilo"
      >
        <h4 class="mb-deca">
          {{ 'most_popular_treatment' | translate }}
        </h4>
        <app-text-offer
          [image]="bestseller?.photo?.path|apiUrl"
          [imageAlt]="bestseller?.photo?.alt"
          [isBestseller]="bestseller.isBestseller"
          [isNew]="bestseller.isNew"
          [link]="'/:lang/wellness/relax/' + bestseller?.slug|i18nRoute"
          [itemId]="bestseller?.id"
          isWellness
        >
          <div>
            <h3
              [innerHTML]="bestseller?.name|removeWidows"
            ></h3>
            <p
              class="pt-deci tiny"
              [innerHTML]="bestseller?.description|removeWidows"
            ></p>
          </div>
          <div class="pt-hecto">
            <a
              (click)="setElementInfo($event, bestseller?.name)"
              class="btn btn-bordered on-white mt-2"
            >
              {{ 'make_appointment'|translate }}
            </a>
          </div>
        </app-text-offer>
      </div>
    </ng-container>
    <ng-container *ngIf="treatments?.new as new">
      <div
        class="col-12 col-lg-6 col-xl-5 pt-kilo"
      >
        <h4 class="mb-deca">
          {{ 'see_whats_new' | translate }}
        </h4>
        <app-text-offer
          [image]="new?.photo?.path|apiUrl"
          [imageAlt]="new?.photo?.alt"
          [isBestseller]="new.isBestseller"
          [isNew]="new.isNew"
          [link]="'/:lang/wellness/relax/' + new?.slug|i18nRoute"
          [itemId]="new?.id"
          isWellness
        >
          <div>
            <h3
              [innerHTML]="new?.name|removeWidows"
            ></h3>
            <p
              class="pt-deci tiny"
              [innerHTML]="new?.description|removeWidows"
            ></p>
          </div>
          <div class="pt-hecto">
            <a
              (click)="setElementInfo($event, new?.name)"
              class="btn btn-bordered on-white mt-2"
            >
              {{ 'make_appointment'|translate }}
            </a>
          </div>
        </app-text-offer>
      </div>
    </ng-container>
  </div>
  <div class="row pb-sm-giga">
    <div class="col-12 text-center pt-kilo">
      <app-wellness-menu-btn text="download_wellness_menu"></app-wellness-menu-btn>
    </div>
  </div>
</div>

<app-contact-form [bookingType]="bookingType" [bookingNames]="treatmentNames"></app-contact-form>
