import { Component, Inject, OnInit } from '@angular/core';
import {ReservationDataService} from '@services/reservations/reservation-data.service';
import IReservationRoom from '../../_interfaces/IReservationRoom';
import IReservationSelectedExtra from '../../_interfaces/IReservationSelectedExtra';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {takeUntil} from 'rxjs/operators';
import {ReservationService} from '../../_services/reservation.service';
import IReservationExtraTab from '../../_interfaces/IReservationExtraTab';
import IReservationDate from '../../_interfaces/IReservationDate';
import * as moment from 'moment';
import { UserService } from '@services/auth/user.service';
import * as cloneDeep from 'lodash/cloneDeep';
import { ReservationStepService } from '@services/reservations/reservation-step.service';
import IReservationStep from '../../_interfaces/IReservationStep';
import ReservationStepEnum from '../../_enums/ReservationStepEnum';
import { ValidationEmitterService } from '../../_services/validation-emitter.service';
import { DOCUMENT } from '@angular/common';
import IReservationExtras from '../../_interfaces/IReservationExtras';

@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss']
})
export class StepThreeComponent extends SubscribeHelper implements OnInit {
  rooms: IReservationRoom[] = [];
  roomsTabsIndexes = {};
  activeRoomIndex = null;
  extras: IReservationExtras;

  nextStepMsg: string = null;

  constructor(
    private reservationData: ReservationDataService,
    private reservationService: ReservationService,
    private stepService: ReservationStepService,
    private validationEmitterService: ValidationEmitterService,
    @Inject(DOCUMENT) private document
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToRooms();
    this.subscribeToExtras();
    this.subscribeToStepsChange();
    this.subscribeToStepFour();
  }

  subscribeToRooms(): void {
    this.reservationData
      .getRooms()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe( rooms => {
        /**
         * po wejściu w krok 3, zaznaczeniu dodatku,
         * przejściu na krok 1 i wejściu znowu w 3,
         * odkliknięciu dodatku, wykonywał się
         * onInit komponentów app-collapse
         */
        if ( this.rooms.length === 0 ) {
          this.rooms = rooms;
        } else {
          this.rooms.forEach((room, index) => {
            room.extras = rooms[index].extras;
          });
        }

        if ( this.activeRoomIndex === null && this.rooms.length === 1 ) {
          this.activeRoomIndex = 0;
        }
      });
  }

  subscribeToStepsChange() {
    this.stepService
      .getSteps()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((steps: IReservationStep[]) => {
        if ( steps.find(step => step.id === ReservationStepEnum.III).isValid ) {
          this.nextStepMsg = null;
        }
      });
  }

  subscribeToExtras(): void {
    const date: IReservationDate = this.reservationData.data.date$.value;
    const rooms: IReservationRoom[] = this.reservationData.data.rooms$.value;

    if ( typeof date.from !== 'undefined' && typeof date.to !== 'undefined' ) {
      if ( date.from && date.to ) {
        const dateFrom = moment([date.from.year, date.from.month - 1, date.from.day]).format('YYYY-MM-DD');
        const dateTo = moment([date.to.year, date.to.month - 1, date.to.day]).format('YYYY-MM-DD');

        this.reservationService.getExtras( dateFrom, dateTo, rooms ).subscribe(data => {
          this.extras = data;

          this.reservationData.removeNotExistingExtras();
        });
      }
    }
  }

  setActiveRoomIndex( index: number ) {
    this.activeRoomIndex = this.activeRoomIndex === index ? null : index;
  }

  setActiveRoomTabIndex( roomIndex: number, index: number ) {
    this.roomsTabsIndexes[roomIndex] = index;
  }

  subscribeToStepFour() {
    this.validationEmitterService
      .getThirdStepValidationToggle()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(res => {
        this.nextStepMsg = res;

        if ( typeof window.scrollTo === 'function' ) {
          const supportsNativeSmoothScroll = 'scrollBehavior' in this.document.documentElement.style;

          if ( supportsNativeSmoothScroll ) {
            window.scrollTo({
              top: this.document.documentElement.scrollHeight,
              behavior: 'smooth'
            });
          }
          else {
            window.scrollTo(0, this.document.documentElement.scrollHeight);
          }
        }
      });
  }
}
