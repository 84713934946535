import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'apiUrl'
})
export class ApiUrlPipe implements PipeTransform {

  transform(value: any, isLocal: boolean = false ): string {
    if ( value && !isLocal ) {
      if ( typeof value === 'string' ) {
        if (value.startsWith('/')) {
          value = value.substring(1);
        }
      }

      return environment.imageHost + value;
    }

    return value;
  }

}
