import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IConfiguration } from '@interfaces/IConfiguration';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor( private http: HttpClient ) { }

  public getConfiguration(schedule: string): Observable<IConfiguration> {
    return this.http.get<IConfiguration>(
      `${environment.shuumApiHost}/configurations/${schedule}`
    );
  }
}
