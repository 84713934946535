<div [ngClass]="'img-wrapper-container ' + (classes ? classes : '')">
  <div [ngStyle]="{'width': allImagesWidth}" [className]="(reversed ? 'reversed' : '') + ' img-wrapper'">
    <div
      class="img-left"
      [ngStyle]="{
        'width': widthFront,
        'bottom': frontBottomSpacing,
        'transform': (frontBottomSpacing ? 'translate(0)' : null),
        'margin-left': reversed ? customMargin : '0',
        'margin-right': reversed ? '0' : customMargin,
        'max-width': imageFrontMaxWidth + 'px'
      }"
    >
      <ng-container *ngIf="lazyLoading">
        <img
          [defaultImage]="'/assets/images/placeholders/placeholder235x300.png'"
          [lazyLoad]="hasSrcSetSupport ? (imageFront + ' 1x, ' + imageFront2x + ' 2x') : imageFront"
          [useSrcset]="hasSrcSetSupport"
          [alt]="imageFrontAlt"
        />
      </ng-container>
      <ng-container *ngIf="!lazyLoading">
        <img
          [srcset]="imageFront + ' 1x, ' + imageFront2x + ' 2x'"
          [alt]="imageFrontAlt"
        />
      </ng-container>
    </div>
    <div class="img-right" [ngStyle]="{ 'width': widthBack, 'max-width': imageBackMaxWidth + 'px' }">
      <ng-container *ngIf="lazyLoading">
        <img
          [ngClass]="{ 'd-none d-sm-block': imageMobile }"
          [defaultImage]="'/assets/images/placeholders/placeholder317x444.png'"
          [lazyLoad]="hasSrcSetSupport ? (imageBack + ' 1x, ' + imageBack2x + ' 2x') : imageBack"
          [useSrcset]="hasSrcSetSupport"
          [alt]="imageBackAlt"
        />
        <img
          *ngIf="imageMobile"
          class="d-sm-none"
          [defaultImage]="'/assets/images/placeholders/placeholder377x248.png'"
          [lazyLoad]="hasSrcSetSupport ? (imageMobile + ' 1x, ' + imageMobile + ' 2x') : imageBack"
          [useSrcset]="hasSrcSetSupport"
          [alt]="imageBackAlt"
        />
      </ng-container>
      <ng-container *ngIf="!lazyLoading">
        <img
          [srcset]="imageBack + ' 1x, ' + imageBack + ' 2x'"
          [alt]="imageBackAlt"
        />
      </ng-container>
    </div>
  </div>
</div>

