<div class="nav-user" [class]="class" [ngClass]="{ 'active': active }">
  <div class="nav-login-user" *ngIf="user">
    <a
      (click)="clickHideSubNav($event)"
      [routerLink]="(user?.loyaltyProgram ? '/:lang/my-reservation/shuumme-panel-group' : '/:lang/my-reservation') | i18nRoute"
    >
      <img
        *ngIf="!user?.loyaltyProgram"
        srcset="/assets/images/reservations/user-profile/avatars/profile.png 1x, /assets/images/reservations/user-profile/avatars/profile@2x.png 2x"
        src="/assets/images/reservations/user-profile/avatars/profile.png"
      />
      <svg *ngIf="user?.loyaltyProgram" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5122 13.8789C16.0258 13.8789 18.874 10.9958 18.874 7.43944C18.874 3.88304 16.0258 1 12.5122 1C8.99868 1 6.15039 3.88304 6.15039 7.43944C6.15039 10.9958 8.99868 13.8789 12.5122 13.8789Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
        <path d="M3 25.9914V22.2973C3 19.4712 5.2735 17.1699 8.06553 17.1699H16.9402C19.7322 17.1699 22.0057 19.4712 22.0057 22.2973V25.9914" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
      </svg>
      <span *ngIf="!isShort" class="pl-centi link-bold d-none d-sm-inline-block">{{ user.name }}</span>
    </a>
  </div>
  <div class="nav-btns d-none d-sm-flex" *ngIf="!user && loginVisible && !isShort" [@fadeAnimation] [@.disabled]="!isNavShort">
    <a
      class="btn mr-deci"
      [class]="isNavShort ? 'btn-border-dark' : 'btn-border-white'"
      [routerLink]="'/:lang/my-reservation/authorization-group'|i18nRoute"
    >{{ 'create_account'|translate }}</a>
    <a
      class="btn"
      [class]="isNavShort ? 'btn-dark' : 'btn-white'"
      [routerLink]="'/:lang/my-reservation/authorization-group/sign-in-group'|i18nRoute"
      [queryParams]="{returnUrl: router.url}"
    >{{ 'log_in'|translate }}</a>
  </div>
</div>
