import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {IModal} from '@interfaces/IModal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor( protected http: HttpClient ) {

  }

  public getModal(): Observable<IModal> {
    // @ts-ignore
    return this.http.get(
      `${environment.shuumApiHost}/modal`
    );
  }
}
