import IDateTimezone from '@interfaces/IDateTimezone';
import {RoleEnum} from '@enums/RoleEnum';

export class User {
  id?: number;
  username?: string;
  name?: string;
  lastName?: string;
  email?: string;

  phone?: string;
  address?: string;
  postcode?: string;
  city?: string;
  country?: string;
  company?: ICompany;
  rules?: number[];

  complete?: boolean;
  service?: string;
  refreshToken?: any;
  createdAt?: IDateTimezone;
  updatedAt?: IDateTimezone;
  expiresAt?: IDateTimezone;
  token?: string;
  avatar?: string;
  loyaltyProgram?: number;
  role?: RoleEnum;
  points?: number;
}

export interface ICompany {
  name?: string;
  taxNumber?: string;
  address?: string;
  postcode?: string;
  city?: string;
  country?: string;
  id: string;
}

/**
 * TODO: przenieść do klasy USER
 */
export interface IUser {
  username?: string;
  name?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  address?: string;
  postcode?: string;
  city?: string;
  country?: string;
  company?: ICompany;
  rules?: number[];
  complete?: boolean;
  loyaltyProgram?: number;
  role?: RoleEnum;
  points?: number;
}
