<div class="container">
  <button class="close-btn" (click)="back()">
    <img src="/assets/images/reservations/forms/close-forms.svg" alt="close" />
  </button>
  <div class="row justify-content-center pt-giga pb-giga pl-deci pr-deci pl-sm-zero pr-sm-zero ng-tns-c82-15 bg-light-gray ng-star-inserted">
    <div class="col-12 col-sm-10 col-md-7 col-xxl-6">
      <h3 class="pb-kilo pb-md-kilo text-center">{{ 'buy_access'|translate }}</h3>
      <p class="p-responsive small text-center pb-kilo" [innerHTML]="'buy_access_desc'|translate|newLineBreak|removeWidows"></p>

      <div class="addons" *ngIf="extras$ | async as extras">
        <div class="addon" *ngFor="let extra of extras">
          <ng-container *ngFor="let e of extra.values">
            <div class="d-flex justify-content-between">
              <div class="addon__name">{{ e?.name }}</div><span class="addon__price text-uppercase">{{ e?.price?.value | price }} {{ e?.price?.currency | currencySign }}</span>
            </div>
            <p *ngIf="e?.description" class="addon__desc">{{ e?.description }}</p>
          </ng-container>
        </div>
      </div>

      <div class="text-center pt-mega">
        <button class="btn btn-pay btn-bordered w-100" [ngClass]="{'disabled': processingForPaymentLink}" [disabled]="processingForPaymentLink || !products.length" type="button" (click)="getPaymentLink($event)">
          <span *ngIf="!processingForPaymentLink">{{ 'pay'|translate }}</span>
          <app-loader [size]="16" [opacity]="'1'" [center]="true" *ngIf="processingForPaymentLink"></app-loader>
        </button>
      </div>

      <p class="login-break mt-giga mb-giga">
        <span class="line"></span>
        <span class="text">{{ 'or'|translate }}</span>
      </p>

      <h3 class="pb-kilo pb-md-kilo text-center">{{ 'unblock_access'|translate }}</h3>
      <p class="p-responsive small text-center pb-hecto" [innerHTML]="'unblock_access_desc'|translate|newLineBreak|removeWidows"></p>

      <div class="validation-msg-container summary-top pb-hecto pb-md-hecto">
        <p class="error">{{ errorMsg|translate }}</p>
        <p class="success">{{ successMsg|translate }}</p>
      </div>
      <form [formGroup]="codeForm" class="form" (ngSubmit)="onSubmit()">
        <div class="pb-hecto pb-md-kilo">
          <app-input-text
            inputId="code-input"
            control="code"
            fieldName="form_code"
            [group]="codeForm"
            #input
          ></app-input-text>
        </div>
        <div class="text-center">
          <button class="btn btn-unlock btn-bordered w-100" [ngClass]="{'disabled': loading}" [disabled]="loading" type="submit">
            <span *ngIf="!loading">{{ 'unlock'|translate }}</span>
            <app-loader [size]="16" [opacity]="'1'" [center]="true" *ngIf="loading"></app-loader>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
