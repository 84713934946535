<app-banner
  header="delicatessen"
  description="delicatessen_desc"
  background="/assets/images/food_and_wine/delicatessen/banner.jpg"
  background2x="/assets/images/food_and_wine/delicatessen/banner@2x.jpg"
>
  <app-line-btn
    class="h4"
    scrollTo="delicatessen"
    [noOffset]="true"
    bottomButton
  >
    <span>{{ 'know_more'|translate }}</span>
  </app-line-btn>
</app-banner>

<div id="delicatessen" class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-text-simple-centered
      header="{{ 'delicatessen_shop_header'|translate}}"
    >
      <p
        class="p-responsive"
        [innerHTML]="
          'delicatessen_shop_desc'
            | translate
            | newLineBreak
            | removeWidows
        "
      ></p>
    </app-text-simple-centered>
  </div>
</div>
<div class="pt-peta pt-md-zetta">
  <app-after-before-grey
    width="55%"
    height="1262px"
    top="38%"
    right="0px"
    additionalClasses="to-top-right"
  >
    <div class="container mobile-padding">
      <div class="row align-items-center">
        <div class="col-10 offset-1 offset-md-0 col-md-6 img-bigger">
          <img
            [defaultImage]="'/assets/images/placeholders/placeholder610x619.png'"
            [lazyLoad]="
              hasSrcSetSupport
              ? '/assets/images/food_and_wine/delicatessen/bakery_img.png 1x, /assets/images/food_and_wine/delicatessen/bakery_img@2x.png 2x'
              : '/assets/images/food_and_wine/delicatessen/bakery_img.png'
            "
            [useSrcset]="hasSrcSetSupport"
            [alt]="'delicatessen_bakery_header'|translate"
          />
        </div>

        <div class="col-md-6 col-lg-5 col-xxl-4 offset-lg-1 offset-xl-0">
          <app-indent-content header="delicatessen_bakery_header">
            <p
              class="small"
              [innerHTML]="
                'delicatessen_bakery_desc'
                  | translate
                  | newLineBreak
                  | removeWidows
              "
            ></p>
          </app-indent-content>
        </div>
      </div>
    </div>
  </app-after-before-grey>
</div>
<div class="container mobile-padding">
  <div class="pt-tera pt-md-zetta pt-lg-peta">
    <div class="row align-items-center flex-column-reverse flex-md-row">
      <div class="col-md-6 col-lg-5 col-xl-5 col-xxl-4 offset-md-0 offset-lg-1">
        <app-indent-content header="delicatessen_sweet_header">
          <p
            class="small"
            [innerHTML]="
              'delicatessen_sweet_desc'
                | translate
                | newLineBreak
                | removeWidows
            "
          ></p>
        </app-indent-content>
      </div>

      <div class="col-10 offset-1 offset-md-0 offset-xxl-1 col-md-6">
        <img
          [defaultImage]="'/assets/images/placeholders/placeholder610x619.png'"
          [lazyLoad]="
            hasSrcSetSupport
            ? '/assets/images/food_and_wine/delicatessen/section_2_img.png 1x, /assets/images/food_and_wine/delicatessen/section_2_img@2x.png 2x'
            : '/assets/images/food_and_wine/delicatessen/section_2_img.png'
          "
          [useSrcset]="hasSrcSetSupport"
          [alt]="'delicatessen_sweet_header'|translate"
          class="max-100"
        />
      </div>
    </div>
  </div>
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-single-image-text
      image="/assets/images/food_and_wine/delicatessen/manufacture_img.jpg"
      image2x="/assets/images/food_and_wine/delicatessen/manufacture_img@2x.jpg"
      [imageAlt]="'delicatessen_manufacture_header'|translate"
    >
      <h3
        class="text-light-gray"
        [innerHTML]="
          'delicatessen_manufacture_header'
            | translate
            | newLineBreak
            | removeWidows
        "
      ></h3>
      <p
        class="pt-lg-kilo pt-hecto small"
        [innerHTML]="
          'delicatessen_manufacture_desc'| translate
          | newLineBreak
          | removeWidows
        "
      ></p>
    </app-single-image-text>
  </div>
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-single-image-text
      image="/assets/images/food_and_wine/delicatessen/basement_img.jpg"
      image2x="/assets/images/food_and_wine/delicatessen/basement_img@2x.jpg"
      [imageAlt]="'delicatessen_basement_header'|translate"
      theme="reversed"
    >
      <h3 class="text-light-gray" [innerHTML]="'delicatessen_basement_header'|translate|newLineBreak|removeWidows"></h3>
      <p
        class="pt-lg-kilo pt-hecto small"
        [innerHTML]="
          'delicatessen_basement_desc'
            | translate
            | newLineBreak
            | removeWidows
        "
      ></p>
    </app-single-image-text>
  </div>
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-single-image-text
      image="/assets/images/food_and_wine/delicatessen/cosmetics_img.jpg"
      image2x="/assets/images/food_and_wine/delicatessen/cosmetics_img@2x.jpg"
      [imageAlt]="'delicatessen_cosmetics_header'|translate"
    >
      <h3 class="text-light-gray" [innerHTML]="'delicatessen_cosmetics_header'|translate|newLineBreak|removeWidows"></h3>
      <p
        class="pt-lg-kilo pt-hecto small"
        [innerHTML]="
          'delicatessen_cosmetics_desc'
            | translate
            | newLineBreak
            | removeWidows
        "
      ></p>
    </app-single-image-text>
  </div>
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-single-image-text
      image="/assets/images/food_and_wine/delicatessen/catering_img.jpg"
      image2x="/assets/images/food_and_wine/delicatessen/catering_img@2x.jpg"
      [imageAlt]="'delicatessen_catering_header'|translate"
      theme="reversed"
    >
      <h3 class="text-light-gray" [innerHTML]="'delicatessen_catering_header'|translate|newLineBreak|removeWidows"></h3>
      <p
        class="pt-lg-kilo pt-hecto small"
        [innerHTML]="
          'delicatessen_catering_desc'
            | translate
            | newLineBreak
            | removeWidows
        "
      ></p>
<!--      <p-->
<!--        class="pt-deca small font-600 xxl-max-width-wider"-->
<!--        [innerHTML]="-->
<!--          'delicatessen_catering_desc_additional'-->
<!--            | translate-->
<!--            | newLineBreak-->
<!--            | removeWidows-->
<!--        "-->
<!--      ></p>-->
    </app-single-image-text>
  </div>
</div>
<div class="pt-peta pt-md-zetta pt-lg-200">
  <app-after-before-grey
    width="37%"
    height="calc(100% + 110px)"
    top="-55px"
    left="0"
    additionalClasses="to-top-left no-responsive"
  >
    <div class="container mobile-padding">
      <div class="row">
        <h2 class="gray-opacity col-md-11 col-lg-10 offset-md-1 offset-lg-2 pb-kilo pb-md-peta">
          {{ "opening_hours" | translate }}
        </h2>
        <div class="col-md-10 col-lg-9 offset-md-2 offset-lg-3">
          <div class="row">
            <div class="col-xl-7 pb-mega pb-xl-0">
              <div class="opening-hours pb-deca pb-md-hecto">
                <h4 class="h1" *ngIf="currentLang !== 'en'">13:00<span> - </span>23:00</h4>
                <h4 class="h1" *ngIf="currentLang === 'en'">1 pm<span> - </span>11 pm</h4>
              </div>
              <p>{{ "every_day"|translate }}</p>
            </div>
            <div class="col-xl-5 pt-centi pb-mega pb-xl-0">
              <p class="gray-opacity pt-deci pt-xl-centi" [innerHTML]="'orders_till'|translate|newLineBreak|removeWidows"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-after-before-grey>
</div>
<div class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row">
      <div class="col-lg-10 offset-lg-1 offset-xxl-2 pb-kilo pb-md-peta">
        <h3 class="h2 text-gray ">{{ 'contact'|translate}}</h3>
      </div>
      <div class="offset-lg-2 col-md-6 col-lg-4 offset-xxl-3">
        <p [innerHTML]="'delicatessen_contact'|translate|newLineBreak|removeWidows" class="smaller-text"></p>
<!--        <a class="btn btn-bordered mt-kilo" [href]="restaurantMenu|apiUrl" target="_blank">{{ 'download_menu'|translate }}</a>-->

        <div class="mt-kilo">
          <app-loader [size]="30" [opacity]="'0.7'" [center]="false" *ngIf="delicatessenMenuFile === null"></app-loader>

          <ng-container *ngIf="delicatessenMenuFile?.value">
            <a class="btn btn-bordered" [href]="delicatessenMenuFile?.value|apiUrl" target="_blank">{{ 'download_menu'|translate }}</a>
          </ng-container>
          <ng-container *ngIf="delicatessenMenuFile !== null && !delicatessenMenuFile?.value">
            <span class="small">{{ 'menu_is_currently_unavailable'|translate }}</span>
          </ng-container>
        </div>
      </div>
      <div class="offset-lg-1 offset-xxl-3 col-md-5 col-lg-5 col-xxl- pt-tera">
        <p class="text-gray-main pb-deca">{{'delicatessen_contact_catering'|translate}}</p>
        <p class="h3"><a class="text-decoration-none text-gray-main font-300" href="mailto:delikatesy@shuumhotel.pl">delikatesy@shuumhotel.pl</a></p>
        <p class="text-gray-main font-300 small">{{'delicatessen_catering_summer_info'|translate}}</p>
      </div>
      <div class="col-md-5 col-lg-4 col-xxl-3 pt-kilo pt-md-tera">
        <p class="text-gray-main pb-deca">{{ 'delicatessen_single'|translate }}</p>
        <p class="h3"><a class="text-decoration-none text-gray-main font-300" href="tel:+48943554020">+48 94 355 4020</a></p>
        <p class="text-gray-main font-300 small">{{'delicatessen_contact_hours'|translate}}</p>
      </div>
    </div>
  </div>
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-center">
      <div class="col-10 col-md-5 col-lg-4 offset-1 offset-md-0 offset-lg-1">
        <img
          [defaultImage]="'/assets/images/placeholders/placeholder318x461.png'"
          [lazyLoad]="
            hasSrcSetSupport
            ? ('/assets/images/food_and_wine/delicatessen/buy_safely_img.jpg 1x, /assets/images/food_and_wine/delicatessen/buy_safely_img@2x.jpg 2x')
            : '/assets/images/food_and_wine/delicatessen/buy_safely_img.jpg'"
          [useSrcset]="hasSrcSetSupport"
          [alt]="'delicatessen_buy_header'|translate"
          class="max-100"
        />
      </div>
      <div class="col-md-6 col-lg-5 col-xxl-4 offset-md-1 pt-giga pt-md-zero">
        <app-indent-content header="delicatessen_buy_header">
          <p
            class="small b-600"
            [innerHTML]="
              'delicatessen_buy_desc'
                | translate
                | newLineBreak
                | removeWidows
            "
          ></p>
        </app-indent-content>
      </div>
    </div>
  </div>
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-center">
      <div class="col-md-6 col-lg-5 col-xxl-4 offset-lg-1 offset-xxl-2 order-2 order-md-1 pt-giga pt-md-zero">
        <app-indent-content header="delicatessen_take_home_header">
          <p
            class="small"
            [innerHTML]="
              'delicatessen_take_home_desc'
                | translate
                | newLineBreak
                | removeWidows
            "
          ></p>
        </app-indent-content>
      </div>
      <div class="col-10 col-md-5 col-lg-4 offset-1 order-1 order-md-2">
        <img
          [defaultImage]="'/assets/images/placeholders/placeholder432x461.png'"
          [lazyLoad]="
            hasSrcSetSupport
            ? ('/assets/images/food_and_wine/delicatessen/take_home_img.jpg 1x, /assets/images/food_and_wine/delicatessen/take_home_img@2x.jpg 2x')
            : '/assets/images/food_and_wine/delicatessen/take_home_img.jpg'"
          [useSrcset]="hasSrcSetSupport"
          [alt]="'delicatessen_take_home_header'|translate"
          class="max-100"
        />
      </div>
    </div>
  </div>
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-gallery
      [header]="'food_and_wine'"
      [description]="'food_and_wine_gallery_description'"
      [images]="galleryImages"
    ></app-gallery>
  </div>
</div>

<app-footer></app-footer>
