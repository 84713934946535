<div
  [class]="'collage-type-' + type"
>
  <div class="image-01">
    <app-img
      *ngIf="bigImage"
      [image]="bigImage"
      [alt]="'big-image'"
    >
    </app-img>
  </div>
  <div
    [class]="'image-02 ' + smallImageClass"
  >
    <app-img
      *ngIf="smallImage"
      [image]="smallImage"
      [alt]="'small-image'"
    >
    </app-img>
  </div>
</div>

