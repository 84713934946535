import { Subject } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { LayoutService } from '@services/layout.service';

export abstract class SubscribeHelper implements OnDestroy {
  protected componentDestroyed$: Subject<boolean> = new Subject();

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
