import {Injectable} from '@angular/core';
import {MainService} from '@services/main.service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import IFilter from '@interfaces/IFilter';
import IOrder from '@interfaces/IOrder';
import {Observable} from 'rxjs';
import {ITreatment, ITreatmentDetails} from '@interfaces/ITreatment';

@Injectable({
  providedIn: 'root'
})
export class MedicalWellnessService extends MainService {
  listUrl = '/medical_wellness';
  detailsUrl = '/medical_wellness';

  constructor( protected http: HttpClient ) {
    super( http );
  }

  getList(filters?: IFilter[], orders?: IOrder[], limit?: number, page?: number): Observable<ITreatment[]> {
    if ( !orders ) {
      orders = this.setDefaultOrder();
    }

    return super.getList( filters, orders, limit, page );
  }

  getListExtended(filters?: IFilter[], orders?: IOrder[], limit?: number, page?: number): Observable<HttpResponse<ITreatment[]>> {
    if ( !orders ) {
      orders = this.setDefaultOrder();
    }

    return super.getList( filters, orders, limit, page, true );
  }

  getDetails( slug: string ): Observable<ITreatmentDetails> {
    return super.getDetails( slug );
  }

  private setDefaultOrder(): IOrder[] {
    const orders: IOrder[] = [];

    orders.push({
      field: 'isSingledOut',
      type: 'DESC'
    });

    orders.push({
      field: 'name',
      type: 'ASC'
    });

    return orders;
  }
}
