import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../../_models/auth/User';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {map, first, tap, finalize} from 'rxjs/operators';
import * as forEach from 'lodash/forEach';
import * as isArray from 'lodash/isArray';
import GlobalFunctions from '@helpers/GlobalFunctions';
import ConfigService from '@config/ConfigService';
import ILogin from '@interfaces/auth/ILogin';
import { I18nRoutePipe } from 'src/app/_pipes/i18n-route.pipe';
import { GlobalStateHelper } from '@helpers/GlobalStateHelper';
import { IAllReservations } from '../../_subprojects/reservation/_interfaces/IAllReservations';
import IReservationSummary, { IReservationSummaryDetails } from 'src/app/_subprojects/reservation-form/_interfaces/IReservationSummary';
import { LayoutService } from '@services/layout.service';
import { SocialTypeEnum } from 'src/app/_subprojects/reservation/_enums/SocialTypeEnum';
import { IMessage } from 'src/app/_subprojects/reservation/_interfaces/IMessage';
import {IUserTreatments} from '@interfaces/IUserTreatments';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly apiUrl: string;
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  public isLoggedIn$: BehaviorSubject<boolean>;
  private reservationStorageKey = environment.reservationStorageKey;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private i18nRoute: I18nRoutePipe,
    private globalStateService: GlobalStateHelper,
    private layoutService: LayoutService
  ) {
    this.apiUrl = environment.shuumApiHostShort;
    let userData = null;

    try {
      userData = sessionStorage ? JSON.parse(localStorage.getItem('user')) : null;
    }
    catch (e) {

    }

    this.userSubject = new BehaviorSubject<User>(userData);
    this.user = this.userSubject.asObservable();

    this.isLoggedIn$ = new BehaviorSubject( !!this.userSubject.value );
  }

  public get userValue(): User {
    return this.userSubject.value;
  }
  //
  // public get isLoggedIn(): boolean {
  //   return !!this.userSubject.value;
  // }

  public isLoggedIn(): BehaviorSubject<boolean> {
    return this.isLoggedIn$;
  }

  getMe( forceRefresh = false ): Observable<User>  {
    return this.http.get<User>(`${this.apiUrl}/users/me`)
      .pipe(map( userData => {
        return this.setUserData( userData, forceRefresh );
      }));
  }

  checkIsLoggedIn( forceRefresh = false ): Observable<boolean> {
    return this.http.get<User>(`${this.apiUrl}/users/me`)
      .pipe(map( userData => {
        const user = this.setUserData( userData, forceRefresh );
        return !!userData;
      }));
  }

  changePassword( data: any ): Observable<ILogin> {
    const fd = GlobalFunctions.convertToFd(new FormData(), data);

    return this.http.post<ILogin>(`${this.apiUrl}/users/password/change`, fd, {}).pipe(
      tap( userData => {
        if ( typeof userData.token !== 'undefined' ) {
          this.setUserData( userData );
        }
      })
    );
  }

  resetPassword( data: any ): Observable<null> {
    const fd = GlobalFunctions.convertToFd(new FormData(), data);

    return this.http.post<null>(`${this.apiUrl}/public/users/password/reset`, fd, {});
  }

  newPassword( data: any, uuid: string ): Observable<null> {
    const fd = GlobalFunctions.convertToFd(new FormData(), data);

    return this.http.post<null>(`${this.apiUrl}/public/users/password/new/${uuid}`, fd, {});
  }

  register( data: any ): Observable<User> {
    const registerData: FormData = this.setReservationData( data );

    return this.http.post<User>(`${this.apiUrl}/register`, registerData, {

    });
  }

  login( username: string, password: string ): Observable<User> {
    const btoa = window.btoa(username + ':' + password);

    return this.http.post<User>(`${this.apiUrl}/login`, null, {
        headers: {
          Authorization: `Basic ${btoa}`
        }
      })
      .pipe(map(userData => {
        return this.setUserData( userData );
      }));
  }

  logout( redirect = true ): void {
    /**
     * W logowaniu na socialach zwracało gdzieś po drodze 401,
     * więc dodałam, że jeżeli token zaczyna się na redirect_
     * to nie wylogowywije
     */
    // if ( !this.userValue?.token?.startsWith('redirect_') ) {
      this.http.get<null>(`${this.apiUrl}/logout`).pipe(first()).subscribe(_ => {});

      try {
        if (localStorage) {
          localStorage.removeItem('user');
          localStorage.removeItem('loyalty-program-modal');
        }

        if (sessionStorage) {
          const data = JSON.parse(sessionStorage.getItem(this.reservationStorageKey));
          data.rooms.forEach(room => {
            room.guestData = null;
          });

          sessionStorage.setItem(this.reservationStorageKey, JSON.stringify(data));
        }
      }
      catch (e) {

      }

      this.userSubject.next(null);
      this.isLoggedIn$.next( false );

      if ( redirect ) {
        const currentRouteGroup = ConfigService.getCurrentRouteGroupPath();

        if ( ':lang/reservation/step-4' === currentRouteGroup.join('/') ) {
          this.router.navigate([ConfigService.getCurrentRoute()]);
        }
        else {
          this.globalStateService.unlockLogoutPopup = true;
          this.router.navigate([ this.i18nRoute.transform('/:lang/my-reservation/authorization-group/logout-group') ]);
        }
      }
    // }
  }

  checkUUID(uuid: string): Observable<null> {
    return this.http.get<null>(`${this.apiUrl}/public/users/token/availability/${uuid}`);
  }

  activation(uuid: string): Observable<null> {
    return this.http.get<null>(`${this.apiUrl}/public/users/activation/${uuid}`);
  }

  reservationUserDataCheck( userValues: any ): Observable<null> {
    if ( this.isLoggedIn$.value ) {
      const data = this.setReservationUserData( userValues );
      return this.http.post<null>(`${this.apiUrl}/booking/customers`, data, {});
    } else {
      const data = this.setReservationUserData( userValues );
      return this.http.post<null>(`${this.apiUrl}/public/booking/customers`, data, {});
    }
  }

  getReservationHistory(): Observable<IAllReservations> {
    this.layoutService.setItemLoader(true);

    return this.http.get<IAllReservations>(`${this.apiUrl}/users/booking`).pipe(
      finalize(() => {
        this.layoutService.setItemLoader(false);
      })
    );
  }

  getReservation(id: string): Observable<IReservationSummaryDetails> {
    this.layoutService.setItemLoader(true);

    return this.http.get<IReservationSummaryDetails>(`${this.apiUrl}/users/booking/${id}`).pipe(
      map(data => {
        if ( data.rooms ) {
          data.rooms.forEach( (room, roomIndex) => {
            if ( room.extras ) {
              if ( room.extras.items ) {
                let totalExtras = 0;

                room.extras.items.forEach( category => {
                  if ( category.values ) {
                    totalExtras += category.values.length;
                  }
                });

                room.extras.totalAmount = totalExtras;
              }
            }
          });
        }

        return data;
      }),
      finalize(() => {
        this.layoutService.setItemLoader(false);
      })
    );
  }

  editUser( data ): Observable<null> {
    const dataToSend = {
      name: data.name,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      address: data.address,
      city: data.city,
      postcode: data.postcode,
      country: data.country,
      rules: data.rules,
      loyaltyProgram: data.loyaltyProgram ? true : false
    };

    const urlEncoded = GlobalFunctions.convertToUrlencoded(dataToSend);
    const fd = GlobalFunctions.convertToFd(new FormData(), dataToSend);

    // todo: to do usuniecia
    return this.http.put<null>(`${this.apiUrl}/users`, urlEncoded, {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    }).pipe(
      map(res => {
        const rules = [];
        data.rules.forEach(rule => {
          if ( typeof rule === 'object' ) {
            forEach( rule, (v, k) => {
              if ( v === true ) {
                try {
                  rules.push(parseInt(k.replace('rule_', ''), 10));
                } catch (e) {}
              }
            });
          } else {
            rules.push(rule);
          }
        });

        data.rules = rules;

        this.setUserData({ ...this.userValue, ...data });
        return res;
      })
    );
  }

  changeReservation( reservationId: string, message: IMessage ): Observable<null> {
    const fd = GlobalFunctions.convertToFd(new FormData(), message);
    return this.http.post<null>(`${this.apiUrl}/users/booking/change/${reservationId}`, fd, {});
  }

  getTreatments(): Observable<IUserTreatments> {
    this.layoutService.setItemLoader(true);

    return this.http.get<IUserTreatments>(`${this.apiUrl}/users/treatments`).pipe(
      finalize(() => {
        this.layoutService.setItemLoader(false);
      })
    );
  }

  setUserData( userData: User, forceRefresh = false ): User {
    let user = this.userValue;

    if ( userData ) {
      if (!user) {
        user = new User();
      }

      if ( !forceRefresh ) {
        user = {
          ...user,
          ...userData
        };
      } else {
        user = {
          ...userData,
          token: user.token,
          expiresAt: user.expiresAt
        };
      }

      try {
        if (localStorage) {
          localStorage.setItem('user', JSON.stringify(user));
        }
      }
      catch (e) {

      }

      this.userSubject.next(user);
    }

    return user;
  }

  private setReservationUserData( reservationUserData: any ) {
    const formData = new FormData();
    return GlobalFunctions.convertToFd( formData, reservationUserData );
  }

  /**
   * zamiana danych z formularza rejestracji na FormData
   * jeśli istnieje klucz "rules" to zgody zapisujemy w następującej formie (dla wybranej 4 i 11):
   * rules[]=4
   * rules[]=11
   *
   * @param registerData - dane z formularza
   */
  private setReservationData( registerData: any ): FormData {
    const data = new FormData();

    forEach( registerData, (value, key) => {
      if ( isArray(value) ) {
        if ( key === 'rules' ) {
          value.forEach( ruleValue => {
            forEach( ruleValue, (v, k) => {
              if ( v === true ) {
                data.append(key + '[]', k.replace('rule_', ''));
              }
            });
          });
        }
      }
      else {
        data.append(key, value);
      }
    });

    return data;
  }
}
