import {Component, Input, OnInit} from '@angular/core';
import {ScrollToConfigOptions, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import GlobalFunctions from '@helpers/GlobalFunctions';
import { first } from 'rxjs/operators';
import {tearsAnimation} from '@helpers/animations';

@Component({
  selector: 'app-line-btn',
  templateUrl: './line-btn.component.html',
  styleUrls: ['./line-btn.component.scss'],
  animations: [
    tearsAnimation
  ]
})
export class LineBtnComponent implements OnInit {

  scrollToConfig: ScrollToConfigOptions;
  anchor = '';
  tearsAnimationState = 'static';
  tearsAnimationInterval = null;

  @Input() class: string;
  @Input() inNav: boolean;
  @Input() scrollTo?: string;
  @Input() link?: string;
  @Input() scrollToNoMap?: string;
  @Input() noOffset = false;

  constructor(
    private scrollToService: ScrollToService
  ) {

  }

  ngOnInit() {
    if ( this.scrollTo ) {
      this.anchor = this.scrollTo;

      const map = GlobalFunctions.getScrollToOffsetMap();

      if ( this.noOffset ) {
        this.scrollToConfig = {
          target: this.scrollTo
        };
      }
      else {
        this.scrollToConfig = {
          target: this.scrollTo,
          offsetMap: map
        };
      }
    } else if ( this.scrollToNoMap ) {
      this.anchor = this.scrollToNoMap;

      this.scrollToConfig = {
        target: this.scrollToNoMap,
        offset: 1
      };
    }
  }

  onClick( e: MouseEvent ): void {
    if ( this.scrollTo || this.scrollToNoMap ) {
      e.preventDefault();

      this.scrollToService
          .scrollTo( this.scrollToConfig );
    }
  }

  startTearsAnimation() {
    this.endTearsAnimation();

    let isFirstLoop = true;
    let iterationNo = 1;

    this.tearsAnimationInterval = setInterval(() => {
      if ( this.tearsAnimationState === 'static' && (iterationNo >= 10 || isFirstLoop) ) {
        this.tearsAnimationState = 'disappear';
        iterationNo = 0;
      }
      else if ( this.tearsAnimationState === 'disappear' && iterationNo >= 10 ) {
        this.tearsAnimationState = 'appear';
        iterationNo = 0;
      }
      else if ( this.tearsAnimationState === 'appear' ) {
        this.tearsAnimationState = 'static';
        iterationNo = 0;
      }

      isFirstLoop = false;
      iterationNo++;
    }, 100);
  }

  endTearsAnimation() {
    clearInterval( this.tearsAnimationInterval );
    this.tearsAnimationInterval = null;

    this.tearsAnimationState = 'static';
  }
}
