import { Component, OnInit, Input, AfterViewInit, Inject, PLATFORM_ID, ViewChild, ElementRef, ViewChildren, QueryList, HostListener, OnDestroy } from '@angular/core';
import { IContent2plus1 } from '@interfaces/IContent2plus1';
import ScrollMagic from 'scrollmagic';
import { isPlatformBrowser } from '@angular/common';
import { debounceEvent } from 'src/app/_decorators/DebounceEvent';
import ConfigService from '@config/ConfigService';

@Component({
  selector: 'app-content2plus1',
  templateUrl: './content2plus1.component.html',
  styleUrls: ['./content2plus1.component.scss']
})
export class Content2plus1Component implements AfterViewInit, OnInit, OnDestroy {
	@Input() data: IContent2plus1[];
	@Input() trigger: string;
	@Input() classes?: string;
	@Input() firstVisible?: boolean;

	@ViewChild('container') container: ElementRef;
	@ViewChild('sticky') sticky: ElementRef;
	@ViewChildren('scrolledElements') scrolledElements: QueryList<ElementRef>
	@ViewChildren('scrolledImages') scrolledImages: QueryList<ElementRef>

	public marginTop = 200;
	public hasSrcSetSupport = false;
	public opacities: number[] = [];
	public firstOpacity: boolean = false;

	private duration: number;
	private ctrl: any;
	private magic: any;
	private magicSticky: any;


	@HostListener('window:resize')
	@debounceEvent()
	onResize() {
		this.calculateMarginAndDuration();

		this.magicSticky.removePin("#sticky");

		this.magicSticky = new ScrollMagic.Scene({
			duration: this.duration,
			triggerElement: this.trigger,
			triggerHook: 0
		})
		.setPin("#sticky")
		.addTo(this.ctrl);
	}

  constructor(
		@Inject(PLATFORM_ID) private platformId: any
	) {
	  this.hasSrcSetSupport = ConfigService.getHasSrcSetSupport();
  }

	ngOnInit() {
		this.data.forEach((_, i) => {
			if(i === 0) {
				this.opacities.push(1);
			} else {
				this.opacities.push(0);
			}
		});
	}

  ngAfterViewInit() {
		setTimeout(
		() => {this.setScrollMagicAnimation()}, 0);
	}

	ngOnDestroy() {
		if(isPlatformBrowser(this.platformId)) {
			this.ctrl.destroy();
			this.ctrl = null;

			this.magic.destroy();
			this.magic = null;

			this.magicSticky.destroy()
			this.magic = null;
		}
	}

	setScrollMagicAnimation() : void {
		if ( isPlatformBrowser(this.platformId) ) {

			if ( this.magic ) {
        if ( typeof this.magic.destroy !== 'undefined' ) {
          this.magic.destroy();
        }
			}

			if ( this.magicSticky ) {
        if ( typeof this.magicSticky.destroy !== 'undefined' ) {
          this.magicSticky.destroy();
        }
			}

			this.ctrl = new ScrollMagic.Controller();

			this.calculateMarginAndDuration();

			// set sticky animation
			this.magicSticky = new ScrollMagic.Scene({
				duration: this.duration,
				triggerElement: this.trigger,
				triggerHook: 0
			})
			.setPin("#sticky")
			.addTo(this.ctrl);

			this.scrolledElements.toArray().forEach((element, i) => {
				let elementHeight = element.nativeElement.clientHeight;
				if(elementHeight < 200) elementHeight = 250;

				// set opacity animation
				if( i !== this.scrolledElements.toArray().length - 1 && i !== 0 ) {
					// if not last or first
					this.magic = new ScrollMagic.Scene({
						triggerElement: element.nativeElement,
						offset:  -window.innerHeight + elementHeight / 1.5,
						duration: window.innerHeight,
						triggerHook: 0
					})
					.on('progress', (e) => {
						if(e.progress < 0.3333) {
							this.opacities[i] = e.progress * 3 > 1 ? 1 : e.progress * 3;
						} else if (e.progress >= 0.3333 && e.progress < 0.6666) {
							this.opacities[i] = 1;
						} else {
							this.opacities[i] = (1 - e.progress) * 3 > 0 ? (1 - e.progress) * 3 : 0;
						}
					})
					.addTo(this.ctrl);

				} else if( i === this.scrolledElements.toArray().length - 1 ) {
					// last stays visible
					this.magic = new ScrollMagic.Scene({
						triggerElement: element.nativeElement,
						offset:  -window.innerHeight + elementHeight / 2.5,
						duration: window.innerHeight / 3*2,
						triggerHook: 0
					})
					.on('progress', (e) => {
						this.opacities[i] = e.progress;
					})
					.addTo(this.ctrl);

				} else if ( i === 0 ) {
					// first stays visible
					this.magic = new ScrollMagic.Scene({
						triggerElement: element.nativeElement,
						offset:  -window.innerHeight + elementHeight / 1.5,
						duration: window.innerHeight,
						triggerHook: 0
					})
					.on('progress', (e) => {
						if(e.progress < 0.3333) {
							this.opacities[i] = 1;
						} else if (e.progress >= 0.3333 && e.progress < 0.6666) {
							this.opacities[i] = 1;
						} else {
							this.opacities[i] = (1 - e.progress) * 3 > 0 ? (1 - e.progress) * 3 : 0;
						}

						if(e.progress > 0.45) {
							this.firstOpacity = true;
						} else {
							this.firstOpacity = false;

						}
					})
					.addTo(this.ctrl);
				}

				// set images animation
				this.magic = new ScrollMagic.Scene({
					triggerElement: element.nativeElement,
					offset: elementHeight / 4,
					triggerHook: 0.5
				})
				.on('start', (event) => {
					if(event.scrollDirection === 'FORWARD') {
						this.scrolledImages.toArray().forEach(el => {
							el.nativeElement.classList.remove('active');
						})

						this.scrolledImages.toArray()[i].nativeElement.classList.add('active');
					} else {
						let prev = this.scrolledImages.toArray()[i-1];

						if (i !== 0) {
							this.scrolledImages.toArray()[i].nativeElement.classList.remove('active');
						}

						if(prev) {
							prev.nativeElement.classList.add('active');
						}

						this.scrolledImages.toArray()[0].nativeElement.classList.add('no-delay');
					}
				})
				.addTo(this.ctrl);
			});
			
			// set animation on container
			this.magic = new ScrollMagic.Scene({
				triggerElement: this.container.nativeElement,
				triggerHook: 1,
				offset: this.marginTop * 1.3
			})
			.on('start', (e) => {
				if(e.scrollDirection === 'FORWARD') {
				this.scrolledImages.toArray()[0].nativeElement.classList.remove('no-delay')
				if(!this.container.nativeElement.classList.contains('appeared'))  {
					this.container.nativeElement.classList.add('appeared');
				}
				} else {
					this.container.nativeElement.classList.remove('appeared');
				}
			})
			.addTo(this.ctrl);
		}
	}

	private calculateMarginAndDuration(): void {
		// calculate margin top
		let lastEl = this.scrolledElements.toArray()[this.scrolledElements.toArray().length - 1].nativeElement.querySelector('.text-content');
		let margin = parseFloat(getComputedStyle(lastEl).marginTop);

		let firstEl = this.scrolledElements.toArray()[0].nativeElement.querySelector('.text-content');
		let firstHeight = firstEl.offsetHeight;

		this.marginTop = window.innerHeight / 2 - firstHeight / 2 + margin / 2;

		// calculate duration for pinned element
		this.duration = 0;
		this.scrolledElements.toArray().forEach((e, i) => {
			if(i !== this.scrolledElements.toArray().length - 1)
			this.duration += e.nativeElement.offsetHeight;
		})

		this.duration += margin;
	}

}
