import { Component, OnInit } from '@angular/core';
import {NotificationsService} from '../../../../_services/notifications.service';
import {INotification} from '../../../../_interfaces/INotification';
import {takeUntil} from 'rxjs/operators';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {IBookingType} from '../../../../_interfaces/IBookingType';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent extends SubscribeHelper implements OnInit {

  notifications: INotification[];

  private limit = 6;
  private page = 2;
  public responseMax = 0;
  public responseCurrent = 0;

  public loading = false;

  constructor(
    private notificationsService: NotificationsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.notificationsService.getNotifications().pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(notifications => {
      this.notificationsService.setNotifications(notifications.list);

      this.responseMax = notifications.headers['Query-Range'];
      this.responseCurrent = notifications.list.length;
    });

    this.notificationsService.notifiactions$.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(notifications => {
      this.notifications = notifications;
    });
  }

  markAsRead(notification: INotification) {
    if (!notification?.is_received) {
      const newNotification: INotification = {
        ...notification,
        is_received: 1
      };
  
      this.notificationsService.updateNotification(newNotification.id, { is_received: 1 }).pipe(
        takeUntil(this.componentDestroyed$)
      ).subscribe(n => {
        this.notificationsService.setNotification(newNotification);
        this.notificationsService.reduceNotificationsQuantity();
      });
    }
  }

  loadMore(): void {
    this.loading = true;

    this.notificationsService.getNotifications([], this.limit, this.page)
      .pipe(
        takeUntil(this.componentDestroyed$)
      ).subscribe(list => {
      this.notifications.push.apply(this.notifications, list.list);

      this.responseCurrent += list.list.length;

      this.page += 1;
      this.loading = false;
    });
  }

}
