<div class="button">
  <a *ngIf="buttonHref" [ngClass]="{ 'light' : buttonLight }" [routerLink]="buttonHref">{{ buttonText }}</a>
  <a 
    *ngIf="scrollTo" 
    [routerLink]="''"
    [ngClass]="{ 'light' : buttonLight }"
    [fragment]="anchor ? anchor : undefined"
    (click)="onClick($event)"
  >
    {{ buttonText }}
  </a>
</div>
