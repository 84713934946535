enum CountryEnum {
  AF = 'country_AF', // Afghanistan
  AX = 'country_AX', // AlandIslands
  AL = 'country_AL', // Albania
  DZ = 'country_DZ', // Algeria
  AS = 'country_AS', // American Samoa
  AD = 'country_AD', // Andorra
  AO = 'country_AO', // Angola
  AI = 'country_AI', // Anguilla
  AQ = 'country_AQ', // Antarctica
  AG = 'country_AG', // Antigua and Barbuda
  AR = 'country_AR', // Argentina
  AM = 'country_AM', // Armenia
  AW = 'country_AW', // Aruba
  AU = 'country_AU', // Australia
  AT = 'country_AT', // Austria
  AZ = 'country_AZ', // Azerbaijan
  BS = 'country_BS', // Bahamas
  BH = 'country_BH', // Bahrain
  BD = 'country_BD', // Bangladesh
  BB = 'country_BB', // Barbados
  BY = 'country_BY', // Belarus
  BE = 'country_BE', // Belgium
  BZ = 'country_BZ', // Belize
  BJ = 'country_BJ', // Benin
  BM = 'country_BM', // Bermuda
  BT = 'country_BT', // Bhutan
  BO = 'country_BO', // Bolivia
  BA = 'country_BA', // Bosnia and Herzegovina
  BW = 'country_BW', // Botswana
  BV = 'country_BV', // BouvetIsland
  BR = 'country_BR', // Brazil
  IO = 'country_IO', // British Indian Ocean Territory
  BN = 'country_BN', // Brunei Darussalam
  BG = 'country_BG', // Bulgaria
  BF = 'country_BF', // Burkina Faso
  BI = 'country_BI', // Burundi
  KH = 'country_KH', // Cambodia
  CM = 'country_CM', // Cameroon
  CA = 'country_CA', // Canada
  CV = 'country_CV', // Cape Verde
  KY = 'country_KY', // Cayman Islands
  CF = 'country_CF', // Central African Republic
  TD = 'country_TD', // Chad
  CL = 'country_CL', // Chile
  CN = 'country_CN', // China
  CX = 'country_CX', // Christmas Island
  CC = 'country_CC', // Cocos Keeling Islands
  CO = 'country_CO', // Colombia
  KM = 'country_KM', // Comoros
  CG = 'country_CG', // Congo
  CD = 'country_CD', // Congo Democratic Republic
  CK = 'country_CK', // Cook Islands
  CR = 'country_CR', // Costa Rica
  CI = 'country_CI', // CoteDIvoire
  HR = 'country_HR', // Croatia
  CU = 'country_CU', // Cuba
  CY = 'country_CY', // Cyprus
  CZ = 'country_CZ', // Czech Republic
  DK = 'country_DK', // Denmark
  DJ = 'country_DJ', // Djibouti
  DM = 'country_DM', // Dominica
  DO = 'country_DO', // Dominican Republic
  EC = 'country_EC', // Ecuador
  EG = 'country_EG', // Egypt
  SV = 'country_SV', // El Salvador
  GQ = 'country_GQ', // Equatorial Guinea
  ER = 'country_ER', // Eritrea
  EE = 'country_EE', // Estonia
  ET = 'country_ET', // Ethiopia
  FK = 'country_FK', // Falkland Islands
  FO = 'country_FO', // FaroeIslands
  FJ = 'country_FJ', // Fiji
  FI = 'country_FI', // Finland
  FR = 'country_FR', // France
  GF = 'country_GF', // French Guiana
  PF = 'country_PF', // French Polynesia
  TF = 'country_TF', // French Southern Territories
  GA = 'country_GA', // Gabon
  GM = 'country_GM', // Gambia
  GE = 'country_GE', // Georgia
  DE = 'country_DE', // Germany
  GH = 'country_GH', // Ghana
  GI = 'country_GI', // Gibraltar
  GR = 'country_GR', // Greece
  GL = 'country_GL', // Greenland
  GD = 'country_GD', // Grenada
  GP = 'country_GP', // Guadeloupe
  GU = 'country_GU', // Guam
  GT = 'country_GT', // Guatemala
  GG = 'country_GG', // Guernsey
  GN = 'country_GN', // Guinea
  GW = 'country_GW', // Guinea Bissau
  GY = 'country_GY', // Guyana
  HT = 'country_HT', // Haiti
  HM = 'country_HM', // Heard Island Mcdonald Islands
  VA = 'country_VA', // Holy See Vatican City State
  HN = 'country_HN', // Honduras
  HK = 'country_HK', // HongKong
  HU = 'country_HU', // Hungary
  IS = 'country_IS', // Iceland
  IN = 'country_IN', // India
  ID = 'country_ID', // Indonesia
  IR = 'country_IR', // Iran
  IQ = 'country_IQ', // Iraq
  IE = 'country_IE', // Ireland
  IM = 'country_IM', // Isle Of Man
  IL = 'country_IL', // Israel
  IT = 'country_IT', // Italy
  JM = 'country_JM', // Jamaica
  JP = 'country_JP', // Japan
  JE = 'country_JE', // Jersey
  JO = 'country_JO', // Jordan
  KZ = 'country_KZ', // Kazakhstan
  KE = 'country_KE', // Kenya
  KI = 'country_KI', // Kiribati
  KR = 'country_KR', // Korea, Republic of
  KP = 'country_KP', // Korea Democratic People\'s Republic of
  KW = 'country_KW', // Kuwait
  KG = 'country_KG', // Kyrgyzstan
  LA = 'country_LA', // Lao Peoples Democratic Republic
  LV = 'country_LV', // Latvia
  LB = 'country_LB', // Lebanon
  LS = 'country_LS', // Lesotho
  LR = 'country_LR', // Liberia
  LY = 'country_LY', // Libyan Arab Jamahiriya
  LI = 'country_LI', // Liechtenstein
  LT = 'country_LT', // Lithuania
  LU = 'country_LU', // Luxembourg
  MO = 'country_MO', // Macao
  MKD = 'country_KD', // Republic of North Macedonia
  MG = 'country_MG', // Madagascar
  MW = 'country_MW', // Malawi
  MY = 'country_MY', // Malaysia
  MV = 'country_MV', // Maldives
  ML = 'country_ML', // Mali
  MT = 'country_MT', // Malta
  MH = 'country_MH', // Marshall Islands
  MQ = 'country_MQ', // Martinique
  MR = 'country_MR', // Mauritania
  MU = 'country_MU', // Mauritius
  YT = 'country_YT', // Mayotte
  MX = 'country_MX', // Mexico
  FM = 'country_FM', // Micronesia
  MD = 'country_MD', // Moldova
  MC = 'country_MC', // Monaco
  MN = 'country_MN', // Mongolia
  ME = 'country_ME', // Montenegro
  MS = 'country_MS', // Montserrat
  MA = 'country_MA', // Morocco
  MZ = 'country_MZ', // Mozambique
  MM = 'country_MM', // Myanmar
  NA = 'country_NA', // Namibia
  NR = 'country_NR', // Nauru
  NP = 'country_NP', // Nepal
  NL = 'country_NL', // Netherlands
  NC = 'country_NC', // New Caledonia
  NZ = 'country_NZ', // New Zealand
  NI = 'country_NI', // Nicaragua
  NE = 'country_NE', // Niger
  NG = 'country_NG', // Nigeria
  NU = 'country_NU', // Niue
  NF = 'country_NF', // Norfolk Island
  MP = 'country_MP', // Northern Mariana Islands
  NO = 'country_NO', // Norway
  OM = 'country_OM', // Oman
  PK = 'country_PK', // Pakistan
  PW = 'country_PW', // Palau
  PS = 'country_PS', // Palestinian Territory
  PA = 'country_PA', // Panama
  PG = 'country_PG', // Papua New Guinea
  PY = 'country_PY', // Paraguay
  PE = 'country_PE', // Peru
  PH = 'country_PH', // Philippines
  PN = 'country_PN', // Pitcairn
  PL = 'country_PL', // Poland
  PT = 'country_PT', // Portugal
  PR = 'country_PR', // PuertoRico,
  QA = 'country_QA', // Qatar
  RE = 'country_RE', // Reunion
  RO = 'country_RO', // Romania
  RU = 'country_RU', // Russian Federation
  RW = 'country_RW', // Rwanda
  BL = 'country_BL', // Saint Barthelemy
  SH = 'country_SH', // Saint Helena
  KN = 'country_KN', // Saint Kitts And Nevis
  LC = 'country_LC', // Saint Lucia
  MF = 'country_MF', // Saint Martin
  PM = 'country_PM', // Saint Pierre And Miquelon
  VC = 'country_VC', // Saint Vincent And Grenadines
  WS = 'country_WS', // Samoa
  SM = 'country_SM', // San Marino
  ST = 'country_ST', // Sao Tome And Principe
  SA = 'country_SA', // Saudi Arabia
  SN = 'country_SN', // Senegal
  RS = 'country_RS', // Serbia
  SC = 'country_SC', // Seychelles
  SL = 'country_SL', // Sierra Leone
  SG = 'country_SG', // Singapore
  SK = 'country_SK', // Slovakia
  SI = 'country_SI', // Slovenia
  SB = 'country_SB', // Solomon Islands
  SO = 'country_SO', // Somalia
  ZA = 'country_ZA', // South Africa
  GS = 'country_GS', // South Georgia And Sandwich Island
  ES = 'country_ES', // Spain
  LK = 'country_LK', // Sri Lanka
  SD = 'country_SD', // Sudan
  SS = 'country_SS', // South Sudan
  SR = 'country_SR', // Suriname
  SJ = 'country_SJ', // Svalbard And Jan Mayen
  SZ = 'country_SZ', // Swaziland
  SE = 'country_SE', // Sweden
  CH = 'country_CH', // Switzerland
  SX = 'country_SX', // Sint Maarten (Dutch part)
  SY = 'country_SY', // Syrian Arab Republic
  TW = 'country_TW', // Taiwan
  TJ = 'country_TJ', // Tajikistan
  TZ = 'country_TZ', // Tanzania
  TH = 'country_TH', // Thailand
  TL = 'country_TL', // TimorLeste
  TG = 'country_TG', // Togo
  TK = 'country_TK', // Tokelau
  TO = 'country_TO', // Tonga
  TT = 'country_TT', // Trinidad And Tobago
  TN = 'country_TN', // Tunisia
  TR = 'country_TR', // Turkey
  TM = 'country_TM', // Turkmenistan
  TC = 'country_TC', // Turks And Caicos Islands
  TV = 'country_TV', // Tuvalu
  UG = 'country_UG', // Uganda
  UA = 'country_UA', // Ukraine
  AE = 'country_AE', // United Arab Emirates
  GB = 'country_GB', // United Kingdom
  US = 'country_US', // United States
  UM = 'country_UM', // United States Outlying Islands
  UY = 'country_UY', // Uruguay
  UZ = 'country_UZ', // Uzbekistan
  VU = 'country_VU', // Vanuatu
  VE = 'country_VE', // Venezuela
  VN = 'country_VN', // Vietnam
  VG = 'country_VG', // Virgin Islands British
  VI = 'country_VI', // Virgin Islands US
  WF = 'country_WF', // Wallis And Futuna
  EH = 'country_EH', // WesternSahara
  YE = 'country_YE', // Yemen
  ZM = 'country_ZM', // Zambia
  ZW = 'country_ZW', // Zimbabwe
}

export default CountryEnum;
