import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReservationFormComponent } from './reservation-form.component';
import { StepOneComponent } from './pages/step-one/step-one.component';
import { StepTwoComponent } from './pages/step-two/step-two.component';
import { LangGuard } from '@guards/LangGuard';
import { StepFourComponent } from './pages/step-four/step-four.component';
import { StepThreeComponent } from './pages/step-three/step-three.component';
import { StepFiveComponent } from './pages/step-five/step-five.component';
import { StepGuard } from './_guards/step.guard';
import { ErrorComponent } from './pages/error/error.component';
import { SuccessComponent } from './pages/success/success.component';
import { LangChangeGuard } from './_guards/langChange.guard';
import {GtmGuard} from '@guards/GtmGuard';


const routes: Routes = [
  {
    path: '',
    component: ReservationFormComponent,
    canActivate: [LangChangeGuard],
    data: {
      menuDark: true,
      preventScroll: true,
      isReservationRoute: true,
      meta: {
        description: 'reservation_meta_description',
        keywords: 'reservation_meta_keywords'
      }
    },
    children: [
      {
        path: '',
        component: StepOneComponent,
        data: {
          meta: {
            title: 'reservation_step_1_header'
          },
          stepNo: 0
        },
      },
      {
        path: 'wybierz-oferte',
        component: StepTwoComponent,
        canActivate: [LangGuard, StepGuard],
        data: {
          forLanguages: ['pl'],
          group: 'step-2',
          meta: {
            title: 'reservation_step_2_header'
          },
          stepNo: 1
        },
      },
      {
        path: 'our-rooms',
        component: StepTwoComponent,
        canActivate: [LangGuard, StepGuard],
        data: {
          forLanguages: ['en'],
          group: 'step-2',
          meta: {
            title: 'reservation_step_2_header'
          },
          stepNo: 1
        },
      },
      {
        path: 'unsere-zimmer',
        component: StepTwoComponent,
        canActivate: [LangGuard, StepGuard],
        data: {
          forLanguages: ['de'],
          group: 'step-2',
          meta: {
            title: 'reservation_step_2_header'
          },
          stepNo: 1
        },
      },

      {
        path: 'pakiety',
        component: StepThreeComponent,
        canActivate: [LangGuard, StepGuard],
        data: {
          forLanguages: ['pl'],
          group: 'step-3',
          meta: {
            title: 'reservation_step_3_header'
          },
          stepNo: 2
        },
      },
      {
        path: 'packages',
        component: StepThreeComponent,
        canActivate: [LangGuard, StepGuard],
        data: {
          forLanguages: ['en'],
          group: 'step-3',
          meta: {
            title: 'reservation_step_3_header'
          },
          stepNo: 2
        },
      },
      {
        path: 'pakete',
        component: StepThreeComponent,
        canActivate: [LangGuard, StepGuard],
        data: {
          forLanguages: ['de'],
          group: 'step-3',
          meta: {
            title: 'reservation_step_3_header'
          },
          stepNo: 2
        },
      },

      {
        path: 'uzupelnij-dane',
        component: StepFourComponent,
        canActivate: [LangGuard, StepGuard],
        data: {
          forLanguages: ['pl'],
          group: 'step-4',
          meta: {
            title: 'fill_data'
          },
          stepNo: 3
        },
      },
      {
        path: 'complete-data',
        component: StepFourComponent,
        canActivate: [LangGuard, StepGuard],
        data: {
          forLanguages: ['en'],
          group: 'step-4',
          meta: {
            title: 'fill_data'
          },
          stepNo: 3
        },
      },
      {
        path: 'vervollstandige-daten',
        component: StepFourComponent,
        canActivate: [LangGuard, StepGuard],
        data: {
          forLanguages: ['de'],
          group: 'step-4',
          meta: {
            title: 'fill_data'
          },
          stepNo: 3
        },
      },

      {
        path: 'dokonaj-platnosci',
        component: StepFiveComponent,
        canActivate: [LangGuard, StepGuard],
        data: {
          forLanguages: ['pl'],
          group: 'step-5',
          loginHidden: true,
          meta: {
            title: 'make_payment'
          },
          stepNo: 4
        },
      },
      {
        path: 'complete-payment',
        component: StepFiveComponent,
        canActivate: [LangGuard, StepGuard],
        data: {
          forLanguages: ['en'],
          group: 'step-5',
          loginHidden: true,
          meta: {
            title: 'make_payment'
          },
          stepNo: 4
        },
      },
      {
        path: 'zur-vollstandigen-bezahlung',
        component: StepFiveComponent,
        canActivate: [LangGuard, StepGuard],
        data: {
          forLanguages: ['de'],
          group: 'step-5',
          loginHidden: true,
          meta: {
            title: 'make_payment'
          },
          stepNo: 4
        },
      },

      {
        path: 'sprobuj-ponownie',
        component: ErrorComponent,
        canActivate: [LangGuard],
        data: {
          forLanguages: ['pl'],
          group: 'error-page',
          meta: {
            title: 'reservation_error_header'
          }
        },
      },
      {
        path: 'try-again',
        component: ErrorComponent,
        canActivate: [LangGuard],
        data: {
          forLanguages: ['en'],
          group: 'error-page',
          meta: {
            title: 'reservation_error_header'
          }
        },
      },
      {
        path: 'versuch-es-noch-einmal',
        component: ErrorComponent,
        canActivate: [LangGuard],
        data: {
          forLanguages: ['de'],
          group: 'error-page',
          meta: {
            title: 'reservation_error_header'
          }
        },
      },

      {
        path: 'dokonano-rezerwacji',
        component: SuccessComponent,
        canActivate: [LangGuard, GtmGuard],
        data: {
          forLanguages: ['pl'],
          group: 'success-page',
          omitGTM: true,
          meta: {
            title: 'reservation_success_meta_header'
          }
        },
      },
      {
        path: 'reservation-accomplished',
        component: SuccessComponent,
        canActivate: [LangGuard, GtmGuard],
        data: {
          forLanguages: ['en'],
          group: 'success-page',
          omitGTM: true,
          meta: {
            title: 'reservation_success_meta_header'
          }
        },
      },
      {
        path: 'eine-reservierung-wurde-gemacht',
        component: SuccessComponent,
        canActivate: [LangGuard, GtmGuard],
        data: {
          forLanguages: ['de'],
          group: 'success-page',
          omitGTM: true,
          meta: {
            title: 'reservation_success_meta_header'
          }
        },
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReservationFormRoutingModule { }

export {
  routes
};
