import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {TreatmentService} from '@services/treatment.service';
import {ITreatment, ITreatmentDetails} from '@interfaces/ITreatment';
import {ActivatedRoute} from '@angular/router';
import ICategory from '@interfaces/ICategory';
import IFilter from '@interfaces/IFilter';
import {MetaService} from '@services/meta.service';
import {I18nRoutePipe} from '../../../../_pipes/i18n-route.pipe';
import {CurrentRouteHelper} from '@helpers/CurrentRouteHelper';
import AssetTypeEnum from '@enums/AssetTypeEnum';
import ConfigService from '@config/ConfigService';
import ICard from '@interfaces/cards/ICard';
import IPrice from '@interfaces/IPrice';
import IOrder from '@interfaces/IOrder';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-relax-and-beauty-details',
  templateUrl: './relax-and-beauty-details.component.html',
  styleUrls: ['./relax-and-beauty-details.component.scss']
})
export class RelaxAndBeautyDetailsComponent extends SubscribeHelper implements OnInit, OnDestroy {

  item: ITreatmentDetails = null;
  recommendedItems: ITreatment[] = [];
  recommendedCategory: ICategory = null;
  card$: EventEmitter<ICard> = new EventEmitter();
  // priceCheckerInfo$: BehaviorSubject<IPriceChecker> = new BehaviorSubject<IPriceChecker>(null);
  lowestPrice: IPrice = null;
  priceCheckInfo: any = {}; // nowy interface?

  constructor(
    private activatedRoute: ActivatedRoute,
    private treatmentService: TreatmentService,
    private metaService: MetaService,
    private i18nRoute: I18nRoutePipe,
    private currentRouteHelper: CurrentRouteHelper
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe( params => {
        this.getTreatmentDetails( params.slug );
      });
  }

  ngOnDestroy() {
    this.currentRouteHelper.removeLastGroupTranslation();
  }

  getRecommended( item: ITreatmentDetails ): void {
    if ( item ) {

        const filters: IFilter[] = [];
        const orders: IOrder[] = [];

        orders.push({
          field: '_rand',
          type: ''
        });

        this.treatmentService.getList(filters, orders, 4)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(items => {
            if (items) {
              items = items.filter(i => {
                return i.id !== item.id;
              });

              if (items.length > 3) {
                items = items.slice(0, 3);
              }
            }

            this.recommendedItems = items;
          });
      // }
      // else {
      //   this.recommendedItems = [];
      // }
    }
  }

  getTreatmentDetails( slug: string ): void {
    this.treatmentService.getDetails( slug )
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe( item => {
        this.item = item;
        this.currentRouteHelper.setLastGroupTranslation(this.item.trans);

        this.metaService.setAlternateURLWithITrans( item.trans, item.slug, this.i18nRoute );
        this.getRecommended( item );
        this.setMetaData( item );

        try {
          item.prices.forEach(treatmentPrice => {
            if (this.lowestPrice) {
              if (this.lowestPrice.value > treatmentPrice.price.value) {
                this.lowestPrice = treatmentPrice.price;
              }
            }
            else {
              this.lowestPrice = treatmentPrice.price;
            }
          });
        }
        catch (e) {
          this.lowestPrice = { value: 0, currency: ConfigService.getCurrentCurrency() };
        }

        this.card$.next( item.card );

        this.priceCheckInfo = {
          durations: item.durations,
          categories: item.categories,
          tags: item.tags
        };

        // this.priceCheckerInfo$.next( this.priceCheckInfo );
      });
  }

  setMetaData( item: ITreatmentDetails ) {
    let ogImage: string;
    if ( item.ogImage ) {
      if ( item.ogImage.path && item.ogImage.type === AssetTypeEnum.Image ) {
        ogImage = item.ogImage.path;
      }
    }

    this.metaService.appendTitle( item.name );
    this.metaService.setKeywords( item.keywords );
    this.metaService.setSocialMediaTags( ConfigService.getCurrentRoute(), null, item.metaDescription || '', ogImage);
  }
}
