import {Component, OnInit} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  goToBlogostan( e: MouseEvent ): void {
    e.preventDefault();

    if ( typeof window !== 'undefined' ) {
      if (window.parent) {
        window.parent.location.href = environment.blogostanUrl;
      }
    }
  }

}
