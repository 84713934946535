<div class="container pt-zetta mobile-padding text-simple-centered">
  <app-text-simple-centered>
    <p class="p-responsive" [innerHTML]="'relax_and_beauty_text_centered_c'|translate|newLineBreak|removeWidows"></p>
    <div class="actions pt-mega pb-md-kilo">
      <app-wellness-menu-btn text="download_wellness_menu"></app-wellness-menu-btn>
    </div>
  </app-text-simple-centered>
</div>

<div class="container mobile-padding">
 <div class="row mt-5">
   <div class="col-12 col-md-10 offset-md-1">
     <div class="out-mobile-padding">
       <app-filters
         [center]="true"
         [categories]="treatmentsCategories"
         (values)="filterValues($event)"
         [hideSearchfield]="true"
         [showLoader$]="showCategoryLoader$"
       ></app-filters>
     </div>
   </div>
 </div>
</div>

<app-after-before-grey
  width="33%"
  height="1174px"
  top="555px"
  left="0"
  additionalClasses="to-top"
>
  <div class="container mobile-padding pt-peta pt-xl-zetta text-simple-centered" *ngIf="list.length > 0">
    <div class="row">
      <ng-container *ngFor="let item of list;index as i">
        <div
          class="col-12 pt-giga"
          [ngClass]="{
            'col-lg-12 col-xl-10': item.isSingledOut,
            'col-lg-6 col-xl-5': !item.isSingledOut,
            'offset-xl-1': ((i - (isFirstSingledOut?1:0)) % 2 === 0 && !item.isSingledOut) || item.isSingledOut,
            'pt-0': item.isSingledOut
          }"
        >
          <div class="full-out-mobile-padding">
            <app-text-offer
              [image]="item?.photo?.path|apiUrl"
              [imageAlt]="item?.photo?.alt"
              [isSingledOut]="item.isSingledOut"
              [isBestseller]="item.isBestseller"
              [isNew]="item.isNew"
              [link]="'/:lang/wellness/relax/' + item?.slug|i18nRoute"
              [itemId]="item?.id"
              [onLinkClick]="setElementInfo"
              isWellness
            >
              <div>
                <h2
                  *ngIf="item.isSingledOut"
                  [innerHTML]="item?.name|removeWidows"
                ></h2>
                <h3
                  *ngIf="!item.isSingledOut"
                  [innerHTML]="item?.name|removeWidows"
                ></h3>
                <p
                  [ngClass]="{
                    'pt-hecto small': item.isSingledOut,
                    'pt-deci tiny': !item.isSingledOut
                  }"
                  [innerHTML]="item?.description|removeWidows"
                ></p>
              </div>
              <div class="pt-hecto">
                <a
                  (click)="setElementInfo(item?.id)"
                  [routerLink]="'/:lang/wellness/relax/' + item?.slug|i18nRoute"
                  class="btn btn-bordered on-white"
                >
                  {{ 'check_details'|translate }}
                </a>
              </div>
            </app-text-offer>
          </div>
        </div>
      </ng-container>
      <div class="col-12 text-center pt-giga content-center" *ngIf="responseCurrent < responseMax && !loading">
        <button class="btn btn-collapse" (click)="loadMore()">{{ 'see_more'|translate }}</button>
      </div>
      <div class="col-12 pt-giga" *ngIf="loading">
        <app-loader [size]="30" [opacity]="'0.7'" [center]="true"></app-loader>
      </div>
    </div>
  </div>

  <div class="container mobile-padding" *ngIf="list.length === 0 && !loading">
    <div class="row">
      <div class="col pt-giga pt-xl-peta text-center">
        <p
          class="text-light-gray h3"
          *ngIf="currentLang == 'pl'"
        >
          {{'no_offers_available'|translate|removeWidows}}
        </p>

        <ng-container *ngIf="currentLang == 'de' || currentLang == 'en'">
          <p
            class="text-light-gray h3"
            *ngIf="currentLang == 'de'"
          >
            Bitte kontaktieren Sie uns, um einen Termin zu vereinbaren:
          </p>
          <p
            class="text-light-gray h3"
            *ngIf="currentLang == 'en'"
          >
            Please contact us to make an appointment:
          </p>
          <p class="pt-deca pb-centi"><a href="tel:+48943554050" class="text-decoration-none">+48 94 355 4050</a></p>
          <p><a href="mailto:wellness@shuumhotel.pl" class="text-decoration-none">wellness@shuumhotel.pl</a></p>
        </ng-container>

      </div>
    </div>
  </div>
</app-after-before-grey>

<!--  <div class="pt-peta pt-md-zetta pt-lg-200">-->
<!--    <app-discover-->
<!--      [header]="'discover_also'"-->
<!--      [name]="'Intense Repair\nShea Butter'"-->
<!--      [description]="'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit in voluptate velit esse.'"-->
<!--      [price]="{ value: 49.99, currency: 'PLN' }"-->
<!--      [link]="''"-->
<!--    ></app-discover>-->
<!--  </div>-->
<div class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 offset-xl-1">
        <div class="out-mobile-padding">
          <app-images-carousel
            [images]="carouselImages"
          >
          </app-images-carousel>
        </div>
      </div>
      <div class="col-12 col-lg-5 col-xl-4 offset-lg-1">
        <div class="pt-kilo pt-md-giga pt-lg-zero">
          <app-indent-content
            [header]="'our_team'"
          >
            <p
              class="small"
              [innerHTML]="'our_team_description'|translate|newLineBreak|removeWidows"
            >
            </p>

            <div class="actions pt-mega pb-deca">
              <a [routerLink]="'/:lang/career'|i18nRoute" class="btn btn-bordered mb-deci">{{ 'join_us'|translate }}</a>
            </div>
          </app-indent-content>
        </div>
      </div>
    </div>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-end">
      <div class="col-10 offset-lg-1 offset-xl-2">
        <h3 class="text-light-gray" [innerHTML]="'certifications'|translate|removeWidows"></h3>
      </div>
      <div class="col-12 col-md-6 col-lg-5 col-xl-4 offset-lg-1 offset-xl-2 order-1 order-md-0">
        <p
          class="pt-giga pt-lg-peta small"
          [innerHTML]="'certifications_description'|translate|newLineBreak|removeWidows"
        ></p>
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-3 offset-lg-1 order-0 order-md-1">
        <div class="pt-giga pt-md-zero">
          <a
            href="https://www.wellnessverband.de/wellness-freunde/anbieter/detail.php?we_oid=245"
            target="_blank"
            class="opacity-link"
          >
            <app-text-centered-image
              header="{{ 'award_h_04'|translate}}"
              image="/assets/images/wellness_page/certificate.png"
              image2x="/assets/images/wellness_page/certificate@2x.png"
              imgMaxHeight="120px"
              imageAlt="certificate logo"
            ></app-text-centered-image>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
