import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  PLATFORM_ID,
  OnDestroy,
} from '@angular/core';
import GlobalFunctions from '../../_helpers/GlobalFunctions';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import ScrollMagic from 'scrollmagic';
import { Router, NavigationEnd } from '@angular/router';
import { GlobalStateHelper } from '@helpers/GlobalStateHelper';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import {LayoutService} from '@services/layout.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import {IVideo} from '@interfaces/IVideo';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent extends SubscribeHelper implements OnInit, OnDestroy {
  public isRetinaDisplay: boolean;
  public bg: string;
  public isButton = false;

  private magic: any;
  private buttonMagicInitialized: boolean = false;
  scrollToConfig: ScrollToConfigOptions;

  @Input() header: string;
  @Input() description: string;
  @Input() set background(path: string) {
    if ( this.isRetinaDisplay !== null ) {
      this.bg = path;
    }
  }
  @Input() set background2x(path: string) {
    if (this.isRetinaDisplay) {
      this.bg = path;
    }
  }
  @Input() textColor = 'text-white';
  @Input() withOverlay = true;
  @Input() isRightStickedBtn;
  @Input() btnScrollTo: string;
  @Input() isTextLong?: boolean;
  @Input() fullWidth: boolean;
  @Input() center: boolean;
  @Input() video?: IVideo;

  @ViewChild('banner', {static: true}) banner: ElementRef;
  @ViewChild('rightBtn', {static: true}) rightBtn: ElementRef;
  @ViewChild('scrollToBanner', {static: true}) scrollToBanner: ElementRef;

  constructor(
    private globalStateService: GlobalStateHelper,
    private scrollToService: ScrollToService,
    private layoutService: LayoutService,
    @Inject(DOCUMENT) private document,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    super();
    this.isRetinaDisplay = GlobalFunctions.isRetinaDisplay();
  }

  ngOnInit() {
    if ( this.bg ) {
      this.layoutService.isBannerLoaded$.next(false);
    }

    this.globalStateService.getBtnStickedChange()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(val => {
        this.isButton = val;

        this.initScrollMagic();
      });

    if ( this.video ) {
      this.layoutService.isBannerLoaded$.next(true);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  scrollToTarget( e: MouseEvent ): void {
    e.preventDefault();
    this.scrollToService
      .scrollTo({
        target: this.btnScrollTo,
        offset: -106,
      });
  }

  initScrollMagic() {
    if ( isPlatformBrowser(this.platformId) ) {
      const ctrl = new ScrollMagic.Controller();

      if ( this.magic ) {
        if ( typeof this.magic.destroy !== 'undefined' ) {
          this.magic.destroy();
        }
      }

      try {
        if ( this.isButton ) {
          this.magic = new ScrollMagic.Scene({
            triggerElement: this.scrollToBanner.nativeElement,
            offset: 1,
            triggerHook: 1
          })
          .setClassToggle(this.rightBtn.nativeElement, 'scrolled')
          .addTo(ctrl);

          this.magic = new ScrollMagic.Scene({
            triggerElement: this.document.getElementById(this.btnScrollTo),
            triggerHook: 0.5
          })
          .setClassToggle(this.rightBtn.nativeElement, 'hidden')
          .addTo(ctrl);

          this.buttonMagicInitialized = true;
        }
      } catch ( e ) {
        console.log('Error! ' + e);
      }
    }
  }

  imgLoaded(e) {
    this.layoutService.isBannerLoaded$.next( true );
  }
}







