import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import ConfigService from '@config/ConfigService';

const I18N_VALUES = {
  en: {
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    monthsFull: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]
  },
  pl: {
    weekdays: ['Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So', 'Nd'],
    months: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
    monthsFull: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień']
  },
  de: {
    weekdays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
    months: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    monthsFull: [ 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ]
  }
};

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  locale: string;

  constructor() {
    super();

    this.locale = ConfigService.getCurrentLang();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this.locale].weekdays[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return I18N_VALUES[this.locale].months[month - 1];
  }

  getMonthFullName(month: number): string {
    return I18N_VALUES[this.locale].monthsFull[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    if ( date ) {
      return `${date.day} ${this.getMonthFullName(date.month)} ${date.year}`;
    }

    return '';
  }

  getFullDateAsString(date: NgbDateStruct): string {
    const dateString = this.getDayAriaLabel( date );

    if ( typeof dateString === 'string' ) {
      return dateString.toLowerCase();
    }

    return dateString;
  }

  getDateAsString(date: NgbDateStruct): string {
    const year = date.year;
    const month = date.month;
    const day = date.day;

    const result = [];
    result.push(year);

    if ( month <= 9 ) {
      result.push( '0' + month );
    }
    else {
      result.push( month );
    }

    if ( day <= 9 ) {
      result.push( '0' + day );
    }
    else {
      result.push( day );
    }

    return result.join('-');
  }
}
