<div class="row">
  <div class="col-12 col-lg-11 col-xl-10 offset-lg-1 offset-xl-2">
    <div class="header pb-tera">
      <h3>{{ header|translate }}</h3>
    </div>
  </div>

  <div class="col-12 col-md-12 col-xl-10 offset-xl-1">
    <div class="out-mobile-padding">
      <div class="discover">
        <div
          class="
            row
            align-items-sm-center
            align-content-sm-center
            align-items-md-center
            align-content-md-center
            align-items-lg-start
            align-content-lg-start
          "
        >
          <div class="col-12 col-sm-5 col-md-4">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-11 col-xl-11 col-xxl-9 text-right">
                <img src="../../../assets/images/discover/01.jpg" alt="discover image" />
              </div>

              <div class="col-12 col-sm-10 col-md-9 offset-sm-2 offset-md-2 d-none d-sm-block d-lg-none">
                <div class="pt-deci text-left">
                  <app-price
                    [price]="price"
                    size="50 ml"
                  ></app-price>

                  <div class="actions pt-deca pb-kilo">
                    <a href="#" class="btn btn-bordered">{{ 'more_details'|translate }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-7 col-md-6 col-lg-4 offset-md-1 offset-lg-0">
            <div class="content">
              <h3 [innerHTML]="name|translate|newLineBreak|removeWidows"></h3>

              <div class="pt-deca">
                <p class="small" [innerHTML]="description|translate|newLineBreak|removeWidows"></p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 d-block d-sm-none d-lg-block">
            <div class="row">
              <div class="col-12 col-md-9 text-left text-sm-right">
                <div class="content">
                  <app-price
                    [price]="price"
                    size="50 ml"
                  ></app-price>

                  <div class="actions pt-kilo pt-sm-mega pb-kilo">
                    <a href="#" class="btn btn-bordered">{{ 'more_details'|translate }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
