<div *ngIf="user" class="nav-notifications">
  <a
    [routerLink]="'/:lang/my-reservation/shuumme-panel-group/notifications-panel-group'|i18nRoute"
    fragment="account_details"
  >
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1094_4585)">
        <path d="M21.6129 20.0497C20.6538 19.0968 20.0666 17.7939 20.0666 16.3354V7.99285C20.0666 4.12299 16.8958 0.972656 13.0008 0.972656C9.10575 0.972656 5.93494 4.12299 5.93494 7.99285V16.3354C5.93494 17.7939 5.34775 19.0968 4.38867 20.0497H21.6129Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
        <path d="M16.054 21.9941C16.054 23.6665 14.6839 25.0278 13.0006 25.0278C11.3174 25.0278 9.94727 23.6665 9.94727 21.9941" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
      </g>
      <defs>
        <clipPath id="clip0_1094_4585">
          <rect width="26" height="26" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    <ng-container *ngIf="notificationsQuantity$ | async as notificationsQuantity">
      <span class="notification-badge" *ngIf="notificationsQuantity">{{ notificationsQuantity }}</span>
    </ng-container>
  </a>
</div>
