<div
  [class]="'content2plus1-container ' + (classes ? classes : '')"
  #container
  [ngClass]="{'first-animated': !firstVisible}"
>
  <div class="container wide">
    <div class="images-wrapper">
      <div id="sticky" #sticky>
        <div
          [class]="'pt-peta pt-md-zetta pt-xl-0 container-2plus1 images' + ((i === 0) ? ' active' : '')"
          *ngFor="let item of data; let i = index"
          #scrolledImages
        >
          <div class="row">
            <div class="image-left col-6 offset-1 offset-md-1 col-lg-6 offset-lg-1 col-xl-5 offset-xl-0 col">
              <img
                class="img-fluid"
                [defaultImage]="'/assets/images/placeholders/placeholder504x494.png'"
                [lazyLoad]="hasSrcSetSupport ? (item.imageLeft.image + ' 1x, ' + item.imageLeft.image2x + ' 2x') : item.imageLeft.image"
                [useSrcset]="hasSrcSetSupport"
                [alt]="item.imageLeft.alt"
              />
            </div>
            <div class="image-right col-4 order-1 col-sm-3 col-md-3 col-lg-3 col-xl-3 offset-xl-4 col">
              <img
                class="img-fluid"
                [defaultImage]="'/assets/images/placeholders/placeholder252x494.png'"
                [lazyLoad]="hasSrcSetSupport ? (item.imageRight.image + ' 1x, ' + item.imageRight.image2x + ' 2x') : item.imageRight.image"
                [useSrcset]="hasSrcSetSupport"
                [alt]="item.imageRight.alt"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-wrapper" [ngStyle]="{'padding-top': marginTop + 'px'}">
      <div
        class="pt-peta pt-md-zetta pt-xl-0 container-2plus1 text"
        *ngFor="let item of data; let i = index"
        [ngStyle]="{'opacity': opacities.length > 0 ? opacities[i] : 0}"
        #scrolledElements
      >
        <div class="row">
          <div class="image-left col-6 offset-1 offset-md-1 col-lg-6 offset-lg-1 col-xl-5 offset-xl-0 col d-block d-xl-none">
            <img
              class="img-fluid"
              [defaultImage]="'/assets/images/placeholders/placeholder504x494.png'"
              [lazyLoad]="hasSrcSetSupport ? (item.imageLeft.image + ' 1x, ' + item.imageLeft.image2x + ' 2x') : item.imageLeft.image"
              [useSrcset]="hasSrcSetSupport"
              [alt]="item.imageLeft.alt"
            />
          </div>
          <div
            class="text-content col-12 col-sm-8 offset-sm-2 col-md-7 offset-md-2 order-2 col-xl-4 offset-xl-5 order-xl-1 col"
            [ngStyle]="{
              'opacity': i === 0 && firstOpacity ? '1' : null,
              'transition-duration': i === 0 && firstOpacity ? '0.5s' : null,
              'animation-duration': i === 0 && firstOpacity ? '0.8s' : null
            }"
          >
            <div>
              <h2 class="heading pb-mega" [innerHTML]="item.heading|translate|newLineBreak|removeWidows"></h2>
              <div class="paragraph">
                <p class="small pb-mega" [innerHTML]="item.content|translate|newLineBreak|removeWidows"></p>
                <app-button
                  *ngIf="item.buttonText && item.buttonLink"
                  [buttonText]="item.buttonText|translate"
                  [buttonHref]="item.buttonLink|i18nRoute"
                  [buttonLight]="false"
                ></app-button>
                <app-button
                  *ngIf="item.buttonText && item.scrollTo"
                  [buttonText]="item.buttonText|translate"
                  [scrollTo]="item.scrollTo"
                  [buttonLight]="false"
                ></app-button>
              </div>
            </div>
          </div>
          <div class="image-right col-4 order-1 col-sm-3 col-md-3 col-lg-3 col-xl-3 col d-block d-xl-none">
            <img
              class="img-fluid"
              [defaultImage]="'/assets/images/placeholders/placeholder252x494.png'"
              [lazyLoad]="hasSrcSetSupport ? (item.imageRight.image + ' 1x, ' + item.imageRight.image2x + ' 2x') : item.imageRight.image"
              [useSrcset]="hasSrcSetSupport"
              [alt]="item.imageRight.alt"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="2plus1-end"></div>
