import { Injectable } from '@angular/core';
import {MainService} from './main.service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import IFilter from '@interfaces/IFilter';
import IOrder from '@interfaces/IOrder';
import {Observable} from 'rxjs';
import {IOffer, IOfferDetails} from '@interfaces/IOffer';

@Injectable({
  providedIn: 'root'
})
export class OfferService extends MainService {
  listUrl = '/offers';
  detailsUrl = '/offers';

  constructor( protected http: HttpClient ) {
    super( http );
  }

  getList( filters?: IFilter[], orders?: IOrder[], limit?: number, page?: number ): Observable<IOffer[]>{
    return super.getList( filters, orders, limit, page );
  }

  getListExtended( filters?: IFilter[], orders?: IOrder[], limit?: number, page?: number ): Observable<HttpResponse<IOffer[]>> {
    return super.getList( filters, orders, limit, page, true );
  }

  getDetails( slug: string ): Observable<IOfferDetails> {
    return super.getDetails( slug );
  }
}
