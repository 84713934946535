import { Injectable } from '@angular/core';
import {MainService} from '@services/main.service';
import {HttpClient} from '@angular/common/http';
import IFilter from '@interfaces/IFilter';
import IOrder from '@interfaces/IOrder';
import {Observable} from 'rxjs';
import {INewRoom, INewRoomDetails} from '@interfaces/INewRoom';

@Injectable({
  providedIn: 'root'
})
export class NewRoomService extends MainService {
  listUrl = '/rooms';
  detailsUrl = '/rooms';

  constructor( protected http: HttpClient ) {
    super( http );
  }

  getList( filters?: IFilter[], orders?: IOrder[], limit?: number, page?: number ): Observable<INewRoom[]>{
    return super.getList( filters, orders, limit, page );
  }

  getDetails( slug: string ): Observable<INewRoomDetails> {
    return super.getDetails( slug );
  }
}
