<app-popup-content #popup>
  <div content1>
    <h3 class="pb-deca pb-md-hecto text-center">{{ 'log_in'|translate }}</h3>
    <app-login-content (loginOutput)="onLogin()">
      <a
        class="link-underlined text-center d-block mt-hecto mt-md-zero"
        (click)="redirectWithBlockedScroll($event, forgottenPassword.getAttribute('href'))"
        [href]="'/:lang/my-reservation/authorization-group/recover-password-group'|i18nRoute"
        #forgottenPassword
      >
        {{ 'forgotten_password'|translate }}
      </a>
    </app-login-content>
  </div>
  <div content2>
    <h3 class="pb-mega text-center">{{ 'create_account'|translate }}</h3>
    <a
      class="btn btn-bordered on-white w-100"
      (click)="redirectWithBlockedScroll($event, register.getAttribute('href'))"
      [href]="'/:lang/my-reservation/authorization-group/sign-up-group'|i18nRoute"
      #register
    >
      {{ 'register'|translate }}
    </a>
  </div>
</app-popup-content>
