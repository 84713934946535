import { Pipe, PipeTransform } from '@angular/core';
import { ReservationDataService } from '@services/reservations/reservation-data.service';
import * as moment from 'moment';

@Pipe({
  name: 'formatExtraDate'
})
export class FormatExtraDatePipe implements PipeTransform {

  constructor(private reservationData: ReservationDataService) {
       
  }

  transform(offerDates: Date[]): string {
    const [ dateStart, dateEnd ] = offerDates;
    let momentDateStart = moment(dateStart);
    let momentDateEnd = moment(dateEnd);

    const { from, to } = this.reservationData.data.date$.value;
    const momentFrom = moment([from.year, from.month - 1, from.day]);
    const momentTo = moment([to.year, to.month - 1, to.day]);

    if (momentTo.isSameOrBefore(momentDateEnd)) {
      momentDateEnd = momentTo;
    }

    if (momentDateStart.isSameOrBefore(momentFrom)) {
      momentDateStart = momentFrom;
    }


    const isSameYear = momentDateStart.isSame(momentDateEnd, 'year');
    const isSameMonth = momentDateStart.isSame(momentDateEnd, 'month');
    const isSameDay = momentDateStart.isSame(momentDateEnd, 'day');

    let parseDate = `${momentDateStart.format('D')}
              ${momentDateStart.format('MMMM')}
              ${momentDateStart.format('YYYY')}`;

    if (!isSameDay) {
      parseDate = `${momentDateStart.format('D')}-${momentDateEnd.format('D')}
              ${momentDateStart.format('MMMM')}
              ${momentDateStart.format('YYYY')}`;
    }

    if (!isSameMonth) {
      parseDate = `${momentDateStart.format('D MMMM')}
                   - ${momentDateEnd.format('D MMMM')}
                   ${momentDateStart.format('YYYY')}`;
    }

    if (!isSameYear) {
      parseDate = `${momentDateStart.format('D MMMM YYYY')}
                   - ${momentDateEnd.format('D MMMM YYYY')}`;
    }

    return parseDate;
  }

}
