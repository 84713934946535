<div class="text-box">
  <h5 *ngIf="header">{{ header | translate }}</h5>
  <div class="text-content">
    <ng-content></ng-content>
  </div>
  <div *ngIf="button" class="button-wrapper mt-deca">
    <app-loader [size]="30" [opacity]="'0.7'" *ngIf="button.url === null"></app-loader>
    <a
      *ngIf="button.url"
      [href]="button.url"
      target="_blank"
      class="btn btn-bordered"
    >{{ button.text | translate }}</a>
    <a
      *ngIf="button.link"
      [routerLink]="button.link"
      [target]="button.target"
      class="btn btn-bordered"
    >{{ button.text | translate }}</a>
  </div>
</div>
