<app-popup-content #popup [hideSecondPart]="true" [scrollToTop]="true">
  <div content1 class="pt-centi pb-centi">
    <h3 class="pb-giga text-center">{{ 'logout_header'|translate }}</h3>
    <p class="smaller-text weight-300 pb-giga text-center">{{ 'logout_desc'|translate }}</p>
    <app-back-arrow
      [linkTo]="'/:lang'|i18nRoute"
      text="back_to_home"
      [popup]="popup"
    ></app-back-arrow>
  </div>
</app-popup-content>
