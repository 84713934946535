import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-container-h3-header',
  templateUrl: './container-h3-header.component.html',
  styleUrls: ['./container-h3-header.component.scss']
})
export class ContainerH3HeaderComponent implements OnInit {
  @Input() header: string;

  constructor() { }

  ngOnInit() {
  }

}
