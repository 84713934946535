<div class="container mobile-padding" [ngClass]="{ 'pt-kilo pb-giga': user?.loyaltyProgram, 'loyalty-program': user?.loyaltyProgram }">
  <div class="row">
    <div class="col-xl-6 offset-xl-1 pt-giga pt-lg-peta pb-giga" *ngIf="reservationsLength > 0 && !user?.loyaltyProgram">
      <p class="small">
        {{ 'reservation_account_desc'|translate }}
      </p>
    </div>
    <div class="offset-lg-2 col-lg-8 col-xl-6 offset-xl-3 text-center" [ngClass]="{ 'pt-exa pt-md-zetta': !user?.loyaltyProgram }" *ngIf="reservationsLength === 0">
      <p class="small text-center pb-mega">
        {{ 'my_account_no_reservation'|translate }}
      </p>
      <a class="btn btn-bordered pl-hecto pr-hecto" [routerLink]="'/:lang/reservation/'|i18nRoute">{{ 'go_to_reservation'|translate }}</a>
    </div>
    <ng-container *ngFor="let key of reservationStayTypesEnum | keys; index as i">
      <div
        [class]="user?.loyaltyProgram ? 'pb-giga' : 'pb-giga pb-lg-peta'"
        class="col-xl-10 offset-xl-1 items-container"
        *ngIf="reservations && reservations[key]"
      >
        <h3 [class]="user?.loyaltyProgram ? 'smaller font-weight-normal text-loyalty-gray pb-deca' : 'text-light-gray-color pb-hecto pb-lg-giga'"
        >
          {{ reservationStayTypesEnum[key]|translate }}
        </h3>
        <div
          [ngClass]="[
            j < reservations[key].length - 1 ? ' mb-hecto' : '',
            user?.loyaltyProgram ? key : 'pt-deca pb-deca pl-deca pr-deca box-shadowed'
          ]"
          *ngFor="let reservation of reservations[key] | slice: 0 : visibleItems[key]; index as j"
        >
          <div class="reservation-fragments">
            <div class="reservation-fragment number">
              <p class="reservation-fragment-title light-subtitle" [class]="user?.loyaltyProgram ? 'pb-centi' : 'pb-deca pb-xl-hecto'">{{ 'reservation_number'|translate }}</p>
              <p class="reservation-fragment-element">
                {{ reservation.number }}
              </p>
            </div>
            <div class="reservation-fragment date">
              <p class="reservation-fragment-title light-subtitle" [class]="user?.loyaltyProgram ? 'pb-centi' : 'pt-deca pt-md-zero pb-deca pb-xl-hecto'">{{ 'date_of_stay'|translate }}</p>
              <p class="reservation-fragment-element">
                {{ reservation.start }} — {{ reservation.end }}
              </p>
            </div>
            <div class="reservation-fragment shuumME-points">
              <p class="reservation-fragment-title light-subtitle" [class]="user?.loyaltyProgram ? 'pb-centi' : 'pt-deca pt-lg-zero pb-deca pb-xl-hecto'">{{ 'shuumME_points'|translate }}</p>
              <p class="reservation-fragment-element">
                {{ reservation.points === null ? '-' : reservation.points }}
              </p>
            </div>
<!--            <div class="reservation-fragment room-type">-->
<!--              <p class="reservation-fragment-title light-subtitle" [class]="user?.loyaltyProgram ? 'pb-centi' : 'pt-deca pt-lg-zero pb-deca pb-xl-hecto'">{{ 'room_type'|translate }}</p>-->
<!--              <p class="reservation-fragment-element" *ngFor="let room of reservation.rooms">-->
<!--                {{ room.name }}-->
<!--              </p>-->
<!--            </div>-->
            <div class="reservation-fragment price">
              <p class="reservation-fragment-title light-subtitle" [class]="user?.loyaltyProgram ? 'pb-centi' : 'pt-deca pt-lg-zero pb-deca pb-xl-hecto'">{{ 'value'|translate }}</p>
              <p class="reservation-fragment-element">
                <span>
                  <span class="price-main">{{ reservation.bookingPrice.value|price }}</span>
                  <span class="price-currency uppercase">{{ reservation.bookingPrice.currency }}</span>
                </span>
              </p>
            </div>
          </div>
          <ng-container *ngIf="!user?.loyaltyProgram">
            <div class="row justify-content-center actions pt-hecto">
              <div class="col-md-4 pr-md-zero mr-md-centi">
                <a class="btn btn-bordered w-100" [routerLink]="('/:lang/my-reservation/reservation-panel-group/' + reservation.id) |i18nRoute">{{ 'reservation_details'|translate }}</a>
              </div>
              <div class="col-md-4 pl-md-zero ml-md-centi pt-deci pt-md-zero">
                <button
                  class="btn btn-bordered w-100"
                  (click)="showPopup(reservation)"
                  [disabled]="reservationStayTypesEnum[key] !== reservationStayTypesEnum.upcoming"
                >{{ 'change_reservation'|translate }}</button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="user?.loyaltyProgram">
            <div class="d-flex flex-wrap justify-content-center actions">
              <div class="pt-deci pl-centi pr-centi">
                <a class="btn btn-bordered w-100" [routerLink]="('/:lang/my-reservation/reservation-panel-group/' + reservation.id) |i18nRoute">{{ 'details'|translate }}</a>
              </div>
              <div *ngIf="reservationStayTypesEnum[key] === reservationStayTypesEnum.upcoming" class="pt-deci pl-centi pr-centi">
                <button
                  class="btn btn-bordered w-100"
                  (click)="showPopup(reservation)"
                >{{ 'change_reservation'|translate }}</button>
              </div>
              <div *ngIf="reservationStayTypesEnum[key] === reservationStayTypesEnum.cancelled || reservationStayTypesEnum[key] === reservationStayTypesEnum.rest" class="pt-deci pl-centi pr-centi">
                <a class="btn btn-bordered w-100" [routerLink]="('/:lang/reservation') |i18nRoute">{{ 'book_again'|translate }}</a>
              </div>
              <div *ngIf="reservationStayTypesEnum[key] === reservationStayTypesEnum.rest" class="pl-centi pr-centi pt-deci">
                <a class="btn btn-bordered w-100" target="_blank" href="https://www.google.com/travel/hotels/shuum%20hotel/entity/CgoIkorzqrjUxfd8EAE/reviews?q=shuum%20hotel&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4597339%2C4754388%2C4757164%2C4814050%2C4850738%2C4861688%2C4864715%2C4874190%2C4886480%2C4893075%2C4920132%2C4924070%2C4935108%2C4936396%2C4965990%2C4968087%2C4972345%2C4977499%2C4977987%2C4987686%2C4991446%2C4992509%2C47067249&hl=pl-PL&gl=pl&cs=1&ssta=1&rp=EJKK86q41MX3fBCSivOquNTF93w4AkAASAHAAQKaAgIIAA&ictx=1&sa=X&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESABpJCisSJzIlMHg0NzAwMTU4YzA1MmZlNjFmOjB4N2NlZjE2YTM4NTVjYzUxMhoAEhoSFAoHCOcPEAQYExIHCOcPEAQYFBgBMgIQACoJCgU6A1BMThoA">{{ 'share_opinion'|translate }}</a>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="text-center" *ngIf="visibleItems[key] < reservations[key].length">
          <button class="btn btn-bordered" (click)="showMore(key)">{{ 'see_more' | translate }}</button>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="user?.loyaltyProgram">
  <app-offer-stays [offers]="offers$ | async" [loading]="offersLoading"></app-offer-stays>
  <app-contact-form [bookingType]="bookingType" [bookingNames]="[]"></app-contact-form>
</ng-container>

<app-change-reservation-popup #popup></app-change-reservation-popup>
