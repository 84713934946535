import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {environment} from '../../environments/environment';
import GlobalFunctions from '@helpers/GlobalFunctions';
import ConfigService from '@config/ConfigService';

@Injectable()
export class DefaultLangGuard implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    try {
      this.redirectToLang( ConfigService.getCurrentLang() );
      return false;
    }
    catch ( e ) {

    }

    return true;
  }

  private redirectToLang( lang: string ) {
    const languages = environment.languages;

    if ( !GlobalFunctions.isLangAvailable(lang) ) {
      lang = environment.defaultLanguage;
    }

    this.router.navigateByUrl('/' + lang);
  }
}
