import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {LangGuard} from '@guards/LangGuard';
import {SaunasAndPoolComponent} from '@pages/wellness/_pages/saunas-and-pool/saunas-and-pool.component';
import {JogaAndFitnessComponent} from '@pages/wellness/_pages/joga-and-fitness/joga-and-fitness.component';
import {RelaxAndBeautyComponent} from '@pages/wellness/_pages/relax-and-beauty/relax-and-beauty.component';
import {MedicalWellnessComponent} from '@pages/wellness/_pages/medical-wellness/medical-wellness.component';
import {PrivateWellnessComponent} from '@pages/wellness/_pages/private-wellness/private-wellness.component';
import {WellnessComponent} from '@pages/wellness/wellness.component';
import {RelaxAndBeautyDetailsComponent} from '@pages/wellness/_pages/relax-and-beauty-details/relax-and-beauty-details.component';
import {WellnessEtiquetteComponent} from '@pages/wellness/_pages/wellness-etiquette/wellness-etiquette.component';
import {MedicalWellnessDetailsComponent} from '@pages/wellness/_pages/medical-wellness-details/medical-wellness-details.component';

const routes: Routes = [
  {
    path: 'saunas-and-pool',
    component: SaunasAndPoolComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['en'],
      group: 'saunas',
      meta: {
        title: 'saunas_and_pool',
        description: 'saunas_description_meta',
        keywords: 'saunas_keywords_meta'
      }
    }
  },
  {
    path: 'saunen-und-schwimmbad',
    component: SaunasAndPoolComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['de'],
      group: 'saunas',
      meta: {
        title: 'saunas_and_pool',
        description: 'saunas_description_meta',
        keywords: 'saunas_keywords_meta'
      }
    }
  },
  {
    path: 'sauny-i-basen',
    component: SaunasAndPoolComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['pl'],
      group: 'saunas',
      meta: {
        title: 'saunas_and_pool',
        description: 'saunas_description_meta',
        keywords: 'saunas_keywords_meta'
      }
    }
  },

  {
    path: 'yoga-and-fitness',
    component: JogaAndFitnessComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['en'],
      group: 'yoga',
      meta: {
        title: 'yoga_and_fitness',
        description: 'yoga_and_fitness_meta_description',
        keywords: 'yoga_and_fitness_meta_keywords'
      },
      menuDark: true
    }
  },
  {
    path: 'joga-and-fitness',
    component: JogaAndFitnessComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['de', 'pl'],
      group: 'yoga',
      meta: {
        title: 'yoga_and_fitness',
        description: 'yoga_and_fitness_meta_description',
        keywords: 'yoga_and_fitness_meta_keywords'
      },
      menuDark: true
    }
  },

  {
    path: 'medical-wellness',
    component: WellnessComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['en', 'de', 'pl'],
      group: 'medical-wellness',
      meta: {
        title: 'medical_wellness',
      },
      pageData: {
        header: 'medical_wellness',
        description: 'medical_wellness_description',
        background: '/assets/images/wellness_page/medical/banner_tiny.jpg',
        background2x: '/assets/images/wellness_page/medical/banner@2x_tiny.jpg',
        wrapperId: 'wellness'
      }
    },
    children: [
      {
        path: '',
        component: MedicalWellnessComponent,
        data: {
          meta: {
            description: 'medical_wellness_meta_description',
            keywords: 'medical_wellness_meta_keywords'
          }
        }
      },
      {
        path: ':slug',
        component: MedicalWellnessDetailsComponent,
        data: {
          group: 'details',
          scrollTo: '#wellness',
          scrollListTo: '#wellness',
          meta: {
            description: '',
            keywords: ''
          }
        }
      },
    ]
  },

  {
    path: 'relax-and-beauty',
    component: WellnessComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['en', 'de', 'pl'],
      group: 'relax',
      meta: {
        title: 'relax_and_beauty'
      },
      pageData: {
        header: 'relax_and_beauty',
        description: 'relax_and_beauty_description',
        background: '/assets/images/wellness_page/relax/banner_tiny.jpg',
        background2x: '/assets/images/wellness_page/relax/banner@2x_tiny.jpg',
        wrapperId: 'relax'
      }
    },
    children: [
      {
        path: '',
        component: RelaxAndBeautyComponent,
        data: {
          meta: {
            description: 'meta_desc_wellness_relax',
            keywords: 'meta_key_wellness_relax'
          }
        }
      },
      {
        path: ':slug',
        component: RelaxAndBeautyDetailsComponent,
        data: {
          group: 'details',
          scrollTo: '#relax',
          scrollListTo: '#relax',
          meta: {
            description: '',
            keywords: ''
          }
        }
      },
    ]
  },

  {
    path: 'private-wellness-room',
    component: PrivateWellnessComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['en', 'de', 'pl'],
      group: 'private-wellness',
      meta: {
        title: 'private_wellness_room',
        description: 'private_wellness_meta_description',
        keywords: 'private_wellness_meta_keywords'
      }
    }
  },

  {
    path: 'etiquette',
    component: WellnessEtiquetteComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['en'],
      group: 'etiquette',
      meta: {
        title: 'saunas_and_pool',
        description: 'saunas_description_meta',
        keywords: 'saunas_keywords_meta'
      }
    }
  },
  {
    path: 'etikette',
    component: WellnessEtiquetteComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['de'],
      group: 'etiquette',
      meta: {
        title: 'saunas_and_pool',
        description: 'saunas_description_meta',
        keywords: 'saunas_keywords_meta'
      }
    }
  },
  {
    path: 'etykieta',
    component: WellnessEtiquetteComponent,
    canActivate: [LangGuard],
    data: {
      forLanguages: ['pl'],
      group: 'etiquette',
      meta: {
        title: 'saunas_and_pool',
        description: 'saunas_description_meta',
        keywords: 'saunas_keywords_meta'
      }
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WellnessRoutingModule { }

export {
  routes
};
