<app-step-header
  header="fill_data"
  description="step_4_description"
  [showDate]="true"
></app-step-header>

<div class="reservation-step">
  <div *ngIf="loggedIn === false">
    <app-collapse
      [index]="1"
      header="login_register"
      [isActive]="1 === activeRoomIndex"
      (onTabClick)="clickOnTab($event)"
      [showBottomLine]="true"
      [disableOpacity]="true"
    >
      <div class="row pb-hecto pt-hecto pt-md-mega pb-lg-giga pt-lg-tera pl-deci pr-deci pl-sm-56 pr-sm-56 pl-xxl-peta pr-xxl-peta">
        <div class="col-12 col-lg-5">
          <app-login (loginHandler)="onLogin($event)"></app-login>
        </div>
        <div class="col-12 col-lg-7 pl-xl-giga">
          <app-register></app-register>
        </div>
      </div>
    </app-collapse>
  </div>

  <div>
    <app-collapse
      [index]="2"
      [header]="loggedIn ? 'booking_details' : 'book_without_registration'"
      [isActive]="2 === activeRoomIndex"
      (onTabClick)="clickOnTab($event)"
      [showBottomLine]="true"
      [disableOpacity]="true"
    >
      <div class="row pt-kilo pt-lg-giga pb-giga pl-deci pr-deci pl-sm-56 pr-sm-56 pl-xxl-peta pr-xxl-peta">
        <div class="col-12">
          <app-book-without-registration #bookData></app-book-without-registration>
        </div>
      </div>
    </app-collapse>
  </div>

  <p class="validation-msg-above" *ngIf="refreshError || nextStepMsg">{{ (refreshError ? 'refresh_error' : nextStepMsg) | translate }}</p>

  <app-booking-nav
    [backLink]="'/:lang/reservation/step-3'"
    [nextLink]="'/:lang/reservation/step-4'"
    [blocked]="buttonBlocked || loading"
    [nextText]="'next_step'"
    [loading]="loading"
    (clickHandler)="nextStep()"
  ></app-booking-nav>

  <app-footer></app-footer>
</div>