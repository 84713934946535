import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CounterComponent} from '@shared/counter/counter.component';
import {TranslateModule} from '@ngx-translate/core';



@NgModule({
  declarations: [
    CounterComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    CounterComponent
  ]
})
export class CounterModule { }
