<div class="user-panel-container container mobile-padding">
  <div class="row">
    <div class="col-xl-10 offset-xl-1 pt-tera user-panel-navigation">
      <ng-container>
        <a
          [ngClass]="{'active': activeLink === 1}"
          [routerLink]="'/:lang/my-reservation/shuumme-panel-group'|i18nRoute"
        >
          <span>{{ (user?.loyaltyProgram ? 'shuum_me' : 'join') | translate }}</span>
        </a>
        <a
          [ngClass]="{'active': activeLink === 2}"
          [routerLink]="'/:lang/my-reservation/reservation-panel-group'|i18nRoute"
        >
          <span>{{ 'my_reservations'|translate }}</span>
        </a>
        <a
          [ngClass]="{'active': activeLink === 3}"
          [routerLink]="'/:lang/my-reservation'|i18nRoute"
        >
          <span>{{ 'my_account'|translate }}</span>
        </a>
      </ng-container>
    </div>
  </div>
</div>
<router-outlet></router-outlet>

<div *ngIf="user?.loyaltyProgram" class="bg-white pt-deca pb-deca">
  <div class="container mobile-padding">
    <div class="row">
      <div class="col-xl-10 offset-xl-1">
        <a
          class="light-subtitle"
          [href]="'assets/files/regulamin_programu_lojalnoscowego_hotelu_shuum_me_' + this.currentLang + '.pdf'" target="_blank"
        >
          {{ 'loyalty_program_rules' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>