import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IConfiguration} from '@interfaces/IConfiguration';
import ApiConfigurationEndpointEnum from '@enums/ApiConfigurationEndpointEnum';
import {takeUntil} from 'rxjs/operators';
import {catchError} from 'rxjs/internal/operators';
import {ConfigurationService} from '@services/configuration.service';
import {SubscribeHelper} from '@helpers/SubscribeHelper';

@Component({
  selector: 'app-ala-carte-menu-btn',
  templateUrl: './ala-carte-menu-btn.component.html',
  styleUrls: ['./ala-carte-menu-btn.component.scss']
})
export class AlaCarteMenuBtnComponent extends SubscribeHelper implements OnInit, OnDestroy {

  public alaCarteMenuFile: IConfiguration = null;

  @Input() text: string;

  constructor(
    private apiConfiguration: ConfigurationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getAlaCarteMenuFile();
  }

  getAlaCarteMenuFile(): void {
    this.apiConfiguration
      .getConfiguration(ApiConfigurationEndpointEnum.AlaCarteMenu)
      .pipe(
        takeUntil(this.componentDestroyed$),
        catchError(err => {
          this.alaCarteMenuFile = { id: null, value: '', iso: '', name: '' };
          return err;
        })
      )
      .subscribe((res: IConfiguration) => {
        this.alaCarteMenuFile = res;
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
