import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import {ReservationData} from '../../_subprojects/reservation-form/_models/ReservationData';
import {BehaviorSubject, Subject, Observable} from 'rxjs';
import IReservationDate from '../../_subprojects/reservation-form/_interfaces/IReservationDate';
import IReservationRoom, {IReservationRoomDetails} from '../../_subprojects/reservation-form/_interfaces/IReservationRoom';
import ReservationConfig from '../../_subprojects/reservation-form/_config/ReservationConfig';
import * as isArray from 'lodash/isArray';
import * as concat from 'lodash/concat';
import * as findIndex from 'lodash/findIndex';
import { ReservationStepService } from './reservation-step.service';
import * as moment from 'moment';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import IReservationExtraNew from '../../_subprojects/reservation-form/_interfaces/IReservationExtraNew';
import IReservationExtraTab from '../../_subprojects/reservation-form/_interfaces/IReservationExtraTab';
import {IReservationExistingExtra} from '../../_subprojects/reservation-form/_interfaces/IReservationExistingExtra';
import {environment} from '../../../environments/environment';
import { LayoutService } from '@services/layout.service';
import { ReservationErrorModalEnum } from '../../_subprojects/reservation-form/_enums/ReservationErrorModalEnum';
import { isPlatformBrowser } from '@angular/common';
import ReservationExtraTypeEnum from '../../_subprojects/reservation-form/_enums/ReservationExtraTypeEnum';
import ReservationAvailableTypeEnum from '../../_subprojects/reservation-form/_enums/ReservationAvailableTypeEnum';
import { UserService } from '@services/auth/user.service';
import * as cloneDeep from 'lodash/cloneDeep';
import { consoleTestResultHandler } from 'tslint/lib/test';
import { IReservationOffer } from 'src/app/_subprojects/reservation-form/_interfaces/IReservationOffer';
import ReservationPaymentUnitEnum from 'src/app/_subprojects/reservation-form/_enums/ReservationPaymentUnitEnum';

/**
 * Przechowuje dane dot. rezerwacji
 * Dane są zapisywane również w sessionStorage
 */
@Injectable({
  providedIn: 'root'
})
export class ReservationDataService {
  private reservationStorageKey = environment.reservationStorageKey;
  private rooms: IReservationRoom[] = [];
  private roomsExtrasData: IReservationExtraNew[] = [];
  // private existingExtras: any[] = [];
  data: ReservationData = null;

  currentStep$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    private reservationStepService: ReservationStepService,
    private layoutService: LayoutService,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    let storageData = null;
    let langHasChanged = false;

    try {
      storageData = typeof sessionStorage !== 'undefined' ? JSON.parse(sessionStorage.getItem(this.reservationStorageKey)) : null;
      langHasChanged = typeof sessionStorage !== 'undefined' ? !!sessionStorage.getItem('langChanged') : false;
    }
    catch (e) {

    }

    this.data = new ReservationData();

    this.data.errors$.subscribe(error => {
      if (isPlatformBrowser(this.platformId)) {
        setTimeout(() => {
          this.handleError(error);
        }, 0);
      }
    });
    this.data.setData( storageData );

    this.rooms = this.data.rooms;
    this.rooms.forEach( (room, index) => {
      this.setSelectedRoomsExtrasData( index );
    });

    /**
     * Jeśli użytkownik wylogował się,
     * zostały usunięte z sessionStorage dane gości.
     * Usuń dane również z serwisu
     */
    this.userService.isLoggedIn().subscribe(res => {
      if ( typeof sessionStorage !== 'undefined' ) {
        const item = JSON.parse(sessionStorage.getItem(this.reservationStorageKey));

        if (res === false && item?.rooms && item.rooms[0]?.guestData === null) {
          this.refreshData();
        }
      }
    });

    /**
     * Odświeżenie danych
     * (po przekierowaniu przez redirector)
     */
    this.layoutService.getForceReservationDataRefresh().subscribe(res => {
      if ( res === true ) {
        this.refreshData();
      }
    });

    /**
     * jeśli jezyk uległ zmianie trzeba
     * zapisane nazwy pokoi i dodatkow podmienić
     */
    if ( langHasChanged ) {
      this.data.replaceDataAfterLangChanged$.next( 2 );
    }
  }

  clear(): void {
    try {
      this.clearStorage();
      this.setNullData();
    }
    catch (e) {

    }
  }

  clearStorage(): void {
    try {
      if ( typeof sessionStorage !== 'undefined' ) {
        sessionStorage.removeItem(this.reservationStorageKey);
      }
    }
    catch ( e ) {

    }
  }

  setNullData() {
    this.setDate({
      from: null,
      to: null
    }, false);
    this.setRooms([]);
  }

  refreshData() {
    if ( typeof sessionStorage !== 'undefined' ) {
      const data = JSON.parse(sessionStorage.getItem(environment.reservationStorageKey));

      this.data.date$.next(data.date);
      this.data.rooms$.next(data.rooms);
    }
  }

  /**
   * Funkcja validate jest wywoływana w stepGuardzie,
   * aby po odświeżeniu strony sprawdzić,
   * czy konkretne kroki są poprawne.
   *
   * ValidateFirst jest wywoływana z kroku 1, ponieważ
   * do niego nie jest podpięty stepGuard, a po odświeżeniu strony
   * na tym kroku również musi być walidacja.
   *
   * ValidateFourth jest uruchamiane osobno, ponieważ aby
   * zwalidować ten krok, trzeba wysłać zapytanie do API,
   * a funkcja musi zwracać Observable.
   * Zapytanie to wysyłane jest w stepGuardzie - po odebraniu odpowiedzi
   * o błędnym formularzu, stepGuard przekieruje użytkownika na krok 3,
   * oraz w formularzu kroku 4 - po wypełnieniu całego formularza poprawnie,
   * i zwalidowaniu przez front-end, jest wysyłane zapytanie do API.
   */

  validateFirst() {
    this.reservationStepService.validateFirstStep( this.rooms, this.data.date$.value, this.getNightsOfStay(), this.data.reservationOffer$.value );
  }

  validateFourth(): Observable<any> {
    return this.reservationStepService.validateFourthStep( this.rooms );
  }

  validate() {
    this.reservationStepService.validateFirstStep( this.rooms, this.data.date$.value, this.getNightsOfStay(), this.data.reservationOffer$.value );
    this.reservationStepService.validateSecondStep( this.rooms );
  }

  handleError( error: ReservationErrorModalEnum ) {
    if ( error === ReservationErrorModalEnum.DateExpired ) {
      this.layoutService.setReservationInfoPopup({
        header: 'error_date_header',
        description: 'error_date_description'
      });
    }
  }

  getDate(): BehaviorSubject<IReservationDate> {
    return this.data.date$;
  }

  setDate( date: IReservationDate, updateNights = true ): void {
    this.data.date$.next(date);

    if ( updateNights ) {
      this.updateNightsInRoomsExtras();
    }

    try {
      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem(this.reservationStorageKey, JSON.stringify({
          ...JSON.parse(sessionStorage.getItem(this.reservationStorageKey)),
          date
        }));
      }
    }
    catch (e) {

    }

    this.reservationStepService.validateFirstStep( this.rooms, this.data.date$.value, this.getNightsOfStay(), this.data.reservationOffer$.value );
  }

  getRooms(): BehaviorSubject<IReservationRoom[]> {
    return this.data.rooms$;
  }

  getCoupon(): BehaviorSubject<string> {
    return this.data.coupon$;
  }

  getPointsUsed(): BehaviorSubject<boolean> {
    return this.data.pointsUsed$;
  }

  getRoomsValue(): IReservationRoom[] {
    return this.data.rooms$.value;
  }

  getTempRoomsValue(): IReservationRoom[] {
    return this.data.tempRooms$.value;
  }

  setTempRoomsNull(): void {

  }

  getDateValue() {
    return this.data.date$.value;
  }

  setRooms( rooms: IReservationRoom[] ): void {
    this.data.rooms$.next(rooms);
    this.data.rooms = rooms;
    this.rooms = rooms;

    const tempRooms = cloneDeep( rooms );
    const tempRoomsSaved = cloneDeep( this.getTempRoomsValue() );

    tempRooms.forEach((room, i) => {
      tempRooms[i] = {
        ...room,
        name: rooms[i]?.name ?? tempRoomsSaved[i]?.name ?? null,
        price: rooms[i]?.price ?? tempRoomsSaved[i]?.price ?? null,
        priceType: rooms[i]?.priceType ?? tempRoomsSaved[i]?.priceType ?? null,
        standardId: rooms[i]?.standardId ?? tempRoomsSaved[i]?.standardId ?? null,
        withDinner: rooms[i]?.withDinner ?? tempRoomsSaved[i]?.withDinner ?? null,
      };
    });

    this.data.tempRooms$.next( tempRooms );

    try {
      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem(this.reservationStorageKey, JSON.stringify({
          ...JSON.parse(sessionStorage.getItem(this.reservationStorageKey)),
          rooms
        }));
      }
    }
    catch (e) {

    }

    this.validate();
  }

  setCoupon(coupon: string) {
    this.data.coupon$.next(coupon);

    try {
      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem(this.reservationStorageKey, JSON.stringify({
          ...JSON.parse(sessionStorage.getItem(this.reservationStorageKey)),
          coupon
        }));
      }
    }
    catch (e) {

    }
  }

  setReservationOffer(offer: IReservationOffer) {
    this.data.reservationOffer$.next(offer);

    try {
      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem(this.reservationStorageKey, JSON.stringify({
          ...JSON.parse(sessionStorage.getItem(this.reservationStorageKey)),
          reservationOffer: offer
        }));
      }
    }
    catch (e) {

    }
  }

  setShuummePointsPercent(shuummePointsPercent: number) {
    this.data.shuummePointsPercent$.next(shuummePointsPercent);

    try {
      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem(this.reservationStorageKey, JSON.stringify({
          ...JSON.parse(sessionStorage.getItem(this.reservationStorageKey)),
          shuummePointsPercent
        }));
      }
    }
    catch (e) {

    }
  }

  setPointsUsed(pointsUsed: boolean) {
    this.data.pointsUsed$.next(pointsUsed);

    try {
      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem(this.reservationStorageKey, JSON.stringify({
          ...JSON.parse(sessionStorage.getItem(this.reservationStorageKey)),
          pointsUsed
        }));
      }
    }
    catch (e) {

    }
  }

  getCurrentStep(): BehaviorSubject<number> {
    return this.currentStep$;
  }

  setCurrentStep( step: number ): void {
    this.currentStep$.next( step );
  }

  getSelectedExtras(): BehaviorSubject<any> {
    return this.data.roomsSelectedExtras$;
  }

  setExtra( roomIndex: number, data: IReservationExtraNew, isAdded: boolean ): boolean {
    if ( typeof this.rooms[roomIndex] !== 'undefined' ) {
      if ( typeof this.rooms[roomIndex].extras === 'undefined' ) {
        this.rooms[roomIndex].extras = [];
      }

      const index = findIndex(this.rooms[roomIndex].extras, { id: data.id, type: data.type, inPackage: !!data.inPackage });
      if ( index !== -1 ) {
        if ( isAdded ) {
          this.rooms[roomIndex].extras.splice(index, 1);
        }
        else {
          this.rooms[roomIndex].extras[index] = data;
        }
      }
      else {
        this.rooms[roomIndex].extras.push(data);
      }

      this.setSelectedRoomsExtrasData( roomIndex );
      this.setRooms( this.rooms );

      return true;
    }

    return false;
  }

  /**
   * Usuwanie dodatków po shuumSymbol i roomIndex
   *
   * @param data - { symbol: string, roomIndex: number }[]
   */
  removeExtra( data: { symbol: string, roomIndex: number }[] ) {
    let removed = false;

    data.forEach( d => {
      let localRemoved = false;
      const roomIndex = d.roomIndex;

      if ( typeof this.rooms[roomIndex] !== 'undefined' ) {
        if ( this.rooms[roomIndex].extras ) {
          const index = findIndex(this.rooms[roomIndex].extras, { shuumSymbol: d.symbol });

          if ( index >= 0 ) {
            // usuwamy glowny dodatek
            this.rooms[roomIndex].extras.splice(index, 1);
            removed = true;
            localRemoved = true;
          }
          else {
            this.rooms[roomIndex].extras.forEach( (extra, eIndex) => {
              if ( extra.values ) {
                const vIndex = findIndex(extra.values, { shuumSymbol: d.symbol });

                if ( vIndex >= 0 ) {
                  // usuwamy wybrana opcje, jeśli to jedyna wybrana opcja to usuwamy rowniez glowny dodatek

                  this.rooms[roomIndex].extras[eIndex].values.splice(vIndex, 1);

                  if ( this.rooms[roomIndex].extras[eIndex].values.length === 0 ) {
                    this.rooms[roomIndex].extras.splice(eIndex, 1);
                  }

                  removed = true;
                  localRemoved = true;
                }
              }
            });
          }
        }
      }

      if ( localRemoved ) {
        this.setSelectedRoomsExtrasData(roomIndex);
      }
    });

    if ( removed ) {
      this.setRooms(this.rooms);
    }
  }

  setRoomsAsInvalid( roomIndex: number, availType: ReservationAvailableTypeEnum ) {
    this.rooms[roomIndex].availableType = availType;
    this.setRooms(this.rooms);
  }

  clearInvalidMessages() {
    this.rooms.forEach(room => {
      room.availableType = null;
    });

    this.setRooms(this.rooms);
  }

  /**
   * przypisuje ilosc doroslych (type: 0) lub dzieci (type: 1)
   *
   * @param value - ilosc osob
   * @param roomIndex - index zmienianego pokoju
   * @param type - 0 dorosli, 1 dzieci
   * @param ages - tablica z wiekiem dzieci
   */
  setNumberOfPeople( value: number, roomIndex: number, type: number, ages?: number[], updateNights = true ): boolean {
    if ( typeof this.rooms[roomIndex] !== 'undefined' ) {
      const limitations = ReservationConfig.getReservationLimits();
      let maxAdultsValue = limitations.maxAdultsValue;
      const minAdultsValue = limitations.minAdultsValue;
      let maxChildrenValue = limitations.maxChildrenValue + 1;
      const minChildrenValue = limitations.minChildrenValue;

      const adults = this.rooms[roomIndex].adults;
      const children = this.rooms[roomIndex].children;

      if ( this.rooms[roomIndex].standardId !== null ) {
        this.rooms[roomIndex].price = null;
        this.rooms[roomIndex].priceType = null;
        this.rooms[roomIndex].standardId = null;
        this.rooms[roomIndex].name = null;
      }

      if (type === 0) {
        maxAdultsValue -= children;

        if ( children > 0 && children < limitations.maxChildrenValue ) {
          maxAdultsValue += 1;
        }

        if (value > maxAdultsValue || value < minAdultsValue) {
          return false;
        }

        this.rooms[roomIndex].adults = value;

        this.setRooms(this.rooms);
      }
      else {
        maxChildrenValue -= adults;

        if (value > maxChildrenValue || value < minChildrenValue) {
          return false;
        }

        this.rooms[roomIndex].children = value;

        if ( isArray(ages) ) {
          this.rooms[roomIndex].childrenAges = ages;
        }

        if (isArray(this.rooms[roomIndex].childrenAges)) {
          if (this.rooms[roomIndex].childrenAges.length < value) {
            const diff = value - this.rooms[roomIndex].childrenAges.length;

            this.rooms[roomIndex].childrenAges = concat(this.rooms[roomIndex].childrenAges, Array.from(Array(diff), () => null));
          }
          else if (this.rooms[roomIndex].childrenAges.length > value) {
            this.rooms[roomIndex].childrenAges.splice(this.rooms[roomIndex].children);
          }
        }
        else {
          this.rooms[roomIndex].childrenAges = Array.from(Array(value), () => null);
        }

        this.setRooms(this.rooms);
      }

      if ( updateNights ) {
        this.updateNightsInRoomsExtras();
      }

      return true;
    }

    return false;
  }

  /**
   * Przypisuje wybrany pokoj
   *
   * @param roomIndex - indeks pokoju
   * @param data - dane do zapisu
   */
  setRoomStandard( roomIndex: number, data: IReservationRoomDetails ): void {
    if ( typeof this.rooms[roomIndex] !== 'undefined' ) {
      this.rooms[roomIndex].name = data.name;
      this.rooms[roomIndex].price = data.price;
      this.rooms[roomIndex].priceType = data.priceType;
      this.rooms[roomIndex].image = data.image;
      this.rooms[roomIndex].standardId = data.standardId;
      this.rooms[roomIndex].offer = data.offer;
      this.rooms[roomIndex].advancePercent = data.advancePercent;
      this.rooms[roomIndex].withDinner = data.withDinner;

      this.setRooms(this.rooms);
    }
  }

  getNightsOfStay( fromDate?: NgbDate, toDate?: NgbDate): number {
    try {
      if (!fromDate || !toDate) {
        fromDate = this.data.date$.value.from;
        toDate = this.data.date$.value.to;
      }

      return Math.ceil(moment([toDate.year, toDate.month - 1, toDate.day]).diff(
        moment(
          [fromDate.year, fromDate.month - 1, fromDate.day]),
        'days',
        true
      ));
    }
    catch (e) {

    }

    return null;
  }

  getNumberOfPeople( roomIndex: number ): number {
    try {
      const limitations = ReservationConfig.getReservationLimits();

      let numberOfPeople = limitations.minAdultsValue;

      if ( typeof this.rooms[roomIndex] !== 'undefined' ) {
        const adults = typeof this.rooms[roomIndex].adults !== 'undefined' ? this.rooms[roomIndex].adults : limitations.minAdultsValue;
        const children = typeof this.rooms[roomIndex].children !== 'undefined' ? this.rooms[roomIndex].children : 0;

        numberOfPeople = adults;
        numberOfPeople += children;
      }

      return numberOfPeople;
    }
    catch (e) {

    }

    return null;
  }

  getNumberOfBabies( roomIndex: number ) {
    try {
      const limits = ReservationConfig.getChildrenAgesLimits();
      const room = this.rooms[roomIndex];
      let children = 0;

      if ( typeof room !== 'undefined' && typeof room.childrenAges !== 'undefined' ) {
        room.childrenAges.forEach(r => {
          if ( r >= limits.minBabyAge && r <= limits.maxBabyAge ) {
            children += 1;
          }
        });
      }

      return children;
    } catch (e) {

    }

    return null;
  }

  /**
   * dzieci 3-9
   * @param roomIndex
   */
  getNumberOfSmallChildren( roomIndex: number ) {
    try {
      const limits = ReservationConfig.getChildrenAgesLimits();
      const room = this.rooms[roomIndex];
      let children = 0;

      if ( typeof room !== 'undefined' && typeof room.childrenAges !== 'undefined' ) {
        room.childrenAges.forEach(r => {
          if ( r >= limits.minSmallChildAge && r <= limits.maxSmallChildAge ) {
            children += 1;
          }
        });
      }

      return children;
    } catch (e) {

    }

    return null;
  }

  getExistingExtras(): BehaviorSubject<any> {
    return this.data.existingExtras$;
  }

  /**
   * Mapujemy które dodatki są dostępne, w razie gdy któryś zniknie musimy go
   * usunąć z session/localStorage - jeśli klient wybrał ten dodatek
   *
   * @param tabs - dane zwracane przez request do API
   */
  setExistingExtras( tabs: IReservationExtraTab[] ): void {
    const data: IReservationExistingExtra[] = [];

    if ( tabs ) {
      tabs.forEach( tab => {
        if ( tab.values ) {
          tab.values.forEach( extra => {
            if ( extra.values ) {
              extra.values.forEach( value => {
                data.push({
                  id: value?.id || extra?.id,
                  type: extra?.type,
                  name: [extra?.name, value?.name].join(' - '),
                  shuumSymbol: value?.shuumSymbol || extra?.shuumSymbol,
                  inPackage: !!value?.inPackage,
                  isRequired: !!value?.isRequired
                });
              });
            }
            else {
              data.push({
                id: extra?.id,
                type: extra?.type,
                name: extra?.name,
                shuumSymbol: extra?.shuumSymbol,
                inPackage: !!extra?.inPackage,
                isRequired: !!extra?.isRequired
              });
            }
          });
        }
      });
    }

    return this.data.existingExtras$.next( data );
  }

  public setSelectedRoomsExtrasData( roomIndex: number ): void {
    if ( typeof this.rooms[roomIndex] !== 'undefined' ) {
      if ( typeof this.rooms[roomIndex].extras !== 'undefined' ) {
        this.roomsExtrasData[roomIndex] = null;
        this.rooms[roomIndex].extras.forEach( extra => {
          try {
            this.roomsExtrasData[roomIndex] = {
              ...this.roomsExtrasData[roomIndex],
              [extra.id + '_' + extra.type + '_' + (extra.inPackage ? 1 : 0)]: {...extra}
            };
          }
          catch (e) {

          }
        });

        this.data.roomsSelectedExtras$.next( this.roomsExtrasData );
      }
    }
  }

  /**
   * Jeśli zmieni się liczba nocy lub osób w pokoju to wybrane dodatki trzeba na nowo przeliczyc
   */
  public updateNightsInRoomsExtras(): void {
    this.data.refreshExtras$.next( { force: false, delay: 150 } );
  }

  public shouldRefreshExtras(): Subject<{ force: boolean, delay: number }> {
    return this.data.refreshExtras$;
  }

  /**
   * usuwamy dodatki, które nie zostały zwrocone przez API a są w session/localStorage
   */
  removeNotExistingExtras(): void {
    const extras: IReservationExistingExtra[] = this.data.existingExtras$.value;
    let emitAllChanges = false;

    this.rooms?.forEach( (room, roomIndex) => {
      let emitChanges = false;

      room?.extras?.forEach( (extra, extraIndex) => {
        if ( extra.values ) {
          const valuesLength = extra.values.length;

          extra.values.forEach( (value, valueIndex) => {
            const index = findIndex(extras, { shuumSymbol: value.shuumSymbol || extra.shuumSymbol, inPackage: extra.inPackage, isRequired: !!value.isRequired });

            if ( index === -1 ) {
              emitChanges = true;
              emitAllChanges = true;

              // usun tylko jedna opcje jeśli jest więcej
              if ( valuesLength > 1 ) {
                if (value.payoffType === ReservationPaymentUnitEnum.dinner) {
                  return this.rooms[roomIndex].extras.splice(extraIndex, 1);
                }

                this.rooms[roomIndex].extras[extraIndex].values.splice(valueIndex, 1);
              }
              // usun caly dodatek, bo wybrana byla tylko jedna opcja
              else {
                this.rooms[roomIndex].extras.splice(extraIndex, 1);
              }
            }
          });
        }
        else {
          const index = findIndex(extras, { shuumSymbol: extra.shuumSymbol, inPackage: extra.inPackage, isRequired: !!extra.isRequired });

          if ( index === -1 ) {
            emitChanges = true;
            emitAllChanges = true;
            this.rooms[roomIndex].extras = this.rooms[roomIndex].extras.filter(e => e?.id !== extra?.id || e?.inPackage !== extra.inPackage || e?.isRequired !== extra.isRequired);
          }
        }
      });

      if ( emitChanges ) {
        this.setSelectedRoomsExtrasData( roomIndex );
      }
    });

    if ( emitAllChanges ) {
      this.setRooms( this.rooms );
    }
  }

  getExtrasNames(): { symbol: string; name: string; duration?: number; isAmountShown?: boolean; }[] {
    const extrasCombined = [];

    this.getRoomsValue().forEach(room => {
      room.extras?.forEach(extra => {
        if ( !extra.values ) {
          if ( !extrasCombined.find(el => el.symbol === extra.shuumSymbol) ) {
            extrasCombined.push({
              symbol: extra.shuumSymbol,
              name: extra.name,
              isAmountShown: extra.isAmountShown
            });
          }
        } else {
          extra.values.forEach(value => {
            if ( !extrasCombined.find(el => el.symbol === value.shuumSymbol) ) {
              let symbol = value?.shuumSymbol || extra?.shuumSymbol;
              let name = `${extra.name} - ${value.name}`;
              let duration = null;

              if ( extra?.type === ReservationExtraTypeEnum.treatment ) {
                symbol = extra.shuumSymbol;
                name = extra.name;
                duration = value?.shuumSymbol.replace(extra?.shuumSymbol + '_', '');
              }

              extrasCombined.push({
                symbol,
                name,
                duration,
                isAmountShown: extra.isAmountShown
              });
            }
          });
        }
      });
    });

    return extrasCombined;
  }
}
