enum ReservationPriceTypeEnum {
  standard,
  standardFirstMinute,
  standardLastMinute,
  offer,
  offerFirstMinute,
  offerLastMinute
}

export default ReservationPriceTypeEnum;
