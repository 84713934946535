import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import IReservationCalendarPrices from '../_interfaces/IReservationCalendarPrices';
import IReservationRoom from '../_interfaces/IReservationRoom';
import ReservationConfig from '../_config/ReservationConfig';
import IReservationPricePerRoom from '../_interfaces/IReservationPricePerRoom';
import IReservationRoomStandard from '../_interfaces/IReservationRoomStandard';
import IReservationRoomStandardPricePerRoom from '../_interfaces/IReservationRoomStandardPricePerRoom';
import {map} from 'rxjs/operators';
import * as toArray from 'lodash/toArray';
import {ReservationDataService} from '@services/reservations/reservation-data.service';
import {UserService} from '@services/auth/user.service';
import IReservationSummary from '../_interfaces/IReservationSummary';
import ReservationExtraTypeEnum from '../_enums/ReservationExtraTypeEnum';
import GlobalFunctions from '@helpers/GlobalFunctions';
import IReservationPayment from '../_interfaces/IReservationPayment';
import ReservationAvailableTypeEnum from '../_enums/ReservationAvailableTypeEnum';
import * as moment from 'moment';
import * as cloneDeep from 'lodash/cloneDeep';
import * as uniq from 'lodash/uniq';
import ReservationPaymentUnitEnum from '../_enums/ReservationPaymentUnitEnum';
import {type} from 'os';
import IReservationDate from '../_interfaces/IReservationDate';
import IReservationExtraNew from '../_interfaces/IReservationExtraNew';
import IReservationCoupon from '../_interfaces/IReservationCoupon';
import IReservationExtras from '../_interfaces/IReservationExtras';

@Injectable()
export class ReservationService {

  constructor(
    protected http: HttpClient,
    public reservationData: ReservationDataService,
    private userService: UserService
  ) {
    this.reservationData.shouldRefreshExtras().subscribe( data => {
      if ( data.force ) {
        this.refreshExtraData(this.reservationData.data.date$.value);
      }
      else {
        GlobalFunctions.delayer( () => {
          this.refreshExtraData(this.reservationData.data.date$.value);
        }, data.delay || 600);
      }
    });

    this.reservationData.getDate().subscribe( date => {
      this.refreshExtraData( date );
    }).unsubscribe();

    this.reservationData.data.replaceDataAfterLangChanged$.subscribe( d => {
      if ( d === null ) { // 2 to max value
        return;
      }
      else if ( d < 2 ) {
        if ( typeof sessionStorage !== 'undefined' ) {
          sessionStorage.removeItem('langChanged');
        }
      }

      // try {
      //   const rooms = this.reservationData.getRoomsValue();
      //
      //   if (rooms.length) {
      //     const date = this.reservationData.data.date$.value;
      //     const dateFrom = moment([date.from.year, date.from.month - 1, date.from.day]).format('YYYY-MM-DD');
      //     const dateTo = moment([date.to.year, date.to.month - 1, date.to.day]).format('YYYY-MM-DD');
      //
      //     this.getRoomsStandards( dateFrom, dateTo );
      //   }
      // }
      // catch ( e ) {
      //   console.log(e);
      // }

    });
  }

  getCalendarPrices( startDate?: string, endDate?: string, offerSlug?: string ): Observable<IReservationCalendarPrices> {
    const url = '/calendar/prices';
    let params = new HttpParams();
    params = this.setDates( params, startDate, endDate );
    params = this.setOfferSlug( params, offerSlug );

    return this.http.get<IReservationCalendarPrices>(
      `${environment.shuumApiHost}${url}`, {
        params
      }
    );
  }

  getLowestPricePerRoom( startDate?: string, endDate?: string, rooms?: IReservationRoom[] ): Observable<{ booking: IReservationPricePerRoom }> {
    const url = '/booking/standard/prices/lowest';
    // const people = this.setPeopleData( rooms );

    // const config = new HttpHeaders().set('Content-Type', 'application/json');

    let params = null;

    try {
      params = new FormData();
      this.setDates(params, startDate, endDate);
      this.setPeopleData(params, rooms);
    }
    catch (e) {

    }

    return this.http.post<{ booking: IReservationPricePerRoom }>(
      `${environment.shuumApiHost}${url}`, params
    );
  }

  getRoomsStandards(
    startDate?: string,
    endDate?: string,
    rooms?: IReservationRoom[],
    coupon?: string,
    offerId?: number,
    validate = false
  ): Observable<{
    booking: IReservationRoomStandardPricePerRoom, rooms: IReservationRoomStandard[] }> {
    const url = '/booking/standard/prices';

    let params = null;

    try {
      params = new FormData();
      this.setDates(params, startDate, endDate);
      this.setPeopleData(params, rooms);
      this.setCoupon(params, coupon);
      this.setOfferId(params, offerId);
    }
    catch (e) {

    }

    let headers = new HttpHeaders();
    // headers = headers.set('Order-Field', 'sequence, name');
    // headers = headers.set('Order-Type', 'asc, asc');

    return this.http.post<{ booking: IReservationRoomStandardPricePerRoom, rooms: IReservationRoomStandard[] }>(
        `${environment.shuumApiHost}${url}`, params, {
          headers
        }
      );
  }

  getExtras( startDate?: string, endDate?: string, rooms?: IReservationRoom[] ): Observable<IReservationExtras> {
    const url = '/booking/extras';

    let params = null;

    try {
      params = new FormData();
      this.setDates(params, startDate, endDate);
      this.setPeopleData(params, rooms);
      this.setPriceTypeData(params, rooms);
      this.setDinner(params, rooms);
    }
    catch (e) {

    }

    return this.http.post<IReservationExtras>(
        `${environment.shuumApiHost}${url}`,
        params
      )
      .pipe(map(tabs => {
        this.reservationData.setExistingExtras( tabs.extras );

        if ( this.reservationData.data.replaceDataAfterLangChanged$.value ) {
          const r = this.replaceExtrasNames(tabs.extras, rooms);
          this.reservationData.setRooms(r);

          // this.reservationData.data.replaceDataAfterLangChanged$.next(
          //   this.reservationData.data.replaceDataAfterLangChanged$.value - 1
          // );
        }

        return tabs;
      }));
  }

  /**
   * Pobieranie podsumowania do kroku 5
   *
   * @param startDate
   * @param endDate
   * @param rooms
   */
  getSummary( startDate: string, endDate: string, rooms: IReservationRoom[], coupon?: string, pointsUsed?: boolean ): Observable<IReservationSummary> {
    const url = this.userService.isLoggedIn$.value ? '/booking/summary' : '/public/booking/summary';

    let params = null;

    try {
      params = new FormData();
      this.setDates(params, startDate, endDate);
      this.setOfferId(params, rooms[0]?.offer?.id);
      this.setPeopleData(params, rooms);
      this.setRoomData(params, rooms);
      this.setCoupon(params, coupon);
      this.setPointsUsed(params, pointsUsed);
      this.setDinner(params, rooms);
    }
    catch (e) {

    }

    return this.http.post<IReservationSummary>(
      `${environment.shuumApiHostShort}${url}`,
      params
    )
    .pipe(
      map( data => {
        const symbolsToRemove: { symbol: string, roomIndex: number }[] = [];
        const extrasNames = this.reservationData.getExtrasNames();

        /** todo: do sprawdzenia, czy API nie zwraca tego */
        if ( data.rooms ) {
          data.rooms.forEach( (room, roomIndex) => {
            if ( room.extras ) {
              if ( room.extras.items ) {
                let totalExtras = 0;

                room.extras.items.forEach( category => {
                  if ( category.values ) {
                    totalExtras += category.values.length;
                  }
                });

                room.extras.totalAmount = totalExtras;
              }

              /**
               * Błędne dodatki są usuwane ze storage.
               * Przed usunięciem, zmieniana jest nazwa
               * w data z shuumSymbol na prawdziwą nazwę,
               * aby można było je wyświetlić w popupie
               */
              if ( room.extras.invalidExtras ) {
                if ( room.extras.invalidExtras.items ) {
                  room.extras.invalidExtras.items.forEach( item => {
                    symbolsToRemove.push({
                      roomIndex,
                      symbol: item.shuumSymbol
                    });

                    const name = extrasNames.find(el => el.symbol === item.shuumSymbol);
                    const header = name?.name || item.shuumSymbol;

                    item.shuumSymbol = header;
                  });
                }
              }
            }

            /**
             * Ustaw informację, że pokój jest niepoprawny
             */
            if ( room.availableType !== ReservationAvailableTypeEnum.available ) {
              this.reservationData.setRoomsAsInvalid( roomIndex, room.availableType );
            }
          });
        }

        this.reservationData.removeExtra( symbolsToRemove );

        return data;
      })
    );
  }

  getPaymentLink( startDate: string, endDate: string, rooms: IReservationRoom[], coupon?: string, pointsUsed?: boolean, offerId?: number ): Observable<IReservationPayment> {
    const url = this.userService.isLoggedIn$.value ? '/booking/payment' : '/public/booking/payment';
    let params = null;

    try {
      params = new FormData();
      this.setDates(params, startDate, endDate);
      this.setPeopleData(params, rooms);
      this.setRoomData(params, rooms);
      this.setCoupon(params, coupon);
      this.setPointsUsed(params, pointsUsed);
      this.setOfferId(params, offerId);
    }
    catch (e) {

    }

    return this.http.post<IReservationPayment>(
      `${environment.shuumApiHostShort}${url}`,
      params
    );
  }

  addCoupon( code: string ): Observable<IReservationCoupon> {
    const url = `/booking/coupon/${code}`;

    return this.http.get<IReservationCoupon>(`${environment.shuumApiHost}${url}`);
  }

  private setDates( params: HttpParams|FormData, startDate?: string, endDate?: string ): any {

    try {
      if (startDate) {
        if (params instanceof FormData) {
          params.append('start', startDate);
        }
        else {
          params = params.append('start', startDate);
        }
      }

      if (endDate) {
        if (params instanceof FormData) {
          params.append('end', endDate);
        }
        else {
          params = params.append('end', endDate);
        }
      }
    }
    catch (e) {

    }

    return params;
  }

  private setPeopleData( formData: FormData, rooms: IReservationRoom[] = [] ): void {
    if ( rooms.length > 0 ) {
      rooms.forEach( (room, index) => {
        if (typeof room !== 'undefined') {
          const adults = typeof room.adults !== 'undefined' ? room.adults : null;
          const children = typeof room.children !== 'undefined' ? room.children : null;
          const childrenAges = typeof room.childrenAges !== 'undefined' ? room.childrenAges : null;
          const limitations = ReservationConfig.getReservationLimits();

          if (adults) {
            if (adults >= limitations.minAdultsValue && adults <= limitations.maxAdultsValue) {
              formData.append('rooms[' + index + '][adults]', adults.toString());
            }
          }

          if (children && childrenAges) {
            if (children >= limitations.minChildrenValue && children <= limitations.maxChildrenValue) {
              formData.append('rooms[' + index + '][children]', children.toString());

              if (childrenAges.length) {
                childrenAges?.forEach( child => {
                  formData.append('rooms[' + index + '][childrenAges][]', child.toString());
                });
              }
            }
          }
        }
      });
    }
  }

  private setPriceTypeData( formData: FormData, rooms: IReservationRoom[] = [] ): void {
    if ( rooms.length > 0 ) {
      const [ room ] = rooms;

      if (typeof room !== 'undefined') {
        const priceType = typeof room.priceType !== 'undefined' ? room.priceType.toString() : null;
        const offerId = typeof room.offer !== 'undefined' ? room.offer.id.toString() : null;
        const withDinner = typeof room.withDinner !== 'undefined' ? room.withDinner.toString() : null;

        if (priceType) {
          formData.append('priceType', priceType);
        }

        if (offerId) {
          formData.append('offerId', offerId);
        }
      }
    }
  }

  /**
   * Dane dot. pokoju formatowane do FormData
   *
   * Zabieg property duration bierze z shuumSymbolu, np.
   * www_Symbol - symbol zabiegu
   * www_Symbol_90 - zmapowany symbol zabiegu z czasem trwania
   *
   * @param formData - FormData
   * @param rooms - IReservationRoom[]
   */
  private setRoomData( formData: FormData, rooms: IReservationRoom[] = [] ): void {
    if ( rooms.length > 0 ) {
      rooms.forEach((room, index) => {
        if ( room?.standardId ) {
          formData.append('rooms[' + index + '][standardId]', room.standardId.toString());
        }

        if ( room?.priceType || room?.priceType === 0 ) {
          formData.append('rooms[' + index + '][priceType]', room.priceType.toString());
        }

        if ( typeof room?.extras !== 'undefined' ) {
          let extraIndex = 0;

          room.extras.forEach( extra => {
            if ( typeof extra?.values !== 'undefined' ) {
              extra.values.forEach( (value, valueIndex) => {
                if ( extra?.type === ReservationExtraTypeEnum.treatment ) {
                  const duration = value?.shuumSymbol.replace(extra?.shuumSymbol + '_', '');

                  formData.append('rooms[' + index + '][extras][' + extraIndex + '][amount]', value?.selectedAmount.toString());
                  formData.append('rooms[' + index + '][extras][' + extraIndex + '][shuumSymbol]', extra?.shuumSymbol);
                  formData.append('rooms[' + index + '][extras][' + extraIndex + '][duration]', duration);
                } else if ( extra?.type === ReservationExtraTypeEnum.dinner ) {
                  const inPackage = value?.inPackage || extra?.inPackage;
                  formData.append('rooms[' + index + '][dinner][' + extraIndex + '][shuumSymbol]', value?.shuumSymbol);
                  formData.append('rooms[' + index + '][dinner][' + extraIndex + '][inPackage]', inPackage.toString());
                  formData.append('rooms[' + index + '][dinner][' + extraIndex + '][amount]', value?.selectedAmount.toString());
                }
                else {
                  formData.append('rooms[' + index + '][extras][' + extraIndex + '][amount]', value?.selectedAmount.toString());
                  formData.append('rooms[' + index + '][extras][' + extraIndex + '][shuumSymbol]', value?.shuumSymbol || extra?.shuumSymbol);
                }

                extraIndex++;
              });
            }
            else {
              if (extra?.inPackage) {
                formData.append('rooms[' + index + '][offer][extras][' + extraIndex + '][shuumSymbol]', extra?.shuumSymbol);
                formData.append('rooms[' + index + '][offer][extras][' + extraIndex + '][amount]', extra?.selectedAmount.toString());
                formData.append('rooms[' + index + '][offer][extras][' + extraIndex + '][discount]', extra?.itemPrice?.discount.toString());
                formData.append('rooms[' + index + '][offer][extras][' + extraIndex + '][inPackage]', extra?.inPackage.toString());
                if (extra?.duration) {
                  formData.append('rooms[' + index + '][offer][extras][' + extraIndex + '][duration]', extra?.duration);
                }
              } else {
                formData.append('rooms[' + index + '][extras][' + extraIndex + '][shuumSymbol]', extra?.shuumSymbol);
                formData.append('rooms[' + index + '][extras][' + extraIndex + '][amount]', extra?.selectedAmount.toString());
              }

              extraIndex++;
            }
          });
        }

        // na razie wysylamy tylko dane usera glownego
        // @todo
        if ( index === 0 ) {
          if ( room.guestData ) {
            const customer = GlobalFunctions.getUserDataToApi(rooms, this.userService.userValue?.complete);
            formData = GlobalFunctions.convertToFd( formData, customer );
          }
        }
      });
    }
  }

  hasExtras(rooms: IReservationRoom[]): boolean {
    let hasExtras = false;

    if ( typeof rooms.length !== 'undefined' ) {
      rooms.forEach(room => {
        if (typeof room.extras !== 'undefined') {
          if (!!room.extras.length) {
            hasExtras = !!room.extras.length;
          }
        }
      });
    }

    return hasExtras;
  }

  refreshExtraData( date: IReservationDate ): void {
    if ( date ) {
      if ( date.from && date.to ) {
        const dateFrom = moment([date.from.year, date.from.month - 1, date.from.day]).format('YYYY-MM-DD');
        const dateTo = moment([date.to.year, date.to.month - 1, date.to.day]).format('YYYY-MM-DD');
        const originalRooms = this.reservationData.getRoomsValue();
        let rooms: IReservationRoom[] = cloneDeep(originalRooms);
        const roomsToUpdate: number[] = [];
        const nightsOfStay = this.reservationData.getNightsOfStay();

        const hasExtras = this.hasExtras(rooms);

        if ( typeof rooms.length === 'undefined' ) {
          rooms = [];
        }

        if ( hasExtras ) {
          this.getExtras(dateFrom, dateTo, rooms).subscribe(extras => {

            try {
              if ( extras ) {
                if ( extras.extras.length > 0 ) {
                  extras.extras.forEach( extraCategory => {
                    if ( typeof extraCategory.values !== 'undefined' ) {
                      extraCategory.values.forEach(extra => {
                        if (extra.type === ReservationExtraTypeEnum.treatment) {
                          const symbol = extra.shuumSymbol;

                          if ( typeof extra.values !== 'undefined' ) {
                            extra.values.forEach(treatment => {
                              const treatmentSymbol = treatment.shuumSymbol;

                              rooms.forEach((room, index) => {
                                if ( typeof room.extras !== 'undefined' ) {
                                  room.extras.forEach(roomExtra => {
                                    if (roomExtra.type === ReservationExtraTypeEnum.treatment) {
                                      if (roomExtra.shuumSymbol === symbol) {
                                        let recalculateTotalPrice = false;
                                        let totalPrice = 0;

                                        roomExtra.values.forEach(roomTreatment => {
                                          if (roomTreatment.shuumSymbol === treatmentSymbol) {
                                            if (roomTreatment.price.value !== treatment.price.value) {
                                              roomTreatment.price = cloneDeep(treatment.price);
                                              recalculateTotalPrice = true;
                                            }
                                          }

                                          // tslint:disable-next-line:max-line-length
                                          totalPrice = totalPrice + (parseFloat(roomTreatment.price.value.toString()) * (roomTreatment.selectedAmount || 1));
                                        });

                                        if (recalculateTotalPrice) {
                                          roomsToUpdate.push(index);
                                          roomExtra.price.value = totalPrice;
                                        }
                                      }
                                    }
                                  });
                                }
                              });
                            });
                          }
                        } else if (extra.type === ReservationExtraTypeEnum.extraGroup) {
                          extra.values.forEach(extraGroup => {
                            const symbol = extraGroup.shuumSymbol;
                            const excludedDays = GlobalFunctions.getDurationOfExcludedExtras(extraGroup, date);

                            rooms.forEach((room, index) => {
                              const numberOfPeople = this.reservationData.getNumberOfPeople(index);
                              const numberOfBabies = this.reservationData.getNumberOfBabies(index);
                              const numberOfSmallChildren = this.reservationData.getNumberOfSmallChildren(index);
                              const numberOfAdults = numberOfPeople - numberOfBabies - numberOfSmallChildren;

                              if ( typeof room.extras !== 'undefined' ) {
                                room.extras.forEach(roomExtra => {
                                  if (roomExtra.type === ReservationExtraTypeEnum.extraGroup) {
                                    let recalculateTotalPrice = false;
                                    let totalPrice = 0;

                                    roomExtra.values.forEach(roomGroup => {
                                      let amount = roomExtra.selectedAmount || 1;

                                      if (extra.payoffType === ReservationPaymentUnitEnum.day) {
                                        amount = nightsOfStay - excludedDays;
                                      } else if (extra.payoffType === ReservationPaymentUnitEnum.dinner) {
                                        amount = (nightsOfStay - excludedDays) * numberOfAdults;
                                      }

                                      if (roomGroup.shuumSymbol === symbol) {
                                        if (roomGroup.price.value !== extraGroup.price.value) {
                                          roomGroup.price = cloneDeep(extraGroup.price);
                                          roomGroup.selectedAmount = amount;
                                          recalculateTotalPrice = true;
                                        }
                                      }

                                      totalPrice = totalPrice + (parseFloat(roomGroup.price.value.toString()) * amount);

                                      if (extraGroup.payoffType === ReservationPaymentUnitEnum.dinner) {
                                        if (typeof extra.childPrice !== 'undefined') {
                                          totalPrice = totalPrice + (parseFloat(extraGroup.childPrice.value.toString()) * (numberOfSmallChildren * (nightsOfStay - excludedDays)));
                                        }
                                      }
                                    });

                                    if (recalculateTotalPrice) {
                                      roomsToUpdate.push(index);
                                      roomExtra.price.value = totalPrice;
                                    }
                                  }
                                });
                              }
                            });
                          });
                        } else if (extra.type === ReservationExtraTypeEnum.extra) {
                          const symbol = extra.shuumSymbol;

                          rooms.forEach((room, index) => {
                            const numberOfPeople = this.reservationData.getNumberOfPeople(index);
                            const numberOfBabies = this.reservationData.getNumberOfBabies(index);
                            const numberOfSmallChildren = this.reservationData.getNumberOfSmallChildren(index);
                            const numberOfAdults = numberOfPeople - numberOfBabies - numberOfSmallChildren;

                            if ( typeof room.extras !== 'undefined' ) {
                              room.extras.forEach(roomExtra => {
                                if (roomExtra.type === ReservationExtraTypeEnum.extra) {
                                  if (roomExtra.shuumSymbol === symbol) {
                                    let totalPrice = 0;

                                    if (roomExtra.price.value !== extra.price.value) {
                                      roomExtra.price = cloneDeep(extra.price);

                                      if (typeof extra.childPrice !== 'undefined') {
                                        roomExtra.childPrice = cloneDeep(extra.childPrice);
                                      }

                                      const excludedDays = GlobalFunctions.getDurationOfExcludedExtras(extra, date);
                                      let amount = roomExtra.selectedAmount || 1;

                                      if (extra.payoffType === ReservationPaymentUnitEnum.day) {
                                        amount = nightsOfStay - excludedDays;
                                      } else if (extra.payoffType === ReservationPaymentUnitEnum.dinner) {
                                        amount = (nightsOfStay - excludedDays) * numberOfAdults;
                                      }

                                      // tslint:disable-next-line:max-line-length
                                      totalPrice = totalPrice + (parseFloat(roomExtra.price.value.toString()) * amount);

                                      if (extra.payoffType === ReservationPaymentUnitEnum.dinner) {
                                        if (typeof extra.childPrice !== 'undefined') {
                                          totalPrice = totalPrice + (parseFloat(extra.childPrice.value.toString()) * (numberOfSmallChildren * (nightsOfStay - excludedDays)));
                                        }
                                      }

                                      roomsToUpdate.push(index);
                                      roomExtra.price.value = totalPrice;
                                      roomExtra.selectedAmount = amount;
                                    }
                                  }
                                }
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                }
              }
            }
            catch (e) {
              console.log('err', e);
            }

            if ( roomsToUpdate.length ) {
              this.reservationData.setRooms(rooms);

              uniq(roomsToUpdate).forEach(roomIndex => {
                this.reservationData.setSelectedRoomsExtrasData(roomIndex);
              });
            }

            // if ( this.reservationData.data.replaceDataAfterLangChanged$.value ) {
            //   this.getRoomsStandards(dateFrom, dateTo);
            // }

            this.reservationData.removeNotExistingExtras();
          });
        }
        else {
          if ( this.reservationData.data.replaceDataAfterLangChanged$.value ) {
            this.getRoomsStandards(dateFrom, dateTo);
          }
        }
      }
    }
  }

  public replaceRoomsNames(rooms: IReservationRoom[], standards: IReservationRoomStandard[]) {
    try {
      if (rooms) {
        if (rooms.length > 0) {
          standards = toArray(standards);

          rooms.forEach( room => {
            const standard = room.standardId;

            standards.forEach( st => {
              // tslint:disable-next-line:triple-equals
              if ( st.shuumStandardId == standard ) {
                room.name = st.name;
              }
            });
          });

          this.reservationData.setRooms( rooms );
        }
      }
    }
    catch (e) {
      console.log('err', e);
    }

    return rooms;
  }

  /**
   * Podmienia nazwy jesli nastopila zmiana jezyka
   *
   * @param extras
   * @param rooms
   * @private
   */
  private replaceExtrasNames( extras: IReservationExtraNew[], rooms: IReservationRoom[] ) {
    try {
      if ( extras ) {
        if ( extras.length > 0 ) {
          extras.forEach( extraCategory => {
            if ( typeof extraCategory.values !== 'undefined' ) {
              extraCategory.values.forEach(extra => {
                if (extra.type === ReservationExtraTypeEnum.treatment) {
                  const symbol = extra.shuumSymbol;

                  if ( typeof extra.values !== 'undefined' ) {
                    extra.values.forEach(treatment => {
                      const treatmentSymbol = treatment.shuumSymbol;

                      rooms.forEach((room, index) => {
                        if ( typeof room.extras !== 'undefined' ) {
                          room.extras.forEach(roomExtra => {
                            if (roomExtra.type === ReservationExtraTypeEnum.treatment) {
                              if (roomExtra.shuumSymbol === symbol) {
                                roomExtra.name = extra.name;
                                roomExtra.unit = extra.unit;
                                const descriptions = [];

                                roomExtra.values.forEach(roomTreatment => {
                                  if (roomTreatment.shuumSymbol === treatmentSymbol) {
                                    roomTreatment.name = treatment.name;
                                    descriptions.push(treatment.name);
                                  }
                                });

                                roomExtra.description = descriptions.join(' ');
                              }
                            }
                          });
                        }
                      });
                    });
                  }
                }
                else if (extra.type === ReservationExtraTypeEnum.extraGroup) {
                  extra.values.forEach(extraGroup => {
                    rooms.forEach((room, index) => {
                      if ( typeof room.extras !== 'undefined' ) {
                        room.extras.forEach(roomExtra => {
                          if (roomExtra.type === ReservationExtraTypeEnum.extraGroup) {
                            if ( roomExtra.id === extra.id ) {
                              let counter = 0;

                              roomExtra.values.forEach(roomGroup => {
                                if (roomGroup.shuumSymbol === extraGroup.shuumSymbol) {
                                  roomExtra.name = extra.name;
                                  roomExtra.unit = extra.unit;

                                  if ( counter === 0 ) {
                                    roomExtra.description = '';
                                  }

                                  roomGroup.name = extraGroup.name;
                                  roomGroup.unit = extraGroup.unit;

                                  roomExtra.description = (roomExtra.description + ' ' + extraGroup.name).trim();

                                  counter++;
                                }
                              });
                            }
                          }
                        });
                      }
                    });
                  });
                }
                else if (extra.type === ReservationExtraTypeEnum.extra) {
                  const symbol = extra.shuumSymbol;

                  rooms.forEach((room, index) => {
                    if ( typeof room.extras !== 'undefined' ) {
                      room.extras.forEach(roomExtra => {
                        if (roomExtra.type === ReservationExtraTypeEnum.extra) {
                          if (roomExtra.shuumSymbol === symbol) {
                            roomExtra.name = extra.name;
                            roomExtra.unit = extra.unit;
                          }
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      }
    }
    catch (e) {
      console.log('err', e);
    }

    return rooms;
  }

  private setCoupon( params: HttpParams|FormData, coupon?: string): any {

    try {
      if (coupon) {
        if (params instanceof FormData) {
          params.append('coupon', coupon);
        }
        else {
          params = params.append('coupon', coupon);
        }
      }
    }
    catch (e) {

    }

    return params;
  }

  private setPointsUsed( params: HttpParams|FormData, pointsUsed?: boolean): any {

    try {
      if (pointsUsed) {
        if (params instanceof FormData) {
          params.append('pointsUsed', pointsUsed.toString());
        }
        else {
          params = params.append('pointsUsed', pointsUsed.toString());
        }
      }
    }
    catch (e) {

    }

    return params;
  }

  private setOfferSlug( params: HttpParams|FormData, offerSlug?: string): any {

    try {
      if (offerSlug) {
        if (params instanceof FormData) {
          params.append('slug', offerSlug);
        }
        else {
          params = params.append('slug', offerSlug);
        }
      }
    }
    catch (e) {

    }

    return params;
  }

  private setOfferId( params: HttpParams|FormData, offerId?: number): any {

    try {
      if (offerId) {
        if (params instanceof FormData) {
          params.append('offerId', offerId.toString());
        }
        else {
          params = params.append('offerId', offerId.toString());
        }
      }
    }
    catch (e) {

    }

    return params;
  }

  private setDinner( formData: FormData, rooms: IReservationRoom[] = [] ): void {
    if ( rooms.length > 0 ) {
      const [ room ] = rooms;

      if (typeof room !== 'undefined') {
        const withDinner = typeof room.withDinner !== 'undefined' ? room.withDinner.toString() : null;

        if (withDinner) {
          formData.append('withDinner', withDinner);
        }
      }
    }
  }
}
