import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GlobalStateHelper } from '@helpers/GlobalStateHelper';
import { PopupContentComponent } from 'src/app/_subprojects/reservation/_shared/popup-content/popup-content.component';
import { Router } from '@angular/router';
import ConfigService from '@config/ConfigService';
import { RouteMapHelper } from '@helpers/RouteMapHelper';

@Component({
  selector: 'app-sign-up-popup',
  templateUrl: './sign-up-popup.component.html',
  styleUrls: ['./sign-up-popup.component.scss'],
})
export class SignUpPopupComponent implements OnInit {
  @ViewChild('popup') popup: PopupContentComponent;

  constructor(
    private globalStateHelper: GlobalStateHelper,
    private router: Router,
    private routeMapHelper: RouteMapHelper
  ) { }

  ngOnInit(): void {
  }

  formSent(_: FormGroup) {
    this.globalStateHelper.unlockActivateAccountPopup = true;

    const currentLang = ConfigService.getCurrentLang();
    this.router.navigate(
      [
        `${currentLang}/${this.routeMapHelper.getRoute('my-reservation', currentLang)}/${this.routeMapHelper.getRoute('my-reservation_authorization-group', currentLang)}/${this.routeMapHelper.getRoute('my-reservation_authorization-group_activate-account-group', currentLang)}`
      ])
  }

  redirectWithBlockedScroll($e: MouseEvent, link: string): void {
    $e.preventDefault();
    this.globalStateHelper.showPopupWithoutAnimation = true;
    this.popup.redirectWithoutHiding(link);
  }
}
