import { Pipe, PipeTransform } from '@angular/core';
import {RouteMapHelper} from '../_helpers/RouteMapHelper';
import ConfigService from '../_config/ConfigService';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'i18nRoute'
})
export class I18nRoutePipe implements PipeTransform {
  private readonly currentLang: string;

  constructor( private routeHelper: RouteMapHelper ) {
    this.currentLang = ConfigService.getCurrentLang();
  }

  transform(value: string, lang: string = this.currentLang): string {
    if ( value ) {
      const splitTo = value.split('/');
      let result = value;
      let keys = [];

      result = result.replace(':lang', lang);

      splitTo.forEach(val => {
        if (val === environment.routeLanguageKey) {
          return;
        }

        if (val) {
          keys.push(val);

          const route = this.routeHelper.getRoute(keys.join('_'), lang);

          if (route && val) {
            result = result.replace(val, route);
          }
        }
      });

      result = result.replace('/:id', '');
      return result;
    }

    return value;
  }

}
