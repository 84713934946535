import {Component, OnDestroy, OnInit} from '@angular/core';
import ConfigService from '@config/ConfigService';
import { IImage } from '@interfaces/IImage';
import {takeUntil} from 'rxjs/operators';
import {IMenuElement} from '@interfaces/IMenuElement';
import {MenuService} from '@services/menu.service';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {IConfiguration} from '@interfaces/IConfiguration';
import ApiConfigurationEndpointEnum from '@enums/ApiConfigurationEndpointEnum';
import {catchError} from 'rxjs/internal/operators';
import {ConfigurationService} from '@services/configuration.service';

@Component({
  selector: 'app-delicatessen',
  templateUrl: './delicatessen.component.html',
  styleUrls: ['./delicatessen.component.scss']
})
export class DelicatessenComponent extends SubscribeHelper implements OnInit, OnDestroy {
  public hasSrcSetSupport: boolean;
  galleryImages: IImage[] = [];

  menuElements: IMenuElement[];
  // restaurantMenu: string;
  currentLang: string;
  delicatessenMenuFile: IConfiguration = null;

  constructor(
    private menuService: MenuService,
    private apiConfiguration: ConfigurationService
  ) {
    super();

    this.hasSrcSetSupport = ConfigService.getHasSrcSetSupport();
    this.currentLang = ConfigService.getCurrentLang();
    this.setGalleryImages();
   }

  ngOnInit() {
    this.menuService
      .getList()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((res: IMenuElement[]) => {
        this.menuElements = res;
        // if (this.menuElements && this.menuElements.length > 0) {
        //   this.restaurantMenu = this.menuElements[0].restaurantMenu;
        // }
      });

    this.getDelicatessenMenuFile();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  setGalleryImages() {
    const path = '../../../assets/images/gallery/food_wine';

    for (let i = 1; i < 6; i++) {
      for (let j = 1; j < 6; j++) {
        this.galleryImages.push({
          image: `${path}/${i}_slide/${j}.jpg`,
          image2x: `${path}/${i}_slide/${j}.jpg`,
        });
      }
    }
  }

  getDelicatessenMenuFile(): void {
    this.apiConfiguration
      .getConfiguration(ApiConfigurationEndpointEnum.DelicatessenMenu)
      .pipe(
        takeUntil(this.componentDestroyed$),
        catchError(err => {
          this.delicatessenMenuFile = { id: null, value: '', iso: '', name: '' };
          return err;
        })
      )
      .subscribe((res: IConfiguration) => {
        this.delicatessenMenuFile = res;
      });
  }
}
