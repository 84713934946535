import { Component, OnInit, Input } from '@angular/core';
import ConfigService from '@config/ConfigService';

@Component({
  selector: 'app-single-image-text',
  templateUrl: './single-image-text.component.html',
  styleUrls: ['./single-image-text.component.scss']
})
export class SingleImageTextComponent implements OnInit {
  public hasSrcSetSupport: boolean;

  @Input() image: string;
  @Input() image2x: string;
  @Input() imageAlt: string;
  @Input() theme: string;

  constructor() {
    this.hasSrcSetSupport = ConfigService.getHasSrcSetSupport();
  }

  ngOnInit() {
  }

}
