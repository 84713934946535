import { Component, OnInit } from '@angular/core';
import CollageImageEnum from '@enums/CollageImageEnum';
import IImageCollage from '@interfaces/IImageCollage';
import {IImage} from '@interfaces/IImage';
import ICarouselImages from '@interfaces/ICarouselImages';
import CarouselImageEnum from '@enums/CarouselImageEnum';
import { NavigationExtras, Router } from '@angular/router';
import { ConfigurationService } from '@services/configuration.service';
import { IConfiguration } from '@interfaces/IConfiguration';
import { takeUntil } from 'rxjs/operators';
import { SubscribeHelper } from '@helpers/SubscribeHelper';

@Component({
  selector: 'app-joga-and-fitness',
  templateUrl: './joga-and-fitness.component.html',
  styleUrls: ['./joga-and-fitness.component.scss']
})
export class JogaAndFitnessComponent extends SubscribeHelper implements OnInit {

  collageEnum: typeof CollageImageEnum;
  imageCollage: IImageCollage;
  imageCollage2: IImageCollage;
  imageCollage3: IImageCollage;
  galleryImages: IImage[] = [];
  carouselImages: ICarouselImages[] = [];
  configuration: IConfiguration;

  constructor(private configurationService: ConfigurationService) {
    super();
   }

  ngOnInit() {
    this.collageEnum = CollageImageEnum;

    this.getScheduleUrl();
    this.setImageCollages();
    this.setGalleryImages();
    this.setCarouselImages();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getScheduleUrl() {
    this.configurationService
      .getConfiguration('yoga_schedule')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((res: IConfiguration) => {
        this.configuration = res;
      });
  }

  setImageCollages() {
    const path = '../../../assets/images/wellness_page/';

    this.imageCollage = {
      smallImage: {
        image: path + 'yoga/joga_02_tiny.jpg',
        image2x: path + 'yoga/joga_02@2x_tiny.jpg'
      },
      bigImage: {
        image: path + 'yoga/joga_01_tiny.jpg',
        image2x: path + 'yoga/joga_01@2x_tiny.jpg'
      }
    };

    this.imageCollage2 = {
      smallImage: {
        image: path + 'yoga/aguafitness_02_tiny.jpg',
        image2x: path + 'yoga/aguafitness_02@2x_tiny.jpg'
      },
      bigImage: {
        image: path + 'yoga/aguafitness_01_tiny.jpg',
        image2x: path + 'yoga/aguafitness_01@2x_tiny.jpg'
      }
    };

    this.imageCollage3 = {
      smallImage: {
        image: path + 'yoga/rower_02_tiny.jpg',
        image2x: path + 'yoga/rower_02@2x_tiny.jpg'
      },
      bigImage: {
        image: path + 'yoga/rower_01_tiny.jpg',
        image2x: path + 'yoga/rower_01@2x_tiny.jpg'
      }
    };
  }

  setGalleryImages() {
    const path = '../../../assets/images/gallery/wellness_spa';

    for (let i = 1; i < 7; i++) {
      for (let j = 1; j < 6; j++) {
        this.galleryImages.push({
          image: `${path}/${i}_slide/${j}.jpg`,
          image2x: `${path}/${i}_slide/${j}.jpg`,
        });
      }
    }
  }

  setCarouselImages() {
    const path = '../../../assets/images/carousel/wellness/';

    this.carouselImages.push({
      image: {
        image: path + '01.jpg',
        image2x: path + '01@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '02.jpg',
        image2x: path + '02@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '03.jpg',
        image2x: path + '03@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    });
  }

}
