<app-banner
  header="food_menu_header"
  description="food_menu_description"
  background="/assets/images/food_and_wine/menu/banner.jpg"
  background2x="/assets/images/food_and_wine/menu/banner@2x.jpg"
>
  <app-line-btn
    class="h4"
    scrollTo="a-la-carte-menu"
    [noOffset]="true"
    bottomButton
  >
    <span>{{ 'know_more'|translate }}</span>
  </app-line-btn>
</app-banner>

<div id="a-la-carte-menu" class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-text-simple-centered
      header="{{ 'food_menu_h_01'|translate}}"
    >
      <p class="p-responsive" [innerHTML]="'food_menu_c_01'|translate|newLineBreak|removeWidows"></p>
    </app-text-simple-centered>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row">
      <div class="col-12 col-md-5 col-lg-4 offset-xl-1">
        <app-images-collage
          [images]="imagesCollage"
          [type]="collageEnum.LargeTopSmall2ColBottomRight"
        ></app-images-collage>
      </div>

      <div class="col-md-6 col-xl-4 offset-md-1 pt-giga pt-md-zero">
        <app-indent-content
            [header]="'food_menu_h_02'|translate|newLineBreak|removeWidows"
          >
            <p class="small" [innerHTML]="'food_menu_c_02'|translate|newLineBreak|removeWidows"></p>
          </app-indent-content>
      </div>
    </div>
  </div>
</div>

<app-after-before-grey
  width="14%"
  height="950px"
  top="60px"
  left="0"
  additionalClasses="thick-no-responsive"
>
  <div class="container mobile-padding">
    <div class="pt-peta pt-md-zetta pt-lg-200">
      <div class="row flex-column-reverse flex-md-row">
        <div class="col-md-6 col-xl-4 offset-lg-1 offset-xl-2 pt-giga pt-md-zero">
          <app-indent-content
              [header]="'food_menu_h_03'|translate|newLineBreak|removeWidows"
            >
              <p class="small" [innerHTML]="'food_menu_c_03'|translate|newLineBreak|removeWidows"></p>
            </app-indent-content>
        </div>

        <div class="col-12 col-md-5 col-lg-4 offset-md-1">
          <app-images-collage
            [images]="imagesCollage2"
            [type]="collageEnum.LargeTopSmall2ColBottomLeft"
          ></app-images-collage>
        </div>
      </div>
    </div>
  </div>
</app-after-before-grey>

<div class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row">
      <div class="col-12 col-md-5 col-lg-4 offset-xl-1">
        <app-images-collage
          [images]="imagesCollage3"
          [type]="collageEnum.LargeTopSmall2ColBottomRight"
        ></app-images-collage>
      </div>

      <div class="col-md-6 col-xl-4 offset-md-1 pt-giga pt-md-zero">
        <app-indent-content
            [header]="'food_menu_h_04'|translate|newLineBreak|removeWidows"
          >
            <p class="small" [innerHTML]="'food_menu_c_04'|translate|newLineBreak|removeWidows"></p>
          </app-indent-content>
      </div>
    </div>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <h2 class="text-center">Menu</h2>
    <app-menu [showMenuBtn]="true">
      <h3 header class="pl-deci pl-sm-hecto pt-lg-kilo pt-hecto pt-xl-exa pb-deca">{{ 'proposed'|translate }}</h3>
    </app-menu>
  </div>
</div>

<app-footer></app-footer>
