import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-indent-content',
  templateUrl: './indent-content.component.html',
  styleUrls: ['./indent-content.component.scss']
})
export class IndentContentComponent implements OnInit {

  @Input() header?: string;
  @Input() headerImage?: string;
  @Input() wider?: boolean;
  @Input() headerClass?: string;
  @Input() autoWidth?: boolean;
  @Input() contentClass = 'pt-mega';

  constructor() { }

  ngOnInit() {
    if ( typeof this.wider !== 'undefined' ) {
      if ( this.wider !== false ) {
        this.wider = true;
      }
    }

    if ( typeof this.autoWidth !== 'undefined' ) {
      if ( this.autoWidth !== false ) {
        this.autoWidth = true;
      }
    }
  }

}
