<div class="pt-zetta">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-xl-10">
        <div class="error-container">
          <div class="pb-mega pb-md-giga">
            <h2
              *ngIf="'reservation_error_header'"
              class="small light"
              [innerHTML]="'reservation_error_header'|translate|newLineBreak|removeWidows"
            ></h2>

            <div class="row align-items-md-end">
              <div class="col-12 col-md-6">
                <p
                  *ngIf="'reservation_error_description'"
                  class="pt-hecto pt-md-kilo regular-hind"
                  [innerHTML]="'reservation_error_description'|translate|newLineBreak|removeWidows"
                ></p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 justify-content-end d-flex">
              <div class="pb-mega pb-md-giga">
                <a [routerLink]="'/:lang/blogostan'|i18nRoute" class="btn btn-arrow">{{ 'back'|translate }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
