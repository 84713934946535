import {Injectable} from '@angular/core';
import * as toArray from 'lodash/toArray';
import IReservationGA from '../_subprojects/reservation-form/_interfaces/IReservationGA';

@Injectable({
  providedIn: 'root'
})
export class GaPusherService {
  public getData(data: IReservationGA): any {
    const products = this.getProducts(data);

    return {
      ecommerce: {
        purchase: {
          actionField: {
            id: data.bookingId,
            affiliation: 'Rezerwacja',
            revenue: parseFloat(data.bookingPrice.value.toString()),
          },
          products
        }
      }
    };
  }

  private getProducts(data: IReservationGA): [] {
    const gaData = {};

    data.rooms.forEach((room) => {
      if (typeof gaData[room.standardId] === 'undefined') {
        gaData[room.standardId] = {
          id: room.standardId,
          name: room.name,
          sku: room.standardId,
          category: 'Pokój',
          price: parseFloat(room.roomPrice.value.toString()),
          quantity: 1,
        };
      }
      else {
        gaData[room.standardId].price += parseFloat(room.roomPrice.value.toString());
      }

      room.extras.items.forEach((group) => {
        group.values.forEach( (extra) => {
          if ( typeof extra.values === 'undefined' ) {
            if (typeof gaData[extra.shuumSymbol] === 'undefined') {
              gaData[extra.shuumSymbol] = {
                id: extra.shuumSymbol,
                name: extra.name,
                sku: extra.shuumSymbol,
                category: group.name,
                price: parseFloat(extra.price.value.toString()),
                quantity: 1,
              };
            }
            else {
              gaData[extra.shuumSymbol].price += parseFloat(extra.price.value.toString());
            }
          }
          else {
            extra.values.forEach( extraValue => {
              if (typeof gaData[extraValue.shuumSymbol] === 'undefined') {
                gaData[extraValue.shuumSymbol] = {
                  id: extraValue.shuumSymbol,
                  name: [extra.name, extraValue.name].join(' - '),
                  sku: extraValue.shuumSymbol,
                  category: group.name,
                  price: parseFloat(extraValue.price.value.toString()),
                  quantity: 1,
                };
              }
              else {
                gaData[extraValue.shuumSymbol].price += parseFloat(extraValue.price.value.toString());
              }
            });
          }
        });
      });
    });

    return toArray(gaData);
  }
}
