import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';

import {I18nRoutePipe} from '../_pipes/i18n-route.pipe';
import {NewLineBreakPipe} from '../_pipes/new-line-break.pipe';
import {RemoveWidowsPipe} from '../_pipes/remove-widows.pipe';
import {ApiUrlPipe} from '../_pipes/api-url.pipe';
import {CurrencySignPipe} from '../_pipes/currency-sign.pipe';
import {PricePipe} from '../_pipes/price.pipe';
import {StripHtmlPipe} from '../_pipes/strip-html.pipe';
import {HyphenToLi} from '../_pipes/hyphen-to-li.pipe';
import { TimesPipe } from '../_pipes/times.pipe';
import {ShortenPipe} from '../_pipes/shorten.pipe';
import { KeysPipe } from '../_pipes/keys.pipe';
import { DatexPipe } from '../_pipes/datex.pipe';
import {StripParagraphPipe} from '../_pipes/strip-paragraph.pipe';
import { NoCommaPipe } from '../_pipes/no-comma';
import { FilterExtrasPipe } from '../_pipes/filter-extras.pipe';
import { FormatExtraDatePipe } from '../_pipes/format-extra-date.pipe';

@NgModule({
  declarations: [
    I18nRoutePipe,
    NewLineBreakPipe,
    RemoveWidowsPipe,
    ApiUrlPipe,
    CurrencySignPipe,
    PricePipe,
    StripHtmlPipe,
    HyphenToLi,
    TimesPipe,
    ShortenPipe,
    KeysPipe,
    DatexPipe,
    StripParagraphPipe,
    NoCommaPipe,
    FilterExtrasPipe,
    FormatExtraDatePipe
  ],
  imports: [
    CommonModule
  ],
  providers: [

  ],
  bootstrap: [],
  exports: [
    I18nRoutePipe,
    NewLineBreakPipe,
    RemoveWidowsPipe,
    ApiUrlPipe,
    CurrencySignPipe,
    PricePipe,
    StripHtmlPipe,
    HyphenToLi,
    TimesPipe,
    ShortenPipe,
    KeysPipe,
    DatexPipe,
    StripParagraphPipe,
    NoCommaPipe,
    FilterExtrasPipe,
    FormatExtraDatePipe
  ]
})
export class SharedPipesModule { }
