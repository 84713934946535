<app-banner
  header="404"
  description="404_description"
  [withOverlay]="false"
  background="/assets/images/404/not-found.jpg"
  background2x="/assets/images/404/not-found@2x.jpg"
>
  <app-line-btn
    class="h4"
    [link]="'/:lang'|i18nRoute"
    bottomButton
  >
    <span>{{ 'go_to_homepage'|translate }}</span>
  </app-line-btn>
</app-banner>
