import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { PageLoaderComponent } from './_shared/page-loader/page-loader.component';
import { SharedLoaderModule } from 'src/app/_shared-modules/shared-loader.module';

@NgModule({
  declarations: [
    PageLoaderComponent
  ],
  imports: [
    CommonModule,
    SharedLoaderModule
  ],
  exports: [
    PageLoaderComponent
  ]
})

export class SharedPageLoaderModule {
}
