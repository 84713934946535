import {Component, OnInit} from '@angular/core';
import {routeAnimation} from '@helpers/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {SubscribeHelper} from '@helpers/SubscribeHelper';

@Component({
  selector: 'app-wellness',
  templateUrl: './wellness.component.html',
  styleUrls: ['./wellness.component.scss'],
  animations: [
    routeAnimation
  ]
})
export class WellnessComponent extends SubscribeHelper implements OnInit {

  routeDataParams?: {
    pageData: {
      header: string,
      description: string,
      background: string,
      background2x: string,
      wrapperId: string
    }
  } = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe( data => {
        this.routeDataParams = data as any;
      });
  }
}
