import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeWidows'
})
export class RemoveWidowsPipe implements PipeTransform {

  transform(value: string): string {
    if ( value ) {
      value = value.replace(/(\s)([\S])[\s]+/g, '$1$2&nbsp;'); //jednoznakowe
      value = value.replace(/(\s)([^<][\S]{1})[\s]+/g, '$1$2&nbsp;'); //dwuznakowe
    }

    return value;
  }

}
