import { NgModule } from '@angular/core';
import {CommonModule, isPlatformServer} from '@angular/common';
import {I18nModule} from '../i18n/i18n.module';

import {Content2plus1Component} from '@shared/content2plus1/content2plus1.component';
import { SharedPipesModule } from './shared-pipes.module';
import { SharedButtonsModule } from './shared-buttons.module';
import {intersectionObserverPreset, LazyLoadImageModule, scrollPreset} from 'ng-lazyload-image';

export function isBot(navigator, platformId) {
  return isPlatformServer(platformId) ? false : intersectionObserverPreset.isBot(navigator, platformId);
}

@NgModule({
  declarations: [
    Content2plus1Component
  ],
  imports: [
    CommonModule,
    I18nModule,
    SharedPipesModule,
    SharedButtonsModule,
    LazyLoadImageModule.forRoot({
      preset: scrollPreset, // <-- tell LazyLoadImage that you want to use scrollPreset
      isBot
    })
  ],
  providers: [

  ],
  bootstrap: [],
  exports: [
    Content2plus1Component
  ]
})
export class Shared2plus1Module { }
