import {Component, Input, OnInit} from '@angular/core';
import IHistoryElement from '../../_interfaces/IHistoryElement';

@Component({
  selector: 'app-hotel-history',
  templateUrl: './hotel-history.component.html',
  styleUrls: ['./hotel-history.component.scss']
})
export class HotelHistoryComponent implements OnInit {
  @Input() header: string;
  public historyElements: Array<IHistoryElement>;

  constructor() {
    this.historyElements = [
      {
        year: '2003',
        description: 'hotel_history_01',
        image1x: 'assets/images/career_page/history/_G182682.jpg',
        image2x: 'assets/images/career_page/history/_G182682@2x.jpg',
      },
      {
        year: '2014',
        description: 'hotel_history_02',
        image1x: 'assets/images/career_page/history/img_4109.jpg',
        image2x: 'assets/images/career_page/history/img_4109@2x.jpg',
      },
      {
        year: '2015',
        description: 'hotel_history_03',
        image1x: 'assets/images/career_page/history/img_2015_v2.jpg',
        image2x: 'assets/images/career_page/history/img_2015_v2@2x.jpg',
      },
      {
        year: '2016-20',
        description: 'hotel_history_04',
        image1x: 'assets/images/career_page/history/IMG_7791.jpg',
        image2x: 'assets/images/career_page/history/IMG_7791@2x.jpg',
      },
      {
        year: '2017',
        description: 'hotel_history_05',
        image1x: 'assets/images/career_page/history/img_3340.jpg',
        image2x: 'assets/images/career_page/history/img_3340@2x.jpg',
      },
      {
        year: '2018',
        description: 'hotel_history_06',
        image1x: 'assets/images/career_page/history/IMG_3253.jpg',
        image2x: 'assets/images/career_page/history/IMG_3253@2x.jpg',
      },
    ];
  }

  ngOnInit() {
  }

}
