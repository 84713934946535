<div class="container">
  <div class="row justify-content-center pt-giga pb-giga pl-deci pr-deci pl-sm-zero pr-sm-zero">
    <div class="col-12 col-sm-10 col-md-7 col-xxl-6">
      <app-activate-account-content
        [link]="'/:lang/blogostan'|i18nRoute"
        linkText="back_to_home"
      ></app-activate-account-content>
    </div>
  </div>
</div>
