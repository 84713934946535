<div class="hotel-history">
  <h3 class="text-light-gray offset-2">{{ header }}</h3>
  <div class="row justify-content-center">
    <div class=" offset-2 offset-md-0 col-md-12 col-xl-10">
      <div class="mt-giga mt-xl-exa history-wrapper">
        <div *ngFor="let historyElement of historyElements; let first = first" class="row pt-exa pt-xl-200 history-element-wrapper justify-content-between" [ngClass]="{ 'pt-0': first }">
          <div class="col-12 col-md-6 history-element-text history-part">
            <div class="">
              <h4 class="col-12">{{historyElement.year}}</h4>
              <p
                class="pt-giga pt-xl-peta col-md-10 offset-md-2 small"
                [innerHTML]="historyElement.description|translate|removeWidows|newLineBreak"
              ></p>
            </div>
          </div>
          <div class="pt-mega pt-md-zero col-sm-10 offset-sm-1 offset-md-0 col-md-5 history-part">
            <img
              [srcset]="historyElement.image1x + ' 1x, ' + historyElement.image2x + ' 2x'"
              [alt]="historyElement.year"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

