import { Component, OnInit } from '@angular/core';
import IImageCollage from '@interfaces/IImageCollage';
import CollageImageEnum from '@enums/CollageImageEnum';
import CarouselImageEnum from '@enums/CarouselImageEnum';
import ICarouselImages from '@interfaces/ICarouselImages';

@Component({
  selector: 'app-wellness-etiquette',
  templateUrl: './wellness-etiquette.component.html',
  styleUrls: ['./wellness-etiquette.component.scss']
})
export class WellnessEtiquetteComponent implements OnInit {
  collageEnum: typeof CollageImageEnum;
  imagesCollage: IImageCollage;
  carouselImages: ICarouselImages[] = [];

  constructor() { }

  ngOnInit(): void {
    this.collageEnum = CollageImageEnum;

    this.setImageCollages();
    this.setCarouselImages();
  }

  setImageCollages() {
    const path = '../../../assets/images/wellness_page/';

    this.imagesCollage = {
      smallImage: {
        image: path + 'etiquette/04_small.jpg',
        image2x: path + 'etiquette/04_small@2x.jpg'
      },
      bigImage: {
        image: path + 'etiquette/04_big.jpg',
        image2x: path + 'etiquette/04_big@2x.jpg'
      }
    };
  }

  setCarouselImages() {
    const path = '../../../assets/images/carousel/wellness/';

    this.carouselImages.push({
      image: {
        image: path + '01.jpg',
        image2x: path + '01@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '02.jpg',
        image2x: path + '02@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    }, {
      image: {
        image: path + '03.jpg',
        image2x: path + '03@2x.jpg',
      },
      type: CarouselImageEnum.Tall
    });
  }
}
