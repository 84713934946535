import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nRoutePipe } from 'src/app/_pipes/i18n-route.pipe';
import { UserService } from '@services/auth/user.service';
import { ReservationDataService } from '@services/reservations/reservation-data.service';
import { ReservationService } from '../../_subprojects/reservation-form/_services/reservation.service';
import { ReservationLinkService } from 'src/app/_subprojects/reservation-form/_services/reservation-link.service';
import { environment } from 'src/environments/environment';
import { LayoutService } from '@services/layout.service';
import GlobalFunctions from '@helpers/GlobalFunctions';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-redirector',
  templateUrl: './redirector.component.html',
  styleUrls: ['./redirector.component.scss']
})
export class RedirectorComponent extends SubscribeHelper implements OnInit, OnDestroy {
  /**
   * Akcje wykonujące się w redirectorze
   * link - przekierowanie na dany link,
   * logout - czy wylogować użytkownika,
   * refer - czy przekierować użytkownika na link podany w queryParamie refer,
   * saveReservationData - dane rezerwacji są pobierane z API (udostępnione
   *  przez link rezerwacji)
   */
  map = {
    'password-reset': { link: 'my-reservation/authorization-group/new-password-group', logout: true },
    'account-activation': { link: 'my-reservation/authorization-group/activate-account-mail-group', logout: true },
    'my-reservations': { link: 'my-reservation/reservation-panel-group' },
    'login-social': { refer: true },
    'reservation-link': { link: 'reservation', saveReservationData: true, refresh: true },
    'payment-success': { link: 'reservation/success-page' },
    'payment-error': { link: 'reservation/error-page' },
    'payment-shop-success': { link: 'blogostan/success-page' },
    'payment-shop-error': { link: 'blogostan/error-page' },
    logout: { logout: true, link: 'blogostan/sign-in-group/logout-group' },
    default: { link: '/' }
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private i18nRoute: I18nRoutePipe,
    private userService: UserService,
    private reservationLinkService: ReservationLinkService,
    private layoutService: LayoutService,
  ) { super(); }

  ngOnInit(): void {
    const mapped = this.map[this.route.snapshot.queryParams.action];
    if ( mapped ) {
      if ( mapped.logout && this.userService.userValue ) {
        this.userService.logout();
      }

      /**
       * W przypadku pobierania linku muszą się
       * odświeżyć dane angularowe w step one,
       * metoda setForceReservationDataRefresh
       * powoduje odświeżenie danych w ReservationDataService
       */
      if ( mapped.saveReservationData ) {
        this.reservationLinkService
          .getSharedReservation( this.route.snapshot.queryParams.token )
          .pipe(
            takeUntil(this.componentDestroyed$),
            finalize(() => {
              const newRoute = `/:lang/${mapped.link}`;

              this.router.navigate(
                [ this.i18nRoute.transform(newRoute) ]
              );
            })
          )
          .subscribe(_ => {
            this.layoutService.setForceReservationDataRefresh(true);

            this.layoutService.setReservationInfoPopup({
              header: 'reservation_by_link_success_header',
              description: 'reservation_by_link_success_desc',
              callback: () => {
                this.layoutService.setOpenSidebar(true);
              }
            });
          }, _ => {
            this.layoutService.setReservationInfoPopup({
              header: 'reservation_by_link_error_header',
              description: 'reservation_by_link_error_desc'
            });
          });
      }

      if ( mapped.link && !mapped.saveReservationData ) {
        const newRoute = `/:lang/${mapped.link}`;

        this.router.navigate(
          [ this.i18nRoute.transform(newRoute) ],
          {
            queryParams: {
              token: this.route.snapshot.queryParams.token,
              ga: this.route.snapshot.queryParams.ga
            }
          }
        );
      }

      if ( mapped.refer ) {
        const { token, refer } = this.route.snapshot.queryParams;

        if ( token && refer ) {
          this.userService.setUserData({ token });
          this.userService.isLoggedIn$.next( true );

          this.userService.getMe().subscribe(res => {
            this.userService.setUserData({ token: res.refreshToken.token });
            window.location.replace(decodeURIComponent(refer));
          }, _ => {
              this.userService.isLoggedIn$.next( false );
              this.goToDefault();
          });
        } else {
          this.goToDefault();
        }
      }
    } else {
      this.goToDefault();
    }
  }

  goToDefault() {
    this.router.navigate(
      [ this.i18nRoute.transform(this.map.default.link) ]
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
