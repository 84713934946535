<div
  class="price"
  [ngClass]="{ 'small': small, 'medium': medium, 'very-small': verySmall }"
  *ngIf="price"
>
  <div
    *ngIf="priceFrom"
    class="size price-from text-gray"
  >
    {{ 'price_from'|translate }}
  </div>

  <span class="from" *ngIf="from">{{ 'from'|translate }}</span>

  <span *ngIf="price.advance; else elseBlock" class="value">{{ price?.advance|price }}</span>

  <ng-template #elseBlock>
    <span class="value">{{ priceValue?.value|price }}</span>
  </ng-template>

  <span class="currency uppercase">{{ price?.currency|currencySign }}</span>

  <ng-container *ngIf="regularPrice">
    <s class="text-light-gray2 uppercase ml-1">{{ regularPrice|price }}&nbsp;{{ price?.currency|currencySign }}</s>
  </ng-container>

  <div
    *ngIf="size"
    class="size unit"
    [innerHTML]="size"
  ></div>
</div>
