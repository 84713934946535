import { Component, OnDestroy, OnInit } from '@angular/core';
import {filter, first, map, take, takeUntil} from 'rxjs/operators';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import {UserService} from '@services/auth/user.service';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import {UserBannerService} from '../_services/user-banner.service';
import {IUserBanner} from '../_interfaces/IUserBanner';
import {BannerTypeEnum} from '../_enums/BannerTypeEnum';
import {User} from '../../../_models/auth/User';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.scss']
})
export class ReservationsComponent extends SubscribeHelper implements OnInit, OnDestroy {
  mapped = {
    'reservation-panel-group': 'reservation_details',
    'edit-account-group': 'edit_account',
    default: 'account_details'
  };

  textToDisplay = this.mapped.default;
  user: User;
  loggedIn = false;
  loyaltyProgram: boolean;

  bannerType: typeof BannerTypeEnum = BannerTypeEnum;
  userBanner: IUserBanner;

  constructor(
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userBannerService: UserBannerService
  ) {
    super();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.userService.user.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(user => {
      this.user = user;
      this.loggedIn = !!user;
      this.loyaltyProgram = !!user?.loyaltyProgram;

      if ( !this.userBanner && this.loyaltyProgram ) {
        this.getBannerInfo();
      }
    });

    this.subscribeToRoute();
  }

  subscribeToRoute() {
    const initial = this.findFirstChild(this.activatedRoute.snapshot);
    this.textToDisplay = this.mapped[initial] || this.mapped.default;

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.findFirstChild(this.activatedRoute.snapshot)),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((route: string) => {
        this.textToDisplay = this.mapped[route] || this.mapped.default;
      });
  }

  private findFirstChild(route: ActivatedRouteSnapshot): string {
    let result = route.data?.group || '';

    while (route.firstChild) {
      route = route.firstChild;

      if ( route.data?.group && route.data.group !== '' ) {
        result = route.data.group;
      }
    }

    return result;
  }

  getBannerInfo() {
    this.userBannerService.getBannerInfo()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(bannerInfo => {
        this.userBanner = bannerInfo;
      });
  }
}
