import { Component, OnInit } from '@angular/core';
import ConfigService from '@config/ConfigService';
import { GlobalStateHelper } from '@helpers/GlobalStateHelper';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import { UserService } from '@services/auth/user.service';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/_models/auth/User';

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})
export class UserPanelComponent extends SubscribeHelper implements OnInit {

  readonly currentLang: string;
  activeLink: number = 1;
  user: User;

  constructor(
    private globalStateHelper: GlobalStateHelper,
    private userService: UserService
  ) {
    super();
    this.currentLang = ConfigService.getCurrentLang();
  }

  ngOnInit(): void {
    this.activeLink = this.globalStateHelper.accountNavigation;

    this.globalStateHelper.accountNavigationChange.subscribe(val => {
      this.activeLink = val;
    });

    this.user = this.userService.userValue;
    this.userService.user
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe( user => {
        this.user = user;
      });
  }

}
