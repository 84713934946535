import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import IReservationRoom from '../../_interfaces/IReservationRoom';
import { ReservationDataService } from '@services/reservations/reservation-data.service';
import IPrice from '@interfaces/IPrice';
import IReservationDate from '../../_interfaces/IReservationDate';
import ConfigService from '@config/ConfigService';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import * as moment from 'moment';
import {ReservationService} from '../../_services/reservation.service';
import IReservationSummary from '../../_interfaces/IReservationSummary';
import {Router} from '@angular/router';
import {I18nRoutePipe} from '../../../../_pipes/i18n-route.pipe';
import { IValidationMsg } from 'src/app/_subprojects/reservation/_interfaces/IValidationMsg';
import { isPlatformBrowser } from '@angular/common';
import GlobalFunctions from '@helpers/GlobalFunctions';
import { ReservationLinkService } from '../../_services/reservation-link.service';
import { fadeAnimation } from '@helpers/animations';
import { IPopupInfoMessage } from '../../_interfaces/IPopupInfoMessage';
import ReservationAvailableTypeEnum from '../../_enums/ReservationAvailableTypeEnum';
import { LayoutService } from '@services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/auth/user.service';
import { RoleEnum } from '@enums/RoleEnum';

@Component({
  selector: 'app-step-five',
  templateUrl: './step-five.component.html',
  styleUrls: ['./step-five.component.scss'],
  animations: [
    fadeAnimation
  ]
})
export class StepFiveComponent extends SubscribeHelper implements OnInit, OnDestroy {
  rooms: IReservationRoom[];
  summary: IReservationSummary = null;
  stayDate: IReservationDate;
  activeRoomIndex = null;
  finalSubprice: IPrice;
  advancePercent: number;
  processingForPaymentLink = false;

  euroExchangeRate = 4.4;
  paymentLink: string;

  copyMsg: IValidationMsg;
  timeout: any;

  shuummeDiscount: IPrice = { value: 0, currency: '' };

  offerId: number;

  constructor(
    private reservation: ReservationService,
    private reservationData: ReservationDataService,
    private router: Router,
    private i18nRoutePipe: I18nRoutePipe,
    private reservationLinkService: ReservationLinkService,
    private layoutService: LayoutService,
    private translateService: TranslateService,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToRooms();
    this.subscribeToDate();

    this.subscribeToSummary();
    
    this.shuummeDiscount.currency = ConfigService.getCurrentCurrency();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.reservationData.clearInvalidMessages();
  }

  applyShuummePoints(value: boolean) {
    this.subscribeToSummary();
  }

  subscribeToRooms(): void {
    this.reservationData.getRooms()
      .pipe( takeUntil(this.componentDestroyed$) )
      .subscribe( rooms => {
        this.rooms = rooms;

        if ( this.activeRoomIndex === null && this.rooms.length === 1 ) {
          this.activeRoomIndex = 0;
        }
      });
  }

  subscribeToDate(): void {
    this.reservationData.getDate()
      .pipe( takeUntil(this.componentDestroyed$) )
      .subscribe( date => {
        this.stayDate = date;
      });
  }

  subscribeToSummary(): void {
    let date: IReservationDate = this.reservationData.data.date$.value;
    const rooms: IReservationRoom[] = this.reservationData.data.rooms$.value;
    const coupon: string = this.reservationData.data.coupon$.value;
    const pointsUsed: boolean = this.reservationData.data.pointsUsed$.value;

    if ( typeof date.from !== 'undefined' && typeof date.to !== 'undefined' ) {
      if (date.from && date.to) {
        const dateFrom = moment([date.from.year, date.from.month - 1, date.from.day]).format('YYYY-MM-DD');
        const dateTo = moment([date.to.year, date.to.month - 1, date.to.day]).format('YYYY-MM-DD');

        this.reservation.getSummary( dateFrom, dateTo, rooms, coupon, pointsUsed )
          .pipe( takeUntil(this.componentDestroyed$) )
          .subscribe( data => {
              this.summary = data;

              this.shuummeDiscount.value = data.bookingPrice.shuummeDiscount;
              this.advancePercent = data.paymentBookingPrice.advancePercent;
              this.offerId = data.offerId;

              // this.finalSubprice = {
              //   value: Math.ceil(parseFloat(this.summary.bookingPrice.value.toString()) / this.euroExchangeRate),
              //   currency: 'EUR'
              // };

              if ( !data.isAvailable ) {
                this.showErrorMessage( data );
              }
            },
            error => {
              // jesli jest blad w danych uzytkownika to kierujemy do kroku 4
              if (
                typeof error.customer !== 'undefined'
                || typeof error.company !== 'undefined'
                || typeof error.rooms !== 'undefined'
              ) {
                const lang = ConfigService.getCurrentLang();
                const i18nRoute = this.i18nRoutePipe.transform('/:lang/reservation/step-4', lang);

                this.router.navigate([i18nRoute]);
              }
            });
      } else {
        /**
         * Jeśli błąd daty, przekieruj do kroku 1
         */
        const i18nRoute = this.i18nRoutePipe.transform('/:lang/reservation');
        this.router.navigate([i18nRoute]);
      }
    } else {
      const i18nRoute = this.i18nRoutePipe.transform('/:lang/reservation');
      this.router.navigate([i18nRoute]);
    }
  }

  getPaymentLink( e: MouseEvent ): void {
    e.preventDefault();

    if ( !this.processingForPaymentLink ) {
      this.processingForPaymentLink = true;

      const date: IReservationDate = this.reservationData.data.date$.value;
      const rooms: IReservationRoom[] = this.reservationData.data.rooms$.value;
      const coupon: string = this.reservationData.data.coupon$.value;
      const pointsUsed: boolean = this.reservationData.data.pointsUsed$.value;
      
      if (typeof date.from !== 'undefined' && typeof date.to !== 'undefined') {
        if (date.from && date.to) {
          const dateFrom = moment([date.from.year, date.from.month - 1, date.from.day]).format('YYYY-MM-DD');
          const dateTo = moment([date.to.year, date.to.month - 1, date.to.day]).format('YYYY-MM-DD');

          this.reservation.getPaymentLink(dateFrom, dateTo, rooms, coupon, pointsUsed, this.offerId)
            .pipe( takeUntil(this.componentDestroyed$) )
            .subscribe(link => {
                this.processingForPaymentLink = false;

                if (link.paymentUrl) {
                  this.paymentLink = link.paymentUrl;

                  if (this.paymentLink) {
                    this.reservationData.clearStorage();

                    window.location.href = this.paymentLink;
                  }
                }
              },
              error => {
                this.processingForPaymentLink = false;

                // jesli jest blad w danych uzytkownika to kierujemy do kroku 4
                if (
                  typeof error.customer !== 'undefined'
                  || typeof error.company !== 'undefined'
                  || typeof error.rooms !== 'undefined'
                ) {
                  const lang = ConfigService.getCurrentLang();
                  const i18nRoute = this.i18nRoutePipe.transform('/:lang/reservation/step-4', lang);

                  this.router.navigate([i18nRoute]);
                } else {
                  this.subscribeToSummary();
                }
              });
        } else {
          /**
           * Jeśli błąd daty, przekieruj do kroku 1
           */
          const i18nRoute = this.i18nRoutePipe.transform('/:lang/reservation');
          this.router.navigate([i18nRoute]);
        }
      } else {
        const i18nRoute = this.i18nRoutePipe.transform('/:lang/reservation');
        this.router.navigate([i18nRoute]);
      }
    }
  }

  /**
   * Dane z podsumowania są przekazywane do komponentu
   * informacyjnego
   * @param data dane do pokazania
   */
  showErrorMessage( data: IReservationSummary ) {
    let invalidExtras = false;
    let invalidRooms = false;

    data.rooms.forEach(room => {
      if ( room.extras?.invalidExtras?.items && room.extras?.invalidExtras?.items?.length > 0 ) {
        invalidExtras = true;
      }

      if ( room.availableType !== ReservationAvailableTypeEnum.available ) {
        invalidRooms = true;
      }
    });

    let description = 'reservation_summary_error_description_all';
    const buttons = [];

    if ( invalidExtras && !invalidRooms ) {
      description = 'reservation_summary_error_description_extras';
      buttons.push({
        text: 'next_step',
        link: null
      }, {
        text: 'choose_extras',
        link: '/:lang/reservation/step-3'
      });
    }

    if ( !invalidExtras && invalidRooms ) {
      description = 'reservation_summary_error_description_room';
      buttons.push({
        text: 'choose_room',
        link: '/:lang/reservation/step-2'
      });
    }

    if ( invalidExtras && invalidRooms ) {
      buttons.push({
        text: 'change_term',
        link: '/:lang/reservation'
      }, {
        text: 'change_extras',
        link: '/:lang/reservation/step-3'
      });
    }

    const message: IPopupInfoMessage = {
      header: 'reservation_summary_error_header',
      description,
      summary: data,
      buttons
    };

    this.layoutService.setReservationInfoPopup(message);
  }

  setActiveRoomIndex( index: number ): void {
    this.activeRoomIndex = this.activeRoomIndex === index ? null : index;
  }

  copyReservationLink() {
    this.copyMsg = { loading: true };
    clearTimeout(this.timeout);

    this.reservationLinkService.copyLinkToReservation()
      .pipe(
        takeUntil(this.componentDestroyed$),
        finalize(() => {
          if ( isPlatformBrowser(this.platformId) ) {
            this.timeout = setTimeout(() => {
              this.copyMsg = null;
            }, 1000);
          }
        })
      )
      .subscribe(res => {
        this.copyMsg = { success: 'copied_to_clipboard' };
        GlobalFunctions.copyToClipboard(res.url);
      }, _ => {
        this.copyMsg = { error: 'copied_to_clipboard_failed' };
      });
  }
}
