<app-banner
  header="north_restaurant"
  description="north_restaurant_description"
  background="/assets/images/food_and_wine/north_restaurant/banner.jpg"
  background2x="/assets/images/food_and_wine/north_restaurant/banner@2x.jpg"
>
  <app-line-btn
    class="h4"
    scrollTo="north-restaurant"
    [noOffset]="true"
    bottomButton
  >
    <span>{{ 'know_more'|translate }}</span>
  </app-line-btn>
</app-banner>

<div id="north-restaurant" class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-text-simple-centered
      header="{{ 'north_restaurant_h_01'|translate}}"
    >
      <p class="p-responsive" [innerHTML]="'north_restaurant_c_01'|translate|newLineBreak|removeWidows"></p>
    </app-text-simple-centered>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-center">
      <div class="col-md-5 col-xl-4 col-xxl-5 offset-xl-1 col-10 offset-1 offset-md-0">
        <app-content-images-overlapping-right
          imageFront="/assets/images/food_and_wine/north_restaurant/section-1-right.jpg"
          imageFront2x="/assets/images/food_and_wine/north_restaurant/section-1-right@2x.jpg"
          imageFrontAlt="Double Image Left"
          imageFrontMaxWidth="239"
          imageBack="/assets/images/food_and_wine/north_restaurant/section-1-left.jpg"
          imageBack2x="/assets/images/food_and_wine/north_restaurant/section-1-left@2x.jpg"
          imageBackAlt="Double Image Right"
          reversed="true"
          imageBackMaxWidth="346"
        ></app-content-images-overlapping-right>
      </div>

      <div class="col-md-6 col-xl-5 col-xxl-4 offset-md-1 pt-giga pt-md-zero">
        <app-indent-content header="north_restaurant_h_02">
          <p class="small" [innerHTML]="'north_restaurant_c_02'|translate|newLineBreak|removeWidows"></p>
        </app-indent-content>
      </div>
    </div>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row flex-column-reverse flex-md-row align-md-items-center">
      <div class="col-md-6 col-xl-5 col-xxl-4 offset-xl-1 pt-giga pt-md-zero">
        <app-indent-content header="north_restaurant_h_03">
          <p class="small" [innerHTML]="'north_restaurant_c_03'|translate|newLineBreak|removeWidows"></p>
        </app-indent-content>
      </div>

      <div class="col-10 col-md-6 col-xl-4 col-xxl-5 offset-1 offset-md-0 offset-xl-1">
        <app-content-images-overlapping-right
          imageFront="/assets/images/food_and_wine/north_restaurant/section-2-left.jpg"
          imageFront2x="/assets/images/food_and_wine/north_restaurant/section-2-left@2x.jpg"
          imageFrontAlt="Double Image Left"
          imageBack="/assets/images/food_and_wine/north_restaurant/section-2-right.jpg"
          imageBack2x="/assets/images/food_and_wine/north_restaurant/section-2-right@2x.jpg"
          imageBackAlt="Double Image Right"
          imageBackMaxWidth="318"
        ></app-content-images-overlapping-right>
      </div>
    </div>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row justify-content-center">
      <div class="col-12 col-xl-10 text-center pb-deci">
        <img
          class="max-100"
          src="/assets/images/food_and_wine/north_restaurant/logos.png"
          alt="Partners images"
        />
      </div>
      <div class="col-12 col-xl-10 text-center">
        <img
          class="max-100"
          src="/assets/images/food_and_wine/north_restaurant/logos_02.png"
          alt="Partners images"
        />
      </div>
    </div>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-center">
      <div class="col-md-5 col-xl-4 col-xxl-5 offset-xl-1 col-10 offset-1 offset-md-0">
        <img
          [defaultImage]="'/assets/images/placeholders/placeholder481x561.png'"
          [lazyLoad]="
            hasSrcSetSupport
              ? '/assets/images/food_and_wine/north_restaurant/section-3.jpg 1x, /assets/images/food_and_wine/north_restaurant/section-3@2x.jpg 2x'
              : '/assets/images/food_and_wine/north_restaurant/section-3.jpg'
          "
          [useSrcset]="hasSrcSetSupport"
          alt="Image Single Left"
          class="max-100"
        />
      </div>

      <div class="col-md-6 col-xl-5 col-xxl-4 offset-md-1 pt-giga pt-md-zero">
        <app-indent-content header="north_restaurant_h_04">
          <p class="small" [innerHTML]="'north_restaurant_c_04'|translate|newLineBreak|removeWidows"></p>
        </app-indent-content>
      </div>
    </div>
  </div>
</div>

<app-after-before-grey
  width="50%"
  height="800px"
  top="-10px"
  left="0"
  additionalClasses="to-top-left"
>
  <div class="container mobile-padding">
    <div class="pt-peta pt-md-zetta pt-lg-200">
      <div class="row">
        <div class="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-8 offset-md-1 offset-lg-2">
          <app-indent-content
            [header]="'opening_hours'"
            headerClass="light"
            autoWidth
          >
            <div class="opening-hours pb-deca pb-md-mega">
              <h4 class="h2">
                7:30<span *ngIf="showAmPm" class="h3 smaller">a.m</span><span> - </span>11:00<span *ngIf="showAmPm" class="h3 smaller">a.m</span>
                <span>&nbsp;</span><span class="h3 smaller">-&nbsp;{{ 'breakfast'|translate }}</span>
              </h4>
            </div>
            <div class="opening-hours pb-deca pb-md-mega">
              <h4 class="h2">
                <ng-container *ngIf="!showAmPm else elseHours1">13:00</ng-container>
                <ng-template #elseHours1>1:00</ng-template>
                <span *ngIf="showAmPm" class="h3 smaller">p.m</span><span> - </span>
                <ng-container *ngIf="!showAmPm else elseHours50">17:00</ng-container>
                <ng-template #elseHours50>5:00</ng-template>
                <span *ngIf="showAmPm" class="h3 smaller">p.m</span>
                <span>&nbsp;</span><span class="h3 smaller">-&nbsp;{{ 'lunch_bistro'|translate }}</span>
              </h4>
            </div>
            <div class="opening-hours pb-deca pb-md-hecto">
              <h4 class="h2">
                <ng-container *ngIf="!showAmPm else elseHours5">17:30</ng-container>
                <ng-template #elseHours5>5:30</ng-template>
                <span *ngIf="showAmPm" class="h3 smaller">p.m</span><span> - </span>
                <ng-container *ngIf="!showAmPm else elseHours8">20:00</ng-container>
                <ng-template #elseHours8>8:00</ng-template>
                <span *ngIf="showAmPm" class="h3 smaller">p.m</span>
                <span>&nbsp;</span><span class="h3 smaller">-&nbsp;{{ 'dinner'|translate }}</span>
              </h4>
            </div>
            <p>{{ 'every_day'|translate }}</p>
          </app-indent-content>
        </div>
      </div>
    </div>
  </div>
</app-after-before-grey>

<div class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 offset-xl-1">
        <div class="out-mobile-padding">
          <app-images-carousel
            [images]="carouselImages"
          >
          </app-images-carousel>
        </div>
      </div>
      <div class="col-12 col-lg-5 col-xl-4 offset-lg-1">
        <div class="pt-kilo pt-md-giga pt-lg-zero">
          <app-indent-content
            [header]="'our_team'"
          >
            <p
              class="small"
              [innerHTML]="'food_and_wine_our_team'|translate|newLineBreak|removeWidows"
            >
            </p>

            <div class="actions pt-mega">
              <a [routerLink]="'/:lang/career'|i18nRoute" class="btn btn-bordered">{{ 'join_us'|translate }}</a>
            </div>
          </app-indent-content>
        </div>
      </div>
    </div>
  </div>
</div>

<app-after-before-grey
  width="22%"
  height="367px"
  top="46%"
  right="0"
  additionalClasses="to-top-right thick-no-responsive"
>
  <div class="container mobile-padding">
    <div class="pt-peta pt-md-zetta pt-lg-200">
      <div class="offset-lg-1 offset-xl-2 text-light-gray">
        <h3 [innerHtml]="'certifications' | translate | removeWidows"></h3>
      </div>
      <div class="pt-mega pt-md-peta">
        <app-item-slider [itemTpl]="itemTpl" [items]="foodCertificates">
        </app-item-slider>

        <ng-template let-item #itemTpl>
          <app-certificate-part [data]="item"></app-certificate-part>
        </ng-template>
      </div>
    </div>
  </div>
</app-after-before-grey>

<div class="container mobile-padding">
  <div class="pt-peta pt-md-zetta pt-lg-200">
    <div class="row align-items-end">
      <div class="col-12 col-md-6 col-lg-5 col-xl-4 offset-lg-1 offset-xl-2 order-1 order-md-0">
        <p
          class="pt-giga pt-lg-zero small"
          [innerHTML]="'certifications_description'|translate|newLineBreak|removeWidows"
        ></p>
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-3 offset-lg-1 order-0 order-md-1">
        <app-text-centered-image
          header="{{ 'award_h_04'|translate}}"
          image="/assets/images/wellness_page/certificate.png"
          image2x="/assets/images/wellness_page/certificate@2x.png"
          imgMaxHeight="118px"
          imageAlt="certificate logo"
        ></app-text-centered-image>
      </div>
    </div>
  </div>

  <div class="pt-peta pt-md-zetta pt-lg-200">
    <app-gallery
      [header]="'food_and_wine'"
      [description]="'food_and_wine_gallery_description'"
      [images]="galleryImages"
    ></app-gallery>
  </div>

<!--  <div class="pt-peta pt-md-zetta pt-lg-200">-->
<!--    <app-discover-->
<!--      [header]="'discover_also'"-->
<!--      [name]="'Intense Repair\nShea Butter'"-->
<!--      [description]="'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit in voluptate velit esse.'"-->
<!--      [price]="{ value: 49.99, currency: 'PLN' }"-->
<!--      [link]="''"-->
<!--    ></app-discover>-->
<!--  </div>-->
</div>

<app-footer></app-footer>
