import {Component, Input, OnInit} from '@angular/core';
import CollageImageEnum from '../../_enums/CollageImageEnum';
import IImageCollage from '@interfaces/IImageCollage';

@Component({
  selector: 'app-indent-content-images',
  templateUrl: './indent-content-images.component.html',
  styleUrls: ['./indent-content-images.component.scss']
})
export class IndentContentImagesComponent implements OnInit {

  leftCol: string;
  rightCol: string;

  @Input() header: string;
  @Input() collageType: CollageImageEnum;
  @Input() topHeader?: string;
  @Input() images?: IImageCollage;
  @Input() removeTopSpacing = false;
  @Input() indentMoved = false;

  constructor() { }

  ngOnInit() {
    if ( this.collageType === CollageImageEnum.LargeTopSmall4ColBottomRight ) {
      this.leftCol = 'col-12 col-md-6 col-lg-5 offset-lg-1';
      this.rightCol = 'col-12 col-md-6 col-lg-5 col-xxl-5 offset-lg-1';
    }
    else {
      this.leftCol = 'col-12 col-md-6 col-lg-4 offset-lg-1';
      this.rightCol = 'col-12 col-md-6 col-lg-6 col-xxl-5';
    }
  }

}
