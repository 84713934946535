<div class="error-container pt-lg-tera">
  <app-step-header
    header="reservation_error_header"
    description="reservation_error_description"
  ></app-step-header>
  <div class="pb-mega pb-md-giga" *ngIf="isLoggedIn">
    <a [routerLink]="'/:lang/my-reservation/reservation-panel-group'|i18nRoute" class="btn btn-arrow">{{ 'go_to_reservation'|translate }}</a>
  </div>
  <div class="pb-mega pb-md-giga" *ngIf="!isLoggedIn">
    <a [routerLink]="'/:lang'|i18nRoute" class="btn btn-arrow">{{ 'go_to_home'|translate }}</a>
  </div>
</div>
