import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '@services/main.service';
import IFilter from '@interfaces/IFilter';
import IOrder from '@interfaces/IOrder';
import {Observable} from 'rxjs';
import {IMenuElement} from '@interfaces/IMenuElement';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends MainService {
  listUrl = '/restaurants/menu';

  constructor( protected http: HttpClient ) {
    super( http );
  }

  public getList( filters?: IFilter[], orders?: IOrder[], limit?: number, page?: number ): Observable<IMenuElement[]> {
    return super.getList( filters, orders, limit, page );
  }
}
