import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class ReservationRoomsService {
  roomToShow$: Subject<string> = new Subject<string>();

  constructor() {}

  setRoomToShow( slug: string ): void {
    this.roomToShow$.next( slug );
  }

  getRoomToShow(): Subject<string> {
    return this.roomToShow$;
  }
}
