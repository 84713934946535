import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import GlobalFunctions from '@helpers/GlobalFunctions';
import { environment } from 'src/environments/environment';
import { UserService } from '@services/auth/user.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private readonly apiUrl: string;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
    this.apiUrl = environment.shuumApiHostShort;
  }

  addCompany( data: any ): Observable<any> {
    const fd: FormData = GlobalFunctions.convertToFd(new FormData(), data);

    return this.http.post<any>(`${this.apiUrl}/company`, fd, {})
      .pipe(
        map(company => {
          const user = this.userService.userValue;
          user.company = data;
          user.company.id = company?.id;
          user.company.name = data?.companyName || data?.name;
          this.userService.setUserData(user);

          return company;
        })
      );
  }

  deleteCompany( companyId: string ): Observable<null> {
    return this.http.delete<null>(`${this.apiUrl}/company/${companyId}`, {})
      .pipe(
        map(data => {
          const user = this.userService.userValue;
          delete user.company;
          this.userService.setUserData(user);

          return data;
        })
      );
  }

}
