<app-section-introduction [text]="'facilities_introduction'"></app-section-introduction>
<div class="container mobile-padding">
  <div class="row">
    <div class="col-xl-10 offset-xl-1">
      <app-section-header header="pillow_menu" subHeader="pillow_menu_desc"></app-section-header>
      <div class="pt-kilo pb-kilo">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-5 offset-lg-1 order-md-2 text-center">
            <img
              class="img-fluid"
              [defaultImage]="'/assets/images/placeholders/placeholder377x248.png'"
              [lazyLoad]="
              hasSrcSetSupport
              ? '/assets/images/reservations/loyalty-program/facilities/pillow.jpg 1x, /assets/images/reservations/loyalty-program/facilities/pillow@2x.jpg 2x'
              : '/assets/images/reservations/loyalty-program/facilities/pillow.jpg'
            "
              [useSrcset]="hasSrcSetSupport"
              [alt]="'restaurant_a_la_carte_c_03'|translate"
            />
          </div>
          <div class="col-12 col-md-6 order-md-1">
            <p class="small-light pt-kilo pt-md-0" [innerHTML]="'pillow_menu_text' | translate | removeWidows"></p>
            <div class="actions pt-kilo">
              <app-loader [size]="30" [opacity]="'0.7'" [center]="false" *ngIf="pillowMenuFile === null"></app-loader>

              <ng-container *ngIf="pillowMenuFile?.value">
                <a
                  [href]="pillowMenuFile?.value|apiUrl"
                  target="_blank"
                  class="btn btn-bordered"
                >{{ 'download_pdf' | translate }}</a>
              </ng-container>
              <ng-container *ngIf="pillowMenuFile !== null && !pillowMenuFile?.value">
                <span class="small font-weight-light">{{ 'menu_is_currently_unavailable'|translate }}</span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-light-gray3 pt-giga facilities">
  <div class="container mobile-padding">
    <div class="row">
      <div class="col-xl-10 offset-xl-1">
        <app-section-header header="facilities_title" subHeader="facilities_desc"></app-section-header>
        <div class="pb-giga">
          <div class="row">
            <ng-container *ngFor="let facility of facilities">
              <div class="col-lg-6 pt-kilo">
                <app-text-offer
                  [image]="facility?.image?.image"
                  [imageAlt]="facility?.image?.alt|translate"
                >
                  <div>
                    <h3
                      [innerHTML]="facility?.header|translate|removeWidows"
                    ></h3>
                    <p
                      class="pt-deci tiny"
                      [innerHTML]="facility?.description|translate|removeWidows"
                    ></p>
                  </div>
                  <div class="pt-hecto">
                    <a
                      (click)="setElementInfo($event, '', facility?.id)"
                      class="btn btn-bordered on-white"
                    >
                      {{ 'ask_for_availability'|translate }}
                    </a>
                  </div>
                </app-text-offer>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-contact-form [bookingType]="bookingType"></app-contact-form>
