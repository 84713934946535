import {Component, Input, OnInit, ViewChild, ElementRef, HostListener, OnDestroy} from '@angular/core';
import { IVideo } from '@interfaces/IVideo';

@Component({
  selector: 'app-movie-text-hover',
  templateUrl: './movie-text-hover.component.html',
  styleUrls: ['./movie-text-hover.component.scss']
})
export class MovieTextHoverComponent implements OnInit, OnDestroy {
  @Input() header: string;
  @Input() video: IVideo;
  @ViewChild('videoEl') videoEl: ElementRef;

  @HostListener('mouseenter')
  onMouseEnter() {
    if ( this.videoEl ) {
      const promise = this.videoEl.nativeElement.play();
      promise.catch(_=> { });
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if ( this.videoEl ) {
      try {
        this.videoEl.nativeElement.pause();
      }
      catch (e) { }
    }
  }

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if ( this.videoEl ) {
      try {
        this.videoEl.nativeElement.pause();
      }
      catch (e) { }
    }
  }

}
