import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import ICategory from '@interfaces/ICategory';
import {MainService} from './main.service';
import IFilter from '@interfaces/IFilter';
import IOrder from '@interfaces/IOrder';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends MainService {
  listUrl = '/categories';
  detailsUrl = '';

  constructor( protected http: HttpClient ) {
    super( http );
  }

  getList(filters?: IFilter[], orders?: IOrder[], limit?: number, page?: number): Observable<ICategory[]> {
    this.listUrl = '/categories';

    if ( !orders || orders.length === 0 ) {
      orders = this.setDefaultOrder();
    }

    return super.getList(filters, orders, limit, page);
  }

  getTreatmentList(filters?: IFilter[], orders?: IOrder[], limit?: number, page?: number): Observable<ICategory[]> {
    this.listUrl = '/categories/treatments';

    if ( !orders || orders.length === 0 ) {
      orders = this.setDefaultOrder();
    }

    return super.getList(filters, orders, limit, page);
  }

  getOfferList(filters?: IFilter[], orders?: IOrder[], limit?: number, page?: number): Observable<ICategory[]> {
    this.listUrl = '/categories/offers';

    if ( !orders || orders.length === 0 ) {
      orders = this.setDefaultOrder();
    }

    return super.getList(filters, orders, limit, page);
  }

  getMedicalWellnesList(filters?: IFilter[], orders?: IOrder[], limit?: number, page?: number) {
    this.listUrl = '/categories/medical_wellness';

    if ( !orders || orders.length === 0 ) {
      orders = this.setDefaultOrder();
    }

    return super.getList(filters, orders, limit, page);
  }

  private setDefaultOrder(): IOrder[] {
    const orders: IOrder[] = [];

    orders.push({
      field: 'name',
      type: 'ASC'
    });

    return orders;
  }
}
