import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import ConfigService from '../_config/ConfigService';
import {catchError, first} from 'rxjs/internal/operators';
import {UserService} from '@services/auth/user.service';
import { LayoutService } from '@services/layout.service';
import {tap} from 'rxjs/operators';

@Injectable()
export class RequestsInterceptor implements HttpInterceptor {
  private request: HttpRequest<any> = null;
  private next: HttpHandler = null;

  constructor(
    private userService: UserService,
    private layoutService: LayoutService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.request = req;
    this.next = next;

    const modifiedReq = req.clone({
      headers: req.headers.set('Content-Language', ConfigService.getCurrentLang()),
    });

    return next.handle(modifiedReq).pipe(
      tap(this.handleAll),
      catchError(this.handleError)
    );
  }

  private handleError = (error: HttpErrorResponse) => {
    if (error.status === 401) {
      // auto logout if 401 response returned from api
      if ( this.userService.isLoggedIn$.value === true ) {
        this.userService.logout();
      }
    }

    else if ( error.status === 400 ) {
      return throwError(error.error.form_errors || error.error.message || error.error || 'server error');
    }

    else if ( error.status === 403 ) {
      if ( typeof error.error.refreshToken !== 'undefined' ) {
        if ( typeof error.error.refreshToken.token !== 'undefined' ) {
          this.userService.setUserData(error.error.refreshToken);

          const newReq = this.request.clone();
          newReq.headers.set('Authorization', `Bearer ${error.error.refreshToken.token}`);

          return this.next.handle(newReq);
        }
      }
    }

    else if ( error.status === 500 ) {
      this.layoutService.setError500Popup(true);
    }

    return throwError(error.error.message || error.statusText || error.message || 'server error');
  }

  private handleAll = (evt: HttpResponse<any>) => {
    if ( typeof evt.body !== 'undefined' ) {
      const body = evt.body;

      if ( body ) {
        if (typeof body.refreshToken !== 'undefined') {
          if (typeof body.refreshToken.token !== 'undefined') {
            this.userService.setUserData(body.refreshToken);
          }
        }
      }
    }
  }
}
