<div class="container mobile-padding">
  <div class="row">
    <div class="col-xl-10 offset-xl-1">
      <div class="pt-peta pb-giga">
        <a [routerLink]="'/:lang/my-reservation/reservation-panel-group'|i18nRoute">
          <img src="/assets/images/nav_arrow_dark.svg"/>
        </a>
        <h3 class="text-light-gray-color pt-giga">{{ 'reservation_details'|translate }}</h3>
      </div>

      <div class="d-lg-flex pb-mega pb-md-giga">
        <div class="flex-shrink-0 pr-giga">
          <app-reservation-term
            header="stay_term"
            [date]="stayDate"
          ></app-reservation-term>
        </div>
        <div class="pt-kilo pt-lg-zero flex-shrink-zero d-flex align-items-end pr-giga">
          <app-reservation-term
            header="payment_status"
            [paid]="payment?.isPaid"
          ></app-reservation-term>
          <a *ngIf="payment?.paymentUrl && !payment?.isPaymentExpired" class="btn btn-bordered finish-payment-btn ml-4" [href]="payment.paymentUrl" target="_blank">
            {{ 'make_payment'|translate }}
          </a>
        </div>
        <div class="pt-kilo pt-lg-zero flex-shrink-zero d-flex align-items-end">
          <app-reservation-term
            header="shuumME_points"
            [shuumMEPoints]="shuumMEPoints"
          ></app-reservation-term>
        </div>
      </div>
    </div>

    <div class="col-lg-8 col-xl-7 offset-xl-1">
      <div *ngIf="payment?.isPaymentExpired" class="pb-hecto">
        <p class="text-light-gray" [innerHTML]="'payment_expired'|translate"></p>
      </div>
    </div>

    <div class="col-xl-10 offset-xl-1">
      <div class="rooms pb-xl-hecto">
        <ng-container *ngFor="let room of rooms; index as i">
          <app-room-tab-payment
            [room]="room"
            [roomSummary]="room"
            [index]="i"
            [isActive]="i === activeRoomIndex"
            (tabClickHandler)="setActiveRoomIndex($event)"
          ></app-room-tab-payment>
        </ng-container>
      </div>
    </div>
  </div>
</div>
