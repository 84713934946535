import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {I18nModule} from '../i18n/i18n.module';

import {SharedPipesModule} from './shared-pipes.module';
import { ApplaComponent } from '@shared/appla/appla.component';
import { IndentContentModule } from './shared-indent-content.module';
import { SharedModule } from '@shared/shared.module';
import { CookieBarComponent } from '@shared/cookie-bar/cookie-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    ApplaComponent,
    CookieBarComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    IndentContentModule,
    I18nModule,
    SharedPipesModule,
    TranslateModule,
    RouterModule
  ],
  providers: [

  ],
  bootstrap: [],
  exports: [
    ApplaComponent,
    CookieBarComponent
  ]
})
export class SharedStaticPopupsModule { }
