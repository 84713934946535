import {
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  OnDestroy,
  PLATFORM_ID
} from '@angular/core';
import {environment} from '../../../environments/environment';
import ConfigService from '../../_config/ConfigService';
import * as remove from 'lodash/remove';
import {activeAnimation, listAnimation, fadeAnimation} from '@helpers/animations';
import INavigation from '../../_interfaces/INavigation';
import NavigationEnum from '../../_enums/NavigationEnum';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {debounceEvent} from 'src/app/_decorators/DebounceEvent';
import {CurrentRouteHelper} from '@helpers/CurrentRouteHelper';
import {IRouteTranslations} from '@interfaces/IRouteTranslations';
import {GlobalStateHelper} from '@helpers/GlobalStateHelper';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {LayoutService} from '@services/layout.service';
import {UserService} from '@services/auth/user.service';
import {User} from 'src/app/_models/auth/User';
import {Router} from '@angular/router';
import NavigationTypeEnum from '@enums/NavigationTypeEnum';
import {I18nRoutePipe} from '../../_pipes/i18n-route.pipe';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    activeAnimation,
    listAnimation,
    fadeAnimation
  ]
})

export class NavigationComponent extends SubscribeHelper implements OnInit {
  @ViewChild('navbar', {static: true}) navbar: ElementRef;

  public languages: string[];
  public currentLang: string;
  public isSubNavOpen = false;
  public isSubNavCollapsed = true;
  public isSubNavActive = false;
  public openedSubNavNo: number = null;
  public prevOpenedSubNavNo: number = null;
  public subNavigation: INavigation[] = [];
  public navigation: INavigation[] = [];
  public navigationEnum = NavigationEnum;
  public isMobileMenu = false;
  public currentRoute: IRouteTranslations;
  public isNavShort = false;
  public user: User;
  public navigationTypeEnum: typeof NavigationTypeEnum;
  public loginVisible = true;

  private resize$ = new Subject<number>();
  // private scrollTop$ = new Subject<number>();

  private lastScrollPosition: number = null;
  private scrollTopPosition = 0;

  private scrollTimeout: any = null;
  private isScrolled = false;
  private firstScroll = false;
  private activationOffset = 10;

  /**
   * Chowa menu, kiedy jest ustawione na true i nie pozwala się pojawić,
   * Wykorzystywane w galerii oraz w mapie interaktywnej w homepage
   */
  private isSubNavLocked = false;

  /**
   * Nie pozwala na uruchomienie jakiejkolwiek animacji na navbarze,
   * wykorzystywane w app-popup-content
   */
  private isMenuAnimationLocked = false;

  langHidden = false;

  isUserPanelRoute: boolean;

  constructor(
    @Inject(DOCUMENT) private document,
    private currentRouteHelper: CurrentRouteHelper,
    private globalStateService: GlobalStateHelper,
    private renderer: Renderer2,
    private layoutService: LayoutService,
    private userService: UserService,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    private i18nRoute: I18nRoutePipe
  ) {
    super();
    if (typeof window !== 'undefined') {
      this.isMobileMenu = window.innerWidth <= environment.showMobileMenuMaxWidth;
    }
  }

  ngOnInit() {
    this.resize$.pipe(
      debounceTime(300)
    )
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        innerWidth => {
          this.isMobileMenu = window.innerWidth <= environment.showMobileMenuMaxWidth;

          if (!this.isMobileMenu) {
            if (this.isSubNavOpen && this.openedSubNavNo === NavigationEnum.Main) {
              this.hideSubNav();
            }
          }
        }
      );

    this.subscribeToShortNav();

    const languages = Object.assign([], environment.languages);
    this.currentLang = ConfigService.getCurrentLang();
    this.navigationTypeEnum = NavigationTypeEnum;

    remove(languages, v => {
      return v === this.currentLang;
    });

    this.languages = languages;

    this.navigation = [
      {
        name: 'explore_hotel',
        isActive: false,
        children: [
          {route: '/:lang/our-philosophy', name: 'our_philosophy'},
          {route: '/:lang/gallery', name: 'gallery'},
          {route: '/:lang/room', name: 'rooms'},
          {route: '/:lang/events', name: 'offer_events'}
        ],
        type: NavigationTypeEnum.Basic
      },
      {
        name: 'offer',
        isActive: false,
        children: [
          {route: '/:lang/offer', name: 'offer_stay'},
          {route: '/:lang/wellness/relax', name: 'offer_treatment'},
          {route: '/:lang/food/a-la-carte', name: 'offer_restaurant', offset: true},
          {route: '/:lang/remote-work-group', name: 'work_life_balance'},
          {route: '/:lang/gift-cards-group', name: 'gift_card_single'},
          // { route: '#', name: 'Karta podarunkowa' },
          // { route: '#', name: 'Galeria' }
        ],
        type: NavigationTypeEnum.Basic
      },
      {
        name: 'wellness_and_spa',
        isActive: false,
        children: [
          {route: '/:lang/wellness/relax', name: 'relax_and_beauty'},
          {route: '/:lang/wellness/medical-wellness', name: 'medical_wellness'},
          {route: '/:lang/wellness/yoga', name: 'yoga_and_fitness'},
          {route: '/:lang/wellness/saunas', name: 'saunas_and_pool'},
          {route: '/:lang/wellness/etiquette', name: 'wellness_etiquette'},
          // { route: '/:lang/gallery', name: 'gallery', fragment: 'wellness-and-spa' }
          // { route: '/:lang/wellness/saunas', name: 'Galeria' }
        ],
        type: NavigationTypeEnum.Basic
      },
      {
        name: 'food_and_wine',
        isActive: false,
        children: [
          {route: '/:lang/food/north-restaurant-child', name: 'north_restaurant'},
          {route: '/:lang/food/a-la-carte', name: 'restaurant_a_la_carte_no_breaks'},
          {route: '/:lang/food/lobby-bar-child', name: 'lobby_bar'},
          // { route: '/:lang/food/wine-evenings-child', name: 'wine_evenings' },
          {route: '/:lang/food/delicatessen-group', name: 'delicatessen_single'},
          // { route: '/:lang/gallery', name: 'gallery', fragment: 'food-and-wine' }
        ],
        type: NavigationTypeEnum.Basic
      },
      {
        name: 'blogostan_nav_title',
        route: '/:lang/o-blogostanie',
        languages: ['pl'],
        isActive: false,
        type: NavigationTypeEnum.Basic
      },
      {
        name: 'reservation_main_page',
        route: '/:lang/reservation',
        isActive: false,
        type: NavigationTypeEnum.Basic
      },
      // {
      //   name: 'shop',
      //   route: '/:lang',
      //   isActive: false,
      // },
      {
        name: 'contact',
        route: '/:lang/contact',
        isActive: false,
        type: NavigationTypeEnum.Basic
      },
      {
        name: 'create_account',
        route: '/:lang/my-reservation/authorization-group',
        isLoggedIn: false,
        type: NavigationTypeEnum.LoggedIn
      },
      {
        name: 'log_in',
        route: '/:lang/my-reservation/authorization-group/sign-in-group',
        isLoggedIn: false,
        type: NavigationTypeEnum.LoggedIn
      },
      {
        name: 'your_profile',
        route: '/:lang/my-reservation',
        isLoggedIn: true,
        type: NavigationTypeEnum.LoggedIn
      },
      {
        name: 'log_out',
        route: '/:lang/my-reservation/authorization-group/logout-group',
        isLoggedIn: true,
        onClick: () => { this.logout(); },
        type: NavigationTypeEnum.LoggedIn
      }
    ];

    this.lastScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    this.listenForLangChange();

    this.globalStateService.getDisableMenuAppearChange().subscribe(val => {
      this.isSubNavLocked = val;

      if (val && !this.isMenuAnimationLocked) {
        this.isSubNavCollapsed = false;
      }
    });

    this.globalStateService.lockMenuChange.subscribe(value => {
      this.isMenuAnimationLocked = value;
      const offset = window.pageYOffset || document.documentElement.scrollTop;
      if (offset === 0) {
        this.isMenuAnimationLocked = false;
        this.onScroll();
      }
    });

    if (this.lastScrollPosition > 10 && !this.isMenuAnimationLocked) {
      this.isSubNavActive = true;
    }

    this.layoutService.getLangHidden().subscribe(val => {
      this.langHidden = val;
    });

    this.globalStateService.getLoginVisible().subscribe(val => {
      this.loginVisible = val;
    });

    this.setUserData();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  changeRoute(route: string): void {
    const lang = route.split('/')[1];
    this.updateLang(lang);

    window.location.replace(route);
  }

  updateLang(lang: string): void {
    if ( typeof sessionStorage !== 'undefined' ) {
      const cachedLang = sessionStorage.getItem('lang');

      if ( cachedLang ) {
        if ( cachedLang !== lang ) {
          sessionStorage.setItem('langChanged', '1');
        }
      }

      sessionStorage.setItem('lang', lang);
    }
  }

  setUserData() {
    this.user = this.userService.userValue;

    this.userService.user.subscribe(user => {
      this.user = user;
    });
  }

  listenForLangChange() {
    this.currentRouteHelper.listen();
    this.currentRouteHelper.getCurrentRoute().subscribe(val => {
      this.currentRoute = val;
      this.isUserPanelRoute = this.currentRoute.current.startsWith('/my-reservation');
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (typeof window !== 'undefined') {
      this.resize$.next(window.innerWidth);
    }
  }

  @HostListener('window:scroll')
  onScroll() {
    if (!this.isScrolled && !this.isMenuAnimationLocked) {
      this.isScrolled = true;
      clearTimeout(this.scrollTimeout);

      this.scrollTimeout = setTimeout(() => {
        this.isScrolled = false;

        const st = window.pageYOffset || document.documentElement.scrollTop;

        if (!this.isSubNavOpen) {
          if (!this.lastScrollPosition) {
            this.lastScrollPosition = st;
          }

          if (!this.firstScroll && st > this.activationOffset) {
            this.isSubNavActive = true;
          }

          if (st - 2 > this.lastScrollPosition) {
            this.isSubNavCollapsed = false;
          } else if (st < this.lastScrollPosition) {
            if (st < this.activationOffset) {
              this.isSubNavActive = false;
            } if (st >= this.activationOffset && !this.isSubNavLocked) {
              this.isSubNavActive = true;
            }

            if (!this.isSubNavLocked) {
              this.isSubNavCollapsed = true;
            }
          }
        }

        this.lastScrollPosition = st <= 0 ? -1 : st;

        if (this.firstScroll === false) {
          this.firstScroll = true;
        }
      }, !this.firstScroll ? 10 : 300);
    }
  }

  @HostListener('window:mouseover', ['$event'])
  @debounceEvent(100)
  onMouseover(event) {
    if (this.navbar) {
      if (event.clientY <= this.navbar.nativeElement.clientHeight && !this.isSubNavLocked && !this.isMenuAnimationLocked) {
        this.isSubNavCollapsed = true;

        const st = window.pageYOffset || document.documentElement.scrollTop;
        if (st >= 10) {
          this.isSubNavActive = true;
        }
      }
    }
  }

  public getSubNavigation(id: number): INavigation[] {
    if (this.navigation) {
      if (typeof this.navigation[id] !== 'undefined') {
        if (typeof this.navigation[id].children !== 'undefined') {
          return this.navigation[id].children;
        }
      }
    }

    return [];
  }

  toggleSubNav(e, id: number) {
    if (this.isSubNavOpen) {
      this.hideSubNav(e);
    } else {
      this.showSubNav(e, id);
    }
  }

  showSubNav(e, id: number) {
    const banner = this.document.querySelector('.banner');

    e.preventDefault();
    if (this.isSubNavOpen && this.openedSubNavNo === id) {
      this.hideSubNav(e);

      if (banner) {
        banner.classList.remove('text-hidden');
      }
    } else {
      if (!this.isSubNavOpen) {
        this.disableScroll();
      }

      this.isSubNavOpen = true;
      if (banner) {
        banner.classList.add('text-hidden');
      }

      if (this.openedSubNavNo !== id) {
        this.prevOpenedSubNavNo = this.openedSubNavNo;
        this.openedSubNavNo = id;
        this.subNavigation = [];

        setTimeout(() => {
          if (id === NavigationEnum.Main) {
            this.subNavigation = this.navigation;
          } else {
            this.subNavigation = this.getSubNavigation(id);
          }
        }, 600);
      }
    }
  }

  hideSubNav(e = null, absolute = false, offset = null) {
    this.renderer.removeClass(this.document.body, 'noscroll');
    const banner = this.document.querySelector('.banner');

    if (e) {
      e.preventDefault();
    }

    if (banner) {
      banner.classList.remove('text-hidden');
    }

    this.isSubNavOpen = false;
    this.enableScroll(absolute);
    this.openedSubNavNo = null;
    this.subNavigation = [];


    if (window) {
      if (offset) {
        setTimeout(() => {
          window.scrollTo({
            top: innerHeight
          });
        }, 800);
      }
    }

  }

  showDefaultMenu(e) {
    if (this.isMobileMenu) {
      this.showSubNav(e, NavigationEnum.Main);
    } else {
      this.hideSubNav(e);
    }
  }

  disableScroll() {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollTopPosition = window.pageYOffset || document.documentElement.scrollTop;
      this.renderer.setStyle(this.document.body, 'top', -(this.scrollTopPosition) + 'px');
      this.renderer.addClass(this.document.body, 'noscroll');
    }
  }

  enableScroll(absolute = false) {
    if (isPlatformBrowser(this.platformId)) {
      this.renderer.removeClass(this.document.body, 'noscroll');

      if (absolute) {

      } else {
        this.renderer.setStyle(this.document.body, 'top', null);
        window.scrollTo(0, this.scrollTopPosition);
      }
    }
  }

  subscribeToShortNav(): void {
    this.layoutService.getShortNav().subscribe(value => {
      this.isNavShort = value;
    });
  }

  redirectWithClosedMenu($e: MouseEvent, link: string, returnUrl?: string): void {
    $e.preventDefault();
    this.hideSubNav();

    if (returnUrl) {
      this.router.navigate([link], {queryParams: {returnUrl}});
    } else {
      this.router.navigate([link]);
    }
  }

  logout(): void {
    this.userService.logout(false);
    this.globalStateService.unlockLogoutPopup = true;
    this.hideSubNav();
  }
}
