import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {environment} from '../../../environments/environment';
import {LangGuard} from '../../_guards/LangGuard';
import {CareerHomeComponent} from './_pages/career-home/career-home.component';
import {CareerDetailsComponent} from './_pages/career-details/career-details.component';
import {CareerComponent} from '@pages/career/career.component';

const defaultRoute = '/' + environment.defaultLanguage;

/**
 * !!! DONT FORGET TO UPDATE RouteMapHelper
 */
const routes: Routes = [
  { path: '', component: CareerComponent, data: {
      meta: {
        title: 'career',
      }
    },
    children: [
      {
        path: '',
        component: CareerHomeComponent,
        canActivate: [LangGuard],
        data: {
          forLanguages: ['en', 'pl', 'de'],
          group: 'career-child',
          meta: {
            description: 'career_description_meta',
            keywords: 'career_keywords_meta'
          }
        }
      },
      {
        path: ':slug',
        component: CareerDetailsComponent,
        canActivate: [LangGuard],
        data: {
          forLanguages: ['en', 'pl', 'de'],
          group: 'career-child',
          scrollTo: '#career',
          scrollListTo: '#careerOffers',
          scrollListOffsetParent: '#career',
          meta: {
            description: '',
            keywords: ''
          }
        }
      }
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class CareerRoutingModule { }

export {
  routes
};
