import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../_models/auth/User';
import {NotificationsService} from '../../../_subprojects/reservation/_services/notifications.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-nav-notifications',
  templateUrl: './nav-notifications.component.html',
  styleUrls: ['./nav-notifications.component.scss']
})
export class NavNotificationsComponent implements OnInit {

  @Input() user: User;

  notificationsQuantity$: Observable<number>;

  constructor(
    private notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {
    this.notificationsQuantity$ = this.notificationsService.notificationsQuantity$;
  }

}
