<div class="video-text-wrapper" #videoWrapper>
  <div class="video-text-cut-edges">
    <video preload="none" loop #videoEl [poster]="video.fallbackImage">
      <source [src]="video.webm" type="video/webm">
      <source [src]="video.mp4" type="video/mp4">
      <source [src]="video.ogg" type="video/ogg">
      Your browser does not support the video tag.
    </video>

    <div class="video-overlay">
      <div class="video-text-wrapper-caption">
        <div class="slide-top">
          <h4>{{header}}</h4>
          <div class="pt-deca slide-top-opacity">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
