<div *ngIf="hideField" class="input-container shuum-picker-popup-container" [ngClass]="class">
  <div
    class="form-group"
    [formGroup]="group"
    [ngClass]="
      {
        'invalid': (group?.controls[control]?.errors && (group?.controls[control]?.touched && !dp.isOpen())) || apiValidationMsg,
        'disabled': disabled
      }"
  > 
    <label
      *ngIf="fieldName"
      [for]="inputId"
      class="d-flex justify-content-center"
    >
      {{ fieldName|translate }}
      <span *ngIf="fieldNameSecondary" class="field-name-secondary">{{ fieldNameSecondary|translate }}</span>
    </label>
    <div
      class="form-input d-flex"
      [class]="isOpen ? 'active' : null"
    >
      <input
        type="text"
        [id]="inputId"
        [minDate]="minDate"
        [startDate]="startDate"
        outsideDays="collapsed"
        navigation="arrows"
        name="date"
        [placeholder]="placeholder|translate"
        [formControlName]="control"
        ngbDatepicker
        [readOnly]="true"
        (keyup)="changeValidationMsg( group?.controls[control]?.errors )"
        (blur)="onBlur( group?.controls[control]?.errors )"
        #dp="ngbDatepicker"
        (click)="toggle()"
        (closed)="onClose()"
        (ngModelChange)="onChange($event)"
      />
      <button *ngIf="showCloseBtn && group?.controls[control]?.value" type="button" class="clear-btn" (click)="clear(dp)">
        <img src="/assets/images/reservations/forms/close-forms.svg" alt="close" />
      </button>

    </div>
    <p class="validation-msg">
      <span *ngFor="let msg of validationMsg">
        {{ msg|translate }}
      </span>
      <span class="text-right d-block w-100">{{apiValidationMsg}}</span>
    </p>
  </div>
</div>
