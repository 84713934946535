import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {LayoutService} from '@services/layout.service';

@Component({
  selector: 'app-blogostan',
  templateUrl: './blogostan.component.html',
  styleUrls: ['./blogostan.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlogostanComponent implements OnInit {

  constructor(
    private layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    this.layoutService.isBannerLoaded$.next(true);
  }

}
